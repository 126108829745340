import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import Layout from 'common/CustomLayout';
import EditWinnerPage from './EditWinnerPage';
import {
  getAllRRGroups,
  getAllRRMaster,
  patchRR,
  postRRGroup,
  getAllIdeas,
  getRRById,
  deleteDoc
} from '../redux/slice';
import { useEffect, useState } from 'react';
import { history } from 'app/history';
import moment from 'moment';
import { useParams } from 'react-router-dom';

const EditWinner = () => {
  const dispatch = useDispatch();
  const {
    rrById,
    getRRByIdLoading,
    rrGroups,
    rrMaster,
    postRRGroupLoading,
    ideas
  } = useSelector((state) => state.centreStage);
  const params = useParams();
  const { id } = params;
  const [group, setGroup] = useState('');
  const [serachvalue, setSearchvalue] = useState('');
  const [IdeaSearchvalue, setideaSearchvalue] = useState('');
  const [member, setMember] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [WinnerDetails, setWinnerDetails] = useState('');
  const [showDisablePopup, setShowDisablePopup] = useState(false);

  useEffect(() => {
    handleGetRRById();
  }, [id]);

  const handleGetRRById = () => {
    dispatch(getRRById({ rr_id: id })).then(({ payload }) => {
      if (payload?.success && payload?.data) {
        setWinnerDetails(payload?.data);
      }
    });
  };

  console.log(WinnerDetails);
  useEffect(() => {
    handleGetAllRRGroups();
    handleAllRRMaster();
  }, [serachvalue]);
  useEffect(() => {
    handleAllIdeas();
  }, [IdeaSearchvalue]);

  const handleGetAllRRGroups = () => {
    let payload = { search: serachvalue };
    dispatch(getAllRRGroups(payload));
  };

  const handleAllRRMaster = () => {
    dispatch(getAllRRMaster());
  };

  const handleAllIdeas = () => {
    let payload = { search: IdeaSearchvalue };
    dispatch(getAllIdeas(payload));
  };

  const handlePostRRGroup = () => {
    let payload = { title: group };
    dispatch(postRRGroup(payload)).then(({ payload }) => {
      if (payload?.success) {
        handleGetAllRRGroups();
        setGroup('');
      }
    });
  };

  const handleDeleteDocument = (id, doc_url) => {
    dispatch(deleteDoc(id, doc_url)).then(() => {
      handleGetRRById();
    });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handlePatchRR = (values) => {
    const {
      name,
      designation,
      rr_group_id,
      rr_master_id,
      citation,
      focus_area,
      results,
      images,
      sugg_id,
      rr_date,
      url,
      team_pic_detail
    } = values;
    console.log(team_pic_detail);
    let formData = new FormData();
    formData.append('rr_id', id);
    formData.append('name', name);
    formData.append('designation', designation);
    formData.append('rr_group_id', rr_group_id);
    formData.append('rr_master_id', rr_master_id);
    formData.append('citation', citation);
    formData.append('focus_area', focus_area);
    formData.append('results', results);
    formData.append('rr_date', moment(rr_date).format('YYYY-MM-DD'));
    formData.append('url', url);
    formData.append('sugg_id', sugg_id);
    itemList.map((item) => {
      formData.append('super_squads', item.name);
    });
    images.forEach((o) => formData.append('images', o.originFileObj));
    team_pic_detail !== undefined && team_pic_detail.length
      ? team_pic_detail.forEach((o) =>
          formData.append('team_pic', o.originFileObj)
        )
      : formData.append('team_pic', null);

    // let data = { id, ...values };

    dispatch(patchRR(formData)).then(({ payload }) => {
      if (payload?.success) {
        history.push('/centre-stage/winners');
      }
    });
  };

  return (
    <Layout>
      <Spin spinning={getRRByIdLoading}>
        <EditWinnerPage
          data={rrById}
          normFile={normFile}
          onFinish={handlePatchRR}
          rrGroups={rrGroups}
          rrMaster={rrMaster}
          handlePostRRGroup={handlePostRRGroup}
          postRRGroupLoading={postRRGroupLoading}
          group={group}
          setGroup={setGroup}
          setSearchvalue={setSearchvalue}
          ideas={ideas}
          setideaSearchvalue={setideaSearchvalue}
          setMember={setMember}
          member={member}
          setItemList={setItemList}
          itemList={itemList}
          setWinnerDetails={setWinnerDetails}
          WinnerDetails={WinnerDetails}
          showDisablePopup={showDisablePopup}
          setShowDisablePopup={setShowDisablePopup}
          handleDeleteDocument={handleDeleteDocument}
        />
      </Spin>
    </Layout>
  );
};

export default EditWinner;
