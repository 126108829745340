import { useState } from 'react';
import {
  Button,
  Carousel,
  // Col,
  Image,
  Modal,
  Row,
  Space
} from 'antd';
import {
  // AimOutlined,
  CheckCircleOutlined,
  LeftOutlined,
  // NodeIndexOutlined,
  RightOutlined,
  PlayCircleOutlined
} from '@ant-design/icons';
import { Fade } from 'react-reveal';
import CustomText from 'common/CustomText';
import styles from './about_phoenix.module.less';
import data from '../../data';

const AboutPhoenixPage = () => {
  const [slide, setSlide] = useState(0);

  const [visible, setVisible] = useState(false);

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleCloseModal = () => {
    setVisible(false);
  };

  return (
    <Carousel
      effect="fade"
      arrows={true}
      className={styles.carousel_container}
      nextArrow={<RightOutlined />}
      prevArrow={<LeftOutlined />}
      autoplay
      autoplaySpeed={6000}
      afterChange={(currentSlide) => {
        setSlide(currentSlide);
      }}
    >
      {data.map((item, index) => {
        const {
          title,
          image,
          incline,
          inclineBg,
          left,
          right,
          bgColor,
          data,
          showPhoenixBird
        } = item;

        if (index < 3) {
          return (
            <div key={index} className={styles.slide1}>
              <div
                className={styles.outer_container}
                style={{ backgroundColor: bgColor }}
              >
                <div
                  className={
                    index === 0
                      ? styles.custom_inner_container
                      : styles.inner_container
                  }
                >
                  {showPhoenixBird ? (
                    <div className={styles.phoenix_bird_container}>
                      <Image
                        src="images/phoenix_bird.png"
                        preview={false}
                        className={styles.phoenix_bird}
                      />
                    </div>
                  ) : null}
                  <Space direction="vertical" size="large">
                    <div>
                      {title ? (
                        <CustomText
                          font="medium"
                          size={20}
                          color="secondary"
                          className={styles.item_title}
                        >
                          {title}
                        </CustomText>
                      ) : null}
                      {data?.length ? (
                        <>
                          {index === 2 ? (
                            <Space
                              direction="vertical"
                              size={30}
                              className="mt-5"
                            >
                              {data.map((o, i) => {
                                const { icon, description, image } = o;

                                return (
                                  <div key={i} style={{ width: '65%' }}>
                                    <Space size="middle" align="start">
                                      <Image
                                        src={image}
                                        preview={false}
                                        width={50}
                                      />
                                      <Space direction="vertical" size={0}>
                                        <Image
                                          src={icon}
                                          preview={false}
                                          width={150}
                                        />
                                        <CustomText font="bold" size={16}>
                                          {description}
                                        </CustomText>
                                      </Space>
                                    </Space>
                                  </div>
                                );
                              })}
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://tataprojects4.sharepoint.com/sites/TPLHome/_layouts/15/stream.aspx?id=%2Fsites%2FTPLHome%2FEmailerFolder%2FMVV%20and%20Values%2FTPL%5FMVV%20%283%29%2Emp4"
                                style={{
                                  float: 'right',
                                  fontSize: 18,
                                  color: '#2f80e1',
                                  fontWeight: 'bold'
                                }}
                              >
                                More on MVV
                                <PlayCircleOutlined
                                  style={{ marginLeft: 10, fontSize: 30 }}
                                />
                              </a>
                            </Space>
                          ) : (
                            <div className="mt-2">
                              {data.map((o, i) => {
                                const { icon, label, options } = o;

                                return (
                                  <div
                                    key={i}
                                    className={options?.length ? 'mt-1' : ''}
                                    style={{ width: '90%' }}
                                  >
                                    <Space direction="vertical">
                                      <Space align="start">
                                        {icon ? (
                                          <Image
                                            src={icon}
                                            preview={false}
                                            width={25}
                                          />
                                        ) : null}
                                        {options?.length ? (
                                          <CustomText
                                            size={14}
                                            className="poor-richard"
                                            style={{ fontWeight: 'bold' }}
                                            color="secondary"
                                          >
                                            {label}
                                          </CustomText>
                                        ) : (
                                          <CustomText
                                            font="bold"
                                            size={12}
                                            className={styles.opt_text}
                                          >
                                            {label}
                                          </CustomText>
                                        )}
                                      </Space>
                                      {options?.length ? (
                                        <Space direction="vertical">
                                          {options.map((opt, ind) => {
                                            return (
                                              <Fade key={ind} right>
                                                <Space align="start">
                                                  <CheckCircleOutlined
                                                    className={styles.opt_icon}
                                                  />
                                                  <CustomText
                                                    font="bold"
                                                    size={12}
                                                    className={styles.opt_text}
                                                  >
                                                    {opt}
                                                  </CustomText>
                                                </Space>
                                              </Fade>
                                            );
                                          })}
                                        </Space>
                                      ) : null}
                                    </Space>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>
                    {left ? (
                      <div>
                        <CustomText
                          size={18}
                          // className={`${styles.opt_text}`}
                          className={`${styles.item_title} poor-richard`}
                          // style={{ fontWeight: 'bold' }}
                          color="secondary"
                        >
                          {left?.title}
                        </CustomText>
                        {left?.data.map((o, i) => {
                          const { icon, label } = o;

                          return (
                            <div key={i} style={{ width: '75%' }}>
                              <Space
                                size="middle"
                                align="start"
                                className={`${styles.text_direction} mt-2`}
                              >
                                {icon ? (
                                  <Image src={icon} preview={false} size={12} />
                                ) : (
                                  <CheckCircleOutlined
                                    className={styles.opt_icon}
                                  />
                                )}
                                <CustomText
                                  size={12}
                                  font="bold"
                                  className={styles.opt_text}
                                >
                                  {label}
                                </CustomText>
                              </Space>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </Space>
                </div>
                {slide === index ? (
                  <div
                    className={
                      slide === 1 || slide === 2
                        ? styles.right_container
                        : styles.custom_right_container
                    }
                    style={{ backgroundImage: `url(${image})` }}
                  >
                    {right?.data ? (
                      <div className={styles.bg_transparent} />
                    ) : null}
                    {right ? (
                      <Fade right cascade>
                        <div className={styles.data_container}>
                          <Space direction="vertical">
                            <Space size={12}>
                              <CustomText
                                size={24}
                                color="primary"
                                className={`${styles.item_title} poor-richard`}
                              >
                                {right.title}
                              </CustomText>
                            </Space>
                            <Space direction="vertical" size={12}>
                              {right.data.map((o, i) => {
                                const { icon, label, description } = o;

                                return (
                                  <div key={i}>
                                    <Space align={icon ? 'center' : 'start'}>
                                      {icon ? (
                                        <Image
                                          src={icon}
                                          preview={false}
                                          size={12}
                                        />
                                      ) : (
                                        <CheckCircleOutlined
                                          className={styles.opt_icon}
                                        />
                                      )}
                                      <Space direction="vertical" size={0}>
                                        <CustomText
                                          font="bold"
                                          size={12}
                                          className={styles.opt_text}
                                        >
                                          {label}
                                        </CustomText>
                                        <CustomText
                                          size={12}
                                          className={styles.opt_text}
                                        >
                                          {description}
                                        </CustomText>
                                      </Space>
                                    </Space>
                                  </div>
                                );
                              })}
                            </Space>
                          </Space>
                        </div>
                      </Fade>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          );
        }

        return (
          <div key={index} className={styles.container}>
            <div
              className={styles.item_container}
              style={
                index === 5
                  ? {
                      backgroundImage: `url(${image})`,
                      // backgroundSize: 'cover'
                      backgroundSize: '100% 120%',
                      backgroundColor: '#a6bccf'
                    }
                  : index === 6
                  ? {
                      backgroundImage: `url(${image})`,
                      backgroundSize: '100% 100%',
                      backgroundColor: '#050566'
                    }
                  : { backgroundImage: `url(${image})` }
              }
            >
              {index === 6 ? (
                <>
                  <Button
                    type="primary"
                    title="Watch Video"
                    style={{
                      fontSize: '16px', // Adjust the size as per your requirements
                      color: 'white', // Optional: Set the color
                      margin: '22% 0% 0% 45%' // Optional: Adjust the margin as needed
                    }}
                    onClick={handleOpenModal}
                  >
                    Watch Video <PlayCircleOutlined />
                  </Button>
                  <Modal
                    open={visible}
                    onCancel={handleCloseModal}
                    footer={null}
                    width={800} // Adjust width as per your video size
                  >
                    <video
                      controls
                      style={{ width: '100%', marginTop: '15px' }}
                    >
                      <source src="/Phoenix_video.mp4" type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Modal>
                </>
              ) : (
                ''
              )}
              <div
              //  className={styles.bg_transparent}
              />
              {slide === index ? (
                <Row className={styles.item_row_container}>
                  <div
                    className={`${styles.incline} ${styles[incline]}`}
                    style={{ backgroundColor: inclineBg }}
                  />
                  {/* <Col sm={12} className={styles.left_container}>
                    <Space direction="vertical" size={15}>
                      <CustomText
                        font="medium"
                        size={20}
                        color="secondary"
                        className={styles.item_title}
                      >
                        {title}
                      </CustomText>
                      <Space direction="vertical" size={10}>
                        <Space>
                          <AimOutlined className={styles.icon} />
                          <CustomText
                            size={20}
                            color="secondary"
                            className={`${styles.item_title} poor-richard`}
                          >
                            {left.title}
                          </CustomText>
                        </Space>
                        <Space direction="vertical">
                          {left.data.map((o, i) => {
                            const { label, options } = o;

                            return (
                              <div key={i}>
                                <Space align="start">
                                  <CheckCircleOutlined
                                    className={styles.opt_icon}
                                  />
                                  <CustomText
                                    font="bold"
                                    size={12}
                                    className={styles.opt_text}
                                  >
                                    {label}
                                  </CustomText>
                                </Space>
                                {options?.length ? (
                                  <div className="ml-3 mt-1">
                                    <Space direction="vertical">
                                      {options.map((opt, ind) => {
                                        return (
                                          <Space key={ind} align="start">
                                            <RightOutlined
                                              className={styles.opt_icon}
                                            />
                                            <CustomText
                                              font="bold"
                                              size={12}
                                              className={styles.opt_text}
                                            >
                                              {opt}
                                            </CustomText>
                                          </Space>
                                        );
                                      })}
                                    </Space>
                                  </div>
                                ) : null}
                              </div>
                            );
                          })}
                        </Space>
                      </Space>
                    </Space>
                  </Col> */}
                  {/* <Col sm={24} className={styles.right_container}>
                    <Fade right cascade>
                      <Space direction="vertical" size={15}>
                        <CustomText
                          font="medium"
                          size={20}
                          color="secondary"
                          className={styles.right_title}
                          style={{ visibility: 'hidden' }}
                        >
                          {title}
                        </CustomText>
                        <Space direction="vertical" size={10}>
                          <Space>
                            <NodeIndexOutlined className={styles.icon} />
                            <CustomText
                              size={20}
                              color="primary"
                              className={`${styles.item_title} poor-richard`}
                            >
                              {right.title}
                            </CustomText>
                          </Space>
                          <Space direction="vertical">
                            {right.data.map((o, i) => {
                              const { label, options } = o;

                              return (
                                <div key={i}>
                                  <Space align="start">
                                    <CheckCircleOutlined
                                      className={styles.opt_icon}
                                    />
                                    <CustomText
                                      font="bold"
                                      color="white"
                                      size={12}
                                      className={styles.opt_text}
                                    >
                                      {label}
                                    </CustomText>
                                  </Space>
                                  {options?.length ? (
                                    <div className="ml-3 mt-1">
                                      <Space direction="vertical">
                                        {options.map((opt, ind) => {
                                          return (
                                            <Fade key={ind} right cascade>
                                              <Space align="start">
                                                <RightOutlined
                                                  className={styles.opt_icon}
                                                />
                                                <CustomText
                                                  font="bold"
                                                  color="white"
                                                  size={12}
                                                  className={styles.opt_text}
                                                >
                                                  {opt}
                                                </CustomText>
                                              </Space>
                                            </Fade>
                                          );
                                        })}
                                      </Space>
                                    </div>
                                  ) : null}
                                </div>
                              );
                            })}
                          </Space>
                        </Space>
                      </Space>
                    </Fade>
                  </Col> */}
                </Row>
              ) : null}
            </div>
          </div>
        );
      })}
    </Carousel>
  );
};

export default AboutPhoenixPage;
