import React, { useCallback } from 'react';
import {
  // ArrowRightOutlined,
  // CalendarOutlined,
  // CommentOutlined,
  // DownOutlined,
  LikeFilled,
  LikeOutlined,
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import {
  // Carousel,
  Col,
  Image,
  Row,
  Space,
  // Button,
  // Dropdown,
  // Menu,
  Divider,
  Skeleton,
  DatePicker,
  Popconfirm
} from 'antd';
import CustomButton from 'common/CustomButton';
import CustomText from 'common/CustomText';
import styles from './winners.module.less';
import applauds from '../../images/winners/applauds.png';
import award from '../../images/winners/award.png';
import category from '../../images/winners/category.png';
import idea from '../../images/winners/idea.png';
import initiative from '../../images/winners/initiative.png';
import spirit from '../../images/winners/spirit.png';
import spot from '../../images/winners/spot.png';
import trailblazer from '../../images/winners/trailblazer.png';
import banner from '../../images/winners/winner_bg.png';
import group_image from '../../images/winners/group.png';
import banner1 from '../../images/centre-stage-banner.jpg';
// import moment from 'moment';
// import { startCase } from 'lodash';
import { history } from 'app/history';
import UsersListModal from 'common/UsersListModal';
import { getLoggedInUser } from 'utilities/helpers';
import InfiniteScroll from 'react-infinite-scroll-component';
import dayjs from 'dayjs';
import { apiUrl } from 'utilities/apiClient';
// import Confetti from 'react-confetti';

const monthFormat = 'MMM YYYY';

export const images = {
  applauds,
  award,
  category,
  idea,
  initiative,
  spirit,
  spot,
  trailblazer,
  group_image
};

// const menu = (
//   <Menu
//     items={[
//       {
//         key: '1',
//         label: (
//           <a
//             target="_blank"
//             rel="noopener noreferrer"
//             href="https://www.antgroup.com"
//           >
//             1st menu item
//           </a>
//         )
//       },
//       {
//         key: '2',
//         label: (
//           <a
//             target="_blank"
//             rel="noopener noreferrer"
//             href="https://www.aliyun.com"
//           >
//             2nd menu item (disabled)
//           </a>
//         ),
//         disabled: true
//       },
//       {
//         key: '3',
//         label: (
//           <a
//             target="_blank"
//             rel="noopener noreferrer"
//             href="https://www.luohanacademy.com"
//           >
//             3rd menu item (disabled)
//           </a>
//         ),
//         disabled: true
//       },
//       {
//         key: '4',
//         danger: true,
//         label: 'a danger item'
//       }
//     ]}
//   />
// );

const WinnersPage = ({
  data,
  // groupData,
  showLikes,
  setShowLikes,
  likes,
  setLikes,
  handlePostRRLike,
  handleDeleteRRLike,
  setPage,
  pagination,
  page,
  setDateValue,
  showDisablePopup,
  setShowDisablePopup,
  handleDeleteWinner
}) => {
  const { user_details } = getLoggedInUser();
  const isAdmin = user_details?.role === 'admin';
  const A = data.filter((o) => o.is_active && o?.rr_master?.type === 'A');
  const B = data.filter((o) => o.is_active && o?.rr_master?.type === 'B');
  const C = data.filter((o) => o.is_active && o?.rr_master?.type === 'C');
  const D = data.filter((o) => o.is_active && o?.rr_master?.type === 'D');
  // const [value, setValue] = useState(moment().format('MMM YYYY'));

  const visibility = useCallback((record) => {
    return showDisablePopup[record];
  }, showDisablePopup);

  return (
    <div className={styles.container}>
      <div
        className={styles.item_container}
        style={{ backgroundImage: `url(${banner1})` }}
      >
        <Space direction="vertical" size={40}>
          <div className={styles.header_container}>
            <CustomText font="bold" size={40} color="primary">
              Centre Stage
            </CustomText>
            <CustomText
              font="medium"
              size={16}
              color="white"
              className={styles.description}
            >
              Phoenix Rewards
            </CustomText>
          </div>
          <CustomText size={20} color="white" align="center">
            Celebrating people and ideas shaping the future of TPL
          </CustomText>
        </Space>
      </div>
      {/* <div className="mt-3">
        <Carousel className={styles.banner_carousel_container}>
          {Array.from({ length: 1 }).map((_, index) => {
            return (
              <Image
                key={index}
                preview={false}
                src={banner}
                className={styles.banner}
              />
            );
          })}
        </Carousel>
      </div> */}

      <div className={styles.banner_space}>
        <Row
          gutter={[0, 10]}
          align="middle"
          justify="space-between"
          className={styles.title_container}
        >
          <Col sm={12} xs={24}>
            {/* <Confetti
              className={styles.confetti}
              // numberOfPieces="1000"
              height="90"
              width="850"
            /> */}
            {/* <CustomText color="secondary">
              <span className={styles.award_style}>
                And the award goes to...{' '}
              </span>
            </CustomText> */}
          </Col>
          <Col sm={12} xs={24}>
            <Row gutter={[10, 10]}>
              <Col sm={8} xs={24}>
                {isAdmin ? (
                  <CustomButton
                    type="primary"
                    className="w-100"
                    onClick={() => history.push('/centre-stage/winners/create')}
                  >
                    <PlusCircleOutlined style={{ fontSize: 15 }} />
                    Add Winner
                  </CustomButton>
                ) : null}
              </Col>
              <Col sm={8} xs={12}>
                <CustomButton
                  type="primary"
                  className="w-100"
                  onClick={() => history.push('/centre-stage')}
                >
                  Award Details
                </CustomButton>
              </Col>
              <Col sm={8} xs={12}>
                <DatePicker
                  defaultValue={dayjs('June 2024')}
                  format={monthFormat}
                  picker="month"
                  onChange={(x) => {
                    setDateValue(x);
                  }}
                  // value={value}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        {/* {data.map((group, index) => {
          return ( */}
        <div className={styles.banner_space}>
          {/* <Space size="middle" align="center">
                <Image
                  src={
                    images[(data?.rr_master?.Image || '').replace('.png', '')]
                  }
                  className={styles.image1}
                  preview={false}
                /> */}
          {/* <CustomText font="bold" size={26} color="secondary">
                  {startCase(group)}
                </CustomText> */}
          {/* </Space> */}

          <InfiniteScroll
            dataLength={data?.length}
            next={() =>
              setPage(pagination?.next_page ? pagination?.next_page : page)
            }
            hasMore={data?.length < pagination?.total_records}
            loader={
              <Skeleton
                avatar
                paragraph={{
                  rows: 1
                }}
                active
              />
            }
            // endMessage={<Divider plain>You have reached the end 🤐</Divider>}
            scrollableTarget="scrollableDiv"
            style={{ overflow: 'hidden' }}
          >
            <Row gutter={[20, 20]} wrap>
              {D?.map((item, index) => {
                const {
                  id,
                  // designation,
                  rr_images,
                  Likes,
                  is_like,
                  like_count,
                  // comment_count,
                  name,
                  rr_master
                } = item;
                const img_fil = rr_images?.filter((o) => o?.doc_url !== null);
                const docUrl = img_fil?.[0]?.doc_url;
                const rrImagefileName = docUrl.split('/').pop();

                const likesDisplayText = `${
                  Likes.length
                    ? `${
                        Likes[0]?.full_name?.length > 10
                          ? Likes[0]?.full_name.substring(0, 10) + '...'
                          : Likes[0]?.full_name
                      }`
                    : ''
                }${
                  like_count
                    ? like_count - 1 > 0
                      ? ` and ${like_count - 1} others`
                      : ''
                    : '0 likes'
                }`;

                return (
                  <>
                    <Col key={index} lg={8} md={24}>
                      <div
                        className={styles.card_container}
                        onClick={() => {}}
                        style={{
                          backgroundImage: `url(${banner})`,
                          backgroundSize: '100%',
                          backgroundRepeat: 'no-repeat'
                        }}
                      >
                        <Space
                          direction="vertical"
                          className="w-100"
                          style={{ marginTop: '-13px' }}
                        >
                          <Row gutter={24}>
                            <Col span={isAdmin ? 18 : 24}>
                              <Space size={0} align="center">
                                <Image
                                  src={
                                    images[
                                      (rr_master?.Image || '').replace(
                                        '.png',
                                        ''
                                      )
                                    ]
                                  }
                                  className={styles.image}
                                  preview={false}
                                />
                                <CustomText
                                  font="bold"
                                  size={16}
                                  color="secondary"
                                >
                                  {rr_master?.title || ''}
                                </CustomText>
                              </Space>
                            </Col>

                            {isAdmin ? (
                              <>
                                <Col span={2} style={{ paddingTop: '12px' }}>
                                  {' '}
                                  <a href={`winners/edit/${id}`}>
                                    <EditOutlined />
                                  </a>
                                </Col>
                                <Col span={2} style={{ paddingTop: '12px' }}>
                                  <Popconfirm
                                    title="Are you sure you want to delete this suggestion?"
                                    visible={visibility(rr_master?.id)}
                                    onConfirm={() => {
                                      handleDeleteWinner({
                                        id: id
                                      });
                                    }}
                                    onCancel={() => {
                                      setShowDisablePopup({});
                                    }}
                                    okText="Delete"
                                    cancelText="No"
                                    okButtonProps={{
                                      type: 'danger'
                                    }}
                                  >
                                    <DeleteOutlined style={{ color: 'red' }} />
                                  </Popconfirm>
                                </Col>
                              </>
                            ) : (
                              ''
                            )}
                          </Row>
                          <div className={styles.display_pic_container}>
                            <Image
                              // src={img_fil?.[0]?.doc_url}
                              src={`${apiUrl}/fetch-file?fileName=${rrImagefileName}`}
                              preview={false}
                            />
                            <div className={styles.user_details_container}>
                              <Space direction="vertical">
                                <CustomText
                                  font="bold"
                                  size={16}
                                  color="secondary"
                                >
                                  {name || ''}
                                </CustomText>
                                {/* <CustomText font="medium" size={12}>
                                  {designation === 'undefined'
                                    ? ''
                                    : designation || ''}
                                </CustomText> */}
                              </Space>
                            </div>
                          </div>
                          <Divider className={`mt-4 mb-0 ${styles.divider}`} />
                          <div className={`${styles.footer} df-jb-ac`}>
                            <Space
                              align="center"
                              split={<Divider type="vertical" />}
                              size={0}
                            >
                              <Space>
                                <span
                                  className={`cursor-pointer ${styles.icon}`}
                                  onClick={() => {
                                    if (is_like) {
                                      handleDeleteRRLike(id);
                                    } else {
                                      handlePostRRLike(id);
                                    }
                                  }}
                                >
                                  {is_like ? <LikeFilled /> : <LikeOutlined />}
                                </span>
                                <CustomText
                                  font="medium"
                                  color="primary"
                                  className={`${
                                    like_count ? ` cursor-pointer` : ''
                                  }`}
                                  onClick={() => {
                                    if (like_count) {
                                      setShowLikes(true);
                                      setLikes(Likes);
                                    }
                                  }}
                                >
                                  {likesDisplayText}
                                </CustomText>
                              </Space>
                            </Space>
                            <CustomText
                              font="medium"
                              color="secondary"
                              onClick={() =>
                                history.push(`/centre-stage/winners/${id}`)
                              }
                              className="cursor-pointer"
                            >
                              More
                            </CustomText>
                          </div>
                        </Space>
                      </div>
                    </Col>
                  </>
                );
              })}
              {C?.map((item, index) => {
                const {
                  id,
                  // designation,
                  rr_images,
                  Likes,
                  is_like,
                  like_count,
                  // comment_count,
                  rr_master,
                  name
                } = item;
                const img_fil = rr_images?.filter(
                  (o) => o?.team_pic_doc_url !== null
                );
                const docUrl = img_fil?.[0]?.team_pic_doc_url;
                const rrImagefileName = docUrl.split('/').pop();

                const likesDisplayText = `${
                  Likes.length
                    ? `${
                        Likes[0]?.full_name?.length > 10
                          ? Likes[0]?.full_name.substring(0, 8) + '...'
                          : Likes[0]?.full_name
                      }`
                    : ''
                }${
                  like_count
                    ? like_count - 1 > 0
                      ? ` and ${like_count - 1} others`
                      : ''
                    : '0 likes'
                }`;

                return (
                  <>
                    <Col key={index} lg={8} md={24}>
                      <div
                        className={styles.card_container}
                        onClick={() => {}}
                        style={{
                          backgroundImage: `url(${banner})`,
                          backgroundSize: '100%',
                          backgroundRepeat: 'no-repeat'
                        }}
                      >
                        <Space
                          direction="vertical"
                          className="w-100"
                          style={{ marginTop: '-13px' }}
                        >
                          <Row gutter={24}>
                            <Col span={isAdmin ? 18 : 24}>
                              <Space size={0} align="center">
                                <Image
                                  src={
                                    images[
                                      (rr_master?.Image || '').replace(
                                        '.png',
                                        ''
                                      )
                                    ]
                                  }
                                  className={styles.image}
                                  preview={false}
                                />
                                <CustomText
                                  font="bold"
                                  size={16}
                                  color="secondary"
                                >
                                  {rr_master?.title || ''}
                                </CustomText>
                              </Space>
                            </Col>

                            {isAdmin ? (
                              <>
                                <Col span={2} style={{ paddingTop: '12px' }}>
                                  {' '}
                                  <a href={`winners/edit/${id}`}>
                                    <EditOutlined />
                                  </a>
                                </Col>
                                <Col span={2} style={{ paddingTop: '12px' }}>
                                  <Popconfirm
                                    title="Are you sure you want to delete this suggestion?"
                                    visible={visibility(rr_master?.id)}
                                    onConfirm={() => {
                                      handleDeleteWinner({
                                        id: id
                                      });
                                    }}
                                    onCancel={() => {
                                      setShowDisablePopup({});
                                    }}
                                    okText="Delete"
                                    cancelText="No"
                                    okButtonProps={{
                                      type: 'danger'
                                    }}
                                  >
                                    <DeleteOutlined
                                      style={{
                                        color: 'red'
                                      }}
                                    />
                                  </Popconfirm>
                                </Col>
                              </>
                            ) : (
                              ''
                            )}
                          </Row>
                          <div className={styles.display_pic_container}>
                            <Image
                              // src={img_fil?.[0]?.team_pic_doc_url}
                              src={`${apiUrl}/fetch-file?fileName=${rrImagefileName}`}
                              preview={false}
                              // style={{ borderRadius: '10px' }}
                            />
                            <div className={styles.user_details_container}>
                              <Space direction="vertical">
                                <CustomText
                                  font="bold"
                                  size={12}
                                  color="secondary"
                                >
                                  {name || ''}
                                </CustomText>
                              </Space>
                            </div>
                          </div>
                          <Divider className={`mt-4 mb-0 ${styles.divider}`} />
                          <div className={`${styles.footer} df-jb-ac`}>
                            <Space
                              align="center"
                              split={<Divider type="vertical" />}
                              size={0}
                            >
                              <Space>
                                <span
                                  className={`cursor-pointer ${styles.icon}`}
                                  onClick={() => {
                                    if (is_like) {
                                      handleDeleteRRLike(id);
                                    } else {
                                      handlePostRRLike(id);
                                    }
                                  }}
                                >
                                  {is_like ? <LikeFilled /> : <LikeOutlined />}
                                </span>
                                <CustomText
                                  font="medium"
                                  color="primary"
                                  className={`${
                                    like_count ? ` cursor-pointer` : ''
                                  }`}
                                  onClick={() => {
                                    if (like_count) {
                                      setShowLikes(true);
                                      setLikes(Likes);
                                    }
                                  }}
                                >
                                  {likesDisplayText}
                                </CustomText>
                              </Space>
                            </Space>
                            <CustomText
                              font="medium"
                              color="secondary"
                              onClick={() =>
                                history.push(`/centre-stage/winners/${id}`)
                              }
                              className="cursor-pointer"
                            >
                              More
                            </CustomText>
                          </div>
                        </Space>
                      </div>
                    </Col>
                  </>
                );
              })}
              {A?.map((item, index) => {
                const {
                  id,
                  name,
                  // designation,
                  rr_images,
                  Likes,
                  is_like,
                  like_count,
                  // comment_count,
                  rr_master
                } = item;

                const img_fil = rr_images?.filter((o) => o?.doc_url !== null);
                const docUrl = img_fil?.[0]?.doc_url;
                const rrImagefileName = docUrl.split('/').pop();

                const likesDisplayText = `${
                  Likes.length
                    ? `${
                        Likes[0]?.full_name?.length > 10
                          ? Likes[0]?.full_name.substring(0, 10) + '...'
                          : Likes[0]?.full_name
                      }`
                    : ''
                }${
                  like_count
                    ? like_count - 1 > 0
                      ? ` and ${like_count - 1} others`
                      : ''
                    : '0 likes'
                }`;
                //rr_master.type === 'A'
                return (
                  <Col key={index} lg={8} md={24}>
                    <div
                      className={styles.card_container}
                      onClick={() => {}}
                      style={{
                        backgroundImage: `url(${banner})`,
                        backgroundSize: '100%',
                        backgroundRepeat: 'no-repeat'
                      }}
                    >
                      <Space
                        direction="vertical"
                        className="w-100"
                        style={{ marginTop: '-13px' }}
                      >
                        <Row gutter={24}>
                          <Col span={isAdmin ? 18 : 24}>
                            <Space size={0} align="center">
                              <Image
                                src={
                                  images[
                                    (rr_master?.Image || '').replace('.png', '')
                                  ]
                                }
                                className={styles.image}
                                preview={false}
                              />
                              <CustomText
                                font="bold"
                                size={16}
                                color="secondary"
                              >
                                {rr_master?.title || ''}
                              </CustomText>
                            </Space>
                          </Col>

                          {isAdmin ? (
                            <>
                              <Col span={2} style={{ paddingTop: '12px' }}>
                                {' '}
                                <a href={`winners/edit/${id}`}>
                                  <EditOutlined />
                                </a>
                              </Col>
                              <Col span={2} style={{ paddingTop: '12px' }}>
                                <Popconfirm
                                  title="Are you sure you want to delete this suggestion?"
                                  visible={visibility(rr_master?.id)}
                                  onConfirm={() => {
                                    handleDeleteWinner({
                                      id: id
                                    });
                                  }}
                                  onCancel={() => {
                                    setShowDisablePopup({});
                                  }}
                                  okText="Delete"
                                  cancelText="No"
                                  okButtonProps={{
                                    type: 'danger'
                                  }}
                                >
                                  <DeleteOutlined style={{ color: 'red' }} />
                                </Popconfirm>
                              </Col>
                            </>
                          ) : (
                            ''
                          )}
                        </Row>
                        <div className={styles.display_pic_container}>
                          <Image
                            // src={rr_images?.[0]?.doc_url}
                            src={`${apiUrl}/fetch-file?fileName=${rrImagefileName}`}
                            preview={false}
                            // style={{ borderRadius: '10px' }}
                          />
                          <div className={styles.user_details_container}>
                            <Space direction="vertical">
                              <CustomText
                                font="bold"
                                size={12}
                                color="secondary"
                              >
                                {name || ''}
                              </CustomText>
                              {/* <CustomText font="medium" size={12}>
                                  {designation === 'undefined'
                                    ? ''
                                    : designation || ''}
                                </CustomText> */}
                            </Space>
                          </div>
                        </div>
                        <Divider className={`mt-4 mb-0 ${styles.divider}`} />
                        <div className={`${styles.footer} df-jb-ac`}>
                          <Space
                            align="center"
                            split={<Divider type="vertical" />}
                            size={0}
                          >
                            <Space size={41}>
                              <Space>
                                <span
                                  className={`cursor-pointer ${styles.icon}`}
                                  onClick={() => {
                                    if (is_like) {
                                      handleDeleteRRLike(id);
                                    } else {
                                      handlePostRRLike(id);
                                    }
                                  }}
                                >
                                  {is_like ? <LikeFilled /> : <LikeOutlined />}
                                </span>
                                <CustomText
                                  font="medium"
                                  color="primary"
                                  className={`${
                                    like_count ? ` cursor-pointer` : ''
                                  }`}
                                  onClick={() => {
                                    if (like_count) {
                                      setShowLikes(true);
                                      setLikes(Likes);
                                    }
                                  }}
                                >
                                  {likesDisplayText}
                                </CustomText>
                              </Space>
                            </Space>
                            {/* {comment_count > 0 ? (
                              <a href={`/centre-stage/winners/${id}#comments`}>
                                <Space>
                                  <span
                                    className={`cursor-pointer ${styles.icon}`}
                                  >
                                    <CommentOutlined />
                                  </span>
                                  <CustomText
                                    font="medium"
                                    color="primary"
                                  >{`${comment_count}`}</CustomText>
                                </Space>
                              </a>
                            ) : null} */}
                          </Space>
                          <CustomText
                            font="medium"
                            color="secondary"
                            onClick={() =>
                              history.push(`/centre-stage/winners/${id}`)
                            }
                            className="cursor-pointer"
                          >
                            More
                          </CustomText>
                        </div>
                      </Space>
                    </div>
                  </Col>
                );
                // : (
                //   ''
                // );
              })}
              {B?.map((item, index) => {
                const {
                  id,
                  // designation,
                  rr_images,
                  Likes,
                  is_like,
                  like_count,
                  // comment_count,
                  rr_master
                } = item;
                const img_fil = rr_images?.filter((o) => o?.doc_url !== null);
                const docUrl = img_fil?.[0]?.doc_url;
                const rrImagefileName = docUrl.split('/').pop();
                // console.log('rrImagefileName', rrImagefileName);

                const likesDisplayText = `${
                  Likes.length
                    ? `${
                        Likes[0]?.full_name?.length > 10
                          ? Likes[0]?.full_name.substring(0, 10) + '...'
                          : Likes[0]?.full_name
                      }`
                    : ''
                }${
                  like_count
                    ? like_count - 1 > 0
                      ? ` and ${like_count - 1} others`
                      : ''
                    : '0 likes'
                }`;

                return (
                  <>
                    <Col key={index} lg={8} md={24}>
                      <div
                        className={styles.card_container}
                        onClick={() => {}}
                        style={{
                          backgroundImage: `url(${banner})`,
                          backgroundSize: '100%',
                          backgroundRepeat: 'no-repeat'
                        }}
                      >
                        <Space
                          direction="vertical"
                          className="w-100"
                          style={{ marginTop: '-13px' }}
                        >
                          <Row gutter={24}>
                            <Col span={isAdmin ? 18 : 24}>
                              <Space size={0} align="center">
                                <Image
                                  src={
                                    images[
                                      (rr_master?.Image || '').replace(
                                        '.png',
                                        ''
                                      )
                                    ]
                                  }
                                  className={styles.image}
                                  preview={false}
                                />
                                <CustomText
                                  font="bold"
                                  size={16}
                                  color="secondary"
                                >
                                  {rr_master?.title || ''}
                                </CustomText>
                              </Space>
                            </Col>
                            {isAdmin ? (
                              <>
                                <Col span={2} style={{ paddingTop: '12px' }}>
                                  {' '}
                                  <a href={`winners/edit/${id}`}>
                                    <EditOutlined />
                                  </a>
                                </Col>
                                <Col span={2} style={{ paddingTop: '12px' }}>
                                  <Popconfirm
                                    title="Are you sure you want to delete this suggestion?"
                                    visible={visibility(rr_master?.id)}
                                    onConfirm={() => {
                                      handleDeleteWinner({
                                        id: id
                                      });
                                    }}
                                    onCancel={() => {
                                      setShowDisablePopup({});
                                    }}
                                    okText="Delete"
                                    cancelText="No"
                                    okButtonProps={{
                                      type: 'danger'
                                    }}
                                  >
                                    <DeleteOutlined
                                      style={{
                                        color: 'red'
                                      }}
                                    />{' '}
                                  </Popconfirm>
                                </Col>
                              </>
                            ) : (
                              ''
                            )}
                          </Row>
                          <div className={styles.display_pic_container}>
                            <Image
                              src={`${apiUrl}/fetch-file?fileName=${rrImagefileName}`}
                              preview={false}
                              // style={{ borderRadius: '10px' }}
                            />
                            {/* <div className={styles.user_details_container}>
                            <Space direction="vertical"> */}
                            {/* <CustomText
                                  font="bold"
                                  size={18}
                                  color="secondary"
                                >
                                  {name || ''}
                                </CustomText> */}
                            {/* </Space>
                          </div> */}
                          </div>
                          <Divider className={`mt-4 mb-0 ${styles.divider}`} />
                          <div className={`${styles.footer} df-jb-ac`}>
                            <Space
                              align="center"
                              split={<Divider type="vertical" />}
                              size={0}
                            >
                              <Space>
                                <span
                                  className={`cursor-pointer ${styles.icon}`}
                                  onClick={() => {
                                    if (is_like) {
                                      handleDeleteRRLike(id);
                                    } else {
                                      handlePostRRLike(id);
                                    }
                                  }}
                                >
                                  {is_like ? <LikeFilled /> : <LikeOutlined />}
                                </span>
                                <CustomText
                                  font="medium"
                                  color="primary"
                                  className={`${
                                    like_count ? ` cursor-pointer` : ''
                                  }`}
                                  onClick={() => {
                                    if (like_count) {
                                      setShowLikes(true);
                                      setLikes(Likes);
                                    }
                                  }}
                                >
                                  {likesDisplayText}
                                </CustomText>
                              </Space>
                            </Space>
                            <CustomText
                              font="medium"
                              color="secondary"
                              onClick={() =>
                                history.push(`/centre-stage/winners/${id}`)
                              }
                              className="cursor-pointer"
                            >
                              More
                            </CustomText>
                          </div>
                        </Space>
                      </div>
                    </Col>
                  </>
                );
              })}
            </Row>
            <br />
          </InfiniteScroll>
        </div>
        {/* );
        })} */}
        {/* {Object.keys(groupData).map((group, index) => {
          console.log(group);
          return (
            <div key={index} className="mt-4">
              <Space size="middle" align="center">
                <Image
                  src={images.group_image}
                  className={styles.image1}
                  preview={false}
                />
                <CustomText font="bold" size={26} color="secondary">
                  {startCase(group)}
                </CustomText>
              </Space>
              <Row gutter={[20, 20]} wrap>
                {groupData?.[group].map((item) => {
                  const {
                    id,
                    name,
                    designation,
                    rr_images,
                    Likes,
                    is_like,
                    like_count,
                    comment_count
                    // rr_master
                  } = item;

                  const likesDisplayText = `${
                    Likes.length
                      ? `${
                          Likes[0]?.full_name?.length > 10
                            ? Likes[0]?.full_name.substring(0, 10) + '...'
                            : Likes[0]?.full_name
                        }`
                      : ''
                  }${
                    like_count
                      ? like_count - 1 > 0
                        ? ` and ${like_count - 1} others`
                        : ''
                      : '0 likes'
                  }`;

                  return (
                    <Col key={id} lg={8} md={24}>
                      <div
                        className={styles.card_container}
                        onClick={() => {}}
                        style={{
                          backgroundImage: `url(${banner})`,
                          backgroundSize: '100%',
                          backgroundRepeat: 'no-repeat'
                        }}
                      >
                        <Space
                          direction="vertical"
                          className="w-100"
                          style={{ marginTop: '-6px' }}
                        >
                          <Space size="middle" align="center">
                            <Image
                              src={images.group_image}
                              className={styles.image}
                              preview={false}
                            />
                            <CustomText font="bold" size={16} color="secondary">
                              {group || ''}
                            </CustomText>
                          </Space>
                          <div className={styles.display_pic_container}>
                            <Image
                              src={rr_images?.[0]?.doc_url}
                              preview={false}
                            />
                            <div className={styles.user_details_container}>
                              <Space direction="vertical">
                                <CustomText
                                  font="bold"
                                  size={18}
                                  color="secondary"
                                >
                                  {name || ''}
                                </CustomText>
                                <CustomText font="medium" size={12}>
                                  {designation || ''}
                                </CustomText>
                              </Space>
                            </div>
                          </div>
                          <Divider className="mt-4 mb-0" />
                          <div className={`${styles.footer} df-jb-ac`}>
                            <Space
                              align="center"
                              split={<Divider type="vertical" />}
                              size={0}
                            >
                              <Space>
                                <span
                                  className={`cursor-pointer ${styles.icon}`}
                                  onClick={() => {
                                    if (is_like) {
                                      handleDeleteRRLike(id);
                                    } else {
                                      handlePostRRLike(id);
                                    }
                                  }}
                                >
                                  {is_like ? <LikeFilled /> : <LikeOutlined />}
                                </span>
                                <CustomText
                                  font="medium"
                                  color="primary"
                                  className={`${
                                    like_count ? ` cursor-pointer` : ''
                                  }`}
                                  onClick={() => {
                                    if (like_count) {
                                      setShowLikes(true);
                                      setLikes(Likes);
                                    }
                                  }}
                                >
                                  {likesDisplayText}
                                </CustomText>
                              </Space>
                              {comment_count > 0 ? (
                                <a
                                  href={`/centre-stage/winners/${id}#comments`}
                                >
                                  <Space>
                                    <span
                                      className={`cursor-pointer ${styles.icon}`}
                                    >
                                      <CommentOutlined />
                                    </span>
                                    <CustomText
                                      font="medium"
                                      color="primary"
                                    >{`${comment_count} Comment(s)`}</CustomText>
                                  </Space>
                                </a>
                              ) : null}
                            </Space>
                            <CustomText
                              font="medium"
                              color="secondary"
                              onClick={() =>
                                history.push(`/centre-stage/winners/${id}`)
                              }
                              className="cursor-pointer"
                            >
                              View more
                            </CustomText>
                          </div>
                        </Space>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
          );
        })} */}
      </div>
      {showLikes ? (
        <UsersListModal
          open={showLikes}
          title="Likes"
          onCancel={() => setShowLikes(false)}
          data={likes}
        />
      ) : null}
    </div>
  );
};

export default WinnersPage;
