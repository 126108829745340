import { Space } from 'antd';
import CustomCard from 'common/CustomCard';
import CustomText from 'common/CustomText';
import CustomButton from 'common/CustomButton';
import UserMenu from '../UserMenu';
import { PlusCircleOutlined } from '@ant-design/icons';
import LocalStorage from 'utilities/localStorage';
import { logout } from 'features/login/redux/slice';
import { useMsal } from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { history } from 'app/history';

const UserDetails = () => {
  const user_details = LocalStorage.getItem('phoenix_login_user');
  const { instance } = useMsal();
  const dispatch = useDispatch();

  const signOutClickHandler = () => {
    instance.logoutRedirect({
      account: LocalStorage.homeAccountId,
      postLogoutRedirectUri: '/',
      mainWindowRedirectUri: '/',
      onRedirectNavigate: () => {
        dispatch(logout());
        return true;
      }
    });
  };

  return (
    <CustomCard topBorder={true} className="p-1 px-2">
      <Space size={12} align="center">
        <UserMenu />
        <Space direction="vertical" size={0}>
          <CustomText font="bold" size={15}>
            {user_details?.user_details?.fullname}
          </CustomText>
          <CustomText color={3}>
            {user_details?.user_details?.email
              ? '@' +
                user_details?.user_details?.email.slice(
                  0,
                  user_details?.user_details?.email.indexOf('@')
                )
              : ''}
          </CustomText>
          <Space>
            <CustomText
              font="medium"
              size={12}
              color="primary"
              className="cursor-pointer"
              onClick={signOutClickHandler}
            >
              Logout
            </CustomText>
            {user_details?.user_details?.role === 'admin' ? (
              <CustomText
                font="medium"
                size={12}
                color="primary"
                className="cursor-pointer"
                onClick={() => history.push('/adminconsole')}
              >
                Manage
              </CustomText>
            ) : (
              ''
            )}
          </Space>
        </Space>
      </Space>
      <CustomButton
        type="primary"
        onClick={() => history.push('/suggest-idea')}
        className="mt-1 w-100"
      >
        <PlusCircleOutlined style={{ fontSize: 15 }} />
        Suggest an idea
      </CustomButton>
    </CustomCard>
  );
};

export default UserDetails;
