import UsersPage from './components/UsersPage';
import Layout from 'common/CustomLayout';

const Users = () => {
  return (
    <Layout>
      <UsersPage />
    </Layout>
  );
};

export default Users;
