import Layout from 'common/CustomLayout';
import KeyContactsPage from './components/KeyContactsPage';

const KeyContacts = () => {
  return (
    <Layout>
      <KeyContactsPage />
    </Layout>
  );
};

export default KeyContacts;
