import { Typography } from 'antd';
import styles from './custom_text.module.less';

const { Text } = Typography;

const CustomText = ({
  children,
  className,
  font = 'regular',
  color = 1,
  size,
  style,
  ...rest
}) => {
  return (
    <Text
      className={`cs-${font} text-color-${color} ${styles.container}${
        className ? ` ${className}` : ''
      }`}
      style={{
        ...style,
        ...(size ? { fontSize: size } : {})
      }}
      {...rest}
    >
      {children}
    </Text>
  );
};

export default CustomText;
