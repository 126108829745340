import React, { useState, useEffect } from 'react';
import { Form, Radio, Space, Tag, message } from 'antd';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
import { getAllQuizzesbyId, submitQuiz } from '../../redux/slice';
import { useParams } from 'react-router-dom';
import Layout from 'common/CustomLayout';
import CustomCard from 'common/CustomCard';
import styles from '../../index.module.less';
import CustomText from 'common/CustomText';
import FormItem from 'common/FormItem';
import CustomButton from 'common/CustomButton';
import { Progress } from 'antd';
import QuizResult from '../QuizResult';
// import BGIMG from '../../../quiz/images/bg1.png';

const Quiz = () => {
  const [form] = Form.useForm();
  const [quizQuestion, setQuizQuestion] = useState([
    {
      question: '',
      options: [{ id: 1, option: '' }]
    }
  ]);

  const [Quiztype, setQuizType] = useState('');

  const responseArray = Quiztype;

  const [quizOption, setQuizOption] = useState('');
  const [pagination, setPagination] = useState('');
  const [page, setPage] = useState(1);
  const [progress, setProgress] = useState(
    new Array(responseArray[0]?.options?.length).fill(0)
  );
  // const [progress2, setProgress2] = useState(0);

  const dispatch = useDispatch();
  const params = useParams();

  const type = Quiztype[0]?.quiz?.type;
  const submitted = Quiztype[0]?.response_sumitted;
  const is_expired = Quiztype[0]?.is_expired;

  const surveyQuestion = Quiztype[0];

  console.log('suress', responseArray[0]?.options?.length);

  const submitArray = [];

  if (type) {
    Quiztype.map((element) => {
      if (element.response_sumitted == true) {
        submitArray.push(element);
      }
    });
  }

  console.log('submitArray', submitArray);
  console.log('responseArray', responseArray);

  // const isFound = type
  //   ? Quiztype.some((item) => item.response_sumitted === true)
  //   : Quiztype[0]?.response_sumitted;

  // console.log('submitted', responseArray, submitArray);

  useEffect(() => {
    handleGetAllQuizzesById();
    handleGetTypeById();
  }, [params.id, page]);

  const hanldeClick = (values) => {
    let payload = {
      quiz_id: params.id,
      question_id: quizQuestion[0]?.question_id,
      option_id: quizOption
    };
    values.type === 'next'
      ? dispatch(submitQuiz(payload)).then(() => {
          // handleGetAllQuizzesById();
          setPage(page + 1);
          setQuizOption('');
        })
      : dispatch(submitQuiz(payload)).then((resposne) => {
          resposne.payload.success
            ? (message.success(resposne.payload.message),
              type
                ? history.push(`/quiz-result/${params.id}`)
                : history.push(`/polls-surveys`))
            : '';
        });
  };

  const handleGetAllQuizzesById = () => {
    console.log(page);
    let payload = { quiz_id: params.id, per_page: 1, page: page };
    dispatch(getAllQuizzesbyId(payload)).then((response) => {
      setQuizQuestion(response.payload.data);
      setPagination(response.payload.pagination);
      response.payload?.data[0]?.response_sumitted === true
        ? setPage(page + 1)
        : '';
    });
  };

  const handleGetTypeById = () => {
    let payload = { quiz_id: params.id };
    dispatch(getAllQuizzesbyId(payload)).then((response) => {
      setQuizType(response.payload.data);
    });
  };

  const onChange = (e) => {
    setQuizOption(e.target.value);
  };

  const onChange1 = (e) => {
    setQuizOption(e.target.value);

    let progressPercentage = [];
    {
      responseArray[0]?.options?.map((items) => {
        let value = items?.percentage;
        progressPercentage.push(value);
      });
      setProgress(progressPercentage);
    }
    console.log('value', progress);
  };

  console.log('survey', progress);
  // const onFinish = (values) => {
  //   values['question_options'] = quizQuestion;
  //   dispatch(addQuiz(values)).then(() => {
  //     history.push('/quizzes-list');
  //   });
  // };

  return (responseArray.length != submitArray.length && type & !is_expired) ||
    (responseArray.length != submitArray.length &&
      !type &&
      !submitted & !is_expired) ? (
    <Layout>
      <CustomCard
        className={styles.container}
        style={{
          backgroundImage: `url(${quizQuestion[0]?.quiz?.image})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%'
        }}
      >
        {/* <Row>
          <Col span={19}>
            <CustomText
              font="medium"
              size={24}
              color="secondary"
              className="pl-2"
            >
              {quizQuestion[0]?.quiz?.name}
            </CustomText>
          </Col>
        </Row>
        <br /> */}
        <Space direction="vertical" size={24} style={{ height: '100%' }}>
          <Space
            // size={615}
            className={styles.areas_mobile_container}
          >
            <CustomText
              font="medium"
              size={24}
              className="pl-2"
              // color="primary"
              style={{ color: 'black' }}
            >
              {quizQuestion[0]?.quiz?.name}
              <br />
            </CustomText>
            <Space
              direction="vertical"
              style={{ position: 'absolute', top: '20px', right: '10px' }}
            >
              {pagination?.current_page <= pagination?.total_page ? (
                <>
                  {' '}
                  {type ? (
                    <>
                      <Tag color="orange">
                        Question:{' '}
                        <b>
                          {pagination?.current_page +
                            '/' +
                            pagination?.total_page}
                        </b>
                      </Tag>

                      <Progress
                        percent={Math.round(
                          (pagination?.current_page / pagination?.total_page) *
                            100
                        )}
                      />
                    </>
                  ) : null}
                </>
              ) : (
                ''
              )}
            </Space>
          </Space>

          <Form form={form} layout="vertical" size={24}>
            {quizQuestion?.map((element, index) => (
              <FormItem
                key={index}
                label={pagination?.current_page + '. ' + element?.question}
                name="is_feature"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className="pl-2"
              >
                <br />
                {type ? (
                  <Radio.Group>
                    <Space direction="vertical">
                      {element?.options?.map((items, index) => (
                        <Radio value={items.id} key={index} onChange={onChange}>
                          {items.name}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                ) : (
                  <>
                    <Radio.Group style={{ width: '100%', marginLeft: 12 }}>
                      {/* <Space direction="vertical"> */}
                      {responseArray[0]?.options?.map((items, i) => (
                        <>
                          <Radio
                            value={items?.id}
                            onChange={(e) => onChange1(e, i)}
                            key={i}
                          >
                            {items?.name}
                          </Radio>
                          <Progress
                            percent={progress[i] ?? 0}
                            style={{ width: '50%', marginLeft: 12 }}
                          />
                          <br />
                          {/* <Radio key={i} value={items?.id} onChange={onChange2}>
                            {items?.name}
                          </Radio>

                          <Progress
                            percent={progress2}
                            style={{ width: '50%', marginLeft: 12 }}
                          /> */}
                          <br />
                        </>
                      ))}
                      {/* ))} */}
                      {/* </Space> */}
                    </Radio.Group>
                    {/* <Progress
                      key={index}
                      percent={50}
                      label="50%"
                      style={{ width: '50%', marginLeft: 12 }}
                    /> */}
                  </>
                )}
              </FormItem>
            ))}
          </Form>
        </Space>

        <FormItem className="text-right">
          {/* <CustomButton
            type="primary"
            onClick={() => history.push(`/polls-surveys`)}
            style={{ position: 'absolute', bottom: '40px', right: '75px' }}
          >
            Back
          </CustomButton> */}
          {pagination?.total_page === pagination?.current_page ? (
            <CustomButton
              style={{
                position: 'absolute',
                bottom: '40px',
                right: '0px'
                // left: '41%',
                // display: 'block',
                // width: '200px'
              }}
              type="primary"
              onClick={() => hanldeClick({ type: 'finish' })}
              disabled={quizOption === '' ? true : false}
            >
              Finish
            </CustomButton>
          ) : pagination?.current_page <= pagination?.total_page ? (
            <CustomButton
              style={{
                position: 'absolute',
                bottom: '40px',
                right: '0px'
                // left: '41%',
                // display: 'block',
                // width: '200px'
              }}
              type="primary"
              onClick={() => hanldeClick({ type: 'next' })}
              disabled={quizOption === '' ? true : false}
            >
              Next
            </CustomButton>
          ) : (
            ''
          )}
        </FormItem>
      </CustomCard>
    </Layout>
  ) : responseArray.length == submitArray.length && type ? (
    <QuizResult />
  ) : responseArray.length != submitArray.length && !type ? (
    <Layout>
      <CustomCard
        className={styles.container}
        style={{
          backgroundImage: `url(${quizQuestion[0]?.quiz?.image})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%'
        }}
      >
        {/* {console.log('survey', surveyQuestion?.options)} */}
        <CustomText font="medium" color="primary" size={18}>
          {surveyQuestion?.quiz?.name}
        </CustomText>
        <div>
          <CustomText font="medium">{surveyQuestion?.question}</CustomText>
        </div>

        <div>
          {/* <CustomButton
            type="primary"
            onClick={() => history.push(`/polls-surveys`)}
          >
            Back
          </CustomButton> */}
          {responseArray[0]?.options?.map((items) => (
            <>
              <CustomText font="medium">{items?.name} </CustomText>
              <Progress
                key={items?.id}
                percent={items?.percentage}
                // label="75%"
                style={{ width: '50%', marginLeft: 12 }}
              />
              <br />
            </>
          ))}
        </div>

        {/* <div>
          <CustomText font="medium">
            {surveyQuestion?.options[1]?.name}{' '}
          </CustomText>
          <Progress
            percent={no_percentage}
            // label="25%"
            style={{ width: '50%', marginLeft: 12 }}
          />
        </div> */}
        <div>
          {' '}
          <CustomButton
            type="primary"
            onClick={() => history.push(`/polls-surveys`)}
            style={{ position: 'absolute', top: '30px', right: '10px' }}
          >
            Back
          </CustomButton>
        </div>
      </CustomCard>
    </Layout>
  ) : responseArray.length != submitArray.length && type & is_expired ? (
    <QuizResult />
  ) : null;
};
export default Quiz;
