import React, { useEffect } from 'react';
import CustomModal from 'common/CustomModal';
import { Form, Input, Space, DatePicker, AutoComplete, Select } from 'antd';
import FormItem from 'common/FormItem';
import CustomButton from 'common/CustomButton';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';
const { Option } = Select;

const AddEventModal = ({
  showAddModal,
  handleCancel,
  handleAddEvent,
  editMode,
  selectedEvent,
  setURLlink,
  urls,
  selectedUrlId,
  setSelectedUrlId
}) => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    if (editMode) {
      values['id'] = selectedEvent.id;
    }
    values['event_url'] = 'buzz#' + selectedUrlId;
    handleAddEvent(values);
  };
  const onSelect = (data) => {
    const filtered = !data ? urls : urls.filter((urls) => urls.id === data);
    console.log(filtered);
    setSelectedUrlId(filtered[0]?.id);
    form.setFieldsValue({
      event_url: filtered[0]?.post_description
    });
  };
  useEffect(() => {
    if (editMode && selectedEvent) {
      const { event_name, event_date, event_url } = selectedEvent;

      form.setFieldsValue({
        event_name,
        event_date: moment(event_date, dateFormat),
        event_url
      });
    }
  }, [selectedEvent]);

  const onSearch = (e) => {
    console.log(e);
    if (e.length > 2) {
      setURLlink(e);
    }
  };
  return (
    <CustomModal
      title={'Add Event'}
      open={showAddModal}
      onCancel={handleCancel}
      width={'43%'}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <FormItem
          label="Event Name"
          name="event_name"
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
        >
          <Input allowClear placeholder="Enter event name" />
        </FormItem>
        <FormItem
          label="Event Date"
          name="event_date"
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
        >
          <DatePicker allowClear placeholder="Select event date" />
        </FormItem>
        <FormItem
          label="Event URL"
          name="event_url"
          rules={[
            {
              required: false,
              message: 'Required field'
            }
          ]}
        >
          <AutoComplete
            optionLabelProp="text"
            // onChange={(e) => handleOnChange(e)}
            // onSelect={(val, option) => onSelect(val, option)}
            placeholder="Select URL"
            onSelect={onSelect}
            onSearch={onSearch}
            // options={urls}
          >
            {urls.map((item, index) => (
              <Option key={index} value={item?.id}>
                {item?.post_description}
              </Option>
            ))}
          </AutoComplete>
          {/* <Input allowClear placeholder="Enter event url" /> */}
        </FormItem>
        <FormItem className="text-right">
          <Space>
            <CustomButton htmlType="button" onClick={handleCancel}>
              Cancel
            </CustomButton>
            <CustomButton type="primary" htmlType="submit">
              Save
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
    </CustomModal>
  );
};
export default AddEventModal;
