import { useEffect, useState } from 'react';
import { Form, Image, Input, Radio, Space, Upload } from 'antd';
import CustomModal from 'common/CustomModal';
import CustomButton from 'common/CustomButton';
import CustomText from 'common/CustomText';
import FormItem from 'common/FormItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllIdeas,
  getAreaList,
  getSubAreaList,
  postIdea
} from '../../redux/slice';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './add_idea_modal.module.less';

const { TextArea } = Input;

const AddIdeaModal = ({ addIdeaModal, handleToggleAddIdeaModal }) => {
  const dispatch = useDispatch();
  const { postIdeaLoading, areas, subAreas } = useSelector(
    (state) => state.ideas
  );
  const [form] = Form.useForm();
  const [areaId, setAreaId] = useState(null);

  useEffect(() => {
    dispatch(getAreaList());
  }, []);

  useEffect(() => {
    if (areaId) {
      form.setFieldsValue({ sub_area_id: null });
      dispatch(getSubAreaList(areaId));
    }
  }, [areaId]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFinish = (values) => {
    const { area_id, post_images, problem, solution, sub_area_id } = values;

    // let payload = {
    //   area_id,
    //   post_images: post_images || [],
    //   problem,
    //   solution,
    //   sub_area_id
    // };
    let formData = new FormData();
    formData.append('area_id', area_id);
    formData.append('problem', problem);
    formData.append('solution', solution);
    formData.append('sub_area_id', sub_area_id);
    formData.append('post_images', post_images[0].originFileObj);

    dispatch(postIdea(formData)).then(({ payload }) => {
      if (payload?.success) {
        handleToggleAddIdeaModal();
        dispatch(getAllIdeas());
      }
    });
  };

  return (
    <CustomModal
      title={<Image preview={false} src={'images/featured_icon.svg'} />}
      visible={addIdeaModal}
      onCancel={handleToggleAddIdeaModal}
      footer={null}
      className={styles.container}
    >
      <CustomText font="medium" size={18} className="d-block">
        Contribute to Project Phoenix today. Every idea counts
      </CustomText>
      <CustomText color={2} className="mt-2">
        Enter details of the project
      </CustomText>
      <Form
        form={form}
        name="add-idea"
        layout="vertical"
        onFinish={onFinish}
        className="mt-3"
      >
        <FormItem
          label="Process"
          name="area_id"
          rules={[
            {
              required: true,
              message: 'Required field!'
            }
          ]}
        >
          <Radio.Group
            className={styles.areas_container}
            onChange={({ target: { value } }) => setAreaId(value)}
            buttonStyle="solid"
          >
            {areas.map((item) => {
              const { id, area } = item;
              return (
                <Radio.Button key={id} value={id}>
                  {area}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        </FormItem>

        {areaId ? (
          <FormItem
            label="Process area"
            name="sub_area_id"
            rules={[
              {
                required: true,
                message: 'Required field!'
              }
            ]}
          >
            <Radio.Group className={styles.areas_container} buttonStyle="solid">
              {subAreas.map((item) => {
                const { id, sub_area } = item;
                return (
                  <Radio.Button key={id} value={id}>
                    {sub_area}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </FormItem>
        ) : null}

        <FormItem
          label="Describe the problem that you would like to solve"
          name="problem"
          rules={[
            {
              required: true,
              message: 'Required field!'
            }
          ]}
        >
          <TextArea rows={4} />
        </FormItem>

        <FormItem
          label="Explain how you intend you solve the problem"
          name="solution"
          rules={[
            {
              required: true,
              message: 'Required field!'
            }
          ]}
        >
          <TextArea rows={4} />
        </FormItem>

        <FormItem label="Upload any documents to help us understand the idea better">
          <FormItem
            name="post_images"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            noStyle
          >
            <Upload.Dragger
              name="files"
              customRequest={({ onSuccess }) => {
                setTimeout(() => {
                  onSuccess('ok');
                }, 0);
              }}
            >
              <p className="ant-upload-drag-icon">
                <InfoCircleOutlined />
              </p>
              <Space>
                <CustomText font="medium" color="primary">
                  Click to upload
                </CustomText>
                <CustomText color={2}>or drag and drop</CustomText>
              </Space>
            </Upload.Dragger>
          </FormItem>
        </FormItem>

        <FormItem className="text-right">
          <Space>
            <CustomButton htmlType="button" onClick={handleToggleAddIdeaModal}>
              Cancel
            </CustomButton>
            <CustomButton
              type="primary"
              htmlType="submit"
              loading={postIdeaLoading}
            >
              Submit
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
    </CustomModal>
  );
};

export default AddIdeaModal;
