import React, { useEffect, useState } from 'react';
import CustomModal from 'common/CustomModal';
import { Image, List } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from './image_shuffle_modal.module.less';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPosts, patchDocsSequence } from '../../redux/slice';

const ImageShuffleModal = ({ open, onCancel, data, page, setList }) => {
  const dispatch = useDispatch();
  const { patchDocsSequenceLoading } = useSelector((state) => state.tidings);

  const [images, setImages] = useState([]);
  console.log({ images });

  useEffect(() => {
    setImages(data);
  }, []);

  const onDragEnd = (e) => {
    if (!e.destination) {
      return;
    }
    const sorted = reorder(images, e.source.index, e.destination.index);
    setImages(sorted);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    console.log({ result });

    return result;
  };

  const handleDocSequence = () => {
    let params = {
      image_seq: images.map((o, i) => ({
        doc_id: o.id,
        seq: images.length - i
      }))
    };

    dispatch(patchDocsSequence(params)).then(({ payload }) => {
      if (payload?.success) {
        onCancel();
        dispatch(getAllPosts(page)).then((response) => {
          setList(response.payload.data);
        });
      }
    });
  };

  return (
    <CustomModal
      title={'Re-arrange Images'}
      open={open}
      onCancel={onCancel}
      width={'70%'}
      className={styles.container}
      okButtonProps={{
        loading: patchDocsSequenceLoading,
        onClick: handleDocSequence
      }}
      cancelButtonProps={{ onClick: onCancel }}
    >
      <Image.PreviewGroup>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="Re-arrange Images">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <List
                  itemLayout="horizontal"
                  grid={{
                    gutter: 16
                  }}
                  dataSource={images}
                  rowKey={(item) => item.id}
                  renderItem={(item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={`${item.id}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <List.Item>
                            <Image
                              src={item.doc_url}
                              className={styles.image}
                            />
                          </List.Item>
                        </div>
                      )}
                    </Draggable>
                  )}
                />
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Image.PreviewGroup>
    </CustomModal>
  );
};

export default ImageShuffleModal;
