import { useState, useEffect } from 'react';
import {
  Carousel,
  Space,
  Progress,
  Form,
  Radio,
  Col,
  Row,
  Rate,
  Spin
} from 'antd';
import CustomText from 'common/CustomText';
import styles from './quiz.module.less';
import {
  CalendarOutlined,
  LeftOutlined,
  RightOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import moment from 'moment';
import PendingSurveys from 'common/CustomLayout/components/PendingSurveys';
import { useDispatch, useSelector } from 'react-redux';
import { getAllQuizzes, submitQuiz } from '../../redux/slice';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';
import FormItem from 'common/FormItem';

const QuizPage = () => {
  const [slide, setSlide] = useState(0);
  const dispatch = useDispatch();
  const { getAllEventsLoading } = useSelector((state) => state.quiz);
  const [quizzes, setQuizzes] = useState([]);
  const [progress, setProgress] = useState(
    new Array(quizzes[0]?.options?.length).fill(0)
  );
  const [quizOption, setQuizOption] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    handleGetAllQuizzes();
  }, []);

  const handleGetAllQuizzes = () => {
    let payload = { is_featured: true };
    dispatch(getAllQuizzes(payload)).then((resposne) => {
      console.log('test response', resposne.payload.data);
      setQuizzes(resposne.payload.data);
    });
  };

  console.log(quizzes[0]?.quiz_submitted);

  const hanldeClick = (values) => {
    let payload = {
      quiz_id: values.quiz_id,
      question_id: values.question_id,
      option_id: quizOption
    };

    console.log(values);
    values.type === 'submit'
      ? dispatch(submitQuiz(payload)).then((resposne) => {
          resposne.payload.success ? window.location.reload() : '';
        })
      : '';
  };

  const onChange1 = (e) => {
    setQuizOption(e.target.value);

    let progressPercentage = [];
    {
      quizzes[0]?.options?.map((items) => {
        let value = items?.percentage;
        progressPercentage.push(value);
      });
      setProgress(progressPercentage);
    }
    console.log('value', e.target.value);
  };

  console.log('test quizOption', quizOption);

  const percentage_display = {
    100: '100%',
    '75-99': 'Between 75% - 99%',
    '50-74': 'Between 50% - 74%',
    '0-49': 'Between 0 - 49%'
  };

  console.log(percentage_display['91-100']);
  return (
    <Spin spinning={getAllEventsLoading}>
      <Carousel
        effect="fade"
        arrows={true}
        className={`${styles.carousel_container}${
          slide === 0 ? ` ${styles.hide_left_arrow}` : ''
        }${slide === quizzes.length + 1 ? ` ${styles.hide_right_arrow}` : ''}`}
        nextArrow={<RightOutlined />}
        prevArrow={<LeftOutlined />}
        beforeChange={(currentSlide, nextSlide) => {
          setSlide(nextSlide);
        }}
      >
        {/* <div className={styles.survey_container}>
        <Space direction="vertical" size="middle">
          <Space direction="vertical" size={0}>
            <Space className={styles.date}>
              <CalendarOutlined style={{ color: '#AEAEAE' }} />
              <CustomText font="medium" size={12} style={{ color: '#AEAEAE' }}>
                {moment().format('DD MMM YYYY')}
              </CustomText>
            </Space>
            <CustomText font="medium" size={32} color="black">
              Phoenix Microsite Survey
            </CustomText>
          </Space>
          <div className={styles.description_container}>
            <PendingSurveys withOutCard={true} />
          </div>
        </Space>
        <CustomText color="black" className={styles.bottom_text}>
          Slide for past surveys
        </CustomText>
      </div> */}
        {/* {test_quizzes?.map((item) => (
        <>
          <div className={styles.survey_container}>
            <Space direction="vertical" size="middle">
              <Space direction="vertical" size={0}>
                <Space className={styles.date}>
                  <CalendarOutlined style={{ color: '#AEAEAE' }} />
                  <CustomText
                    font="medium"
                    size={12}
                    style={{ color: '#AEAEAE' }}
                  >
                    {moment().format('DD MMM YYYY')}
                  </CustomText>
                </Space>
                <CustomText font="medium" size={32} color="black">
                  {item.name}
                </CustomText>
              </Space>
              <div className={styles.description_container}>
                {/* <PendingSurveys withOutCard={true} /> */}
        {/* <CustomText
                  color={3}
                  size={12}
                  font="medium"
                  style={{ color: 'white' }}
                >
                  {item?.question}
                </CustomText>
                <br />
                {item?.options?.map((opt, i) => (
                  <>
                    <CustomText
                      color={3}
                      size={12}
                      font="medium"
                      style={{ color: 'white' }}
                    >
                      {opt?.option}{' '}
                    </CustomText>
                    <Progress
                      key={i}
                      percent={opt?.percentage}
                      style={{ width: '500px', marginLeft: 12 }}
                    />
                    <br />
                  </>
                ))}
              </div>
            </Space>
            <CustomText color="black" className={styles.bottom_text}>
              Slide for past surveys
            </CustomText>
          </div>
        </> */}
        {/* ))} } */}
        {quizzes?.map((item) => (
          <>
            <div
              className={styles.survey_container}
              style={{ backgroundImage: `url(${item.image})` }}
            >
              <Space direction="vertical" size="middle">
                <Space direction="vertical" size={0}>
                  <Space className={styles.date}>
                    <CalendarOutlined style={{ color: '#AEAEAE' }} />
                    <CustomText
                      font="medium"
                      size={12}
                      style={{ color: '#AEAEAE' }}
                    >
                      {moment(new Date()).format('YYYY-MM-DD kk:mm:ss') >
                      moment(item.start_date).format('YYYY-MM-DD kk:mm:ss')
                        ? moment(item.start_date).format('DD MMM YY')
                        : moment(new Date()).format('DD MMM YY')}
                    </CustomText>
                  </Space>
                  <CustomText font="medium" size={32} color="black">
                    {item.name}
                  </CustomText>
                  {item.type ? (
                    !item.quiz_submitted && !item.is_expired ? (
                      <CustomButton
                        type="primary"
                        onClick={() => history.push(`/take-to-quiz/${item.id}`)}
                        style={{
                          position: 'absolute',
                          top: '150px',
                          height: '60px',
                          width: '150px',
                          fontSize: '20px'
                        }}
                      >
                        Start quiz
                      </CustomButton>
                    ) : (
                      <>
                        <div className={styles.description_container}>
                          {/* <PendingSurveys  withOutCard={true} /> */}
                          <CustomText
                            color={3}
                            size={16}
                            font="medium"
                            style={{ color: 'white' }}
                            className={styles.top_score}
                          >
                            Here`s how the scores stack up
                          </CustomText>
                          <br /> <br />
                          <>
                            {item?.quizScores?.map((user, j) => (
                              <>
                                <Row gutter={24}>
                                  <Col size={2} style={{ width: '25%' }}>
                                    <CustomText
                                      key={j}
                                      color={3}
                                      size={12}
                                      font="medium"
                                      style={{
                                        color: 'white'
                                        //   marginRight: j == 0 ? 63 : 4
                                      }}
                                    >
                                      {
                                        percentage_display[
                                          user.percentage_range
                                        ]
                                      }{' '}
                                    </CustomText>
                                  </Col>
                                  <Col size={22} style={{ width: '75%' }}>
                                    <Progress
                                      key={j}
                                      percent={Math.round(
                                        (user.participants /
                                          user.total_participants) *
                                          100
                                      )}
                                      style={{
                                        width: '80%',
                                        marginLeft: 2
                                      }}
                                      format={(percent) => (
                                        <span
                                          style={{
                                            color: 'white',
                                            fontWeight: 'bold',
                                            fontSize: '12px',
                                            display: 'flex',
                                            alignItems: 'center'
                                          }}
                                        >
                                          {percent}%
                                          {user.isTheUserInThisRange ? (
                                            <>
                                              <Rate
                                                defaultValue={1}
                                                allowClear={false}
                                                count={1}
                                                style={{
                                                  marginLeft: '8px',
                                                  marginRight: '2px',
                                                  marginTop: '-8px',
                                                  fontSize: '10'
                                                }}
                                                className={styles.rate}
                                              />
                                              <span className={styles.score}>
                                                Your score
                                              </span>
                                            </>
                                          ) : null}
                                        </span>
                                      )}
                                      status="active"
                                    />
                                    {/* {user.isTheUserInThisRange ? (
                                    <>
                                      <Rate
                                        defaultValue={1}
                                        allowClear={false}
                                        count={1}
                                        style={{
                                          fontSize: '12px',
                                          marginLeft: '10px'
                                        }}
                                      />
                                      <span
                                        // className="ant-rate-text"
                                        style={{
                                          fontSize: '12px',
                                          color: 'white',
                                          // border: '1px solid white',
                                          display: 'inline-flex',
                                          padding: '1px'
                                        }}
                                      >
                                        Your score
                                      </span>
                                    </>
                                  ) : null} */}
                                  </Col>
                                </Row>
                                {/* <br /> */}
                                {/* <Space
                                direction="vertical"
                                className={styles.polls}
                              >
                                <div
                                  className={styles.poll_container}
                                  style={{ width: '50%', marginTop: 10 }}
                                  key={j}
                                >
                                  <CustomText
                                    font="medium"
                                    size={12}
                                    color="secondary"
                                  >
                                    {user.participants} ({user.percentage_range}
                                    %)
                                  </CustomText>
                                </div>
                              </Space> */}
                              </>
                            ))}
                          </>
                          <br />
                          <CustomText
                            size={12}
                            style={{
                              color: 'white'
                            }}
                          >
                            <InfoCircleOutlined
                              style={{
                                marginRight: '10px'
                              }}
                            />
                            Bars denote percentage of quiz takers who scored in
                            a specific percentile band
                          </CustomText>
                        </div>
                        <br />
                        <CustomButton
                          type="primary"
                          onClick={() =>
                            history.push(`/take-to-quiz/${item.id}`)
                          }
                        >
                          View quiz
                        </CustomButton>
                        <br />
                      </>
                    )
                  ) : (
                    <div
                      className={styles.description_container}
                      // style={{ width: '600px' }}
                    >
                      {/* <PendingSurveys withOutCard={true} /> */}

                      {!item.type & !item.quiz_submitted ? (
                        <Form form={form} layout="vertical" size={24}>
                          <FormItem
                            name="is_feature"
                            rules={[
                              {
                                required: true,
                                message: 'Required field'
                              }
                            ]}
                            className="pl-2"
                          >
                            <br />
                            <CustomText
                              color={3}
                              size={16}
                              font="medium"
                              style={{ color: 'white' }}
                            >
                              {item?.Questions[0]?.question}
                            </CustomText>

                            <Radio.Group style={{ width: '100%' }}>
                              {/* <Space direction="vertical"> */}
                              {item?.Questions[0]?.options?.map((items, i) => (
                                <>
                                  <Row gutter={24}>
                                    <Col size={2} style={{ width: '25%' }}>
                                      <Radio
                                        value={items?.id}
                                        onChange={(e) => onChange1(e, i)}
                                        key={i}
                                        // style={{ marginRight: 2 }}
                                      >
                                        <CustomText
                                          //   font="medium"
                                          size={12}
                                          style={{
                                            color: 'white',
                                            fontWeight: 'bold'
                                            // marginRight: i == 0 ? 6 : -2
                                          }}
                                        >
                                          {' '}
                                          {items?.name}{' '}
                                        </CustomText>
                                      </Radio>
                                    </Col>
                                    <Col size={22} style={{ width: '75%' }}>
                                      <Progress
                                        percent={progress[i] ?? 0}
                                        // style={{ width: '400px', marginLeft: 12 }}
                                        style={{ width: '80%', marginLeft: 0 }}
                                        format={(percent) => (
                                          <span
                                            style={{
                                              color: 'white',
                                              fontWeight: 'bold',
                                              fontSize: '12px'
                                            }}
                                          >
                                            {percent}%
                                          </span>
                                        )}
                                      />
                                    </Col>
                                  </Row>
                                  {/* <br /> */}
                                </>
                              ))}
                              {/* ))} */}
                              {/* </Space> */}
                            </Radio.Group>
                          </FormItem>
                          <CustomButton
                            type="primary"
                            onClick={() =>
                              hanldeClick({
                                type: 'submit',
                                quiz_id: item?.id,
                                question_id: item?.Questions[0]?.id
                              })
                            }
                            disabled={quizOption === '' ? true : false}
                          >
                            Submit
                          </CustomButton>
                        </Form>
                      ) : (
                        <>
                          <CustomText
                            color={3}
                            size={16}
                            font="medium"
                            style={{ color: 'white' }}
                          >
                            {item?.Questions[0]?.question}
                          </CustomText>
                          <br />
                          {item?.Questions[0]?.options?.map((opt, i) => (
                            <>
                              <Row gutter={24}>
                                <Col size={2} style={{ width: '25%' }}>
                                  <CustomText
                                    color={3}
                                    size={12}
                                    font="medium"
                                    style={{
                                      color: 'white'
                                      //   marginRight: i == 0 ? 6 : -1
                                    }}
                                  >
                                    {opt.name}
                                  </CustomText>
                                </Col>
                                <Col size={22} style={{ width: '75%' }}>
                                  <Progress
                                    key={i}
                                    percent={Math.round(opt.percentage)}
                                    format={(percent) => (
                                      <span
                                        style={{
                                          color: 'white',
                                          fontWeight: 'bold',
                                          fontSize: '12px'
                                        }}
                                      >
                                        {percent}%
                                      </span>
                                    )}
                                    status="active"
                                    // style={{ width: '500px', marginLeft: 12 }}
                                    style={{ width: '80%', marginLeft: 0 }}
                                  />
                                </Col>
                              </Row>
                              {/* <br /> */}
                            </>
                          ))}
                        </>
                      )}
                    </div>
                  )}
                </Space>
                {/* <div className={styles.description_container}> */}
                {/* <PendingSurveys withOutCard={true} /> */}
                {/* <CustomText
                  color={3}
                  size={12}
                  font="medium"
                  style={{ color: 'white' }}
                >
                  {item?.question}
                </CustomText>
                <br /> */}
                {/* {item?.options?.map((opt, i) => (
                  <>
                    <CustomText
                      color={3}
                      size={12}
                      font="medium"
                      style={{ color: 'white' }}
                    >
                      {opt?.option}{' '}
                    </CustomText>
                    <Progress
                      key={i}
                      percent={opt?.percentage}
                      style={{ width: '500px', marginLeft: 12 }}
                    />
                    <br />
                  </> */}
                {/* ))} */}
                {/* </div> */}
              </Space>
              <CustomText color="black" className={styles.bottom_text}>
                Slide for past surveys
              </CustomText>
            </div>
          </>
        ))}
        <div className={styles.survey_container}>
          <Space direction="vertical" size="middle">
            <Space direction="vertical" size={0}>
              <Space className={styles.date}>
                <CalendarOutlined style={{ color: '#AEAEAE' }} />
                <CustomText
                  font="medium"
                  size={12}
                  style={{ color: '#AEAEAE' }}
                >
                  {moment().format('DD MMM YYYY')}
                </CustomText>
              </Space>
              <CustomText font="medium" size={32} color="black">
                Phoenix Microsite Survey
              </CustomText>
            </Space>
            <div className={styles.description_container}>
              <PendingSurveys withOutCard={true} />
            </div>
          </Space>
          <CustomText color="black" className={styles.bottom_text}>
            Slide for past surveys
          </CustomText>
        </div>
        <div className={styles.survey_result_container}>
          <Space direction="vertical" size="middle">
            <Space direction="vertical" size={0}>
              <Space className={styles.date}>
                <CalendarOutlined />
                <CustomText font="medium" size={12} color="white">
                  20 Sep 2022
                </CustomText>
              </Space>
              <CustomText font="medium" size={32} color="white">
                Phoenix One Voice Quiz
              </CustomText>
            </Space>
            <div className={styles.description_container}>
              <Space direction="vertical" size={10}>
                <CustomText font="medium" size={18} color="white">
                  {`The Phoenix One Voice quiz received a great response with 1600 participants chiming in with their answers in just 36 hours. It shows why Team TPL is strong, engaged and tight-knit as ever. Most of the respondents got the answers right. Here's how it all stacked up:`}
                </CustomText>
                <Space direction="vertical" className={styles.polls}>
                  <div
                    className={styles.poll_container}
                    style={{ width: '72%' }}
                  >
                    <CustomText font="medium" size={12} color="secondary">
                      Right Logo (72%)
                    </CustomText>
                  </div>
                  <div
                    className={styles.poll_container}
                    style={{ width: '80%' }}
                  >
                    <CustomText font="medium" size={12} color="secondary">
                      Organization transformation (80%)
                    </CustomText>
                  </div>
                  <div
                    className={styles.poll_container}
                    style={{ width: '96%' }}
                  >
                    <CustomText font="medium" size={12} color="secondary">
                      For All employees (96%)
                    </CustomText>
                  </div>
                </Space>
                <CustomText font="medium" size={18} color="white">
                  {`Again, it is great to see us all echoing "One Voice". The Phoenix initiative benefits each one of us and we all need to come together to drive this transformation. Let's keep the engagement & momentum high!`}
                </CustomText>
              </Space>
            </div>
          </Space>
        </div>
      </Carousel>
    </Spin>
  );
};

export default QuizPage;
