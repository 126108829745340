import { Progress, Space } from 'antd';
import CustomText from 'common/CustomText';
import CustomButton from 'common/CustomButton';
import styles from '../PendingSurveys/pending_surveys.module.less';

const textToPercentage = (text) => {
  return Number(text.replace('%', ''));
};

const PollResponse = ({
  pollData,
  pollResponseData,
  setShowThankyou,
  canShowThankyou,
  withOutCard
}) => {
  if (!pollData || !pollResponseData) return null;

  const { poll_question } = pollData;
  const { yes_percentage, no_percentage } = pollResponseData;

  let textColorStyle = withOutCard ? { color: 'white' } : {};

  return (
    <Space direction="vertical" size={4}>
      <CustomText color={3} size={12} font="medium" {...textColorStyle}>
        {poll_question}
      </CustomText>
      <div>
        <CustomText font="medium" {...textColorStyle}>
          Yes
        </CustomText>
        <Progress
          style={{ width: '85%', marginLeft: 12 }}
          percent={textToPercentage(yes_percentage)}
          className={withOutCard ? `${styles.text_white}` : ''}
        />
      </div>
      <div>
        <CustomText font="medium" {...textColorStyle}>
          No
        </CustomText>
        <Progress
          style={{ width: '85%', marginLeft: 12 }}
          percent={textToPercentage(no_percentage)}
          className={withOutCard ? `${styles.text_white}` : ''}
        />
      </div>
      {canShowThankyou ? (
        <CustomButton type="primary" onClick={() => setShowThankyou(true)}>
          Okay
        </CustomButton>
      ) : null}
    </Space>
  );
};

export default PollResponse;
