import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Space, Table, Popconfirm, Tooltip, Input } from 'antd';
import CustomCard from 'common/CustomCard';
import CustomText from 'common/CustomText';
import styles from './admin_console.module.less';
import { UserSwitchOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers, updateRole, deactivateUser } from '../../redux/slice';
import CustomButton from '@/common/CustomButton';
import { history } from 'app/history';

// const { Search } = Input;
const UsersPage = () => {
  const { users, pagination } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [page, setPage] = useState('');
  const [search, setSearch] = useState('');

  const visibility = useCallback((record) => {
    return showDisablePopup[record];
  }, showDisablePopup);

  useEffect(() => {
    handleGetAllUsers();
  }, [page, search]);

  const handleGetAllUsers = () => {
    let payload = {
      page,
      search
    };
    dispatch(getAllUsers(payload));
  };

  const handleSwitchRole = (data) => {
    console.log(data);
    let payload = {
      id: data.data.id,
      role: data.data.role === 'user' ? 'admin' : 'user'
    };
    dispatch(updateRole(payload)).then(() => {
      handleGetAllUsers();
    });
  };

  const handleDeactivateUser = (data) => {
    console.log(data);
    let payload = {
      id: data.data.id,
      active: data.data.is_active === true ? false : true
    };
    dispatch(deactivateUser(payload)).then(() => {
      handleGetAllUsers();
    });
  };

  const columns = [
    {
      title: 'Name',
      key: 'first_name',
      dataIndex: 'first_name',
      render: (text, record) => {
        return <p>{text + ' ' + record.last_name}</p>;
      }
    },
    {
      title: 'E-mail',
      key: 'email',
      dataIndex: 'email'
    },
    {
      title: 'Role',
      key: 'role',
      dataIndex: 'role',
      render: (text) => {
        return <p>{text.charAt(0).toUpperCase() + text.slice(1)}</p>;
      }
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (record) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure you want to switch this role to user?"
            visible={visibility(record?.id)}
            onConfirm={() => {
              handleSwitchRole({
                data: record
              });
            }}
            onCancel={() => {
              setShowDisablePopup({});
            }}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip placement="top" title="Switch">
              <UserSwitchOutlined
                onClick={() => {
                  setShowDisablePopup({ [record?.id]: true });
                }}
                className={styles.deletePost}
              />
            </Tooltip>
          </Popconfirm>
          <Popconfirm
            title="Are you sure you want to deactivate this user?"
            visible={visibility(record?.id)}
            onConfirm={() => {
              handleDeactivateUser({
                data: record
              });
            }}
            onCancel={() => {
              setShowDisablePopup({});
            }}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip placement="top" title="Deactivate">
              <UserDeleteOutlined
                onClick={() => {
                  setShowDisablePopup({ [record?.id]: true });
                }}
                className={styles.deletePost}
              />
            </Tooltip>
          </Popconfirm>
        </Space>
      )
    }
  ];
  return (
    <>
      <CustomCard className={`${styles.container} p-4`}>
        <Row>
          <Col span={16}>
            <CustomText
              font="medium"
              size={24}
              color="secondary"
              className="pl-2"
            >
              Users
            </CustomText>
          </Col>
          <Space>
            <Col span={2}>
              <CustomButton onClick={() => history.push('/adminconsole')}>
                Back
              </CustomButton>
            </Col>
            <Col span={2}>
              <Input
                placeholder="Search by name"
                allowClear
                // onSearch={onSearch}
                onChange={(e) => setSearch(e.target.value)}
                style={{
                  width: 220,
                  height: 35,
                  border: '1px solid #b2abab'
                }}
              />
            </Col>
          </Space>
        </Row>
        <br />
        <Table
          columns={columns}
          dataSource={users}
          pagination={{
            size: 'small',
            total: pagination?.total_records || 0,
            onChange: (page) => {
              setPage(page);
            }
          }}
        />
      </CustomCard>
    </>
  );
};

export default UsersPage;
