import apiClient from 'utilities/apiClient';

export const Suggestion = (url) => {
  return apiClient.get(`${apiClient.Urls.suggestion}/${url}`, null, true);
};

export const suggestionUpvote = (payload) => {
  return apiClient.post(apiClient.Urls.suggestionUpvote, payload, true);
};

export const suggestionDownvote = (payload) => {
  return apiClient.delete(apiClient.Urls.suggestionDownvote, payload, true);
};

export const areaSearch = (payload) => {
  return apiClient.get(apiClient.Urls.areaSearch, payload, true);
};

export const implement = (payload) => {
  return apiClient.post(apiClient.Urls.implement, payload, true, 'file');
};

export const addSuggestionComment = (payload) => {
  return apiClient.post(apiClient.Urls.addSuggestionComment, payload, true);
};

export const dltSuggestionComment = (id) => {
  return apiClient.delete(
    `${apiClient.Urls.dltSuggestionComment}?id=${id}`,
    {},
    true
  );
};

export const addSuggestion = (payload) => {
  return apiClient.post(apiClient.Urls.addSuggestion, payload, true, 'file');
};

export const ideaAllTags = () => {
  return apiClient.get(
    `${apiClient.Urls.ideaAllTags}?tags_type=idea`,
    null,
    true
  );
};
