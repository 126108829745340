import MDMessagePage from './components/MDMessagePage';
import Layout from 'common/CustomLayout';

const MDMessage = () => {
  return (
    <Layout>
      <MDMessagePage />
    </Layout>
  );
};

export default MDMessage;
