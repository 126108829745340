import apiClient from 'utilities/apiClient';

export const allRR = (payload) => {
  return apiClient.get(`${apiClient.Urls.allRR}`, payload, true);
};

export const rrCreate = (payload) => {
  return apiClient.post(`${apiClient.Urls.rrCreate}`, payload, true, 'file');
};

export const rrEdit = (payload) => {
  return apiClient.post(`${apiClient.Urls.rrCreate}`, payload, true, 'file');
};

export const rrAllGroups = (payload) => {
  return apiClient.get(`${apiClient.Urls.rrAllGroups}`, payload, true);
};

export const rrMasterDD = () => {
  return apiClient.get(`${apiClient.Urls.rrMasterDD}`, null, true);
};

export const addRRGroup = (payload) => {
  return apiClient.post(`${apiClient.Urls.addRRGroup}`, payload, true);
};

export const rrLike = (payload) => {
  return apiClient.post(apiClient.Urls.rrLike, payload, true);
};

export const rrDisLike = (payload) => {
  return apiClient.delete(apiClient.Urls.rrDisLike, payload, true);
};

export const rrById = (payload) => {
  return apiClient.get(apiClient.Urls.rrById, payload, true);
};

export const rrAddComment = (payload) => {
  return apiClient.post(apiClient.Urls.rrAddComment, payload, true);
};

export const rrDeleteComment = (id) => {
  return apiClient.delete(
    `${apiClient.Urls.rrDeleteComment}?id=${id}`,
    {},
    true
  );
};

export const ideas = (payload) => {
  return apiClient.get(`${apiClient.Urls.allSuggestions}`, payload, true);
};

export const deleteWinners = (payload) => {
  return apiClient.delete(`${apiClient.Urls.deleterr}`, payload, true);
};

export const deleteDocument = (url, payload) => {
  return apiClient.delete(`${apiClient.Urls.rrDeleteDocument}`, payload, true);
};
