import apiClient from 'utilities/apiClient';

export const addQuizQuestions = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.addQuiz}/${url}`,
    payload,
    true,
    'file'
  );
};

export const getAllQuizQuestionbyId = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.addQuiz}/${url}`, payload, true);
};

export const editQuizQuestions = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.addQuiz}/${url}`, payload, true);
};

export const deleteQuiz = (url, payload) => {
  return apiClient.delete(`${apiClient.Urls.addQuiz}/${url}`, payload, true);
};

export const submitQuizQuestions = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.addQuiz}/${url}`, payload, true);
};
