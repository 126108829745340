import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { chunk } from 'lodash';
import {
  addArticle,
  allArticles,
  articleAddComment,
  articleById,
  articleDeleteComment,
  articleDisLike,
  articleLike
} from './api';

const initialState = {
  articles: [],
  getAllArticlesLoading: false,
  articlesToRender: [],
  article: null,
  getArticleByIdLoading: false,
  deleteArticleCommentLoading: false,
  postArticleLoading: false
};

const actions = {
  GET_ALL_ARTICLES: 'leaderSpeak/GET_ALL_ARTICLES',
  POST_ARTICLE_LIKE: 'leaderSpeak/POST_ARTICLE_LIKE',
  DELETE_ARTICLE_LIKE: 'leaderSpeak/DELETE_ARTICLE_LIKE',
  GET_ARTICLE_BY_ID: 'leaderSpeak/GET_ARTICLE_BY_ID',
  POST_ARTICLE_COMMENT: 'leaderSpeak/POST_ARTICLE_COMMENT',
  DELETE_ARTICLE_COMMENT: 'leaderSpeak/DELETE_ARTICLE_COMMENT',
  POST_ARTICLE: 'leaderSpeak/POST_ARTICLE'
};

export const getAllArticles = createAsyncThunk(
  actions.GET_ALL_ARTICLES,
  async () => {
    const response = await allArticles();

    return response;
  }
);

export const postArticleLike = createAsyncThunk(
  actions.POST_ARTICLE_LIKE,
  async (article_id) => {
    let payload = {
      article_id
    };

    const response = await articleLike(payload);

    return response;
  }
);

export const deleteArticleLike = createAsyncThunk(
  actions.DELETE_ARTICLE_LIKE,
  async (article_id) => {
    let payload = {
      article_id
    };

    const response = await articleDisLike(payload);

    return response;
  }
);

export const getArticleById = createAsyncThunk(
  actions.GET_ARTICLE_BY_ID,
  async (article_id) => {
    let payload = {
      article_id
    };

    const response = await articleById(payload);

    return response;
  }
);

export const postArticleComment = createAsyncThunk(
  actions.POST_ARTICLE_COMMENT,
  async (payload) => {
    const response = await articleAddComment(payload);

    return response;
  }
);

export const deleteArticleComment = createAsyncThunk(
  actions.DELETE_ARTICLE_COMMENT,
  async (id) => {
    const response = await articleDeleteComment(id);

    return response;
  }
);

export const postArticle = createAsyncThunk(
  actions.POST_ARTICLE,
  async (payload) => {
    const response = await addArticle(payload);

    return response;
  }
);

export const leaderSpeakSlice = createSlice({
  name: 'leaderSpeak',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllArticles.pending, (state) => {
        state.getAllArticlesLoading = true;
      })
      .addCase(getAllArticles.fulfilled, (state, action) => {
        state.getAllArticlesLoading = false;

        const { success, data, message: msg } = action.payload;

        if (success) {
          state.articles = data;
          state.articlesToRender = [
            data.slice(0, 1),
            ...chunk(data.slice(1), 2)
          ];
        } else {
          state.articles = [];
          state.articlesToRender = [];
          message.error(msg);
        }
      })
      .addCase(getAllArticles.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllArticlesLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postArticleLike.pending, (state) => {
        state.postArticleLikeLoading = true;
      })
      .addCase(postArticleLike.fulfilled, (state, action) => {
        state.postArticleLikeLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(postArticleLike.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postArticleLikeLoading = false;

        message.error(msg);
      });

    builder
      .addCase(deleteArticleLike.pending, (state) => {
        state.deleteArticleLikeLoading = true;
      })
      .addCase(deleteArticleLike.fulfilled, (state, action) => {
        state.deleteArticleLikeLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(deleteArticleLike.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.deleteArticleLikeLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getArticleById.pending, (state) => {
        state.getArticleByIdLoading = true;
      })
      .addCase(getArticleById.fulfilled, (state, action) => {
        state.getArticleByIdLoading = false;

        const { data, success, message: msg } = action.payload;

        if (success) {
          state.article = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getArticleById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getArticleByIdLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postArticleComment.pending, (state) => {
        state.postArticleCommentLoading = true;
      })
      .addCase(postArticleComment.fulfilled, (state, action) => {
        state.postArticleCommentLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(postArticleComment.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postArticleCommentLoading = false;

        message.error(msg);
      });

    builder
      .addCase(deleteArticleComment.pending, (state) => {
        state.deleteArticleCommentLoading = true;
      })
      .addCase(deleteArticleComment.fulfilled, (state, action) => {
        state.deleteArticleCommentLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(deleteArticleComment.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.deleteArticleCommentLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postArticle.pending, (state) => {
        state.postArticleLoading = true;
      })
      .addCase(postArticle.fulfilled, (state, action) => {
        state.postArticleLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(postArticle.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postArticleLoading = false;

        message.error(msg);
      });
  }
});

export default leaderSpeakSlice.reducer;
