import React, { useCallback } from 'react';
import {
  Col,
  Row,
  Space,
  Tooltip,
  Radio,
  Form,
  // Empty,
  Popconfirm,
  Select,
  Dropdown,
  Menu,
  Tag,
  Spin,
  Divider,
  Skeleton,
  Input,
  DatePicker,
  Button
  // Badge
} from 'antd';
import CustomCard from 'common/CustomCard';
import CustomText from 'common/CustomText';
import UserMenu from 'common/CustomLayout/components/UserMenu';
import styles from './ideas.module.less';
import {
  LikeFilled,
  LikeOutlined,
  DeleteOutlined,
  FormOutlined,
  StarOutlined,
  StarFilled,
  TagOutlined
  // CommentOutlined
} from '@ant-design/icons';
import FormItem from 'common/FormItem';
import LocalStorage from 'utilities/localStorage';
import { history } from 'app/history';
import UsersListModal from 'common/UsersListModal';
import { startCase } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
// import { Alert } from 'antd';
// import Marquee from 'react-fast-marquee';
import { debounce } from 'lodash';
// import dayjs from 'dayjs';

const { Option } = Select;

const monthFormat = 'MMM YYYY';
const IdeasPage = ({
  allIdeas,
  handlePostIdeaUpvote,
  handlePostIdeaDownvote,
  setAreaId,
  areaId,
  areas,
  showDisablePopup,
  setShowDisablePopup,
  handleDeletePost,
  showLikes,
  setShowLikes,
  likes,
  setLikes,
  handlePostUnStared,
  handlePostStared,
  getAllIdeasLoading,
  tags,
  handlePostIdeaTag,
  setPage,
  pagination,
  allStaredIdeas,
  setEnableFiltering,
  handleSeachText,
  setDateValue,
  setMyIdeas,
  actionTags,
  myIdeas
}) => {
  const userDetails = LocalStorage.getItem('phoenix_login_user');
  const [form] = Form.useForm();
  const visibility = useCallback((record) => {
    return showDisablePopup[record];
  }, showDisablePopup);

  const handleSearchValue = (e) => {
    console.log('search value', e.target.value);
    if (e.target.value) {
      handleSeachText(e.target.value);
    } else {
      window.location.reload();
    }
  };

  const handleSearch = useCallback(debounce(handleSearchValue, 500), []);

  console.log('actionTags', actionTags);
  // console.log('tags', tags);

  const handleGetMyIdeas = () => {
    setMyIdeas(true);
  };

  return (
    <Spin spinning={getAllIdeasLoading}>
      <CustomCard className={`${styles.container} pt-0`}>
        <Form
          form={form}
          name="add-idea"
          layout="vertical"
          className={`${styles.formStyles} mt-3`}
        >
          <FormItem name="area_id">
            <>
              <Radio.Group
                className={styles.areas_container}
                onChange={({ target: { value } }) => {
                  setEnableFiltering(true);
                  setAreaId(value);
                }}
                buttonStyle="solid"
                value={areaId}
              >
                <Radio.Button value={''}>{'All'}</Radio.Button>
                {areas.map((item) => {
                  const { id, area } = item;

                  return (
                    <>
                      <Radio.Button key={id} value={id}>
                        {area}
                      </Radio.Button>
                    </>
                  );
                })}
              </Radio.Group>
              {/* <Alert
                banner
                message={
                  <Marquee pauseOnHover gradient={false}>
                    Now Live:{' '}
                    <b style={{ marginLeft: '2px', marginRight: '2px' }}>
                      {' '}
                      Phoenix – Have an Idea? Contest, Edition 1.
                    </b>{' '}
                    Submit ideas under the Supply Chain Management category.
                  </Marquee>
                }
                type="info"
                className={styles.infoColor}
              /> */}
              <Select
                placeholder="Select"
                value={areaId}
                onChange={(value) => {
                  setEnableFiltering(true);
                  setAreaId(value);
                }}
                className={styles.areas_mobile_container}
              >
                <Option value={''}>All</Option>
                {areas.map((item) => {
                  const { id, area } = item;

                  return (
                    <>
                      <Option key={id} value={id}>
                        {area}
                      </Option>
                    </>
                  );
                })}
              </Select>
            </>
          </FormItem>
        </Form>
        <br />
        <Row gutter={12}>
          <Col span={8}>
            {!myIdeas ? (
              <FormItem>
                <Button type="primary" onClick={handleGetMyIdeas}>
                  My Ideas
                </Button>
              </FormItem>
            ) : (
              <FormItem>
                <Button type="primary" onClick={() => window.location.reload()}>
                  Back
                </Button>
              </FormItem>
            )}
          </Col>
          <Col span={6}>
            <FormItem style={{ width: '125px', marginLeft: 'auto' }}>
              <DatePicker
                // defaultValue={dayjs('January 2023')}
                format={monthFormat}
                picker="month"
                onChange={(x) => {
                  setDateValue(x);
                }}
                // value={value}
              />
            </FormItem>
          </Col>
          <FormItem label="Search" name="search" style={{ marginLeft: 'auto' }}>
            <Input
              style={{ width: '250px' }}
              onKeyUp={handleSearch}
              placeholder="Search by email/problem/solution"
            />
          </FormItem>
        </Row>
        <Row gutter={8}>
          {allStaredIdeas.length
            ? allStaredIdeas.map((item) => {
                const {
                  id,
                  // user_name,
                  problem,
                  // solution,
                  comment_count,
                  like_count,
                  is_like,
                  User,
                  Area,
                  Likes,
                  subarea,
                  // is_started,
                  idea_tags_id,
                  idea_tag
                } = item;

                const upvotesDisplayText = `${
                  Likes.length
                    ? `${
                        Likes[0]?.full_name?.length > 10
                          ? Likes[0]?.full_name.substring(0, 10) + '...'
                          : Likes[0]?.full_name
                      }`
                    : ''
                }${
                  like_count
                    ? like_count - 1 > 0
                      ? ` and ${like_count - 1} others`
                      : ''
                    : '0 upvotes'
                }`;

                return (
                  <Col key={id} sm={12} xs={24} className="mt-3">
                    <CustomCard
                      leftBorder={true}
                      className={`p-3 ${styles.card_container}`}
                    >
                      <Space className="w-100" direction="vertical" size={12}>
                        <div className="d-flex justify-content-between align-items-start">
                          <Space size={12} align="center">
                            <UserMenu dropdown={false} name={User} />
                            <Space direction="vertical" size={0}>
                              <CustomText font="bold" size={15}>
                                {User?.first_name + ' ' + User?.last_name}
                              </CustomText>
                              <CustomText color={3}>{`@${User?.email.slice(
                                0,
                                User?.email.indexOf('@')
                              )}`}</CustomText>
                            </Space>
                          </Space>
                          <Space direction="vertical" size={0} align="end">
                            <Space>
                              {userDetails?.user_details?.role === 'admin' ? (
                                <Dropdown
                                  overlay={
                                    <Menu>
                                      {tags.map((item) => {
                                        const { id: tag_id, name } = item;

                                        return (
                                          <Menu.Item
                                            key={tag_id}
                                            disabled={idea_tags_id == tag_id}
                                            onClick={() => {
                                              handlePostIdeaTag(id, tag_id);
                                            }}
                                          >
                                            {startCase(name)}
                                          </Menu.Item>
                                        );
                                      })}
                                    </Menu>
                                  }
                                >
                                  <TagOutlined
                                    className={`cursor-pointer ${styles.deletePost}`}
                                  />
                                </Dropdown>
                              ) : (
                                ''
                              )}
                              {User?.id === userDetails?.user_details?.id ||
                              userDetails?.user_details?.role === 'admin' ? (
                                <>
                                  <FormOutlined
                                    onClick={() =>
                                      history.push(`suggest-idea/${id}`)
                                    }
                                    className={styles.deletePost}
                                  />
                                  <Popconfirm
                                    title="Are you sure you want to delete this suggestion?"
                                    visible={visibility(User?.id)}
                                    onConfirm={() => {
                                      handleDeletePost({
                                        id: id
                                      });
                                    }}
                                    onCancel={() => {
                                      setShowDisablePopup({});
                                    }}
                                    okText="Delete"
                                    cancelText="No"
                                    okButtonProps={{
                                      type: 'danger'
                                    }}
                                  >
                                    <DeleteOutlined
                                      onClick={() => {
                                        setShowDisablePopup({
                                          [User?.id]: true
                                        });
                                      }}
                                      className={styles.deletePost}
                                    />
                                  </Popconfirm>
                                </>
                              ) : (
                                ''
                              )}
                              {userDetails?.user_details?.role === 'admin' ? (
                                <StarFilled
                                  className={styles.stared}
                                  onClick={() => {
                                    handlePostUnStared(id);
                                  }}
                                />
                              ) : (
                                ''
                              )}
                            </Space>
                            {userDetails?.user_details?.role === 'user' &&
                            idea_tag?.name !== 'Not Feasible' &&
                            idea_tag?.name !== 'For Information' ? (
                              idea_tag ? (
                                <Tag color="#ED2024" className="m-0">
                                  {startCase(idea_tag?.name || '')}
                                </Tag>
                              ) : null
                            ) : userDetails?.user_details?.role === 'admin' ? (
                              idea_tag ? (
                                <Tag color="#ED2024" className="m-0">
                                  {startCase(idea_tag?.name || '')}
                                </Tag>
                              ) : null
                            ) : null}
                          </Space>
                          {/* <MoreOutlined className={styles.more_icon} /> */}
                        </div>
                        <div style={{ height: '60px' }}>
                          <CustomText font="medium" className="d-block">
                            {Area?.area?.length > 55 ? (
                              <Tooltip placement="top" title={Area?.area}>
                                {Area?.area?.substring(0, 55) + '...'}
                              </Tooltip>
                            ) : (
                              Area?.area
                            )}
                          </CustomText>
                          <CustomText color={2} size={12} className="d-block">
                            {subarea?.sub_area ? subarea?.sub_area : ''}
                          </CustomText>
                          <CustomText color={2}>
                            {problem.length > 90 ? (
                              <Tooltip placement="top" title={problem}>
                                {problem.substring(0, 87) + '...'}
                              </Tooltip>
                            ) : (
                              problem
                            )}
                          </CustomText>
                        </div>
                        <Space
                          className="df-jb-ac"
                          style={{ marginTop: '20px' }}
                        >
                          <Space
                            align="center"
                            split={<Divider type="vertical" />}
                          >
                            <div className={styles.like_icon}>
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  if (is_like) {
                                    handlePostIdeaDownvote(id);
                                  } else {
                                    handlePostIdeaUpvote(id);
                                  }
                                }}
                              >
                                {is_like ? <LikeFilled /> : <LikeOutlined />}
                              </span>
                              <CustomText
                                font="medium"
                                color="primary"
                                className={`${styles.customtext}${
                                  like_count ? ` cursor-pointer` : ''
                                }`}
                                onClick={() => {
                                  if (like_count) {
                                    setShowLikes(true);
                                    setLikes(Likes);
                                  }
                                }}
                              >
                                {upvotesDisplayText}
                              </CustomText>
                            </div>
                            {comment_count > 0 ? (
                              <a href={`/ideas_view/${id}#comments`}>
                                <CustomText
                                  font="medium"
                                  color="primary"
                                >{`${comment_count} Comment(s)`}</CustomText>
                              </a>
                            ) : null}
                          </Space>
                          <a href={`/ideas_view/${id}`}>
                            <CustomText color="4">{`More`}</CustomText>
                          </a>
                        </Space>
                      </Space>
                    </CustomCard>
                  </Col>
                );
              })
            : // <Empty
              //   className="w-100 text-center mt-4"
              //   description={
              //     <CustomText font="medium" color="primary">
              //       No ideas to show. Share one now!
              //     </CustomText>
              //   }
              // />
              ''}
        </Row>
        <InfiniteScroll
          dataLength={allIdeas?.length}
          next={() =>
            setPage(pagination?.next_page ? pagination?.next_page : 1)
          }
          hasMore={allIdeas?.length < pagination?.total_records}
          loader={
            <Skeleton
              avatar
              paragraph={{
                rows: 1
              }}
              active
            />
          }
          endMessage={<Divider plain>You have reached the end 🙂</Divider>}
          scrollableTarget="scrollableDiv"
        >
          <Row gutter={8}>
            {allIdeas.length
              ? allIdeas.map((item) => {
                  const {
                    id,
                    // user_name,
                    problem,
                    // solution,
                    comment_count,
                    like_count,
                    is_like,
                    User,
                    Area,
                    Likes,
                    subarea,
                    is_started,
                    idea_tags_id,
                    idea_tag,
                    action_tag
                  } = item;

                  const upvotesDisplayText = `${
                    Likes.length
                      ? `${
                          Likes[0]?.full_name?.length > 10
                            ? Likes[0]?.full_name.substring(0, 10) + '...'
                            : Likes[0]?.full_name
                        }`
                      : ''
                  }${
                    like_count
                      ? like_count - 1 > 0
                        ? ` and ${like_count - 1} others`
                        : ''
                      : '0 upvotes'
                  }`;

                  return (
                    <Col key={id} sm={12} xs={24} className="mt-3">
                      <CustomCard
                        leftBorder={true}
                        className={`p-3 ${styles.card_container}`}
                      >
                        <Space className="w-100" direction="vertical" size={12}>
                          <div className="d-flex justify-content-between align-items-start">
                            <Space size={12} align="center">
                              <UserMenu dropdown={false} name={User} />
                              <Space direction="vertical" size={0}>
                                <CustomText font="bold" size={15}>
                                  {User?.first_name + ' ' + User?.last_name}
                                </CustomText>
                                <CustomText color={3}>{`@${User?.email.slice(
                                  0,
                                  User?.email.indexOf('@')
                                )}`}</CustomText>
                              </Space>
                            </Space>
                            <Space direction="vertical" size={0} align="end">
                              <Space>
                                {userDetails?.user_details?.role === 'admin' ? (
                                  /***************************add new tag for admin with ne dropdown  */
                                  <>
                                    <Dropdown
                                      overlay={
                                        <Menu>
                                          {actionTags.map((item) => {
                                            const { id: action_tags_id, name } =
                                              item;

                                            return (
                                              <Menu.Item
                                                key={action_tags_id}
                                                // disabled={
                                                //   action_tags_id == action_tags_id
                                                // }
                                                onClick={() => {
                                                  handlePostIdeaTag(
                                                    id,
                                                    action_tags_id,
                                                    name
                                                  );
                                                }}
                                              >
                                                {/* {startCase(name)} */}
                                                {name ==
                                                'Great idea – to be taken up'
                                                  ? 'Great Idea - To Be Taken Up'
                                                  : startCase(name || '')}
                                              </Menu.Item>
                                            );
                                          })}
                                        </Menu>
                                      }
                                    >
                                      <TagOutlined
                                        className={`cursor-pointer ${styles.stared}`}
                                      />
                                    </Dropdown>
                                    <Dropdown
                                      overlay={
                                        <Menu>
                                          {tags.map((item) => {
                                            const { id: tag_id, name } = item;

                                            return (
                                              <Menu.Item
                                                key={tag_id}
                                                disabled={
                                                  idea_tags_id == tag_id
                                                }
                                                onClick={() => {
                                                  handlePostIdeaTag(id, tag_id);
                                                }}
                                              >
                                                {/* {startCase(name)} */}
                                                {name ==
                                                'Great idea – to be taken up'
                                                  ? 'Great Idea - To Be Taken Up'
                                                  : startCase(name || '')}
                                              </Menu.Item>
                                            );
                                          })}
                                        </Menu>
                                      }
                                    >
                                      <TagOutlined
                                        className={`cursor-pointer ${styles.deletePost}`}
                                      />
                                    </Dropdown>
                                  </>
                                ) : (
                                  // </>
                                  ''
                                )}
                                {User?.id === userDetails?.user_details?.id ||
                                userDetails?.user_details?.role === 'admin' ? (
                                  <>
                                    <FormOutlined
                                      onClick={() =>
                                        history.push(`suggest-idea/${id}`)
                                      }
                                      className={styles.deletePost}
                                    />
                                    <Popconfirm
                                      title="Are you sure you want to delete this suggestion?"
                                      visible={visibility(User?.id)}
                                      onConfirm={() => {
                                        handleDeletePost({
                                          id: id
                                        });
                                      }}
                                      onCancel={() => {
                                        setShowDisablePopup({});
                                      }}
                                      okText="Delete"
                                      cancelText="No"
                                      okButtonProps={{
                                        type: 'danger'
                                      }}
                                    >
                                      <DeleteOutlined
                                        onClick={() => {
                                          setShowDisablePopup({
                                            [User?.id]: true
                                          });
                                        }}
                                        className={styles.deletePost}
                                      />
                                    </Popconfirm>
                                  </>
                                ) : (
                                  ''
                                )}
                                {userDetails?.user_details?.role === 'admin' ? (
                                  is_started ? (
                                    <StarFilled
                                      className={styles.stared}
                                      onClick={() => {
                                        handlePostUnStared(id);
                                      }}
                                    />
                                  ) : (
                                    <StarOutlined
                                      className={styles.stared}
                                      onClick={() => {
                                        handlePostStared(id);
                                      }}
                                    />
                                  )
                                ) : (
                                  ''
                                )}
                              </Space>
                              {userDetails?.user_details?.role === 'user' &&
                              // idea_tag?.name !== 'Not Feasible' &&
                              // idea_tag?.name !== 'For Information'
                              idea_tag?.name !== 'Ignore' &&
                              idea_tag?.name !== 'Taken Up For Future' &&
                              idea_tag?.name !== 'Comments Only' &&
                              idea_tag?.name !== 'TBD' ? (
                                idea_tag ? (
                                  <Tag color="#ED2024" className="m-0">
                                    {/* {startCase(idea_tag?.name || '')} */}
                                    {idea_tag?.name ==
                                    'Great idea – to be taken up'
                                      ? 'Great Idea - To Be Taken Up'
                                      : startCase(idea_tag?.name || '')}
                                  </Tag>
                                ) : null
                              ) : userDetails?.user_details?.role ===
                                'admin' ? (
                                idea_tag ? (
                                  <>
                                    <Tag color="#ED2024" className="m-0">
                                      {idea_tag?.name ==
                                      'Great idea – to be taken up'
                                        ? 'Great Idea - To Be Taken Up'
                                        : startCase(idea_tag?.name || '')}
                                    </Tag>
                                    <Tag
                                      color="#ED2024"
                                      style={{ backgroundColor: '#ffb211' }}
                                      className="m-0"
                                    >
                                      {action_tag?.name ==
                                      'Great idea – to be taken up'
                                        ? 'Great Idea - To Be Taken Up'
                                        : startCase(action_tag?.name || '')}
                                    </Tag>
                                  </>
                                ) : null
                              ) : null}
                            </Space>
                            {/* <MoreOutlined className={styles.more_icon} /> */}
                          </div>
                          <div style={{ height: '60px' }}>
                            <CustomText font="medium" className="d-block">
                              {Area?.area?.length > 55 ? (
                                <Tooltip placement="top" title={Area?.area}>
                                  {Area?.area?.substring(0, 55) + '...'}
                                </Tooltip>
                              ) : (
                                Area?.area
                              )}
                            </CustomText>
                            <CustomText color={2} size={12} className="d-block">
                              {subarea?.sub_area ? subarea?.sub_area : ''}
                            </CustomText>
                            <CustomText color={2}>
                              {problem.length > 90 ? (
                                <Tooltip placement="top" title={problem}>
                                  {problem.substring(0, 87) + '...'}
                                </Tooltip>
                              ) : (
                                problem
                              )}
                            </CustomText>
                          </div>
                          <Space
                            className="df-jb-ac"
                            style={{ marginTop: '20px' }}
                          >
                            <Space
                              align="center"
                              split={<Divider type="vertical" />}
                            >
                              <div className={styles.like_icon}>
                                <span
                                  className="cursor-pointer"
                                  onClick={() => {
                                    if (is_like) {
                                      handlePostIdeaDownvote(id);
                                    } else {
                                      handlePostIdeaUpvote(id);
                                    }
                                  }}
                                >
                                  {is_like ? <LikeFilled /> : <LikeOutlined />}
                                </span>
                                <CustomText
                                  font="medium"
                                  color="primary"
                                  className={`${styles.customtext}${
                                    like_count ? ` cursor-pointer` : ''
                                  }`}
                                  onClick={() => {
                                    if (like_count) {
                                      setShowLikes(true);
                                      setLikes(Likes);
                                    }
                                  }}
                                >
                                  {upvotesDisplayText}
                                </CustomText>
                              </div>
                              {comment_count > 0 ? (
                                <a href={`/ideas_view/${id}#comments`}>
                                  <CustomText
                                    font="medium"
                                    color="primary"
                                  >{`${comment_count} Comment(s)`}</CustomText>
                                </a>
                              ) : null}
                            </Space>
                            <a href={`/ideas_view/${id}`} target={`blank${id}`}>
                              <CustomText color="4">{`More`}</CustomText>
                            </a>
                          </Space>
                        </Space>
                      </CustomCard>
                    </Col>
                  );
                })
              : // <Empty
                //   className="w-100 text-center mt-4"
                //   description={
                //     <CustomText font="medium" color="primary">
                //       No ideas to show. Share one now!
                //     </CustomText>
                //   }
                // />
                ''}
          </Row>
        </InfiniteScroll>
      </CustomCard>
      {showLikes ? (
        <UsersListModal
          open={showLikes}
          title="Upvotes"
          onCancel={() => setShowLikes(false)}
          data={likes}
        />
      ) : null}
    </Spin>
  );
};

export default IdeasPage;
