import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton, Space } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import CustomCard from 'common/CustomCard';
import CustomText from 'common/CustomText';
import styles from './events.module.less';
import { getAllEvents } from '../../redux/slice';
import moment from 'moment';
import { useMsal } from '@azure/msal-react';
import LocalStorage from 'utilities/localStorage';
import { logout } from 'features/login/redux/slice';
import { history } from 'app/history';

const UI = ({ getAllEventsLoading, allEvents, expand, setExpand, count }) => {
  const upcoming = allEvents.filter(
    (allEvents) => allEvents.event_date >= moment().format('YYYY-MM-DD')
  );
  const past = allEvents.filter(
    (allEvents) => allEvents.event_date < moment().format('YYYY-MM-DD')
  );

  return (
    <>
      {getAllEventsLoading ? (
        <Skeleton className="mt-2" />
      ) : (
        <>
          <Space direction="vertical" size={0} className="mt-0">
            <Space direction="vertical" size={0}>
              {/* <CustomText size={12}>
                <InfoCircleOutlined /> Go to Phoenix Buzz for past event details
              </CustomText> */}
              <CustomText font="medium" size={12}>
                Upcoming
              </CustomText>
              {/* <Space size="middle" align="start" className="mt-1"> */}
              {/* <CalendarOutlined className={styles.calendar_icon} /> */}
              {(expand ? upcoming.slice(0, count || 2) : upcoming).map(
                (item) => {
                  const { id, event_name, event_date, event_url } = item;

                  return (
                    <div
                      key={id}
                      className={`df-jb-ac cursor-pointer ${styles.container}`}
                      onClick={() => history.push(`${event_url}`)}
                    >
                      <Space size="middle" align="start">
                        <CalendarOutlined className={styles.calendar_icon} />
                        <CustomText color={2}>{`${event_name} (${moment(
                          event_date
                        ).format('MMM D')})`}</CustomText>
                      </Space>
                    </div>
                  );
                }
              )}
              {/* <CustomText color={2}>{'TPL Vision Statement'}</CustomText> */}
              {/* </Space> */}
            </Space>
            <Space direction="vertical" size={0}>
              <CustomText font="medium" size={12}>
                Past
              </CustomText>
              {(expand
                ? past.slice(0, count || 2)
                : past.slice(0, count || 5)
              ).map((item) => {
                const { id, event_name, event_date, event_url } = item;

                return (
                  <div
                    key={id}
                    className={`df-jb-ac cursor-pointer ${styles.container}`}
                    onClick={() => history.push(`${event_url}`)}
                  >
                    <Space size="middle" align="start">
                      <CalendarOutlined className={styles.calendar_icon} />
                      <CustomText color={2}>{`${event_name} (${moment(
                        event_date
                      ).format('MMM D')})`}</CustomText>
                    </Space>
                  </div>
                );
              })}
              {allEvents.slice(0, count || 2).length < allEvents.length ? (
                <CustomText
                  font="medium"
                  size={12}
                  color="primary"
                  className="cursor-pointer"
                  onClick={() => {
                    if (expand) {
                      setExpand(false);
                    } else {
                      setExpand(true);
                    }
                  }}
                >
                  {expand ? 'More' : 'Less'}
                </CustomText>
              ) : null}
            </Space>
          </Space>
        </>
      )}
    </>
  );
};

const Events = ({ className, style = {}, count, noCard }) => {
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const { allEvents, getAllEventsLoading, code } = useSelector(
    (state) => state.customLayout
  );
  const [expand, setExpand] = useState(true);
  useEffect(() => {
    // instance.logoutRedirect({
    //   account: LocalStorage.homeAccountId,
    //   postLogoutRedirectUri: '/',
    //   mainWindowRedirectUri: '/',
    //   onRedirectNavigate: () => {
    //     dispatch(logout());
    //     return true;
    //   }
    // });
    handleGetAllEvents();
  }, []);
  if (code === 401) {
    instance.logoutRedirect({
      account: LocalStorage.homeAccountId,
      postLogoutRedirectUri: '/',
      mainWindowRedirectUri: '/',
      onRedirectNavigate: () => {
        dispatch(logout());
        return true;
      }
    });
  }
  const handleGetAllEvents = () => {
    dispatch(getAllEvents());
  };

  if (noCard) {
    return (
      <UI
        getAllEventsLoading={getAllEventsLoading}
        allEvents={allEvents}
        expand={expand}
        setExpand={setExpand}
        count={count}
      />
    );
  }

  return (
    <CustomCard
      title="Events"
      topBorder={true}
      className={`p-1 px-2 ${className ? ` ${className}` : ''}`}
      style={style}
    >
      <UI
        getAllEventsLoading={getAllEventsLoading}
        allEvents={allEvents}
        expand={expand}
        setExpand={setExpand}
        count={count}
      />
    </CustomCard>
  );
};

export default Events;
