import apiClient from 'utilities/apiClient';

export const quizzes = (payload) => {
  return apiClient.get(`${apiClient.Urls.allQuizzes}`, payload, true);
};

export const addEventDetails = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.addEvent}${url}`, payload, true);
};

export const removeQuize = (url, payload) => {
  return apiClient.delete(`${apiClient.Urls.addQuiz}${url}`, payload, true);
};

export const all_urls = (payload) => {
  return apiClient.get(`${apiClient.Urls.allUrls}`, payload, true);
};
