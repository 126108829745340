import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import ArticlePage from './ArticlePage';
import Layout from 'common/CustomLayout';
import {
  deleteArticleComment,
  deleteArticleLike,
  getArticleById,
  postArticleComment,
  postArticleLike
} from '../redux/slice';

const LeaderSpeakArticle = () => {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const { article, getArticleByIdLoading, deleteArticleCommentLoading } =
    useSelector((state) => state.leaderSpeak);

  const [showLikes, setShowLikes] = useState(false);
  const [likes, setLikes] = useState([]);
  const [commentText, setCommentText] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);

  useEffect(() => {
    handleGetArticleById();
  }, []);

  const handleGetArticleById = () => {
    dispatch(getArticleById(id)).then(({ payload }) => {
      if (payload?.success && payload?.data?.video_documents?.length) {
        setSelectedVideo(payload.data.video_documents[0]);
      }
    });
  };

  const handlePostArticleLike = (article_id) => {
    dispatch(postArticleLike(article_id)).then(({ payload }) => {
      if (payload.success) {
        dispatch(handleGetArticleById());
      }
    });
  };

  const handleDeleteArticleLike = (article_id) => {
    dispatch(deleteArticleLike(article_id)).then(({ payload }) => {
      if (payload.success) {
        dispatch(handleGetArticleById());
      }
    });
  };

  const handlePostArticleComment = (article_id) => {
    let payload = { article_id, user_comment: commentText };

    dispatch(postArticleComment(payload)).then(({ payload }) => {
      if (payload?.success) {
        handleGetArticleById();
        setCommentText(null);
      }
    });
  };

  const handleDeleteArticleComment = (comment_id) => {
    dispatch(deleteArticleComment(comment_id)).then(({ payload }) => {
      if (payload?.success) {
        handleGetArticleById();
      }
    });
  };

  return (
    <Layout>
      <Spin spinning={getArticleByIdLoading}>
        <ArticlePage
          data={article}
          handlePostArticleLike={handlePostArticleLike}
          handleDeleteArticleLike={handleDeleteArticleLike}
          showLikes={showLikes}
          setShowLikes={setShowLikes}
          likes={likes}
          setLikes={setLikes}
          commentText={commentText}
          setCommentText={setCommentText}
          handlePostArticleComment={handlePostArticleComment}
          handleDeleteArticleComment={handleDeleteArticleComment}
          deleteArticleCommentLoading={deleteArticleCommentLoading}
          selectedVideo={selectedVideo}
          setSelectedVideo={setSelectedVideo}
        />
      </Spin>
    </Layout>
  );
};

export default LeaderSpeakArticle;
