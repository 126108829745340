import EventsPage from './components/EventsPage';
import Layout from 'common/CustomLayout';

const Events = () => {
  return (
    <Layout>
      <EventsPage />
    </Layout>
  );
};

export default Events;
