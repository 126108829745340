// import bg1 from './images/1.jpeg';
import bg2 from './images/2.png';
import bg3 from './images/3.png';
// import bg4 from './images/4.jpg';
// import bg5 from './images/5.png';
// import bg6 from './images/6.jpg';
// import bg7 from './images/7.png';
// import bg8 from './images/8.png';
// import bg9 from './images/9.jpg';
// import bg10 from './images/10.png';
import banner01 from './images/Banner01.jpg';
import banner04 from './images/Banner04.jpg';
import banner05 from './images/Banner05.jpg';
import banner06 from './images/Banner06.svg';
import banner07 from './images/Banner07.svg';
import banner08 from './images/Banner08.jpg';
import banner09 from './images/Banner09.png';
import our_mission from './images/our_mission.png';
import our_vision from './images/our_vision.png';

const data = [
  {
    title: '',
    image: banner01
  },
  {
    title: 'Transforming TPL',
    image: bg2,
    bgColor: '#a1c2e5',
    showPhoenixBird: true,
    data: [
      {
        icon: 'images/grow.svg',
        label: 'Grow profitably'
      },
      {
        icon: 'images/upgrade.svg',
        label: 'Upgrade our capabilities'
      },
      {
        icon: 'images/future.svg',
        label: 'Be future ready'
      },
      {
        icon: 'images/tplway.svg',
        label: 'Create a TPL way of working'
      }
    ],
    left: {
      title: 'Phoenix - Our Identity',
      data: [
        {
          label: 'The Phoenix bird symbolizes regeneration and renewal'
        },
        {
          label:
            'It reflects our intention to reinvent ourselves into a future-ready version and take TPL to greater heights'
        }
      ]
    },
    right: {
      title: 'Journey so far.....',
      data: [
        {
          label: 'New mission, vision, values launched'
        },
        {
          label: 'Portfolio strategy and Sector prioritization completed'
        },
        {
          label:
            '7 Transformation Units driving process design across the Project Lifecycle'
        },
        {
          label:
            'Dedicated Value Transformation Unit (TU) to focus on value creation across organization'
        },
        {
          label: '95+ initiatives focusing on process improvements underway'
        },
        {
          label: '100+ value creation initiatives underway'
        }
      ]
    }
    // right: {
    //   title: 'Transformation Pillars',
    //   // data: [
    //   //   {
    //   //     label: 'The Phoenix bird symbolizes regeneration and renewal'
    //   //   },
    //   //   {
    //   //     label:
    //   //       'It reflects our intention to reinvent ourselves into a future-ready version and take TPL to greater heights'
    //   //   }
    //   // ]
    //   data: [
    //     {
    //       icon: 'images/bank.svg',
    //       label: 'Business Strategy',
    //       description:
    //         'Portfolio strategy, Segment mix, Client mix, New emerging sectors'
    //     },
    //     {
    //       icon: 'images/route.svg',
    //       label: 'Systems & Processes',
    //       description:
    //         'Technology led simple, standardized, best-in-class processes for an agile organization'
    //     },
    //     {
    //       icon: 'images/lightbulb.svg',
    //       label: 'Value',
    //       description: 'Value creation through focus on profitability'
    //     }
    //   ]
    // }
  },
  {
    title: 'Mission, Vision & Values',
    image: bg3,
    bgColor: '#bbf1fd',
    data: [
      {
        icon: our_mission,
        image: 'images/mission.png',
        label: 'OUR MISSION',
        description: 'Transforming Lives By Building A Better World'
      },
      {
        icon: our_vision,
        image: 'images/vision.png',
        label: 'OUR VISION',
        description:
          'Delivering Predictable & Sustainable Projects Through Innovation & Technology'
      }
    ]
  },
  {
    title: '',
    image: banner04
  },
  {
    title: '',
    image: banner05
  },
  {
    title: '',
    image: banner06
  },
  {
    title: '',
    image: banner07
  },
  {
    title: '',
    image: banner08
  },
  {
    title: '',
    image: banner09
  }
  // {
  //   title: 'Phoenix Verticals',
  //   image: bg2,
  //   showPhoenixBird: true,
  //   bgColor: '#dde7e9',
  //   data: [
  //     {
  //       // icon: 'images/building.svg',
  //       label: 'Enterprise Strategy',
  //       options: [
  //         'Mission, Vision and Values',
  //         'Portfolio strategy and Sector prioritization',
  //         'Business specific strategy creation'
  //       ]
  //     },
  //     {
  //       // icon: 'images/building.svg',
  //       label: 'Systems & Processes',
  //       options: [
  //         'Process Simplification, standardization, upgradation & new roll-outs',
  //         'Digital & tech led EPC company with best-in-class systems',
  //         'Single source of truth” enabled by master data management & analytics'
  //       ]
  //     },
  //     {
  //       // icon: 'images/building.svg',
  //       label: 'Value',
  //       options: [
  //         'Focusing on identified value creation levers to optimize Design, Procurement, Construction Methodology &  Overhead'
  //       ]
  //     }
  //   ],
  //   right: {
  //     title: 'Journey so far.....',
  //     data: [
  //       {
  //         label: 'New mission, vision, values launched'
  //       },
  //       {
  //         label: 'Portfolio strategy and Sector prioritization completed'
  //       },
  //       {
  //         label: '8 Transformation Units (TUs) running as part of Phoenix'
  //       },
  //       {
  //         label:
  //           'Dedicated Value Transformation Unit (TU) to focus on value creation across organization'
  //       },
  //       {
  //         label: '80+ initiatives focusing on process improvements underway'
  //       },
  //       {
  //         label: '100+ value creation initiatives underway'
  //       }
  //     ]
  //   }
  // },
  // // {
  // //   title: 'Mission, Vision & Values',
  // //   image: bg3,
  // //   bgColor: '#bbf1fd',
  // //   data: [
  // //     {
  // //       icon: our_mission,
  // //       image: 'images/mission.png',
  // //       label: 'OUR MISSION',
  // //       description: 'Transforming Lives By Building A Better World'
  // //     },
  // //     {
  // //       icon: our_vision,
  // //       image: 'images/vision.png',
  // //       label: 'OUR VISION',
  // //       description:
  // //         'Delivering Predictable & Sustainable Projects Through Innovation & Technology'
  // //     }
  // //   ]
  // // },
  // {
  //   title: 'Tendering & Estimation TU',
  //   image: bg4,
  //   incline: 'left',
  //   inclineBg: '#BACBD7',
  //   left: {
  //     title: 'Objectives',
  //     data: [
  //       {
  //         label:
  //           'Revision and standardization of the tendering & estimation process'
  //       },
  //       {
  //         label: 'Process Improvements:',
  //         options: [
  //           'Reinforce clear roles and responsibilities',
  //           'Strengthen Estimation process',
  //           'Robust risk management framework',
  //           'Seamless HOTO process'
  //         ]
  //       },
  //       {
  //         label:
  //           'Process digitization through digital tools (Go-No Go, CRM, Digitised risk registers)'
  //       }
  //     ]
  //   },
  //   right: {
  //     title: 'Journey so far.....',
  //     data: [
  //       {
  //         label:
  //           'Revised tendering process for projects >Rs.1000cr or new Lines of Business (LoBs) with',
  //         options: [
  //           'introduction of bid manager',
  //           'peer review mechanism',
  //           'standardized checklist & templates'
  //         ]
  //       },
  //       {
  //         label: 'Go-No Go App for bid management launched'
  //       },
  //       {
  //         label: 'Tender Red Flags in place'
  //       },
  //       {
  //         label: 'Customer Meeting log rolled out'
  //       },
  //       {
  //         label:
  //           'Creation of separate functions for Business Development and Tendering & Estimation function'
  //       },
  //       {
  //         label:
  //           'Roll out of customer engagement and relationship management framework'
  //       }
  //     ]
  //   }
  // },
  // {
  //   title: 'Design & Engineering TU',
  //   image: bg5,
  //   incline: 'right',
  //   inclineBg: '#bab9b7',
  //   left: {
  //     title: 'Objectives',
  //     data: [
  //       {
  //         label: 'Business-wise engineering strategy formulation'
  //       },
  //       {
  //         label: 'Central Engineering org creation'
  //       },
  //       {
  //         label:
  //           'Implementing Value Enhancement framework across the project lifecycle'
  //       },
  //       {
  //         label: 'Engineering partners approval process'
  //       },
  //       {
  //         label:
  //           'Optimal resource allocation, utilization & capability enhancement'
  //       },
  //       {
  //         label: 'Design libraries with standardized & modularized components'
  //       }

  //       // {
  //       //   label: 'Process Improvements:',
  //       //   options: [
  //       //     'Engineering partners evaluation & approval process',
  //       //     'Optimal resource allocation, utilization & capability enhancement',
  //       //     'Digitized central repositories for DMS & knowledge management',
  //       //     'Design libraries with standardized & modularized components'
  //       //   ]
  //       // }
  //     ]
  //   },
  //   right: {
  //     title: 'Journey so far.....',
  //     data: [
  //       // {
  //       //   label: 'New roll outs:',
  //       //   options: [
  //       //     'Timesheets for capturing design workhours',
  //       //     'Curated list of approved Engineering Partners'
  //       //   ]
  //       // },
  //       {
  //         label:
  //           'Introduction of Central Engineering function with clear roles and responsibilities'
  //       },
  //       {
  //         label: 'Timesheets roll out for better resource management'
  //       },
  //       {
  //         label: 'Engineering Partner approval process in place'
  //       },
  //       {
  //         label: 'Competency mapping framework in place'
  //       },
  //       {
  //         label: 'Recommendations for optimized CAD tools landscape in place'
  //       },
  //       {
  //         label:
  //           'Refinement of value enhancement framework incorporating business specific value drivers'
  //       }
  //     ]
  //   }
  // },
  // {
  //   title: 'Supply Chain Management TU',
  //   image: bg6,
  //   incline: 'left',
  //   inclineBg: '#FFE5AE',
  //   left: {
  //     title: 'Objectives',
  //     data: [
  //       {
  //         label: 'Revised Procure-to-Pay process'
  //       },
  //       {
  //         label: 'Increased use of e-procurement platforms / aggregators'
  //       },
  //       {
  //         label: 'Revamp of logistics, stores and materials management process'
  //       },
  //       {
  //         label: 'Database management (last purchase price, vendor base etc.)'
  //       },
  //       {
  //         label:
  //           'Vendor Management - on-boarding, relations, performance evaluation, grievances, standardised engagement T&Cs'
  //       },
  //       {
  //         label:
  //           'Category management - Frame agreements, fact pacts & knowledge database, KPIs for monitoring'
  //       },
  //       {
  //         label: 'Source to Pay process digitization'
  //       },
  //       {
  //         label: 'Demand Forecasting'
  //       }
  //     ]
  //   },
  //   right: {
  //     title: 'Journey so far.....',
  //     data: [
  //       {
  //         label: 'Rollout of Digital LoI / LoA'
  //         // options: [
  //         //   'Pilot items for increased usage on aggregator platform',
  //         //   'Fuel contract with IOCL for both direct & indirect procurement',
  //         //   'Rollout of Digital LOA/LOI',
  //         //   'Frame agreements & fact packs for select categories',
  //         //   'Defined SCM boundary conditions along with defined RACI & procurement categories'
  //         // ]
  //       },
  //       {
  //         label:
  //           'Increased usage of aggregator platform for fragmented site spend'
  //       },
  //       {
  //         label: 'Re-negotiated contracts for fuel, bitumen and Emulsion'
  //       },
  //       {
  //         label:
  //           'Defined SCM roles & responsibilities along with RACI and category definitions'
  //       },
  //       {
  //         label: 'Frame agreements & fact packs for select categories'
  //       }
  //     ]
  //   }
  // },
  // {
  //   title: 'Project Management TU',
  //   image: bg7,
  //   incline: 'right',
  //   inclineBg: '#9EBCCF',
  //   left: {
  //     title: 'Objectives',
  //     data: [
  //       {
  //         label: 'TPL way of project management'
  //       },
  //       {
  //         label:
  //           'Simplifying systems and processes to bring in ease of doing business'
  //       },
  //       {
  //         label:
  //           'Automated KPI dashboards and data monitoring mechanisms across projects and functions'
  //       },
  //       {
  //         label:
  //           'Revised project initiation, closure and other processes to ensure faster, standardized, efficient way of working'
  //       },
  //       {
  //         label: 'Productivity and wastages monitoring during project execution'
  //       },
  //       {
  //         label: 'Digitized central repositories for knowledge management'
  //       },
  //       {
  //         label: 'Risk management framework across project lifecycle'
  //       },
  //       {
  //         label: 'Digital tools for Project Management'
  //       }
  //     ]
  //   },
  //   right: {
  //     title: 'Journey so far.....',
  //     data: [
  //       {
  //         label: 'Streamlined MB approval Process'
  //       },
  //       {
  //         label: 'SBG wise emergency cash process roll out'
  //       },
  //       {
  //         label: 'Process roll out for EPC workshop'
  //       },
  //       {
  //         label: 'Revised ORM templates'
  //       },
  //       {
  //         label: 'Project execution plan in place'
  //       }
  //     ]
  //   }
  // },
  // {
  //   title: 'Claims & Contracts TU',
  //   image: bg8,
  //   incline: 'left',
  //   inclineBg: '#C6EBE9',
  //   left: {
  //     title: 'Objectives',
  //     data: [
  //       {
  //         label:
  //           'Updating contracts & claims management EPM processes (pre-award and post-award)'
  //       },
  //       {
  //         label: 'Enhancing claim tracking mechanism'
  //       },
  //       {
  //         label:
  //           'Identifying new claims plus improving timeline and quantum in current claims'
  //       },
  //       {
  //         label: 'Project lifecycle risk assessment and quantification'
  //       },
  //       {
  //         label: 'Upgradation of claim management tool'
  //       }
  //     ]
  //   },
  //   right: {
  //     title: 'Journey so far.....',
  //     data: [
  //       {
  //         label: 'Structured claim identification sprints organized'
  //       },
  //       {
  //         label:
  //           'Updated claims tracking framework with larger focus on claim realization'
  //       },
  //       {
  //         label:
  //           'Introduction of claim trigger checklist to aid claim identification procedure'
  //       },
  //       {
  //         label:
  //           'Revision of EPM processes - Claim & Delay Management, Variation Management, Correspondence Management, Arbitration Management'
  //       }
  //     ]
  //   }
  // },
  // {
  //   title: 'Organization & Culture TU',
  //   image: bg9,
  //   incline: 'right',
  //   inclineBg: '#ECD2C0',
  //   left: {
  //     title: 'Objectives',
  //     data: [
  //       {
  //         label: 'Revision of functional and business organisation structure'
  //       },
  //       {
  //         label: 'Role clarity across business and functions'
  //       },
  //       {
  //         label: 'New HRMS platform and end-to-end digitisation of HR processes'
  //       },
  //       {
  //         label:
  //           'Talent development with enhanced learning and mobility opportunities'
  //       },
  //       {
  //         label: 'Drive and strengthen organisational values and culture'
  //       }
  //     ]
  //   },
  //   right: {
  //     title: 'Journey so far.....',
  //     data: [
  //       {
  //         label: 'New Mission, Vision & Values launched'
  //         // options: [
  //         //   'Mission, Vision & Values',
  //         //   'Performance Improvement Plan (PIP) policy',
  //         //   'New org structure for E&I'
  //         // ]
  //       },
  //       {
  //         label: 'Performance Improvement Plan (PIP) policy roll out'
  //         // options: ['Internal Job Posting (IJP) policy', 'L&D policy']
  //       },
  //       {
  //         label: 'Revised org structure for E&I'
  //       },
  //       {
  //         label: 'Internal Job Posting (IJP) policy revision'
  //       },
  //       {
  //         label: 'L&D policy revision'
  //       },
  //       {
  //         label: 'Centre Stage – Phoenix rewards & recognition program launched'
  //       },

  //     ]
  //   }
  // },
  // {
  //   title: 'Digital TU',
  //   image: bg10,
  //   incline: 'left',
  //   inclineBg: '#DBB2CB',
  //   left: {
  //     title: 'Objectives',
  //     data: [
  //       {
  //         label: 'Transform TPL to a technology led Digital EPC Company with',
  //         options: [
  //           'best in class systems and processes',
  //           'upgraded core IT & Digital systems',
  //           'state-of-the-art cyber security'
  //         ]
  //       },
  //       {
  //         label: 'Finalize futuristic ERP platform architecture'
  //       },
  //       {
  //         label: 'Digitize Source to Pay process'
  //       },
  //       {
  //         label:
  //           'Rationalize organization wide Apps with unified digital store to access Apps'
  //       },
  //       {
  //         label:
  //           'Institutionalize master data, database management and data analytics'
  //       },
  //       {
  //         label: 'Create central repositories for DMS & knowledge management'
  //       }
  //     ]
  //   },
  //   right: {
  //     title: 'Journey so far.....',
  //     data: [
  //       {
  //         label:
  //           'Digital Apps roll outs: Timesheet for Engineering; LOA/LOI, NFA, Gate Pass for Logistics; Go-No Go for bid mgmt'
  //         // options: [
  //         //   'Oracle ERP infrastructure with state of art DR systems',
  //         //   'Planning and scheduling tool Primavera P6 EPPM',
  //         //   'Business continuity plans with cyber security'
  //         // ]
  //       },
  //       {
  //         label: 'Launched Digital Store to access org wide Apps'
  //         // options: [
  //         //   'Digital Apps: Timesheet for Engineering; LOA/LOI, NFA, Gate Pass for Logistics; Go-No Go for bid mgmt',
  //         //   'Digital Store to access org wide Apps'
  //         // ]
  //       },
  //       {
  //         label: 'Easier & faster project creation process roll out'
  //         // options: [
  //         //   'Easier & faster project creation',
  //         //   'Standardised payment terms for PAN'
  //         // ]
  //       },
  //       {
  //         label: 'Roll out of standardised payment terms for PAN'
  //       },
  //       {
  //         label:
  //           'Upgraded Oracle ERP infrastructure with state of art DR systems'
  //       },
  //       {
  //         label: 'Planning and scheduling tool Primavera P6 EPPM upgrade'
  //       },
  //       {
  //         label: 'Upgraded business continuity plans with cyber security'
  //       }
  //     ]
  //   }
  // }
];

export default data;
