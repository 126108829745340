import { useState } from 'react';
import Layout from 'common/CustomLayout';
import CentreStagePage from './components/CentreStagePage';

const CentreStage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [content, setContent] = useState(0);

  return (
    <Layout>
      <CentreStagePage
        openModal={openModal}
        setOpenModal={setOpenModal}
        setContent={setContent}
        content={content}
      />
    </Layout>
  );
};

export default CentreStage;
