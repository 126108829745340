import React, { useState } from 'react';
import {
  Form,
  Input,
  Space,
  DatePicker,
  Switch,
  Radio,
  message,
  Upload,
  Row,
  Col
} from 'antd';
import FormItem from 'common/FormItem';
import CustomButton from 'common/CustomButton';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
import { addQuiz } from '../../redux/slice';
import moment from 'moment';
import {
  PlusCircleOutlined,
  DeleteOutlined,
  HolderOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import CustomText from 'common/CustomText';

const CreateQuizPage = () => {
  const [form] = Form.useForm();
  const [quizQuestion, setQuizQuestion] = useState([
    {
      question: '',
      options: [{ id: 1, name: '' }],
      right_answer: ''
    }
  ]);
  const dispatch = useDispatch();
  const [option, setOption] = useState(0);
  const [isactive, setIsactive] = useState(true);
  const [isfeature, setIsfeature] = useState(false);
  const [type, setType] = useState(true);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFinish = (values) => {
    const { name, post_images, expires_on, start_date, type } = values;
    let formData = new FormData();
    formData.append('type', type);
    formData.append('name', name);
    formData.append('question_options', JSON.stringify(quizQuestion));
    formData.append('is_active', isactive);
    formData.append('is_featured', isfeature);
    formData.append('expires_on', expires_on);
    formData.append('start_date', start_date);
    formData.append('image', post_images ? post_images[0].originFileObj : []);
    console.log(values);
    dispatch(addQuiz(formData)).then((response) => {
      console.log(response);
      message.success(response.payload.message);
      history.push('/quizzes-list');
    });
  };
  let removeFormFields = (index, i) => {
    let newFormValues = [...quizQuestion];
    quizQuestion[index].options.splice(i, 1);
    setQuizQuestion(newFormValues);
  };

  let removeQuestionFormFields = (index) => {
    let newFormValues = [...quizQuestion];
    newFormValues.splice(index, 1);
    setQuizQuestion(newFormValues);
  };

  const handleChange = (i, e) => {
    let newquizQuestion = [...quizQuestion];
    newquizQuestion[i][e.target.name] = e.target.value;
    setQuizQuestion(newquizQuestion);
  };
  const handleChangeOption = (index, i, e) => {
    let newquizQuestion = [...quizQuestion];
    newquizQuestion[index]['options'][i][e.target.name] = e.target.value;
    setQuizQuestion(newquizQuestion);
  };

  const addFormFields = () => {
    setQuizQuestion([
      ...quizQuestion,
      {
        question: '',
        options: [{ id: 1, name: '' }],
        right_answer: ''
      }
    ]);
  };
  const addFormOptionFields = (i) => {
    setOption(option + 1);
    quizQuestion[i].options.push({
      id: quizQuestion[i].options.length + 1,
      name: ''
    });
  };
  console.log(quizQuestion);

  const handleDrop = (index, droppedItem) => {
    console.log(index, droppedItem);
    if (!droppedItem.destination) return;
    var updatedItemList = [...quizQuestion[index].options];
    const [reorderedItemList] = updatedItemList.splice(
      droppedItem.source.index,
      1
    );
    updatedItemList.splice(droppedItem.destination.index, 0, reorderedItemList);
    console.log(updatedItemList);
    let newquizQuestion = [...quizQuestion];
    newquizQuestion[index]['options'] = updatedItemList;

    setQuizQuestion(newquizQuestion);
  };

  const onChange = (checked) => {
    setIsactive(checked);
  };
  const onChangefeature = (checked) => {
    setIsfeature(checked);
  };
  const onChangeRightAnswer = (id, index) => {
    let newquizQuestion = [...quizQuestion];
    newquizQuestion[index]['right_answer'] = id;
    setQuizQuestion(newquizQuestion);
  };

  const radioHandler = (type) => {
    setType(type);
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <FormItem
        label="Type"
        name="type"
        initialValue={type}
        rules={[
          {
            required: true,
            message: 'Required field'
          }
        ]}
      >
        <Radio.Group defaultValue={type}>
          <Radio value={false} onClick={() => radioHandler(false)}>
            Survey
          </Radio>
          <Radio value={true} onClick={() => radioHandler(true)}>
            Quiz
          </Radio>
        </Radio.Group>
      </FormItem>
      <FormItem
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Required field'
          }
        ]}
      >
        <Input allowClear placeholder="Enter the name of quiz/survey" />
      </FormItem>
      <Row gutter={16}>
        <Col span={8}>
          <FormItem
            label="Start Date"
            name="start_date"
            rules={[
              {
                required: true,
                message: 'Required field'
              }
            ]}
          >
            <DatePicker allowClear placeholder="Select start date" showTime />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label="Expires on"
            name="expires_on"
            rules={[
              {
                required: true,
                message: 'Required field'
              }
            ]}
          >
            <DatePicker
              allowClear
              placeholder="Select expiry date"
              showTime
              disabledDate={(current) => {
                return moment().add(-1, 'days') >= current;
              }}
            />
          </FormItem>
        </Col>
      </Row>
      <div>
        <Space size={44}>
          <FormItem
            label="Is active?"
            name="is_active"
            rules={[
              {
                required: false,
                message: 'Required field'
              }
            ]}
          >
            <Switch defaultChecked onChange={onChange} />
          </FormItem>
          <FormItem
            label="Is featured?"
            name="is_featured"
            rules={[
              {
                required: false,
                message: 'Required field'
              }
            ]}
          >
            <Switch defaultUnChecked onChange={onChangefeature} />
          </FormItem>
        </Space>
      </div>
      <FormItem label="Upload background image">
        <FormItem
          name="post_images"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          noStyle
        >
          <Upload.Dragger
            name="files"
            customRequest={({ onSuccess }) => {
              setTimeout(() => {
                onSuccess('ok');
              }, 0);
            }}
          >
            <p className="ant-upload-drag-icon">
              <InfoCircleOutlined />
            </p>
            <Space>
              <CustomText font="medium" color="primary">
                Click to upload
              </CustomText>
              <CustomText color={2}>or drag and drop</CustomText>
            </Space>
          </Upload.Dragger>
        </FormItem>
      </FormItem>
      {quizQuestion?.map((element, index) => (
        <>
          <Space size={14}>
            <FormItem
              key={index}
              label={`Question${index + 1}`}
              rules={[
                {
                  required: false,
                  message: 'Required field'
                }
              ]}
            >
              <Input
                allowClear
                placeholder="Enter question"
                value={element.question || ''}
                name="question"
                onChange={(e) => handleChange(index, e)}
                style={{ width: '600px' }}
              />
            </FormItem>
            {index > 0 ? (
              <CustomButton
                className="button add"
                type="button"
                onClick={() => removeQuestionFormFields(index)}
                style={{ float: 'right' }}
              >
                Remove Question
              </CustomButton>
            ) : !type ? null : (
              <CustomButton
                className="button add"
                type="button"
                onClick={() => addFormFields()}
                style={{ float: 'right' }}
              >
                Add Question
              </CustomButton>
            )}
          </Space>
          <DragDropContext
            onDragEnd={(droppedItem) => handleDrop(index, droppedItem)}
          >
            <Droppable droppableId="list-container">
              {(provided) => (
                <div
                  className="list-container"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {element.options.map((item, i) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id.toString()}
                      index={i}
                    >
                      {(provided) => (
                        <>
                          <div
                            className="item-container"
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <Space size={14} key={i}>
                              <HolderOutlined style={{ fontSize: '20px' }} />
                              <FormItem
                                key={i}
                                label={`Option${i + 1}`}
                                rules={[
                                  {
                                    required: false,
                                    message: 'Required field'
                                  }
                                ]}
                              >
                                <Input
                                  allowClear
                                  name="name"
                                  placeholder="Enter option"
                                  value={item.name || ''}
                                  onChange={(e) =>
                                    handleChangeOption(index, i, e)
                                  }
                                  style={{ width: '566px' }}
                                />
                              </FormItem>
                              {!type ? null : (
                                <FormItem
                                  key={i}
                                  name={`right_answer${index}`}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Required field'
                                    }
                                  ]}
                                  className="pl-2"
                                  style={{ marginTop: '22px' }}
                                >
                                  <Radio.Group>
                                    <Radio
                                      value={item.name}
                                      key={i}
                                      onChange={() =>
                                        onChangeRightAnswer(item.name, index)
                                      }
                                    ></Radio>
                                  </Radio.Group>
                                </FormItem>
                              )}
                              {i > 0 ? (
                                <CustomButton
                                  className="button add"
                                  type="button"
                                  onClick={() => removeFormFields(index, i)}
                                  style={{
                                    float: 'right',
                                    padding: '5px 15px 32px 15px',
                                    fontSize: '19px'
                                  }}
                                >
                                  <DeleteOutlined />
                                </CustomButton>
                              ) : (
                                <CustomButton
                                  className="button add"
                                  type="button"
                                  onClick={() => addFormOptionFields(index)}
                                  style={{
                                    float: 'right',
                                    padding: '5px 15px 32px 15px',
                                    fontSize: '19px'
                                  }}
                                >
                                  <PlusCircleOutlined />
                                </CustomButton>
                              )}
                            </Space>
                          </div>
                          <br />
                        </>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {/* </Space> */}
        </>
      ))}

      <FormItem className="text-right">
        <Space>
          <CustomButton type="primary" htmlType="submit">
            Save
          </CustomButton>
        </Space>
      </FormItem>
    </Form>
  );
};
export default CreateQuizPage;
