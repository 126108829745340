import React, { useEffect } from 'react';
import CustomModal from 'common/CustomModal';
import { Form, Input, Space } from 'antd';
import FormItem from 'common/FormItem';
import CustomButton from 'common/CustomButton';

const AddCategoryModal = ({
  showAddModal,
  handleCancel,
  handleAddCategory,
  editMode,
  selectedCategory
}) => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    if (editMode) {
      values['id'] = selectedCategory.id;
    }
    handleAddCategory(values);
  };
  console.log(selectedCategory);
  useEffect(() => {
    if (editMode && selectedCategory) {
      const { area } = selectedCategory;

      form.setFieldsValue({
        area
      });
    }
  }, [selectedCategory]);
  return (
    <CustomModal
      title={'Add Category'}
      open={showAddModal}
      onCancel={handleCancel}
      width={'43%'}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <FormItem
          label="Category Name"
          name="area"
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
        >
          <Input allowClear placeholder="Enter category name" />
        </FormItem>
        <FormItem className="text-right">
          <Space>
            <CustomButton htmlType="button" onClick={handleCancel}>
              Cancel
            </CustomButton>
            <CustomButton type="primary" htmlType="submit">
              Save
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
    </CustomModal>
  );
};
export default AddCategoryModal;
