import React, { useState, useEffect } from 'react';
import { Form, Input, Space, DatePicker, Col, Row } from 'antd';
import FormItem from 'common/FormItem';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
import { addQuiz, getAllQuizzesbyId } from '../../redux/slice';
import { useParams } from 'react-router-dom';
import Layout from 'common/CustomLayout';
import CustomCard from 'common/CustomCard';
import styles from '../../index.module.less';
import CustomText from 'common/CustomText';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';

const ViewQuiz = () => {
  const [form] = Form.useForm();
  const [quizQuestion, setQuizQuestion] = useState([
    {
      question: '',
      options: [{ id: 1, option: '' }]
    }
  ]);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    handleGetAllQuizzesById();
  }, [params.id]);

  const handleGetAllQuizzesById = () => {
    let payload = { quiz_id: params.id };
    dispatch(getAllQuizzesbyId(payload)).then((response) => {
      setQuizQuestion(response.payload.data);
    });
  };

  console.log(quizQuestion);
  const onFinish = (values) => {
    values['question_options'] = quizQuestion;
    dispatch(addQuiz(values)).then(() => {
      history.push('/quizzes-list');
    });
  };
  const handleChange = (i, e) => {
    let newquizQuestion = [...quizQuestion];
    newquizQuestion[i][e.target.name] = e.target.value;
    setQuizQuestion(newquizQuestion);
  };
  const handleChangeOption = (index, i, e) => {
    let newquizQuestion = [...quizQuestion];
    newquizQuestion[index]['options'][i][e.target.name] = e.target.value;
    setQuizQuestion(newquizQuestion);
  };

  const handleDrop = (index, droppedItem) => {
    console.log(index, droppedItem);
    if (!droppedItem.destination) return;
    var updatedItemList = [...quizQuestion[index].options];
    const [reorderedItemList] = updatedItemList.splice(
      droppedItem.source.index,
      1
    );
    updatedItemList.splice(droppedItem.destination.index, 0, reorderedItemList);
    console.log(updatedItemList);
    let newquizQuestion = [...quizQuestion];
    newquizQuestion[index]['options'] = updatedItemList;

    setQuizQuestion(newquizQuestion);
  };
  return (
    <Layout>
      <CustomCard className={styles.container}>
        <Row>
          <Col span={19}>
            <CustomText
              font="medium"
              size={24}
              color="secondary"
              className="pl-2"
            >
              View Quiz/Survey
            </CustomText>
          </Col>
        </Row>
        <br />
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <FormItem
            label="Name"
            // name="name"
            rules={[
              {
                required: true,
                message: 'Required field'
              }
            ]}
          >
            <Input
              allowClear
              placeholder="Enter quiz/survey name"
              value={quizQuestion[0]?.quiz?.name || ''}
            />
          </FormItem>
          <Row gutter={16}>
            <Col span={8}>
              <FormItem
                label="Start date"
                // name="start_date"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
              >
                <DatePicker
                  allowClear
                  placeholder="Select start date"
                  value={
                    moment(quizQuestion[0]?.quiz?.start_date, dateFormat) || ''
                  }
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Expires on"
                // name="expires_on"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
              >
                <DatePicker
                  allowClear
                  placeholder="Select expiry date"
                  value={
                    moment(quizQuestion[0]?.quiz?.expires_on, dateFormat) || ''
                  }
                />
              </FormItem>
            </Col>
          </Row>

          {quizQuestion?.map((element, index) => (
            <>
              <Space size={14}>
                <FormItem
                  key={index}
                  label={`Question${index + 1}`}
                  rules={[
                    {
                      required: false,
                      message: 'Required field'
                    }
                  ]}
                >
                  <Input
                    allowClear
                    placeholder="Enter question"
                    value={element.question || ''}
                    name="question"
                    onChange={(e) => handleChange(index, e)}
                    style={{ width: '600px' }}
                  />
                </FormItem>
                {/* {index > 0 ? (
                  <CustomButton
                    className="button add"
                    type="button"
                    onClick={() => removeQuestionFormFields(index)}
                    style={{ float: 'right' }}
                  >
                    Remove
                  </CustomButton>
                ) : (
                  <CustomButton
                    className="button add"
                    type="button"
                    onClick={() => addFormFields()}
                    style={{ float: 'right' }}
                  >
                    Add
                  </CustomButton>
                )} */}
              </Space>
              <DragDropContext
                onDragEnd={(droppedItem) => handleDrop(index, droppedItem)}
              >
                <Droppable droppableId="list-container">
                  {(provided) => (
                    <div
                      className="list-container"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {element.options.map((item, i) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id.toString()}
                          index={i}
                        >
                          {(provided) => (
                            <>
                              <div
                                className="item-container"
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                <Space size={14} key={i}>
                                  <FormItem
                                    key={i}
                                    label={`option${i + 1}`}
                                    rules={[
                                      {
                                        required: false,
                                        message: 'Required field'
                                      }
                                    ]}
                                  >
                                    <Input
                                      allowClear
                                      name="name"
                                      placeholder="Enter option"
                                      value={item.name || ''}
                                      onChange={(e) =>
                                        handleChangeOption(index, i, e)
                                      }
                                      style={{ width: '600px' }}
                                    />
                                  </FormItem>
                                  {/* {i > 0 ? (
                                    <CustomButton
                                      className="button add"
                                      type="button"
                                      onClick={() => removeFormFields(index, i)}
                                      style={{ float: 'right' }}
                                    >
                                      Remove
                                    </CustomButton>
                                  ) : (
                                    <CustomButton
                                      className="button add"
                                      type="button"
                                      onClick={() => addFormOptionFields(index)}
                                      style={{ float: 'right' }}
                                    >
                                      Add
                                    </CustomButton>
                                  )} */}
                                </Space>
                              </div>
                              <br />
                            </>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {/* </Space> */}
            </>
          ))}

          {/* <FormItem className="text-right">
            <Space>
              <CustomButton type="primary" htmlType="submit">
                Save
              </CustomButton>
            </Space>
          </FormItem> */}
        </Form>
      </CustomCard>
    </Layout>
  );
};
export default ViewQuiz;
