import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import Layout from 'common/CustomLayout';
import CreateWinnerPage from './CreateWinnerPage';
import {
  getAllRRGroups,
  getAllRRMaster,
  postRR,
  postRRGroup,
  getAllIdeas
} from '../redux/slice';
import { useEffect, useState } from 'react';
import { history } from 'app/history';
import moment from 'moment';

const CreateWinner = () => {
  const dispatch = useDispatch();
  const { postRRLoading, rrGroups, rrMaster, postRRGroupLoading, ideas } =
    useSelector((state) => state.centreStage);

  const [group, setGroup] = useState('');
  const [serachvalue, setSearchvalue] = useState('');
  const [IdeaSearchvalue, setideaSearchvalue] = useState('');
  const [member, setMember] = useState(false);
  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    handleGetAllRRGroups();
    handleAllRRMaster();
  }, [serachvalue]);
  useEffect(() => {
    handleAllIdeas();
  }, [IdeaSearchvalue]);

  const handleGetAllRRGroups = () => {
    let payload = { search: serachvalue };
    dispatch(getAllRRGroups(payload));
  };

  const handleAllRRMaster = () => {
    dispatch(getAllRRMaster());
  };

  const handleAllIdeas = () => {
    let payload = { search: IdeaSearchvalue };
    dispatch(getAllIdeas(payload));
  };

  const handlePostRRGroup = () => {
    let payload = { title: group };
    dispatch(postRRGroup(payload)).then(({ payload }) => {
      if (payload?.success) {
        handleGetAllRRGroups();
        setGroup('');
      }
    });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handlePostRR = (values) => {
    const {
      name,
      designation,
      rr_group_id,
      rr_master_id,
      citation,
      focus_area,
      results,
      images,
      sugg_id,
      rr_date,
      url,
      team_pic_detail
    } = values;
    console.log(team_pic_detail);
    let formData = new FormData();
    formData.append('name', name);
    formData.append('designation', designation);
    formData.append('rr_group_id', rr_group_id);
    formData.append('rr_master_id', rr_master_id);
    formData.append('citation', citation);
    formData.append('focus_area', focus_area);
    formData.append('results', results);
    formData.append('rr_date', moment(rr_date).format('YYYY-MM-DD'));
    formData.append('url', url);
    formData.append('sugg_id', sugg_id);
    itemList.map((item) => {
      formData.append('super_squads', item.name);
    });
    images.forEach((o) => formData.append('images', o.originFileObj));
    team_pic_detail !== undefined && team_pic_detail.length
      ? team_pic_detail.forEach((o) =>
          formData.append('team_pic', o.originFileObj)
        )
      : formData.append('team_pic', null);
    dispatch(postRR(formData)).then(({ payload }) => {
      if (payload?.success) {
        history.push('/centre-stage/winners');
      }
    });
  };

  return (
    <Layout>
      <Spin spinning={postRRLoading}>
        <CreateWinnerPage
          normFile={normFile}
          onFinish={handlePostRR}
          rrGroups={rrGroups}
          rrMaster={rrMaster}
          handlePostRRGroup={handlePostRRGroup}
          postRRGroupLoading={postRRGroupLoading}
          group={group}
          setGroup={setGroup}
          setSearchvalue={setSearchvalue}
          ideas={ideas}
          setideaSearchvalue={setideaSearchvalue}
          setMember={setMember}
          member={member}
          setItemList={setItemList}
          itemList={itemList}
        />
      </Spin>
    </Layout>
  );
};

export default CreateWinner;
