import * as React from 'react';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  unstable_HistoryRouter as HistoryRouter
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import { MsalProvider } from '@azure/msal-react';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { history } from './app/history';
import { getLoggedInUser } from 'utilities/helpers';
import Tidings from 'features/tidings';
import AboutPhoenix from './features/about-phoenix';
import MDMessage from './features/md-message';
import Ideas from './features/ideas';
import KeyContacts from './features/key-contacts';
import IdeasView from './features/ideas-view';
import Quiz from './features/quiz';
import Loader from './common/Loader';
import Login from './features/login';
import SuggestIdea from './features/suggest-idea';
import Desclaimer from './features/desclaimer';
import AdminConsole from './features/admin-console';
import Subcateogories from './features/subcategories';
import Cateogories from './features/categories';
import Users from './features/users';
import Events from './features/events';
import Social from './features/social';
import LeaderSpeak from './features/leader-speak';
import LeaderSpeakArticle from './features/leader-speak/components/article';
import LeaderSpeakArticleCreate from './features/leader-speak/components/article-create';
import CentreStage from './features/centre-stage';
import Winners from './features/centre-stage/components/winners';
import CreateWinner from './features/centre-stage/components/create-winner';
import WinnerDetails from './features/centre-stage/components/winner-details';
import EditWinnerPage from './features/centre-stage/components/edit-winner';
import QuizList from './features/quizList';
import CreateQuiz from './features/createquiz';
import ViewQuiz from './features/createquiz/components/ViewQuiz';
import QuizResult from './features/createquiz/components/QuizResult';
import ViewQuizResult from './features/createquiz/components/ViewQuizResult';
import EditQuiz from './features/createquiz/components/editQuiz';
import MdMessageList from './features/manage-md-message';
import ViewMdMessageList from './features/manage-md-message/components/ViewMDmessage';
import TaketoQuiz from './features/createquiz/components/Quiz';

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

const configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: ''
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    windowHashTimeout: 60000,
    iframeHashTimeout: 60000,
    loadFrameTimeout: 0,
    asyncPopups: false
  }
};

const pca = new PublicClientApplication(configuration);

pca.addEventCallback((event) => {
  console.log(event);
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    pca.setActiveAccount(event.payload.account);
  }
});

const roleBasedNavigation = (id) => {
  switch (id) {
    default:
      return '/login';
  }
};

const PrivateRoute = ({ children }) => {
  let location = useLocation();

  const { loading } = useSelector((state) => state.login);
  const { loggedIn } = getLoggedInUser();

  if (loading) {
    return <Loader />;
  }

  if (!loggedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

const AuthChecker = ({ children }) => {
  let location = useLocation();

  const { loggedIn, user } = getLoggedInUser();

  let goTo = roleBasedNavigation(user?.role_id || '');

  if (loggedIn) {
    return <Navigate to={goTo} state={{ from: location }} />;
  }

  return children;
};

function App() {
  const { user } = getLoggedInUser();

  let goTo = user?.role_id
    ? roleBasedNavigation(user?.role_id || '')
    : '/about-phoenix';

  //to auto scale according to device dimensions
  // React.useEffect(() => {
  //   var siteWidth = 1280;
  //   var scale = window.outerWidth / siteWidth;

  //   document
  //     .querySelector('meta[name="viewport"]')
  //     .setAttribute(
  //       'content',
  //       'width=' + siteWidth + ', initial-scale=' + scale + ''
  //     );
  // }, []);

  history.listen((window) => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <HistoryRouter history={history}>
      <Routes>
        <Route path="/" element={<Navigate replace to={goTo} />} />

        <Route
          path="login"
          element={
            <AuthChecker>
              <MsalProvider instance={pca}>
                <Login />
              </MsalProvider>
            </AuthChecker>
          }
        />

        <Route
          path="buzz"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Tidings />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="about-phoenix"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <AboutPhoenix />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="md-message"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <MDMessage />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="ideas"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Ideas />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="key-contacts"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <KeyContacts />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="ideas_view/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <IdeasView />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="polls-surveys"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Quiz />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="suggest-idea"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <SuggestIdea />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="disclaimer"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Desclaimer />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="suggest-idea/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <SuggestIdea />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="adminconsole"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <AdminConsole />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="subCategories/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Subcateogories />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="categories"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Cateogories />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="users"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Users />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="events"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Events />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="social"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Social />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="leader-speak/articles"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <LeaderSpeak />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="leader-speak/articles/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <LeaderSpeakArticle />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="leader-speak/articles/create"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <LeaderSpeakArticleCreate />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="centre-stage"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <CentreStage />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="centre-stage/winners"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <Winners />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="centre-stage/winners/create"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <CreateWinner />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="centre-stage/winners/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <WinnerDetails />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="centre-stage/winners/edit/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <EditWinnerPage />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="quizzes-list"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <QuizList />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="create-quiz"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <CreateQuiz />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="view-quiz/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ViewQuiz />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="quiz-result/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <QuizResult />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="edit-quiz/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <EditQuiz />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="md-message-list"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <MdMessageList />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="view-md-message-list"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ViewMdMessageList />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="take-to-quiz/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <TaketoQuiz />
              </MsalProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="view-quiz-result/:id"
          element={
            <PrivateRoute>
              <MsalProvider instance={pca}>
                <ViewQuizResult />
              </MsalProvider>
            </PrivateRoute>
          }
        />
      </Routes>
    </HistoryRouter>
  );
}

export default App;
