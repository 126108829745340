import { Avatar, Layout } from 'antd';
import { BulbOutlined } from '@ant-design/icons';
import { history } from 'app/history';
import CustomButton from 'common/CustomButton';
import AddIdeaModal from 'features/ideas/components/AddIdeaModal';
import CustomHeader from '../CustomHeader';
import Events from '../Events';
// import Quizzes from '../Quizzes';
// import PendingSurveys from '../PendingSurveys';
import UserDetails from '../UserDetails';
import styles from './custom_layout.module.less';
import { Fade } from 'react-reveal';
// import HubOrWidgets from '../Hub';

const { Content, Sider, Footer } = Layout;

const CustomLayoutPage = ({
  children,
  header = true,
  leftSider = true,
  rightSider = true,
  addIdeaModal,
  handleToggleAddIdeaModal,
  floatinBtnOn,
  toggleFloatingBtnOn
}) => {
  return (
    <Layout className={styles.container}>
      {header ? (
        <CustomHeader
          addIdeaModal={addIdeaModal}
          handleToggleAddIdeaModal={handleToggleAddIdeaModal}
        />
      ) : null}
      <Layout className={styles.content_layout}>
        <Content
          className={`${styles.content}${
            leftSider ? ` ${styles.left_sider}` : ''
          }${rightSider ? ` ${styles.right_sider}` : ''}`}
        >
          {children}
        </Content>
        {rightSider ? (
          <Sider width={310} className={styles.sider_container}>
            <UserDetails handleToggleAddIdeaModal={handleToggleAddIdeaModal} />
            <Events className="mt-2" />
            {/* <HubOrWidgets className="mt-2" /> */}
            {/* <PendingSurveys className="mt-2" /> */}
          </Sider>
        ) : null}
      </Layout>
      {addIdeaModal ? (
        <AddIdeaModal
          addIdeaModal={addIdeaModal}
          handleToggleAddIdeaModal={handleToggleAddIdeaModal}
        />
      ) : null}
      <Footer className={styles.footer} style={{ textAlign: 'center' }}>
        <a href="disclaimer" target="_blank" style={{ color: '#404040' }}>
          {' '}
          Disclaimer
        </a>
      </Footer>
      <div className={styles.floating_btn_container}>
        {floatinBtnOn ? (
          <Fade right>
            <CustomButton
              type="primary"
              className="d-block mb-3"
              onClick={() => history.push('/suggest-idea')}
            >
              Suggest an idea
            </CustomButton>
          </Fade>
        ) : null}
        <Avatar
          icon={<BulbOutlined />}
          size={50}
          className={styles.floating_btn}
          onClick={toggleFloatingBtnOn}
        />
      </div>
    </Layout>
  );
};

export default CustomLayoutPage;
