import React from 'react';
import { Col, Image, Row } from 'antd';
import styles from './index.module.less';
import banner from '../../images/banner.png';
import logo from '../../images/phoenix-logo.svg';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import LocalStorage from 'utilities/localStorage';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
import { login } from '../../redux/slice';
import CustomButton from 'common/CustomButton';
import { ArrowRightOutlined } from '@ant-design/icons';
import CustomText from '@/common/CustomText';

const LoginPage = ({ from }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const dispatch = useDispatch();

  const signin = () => {
    instance
      .loginPopup({ scopes: ['User.Read'], prompt: 'select_account' })
      .then((result) => {
        return dispatch(login({ token: result.accessToken }));
      })
      .then((result) => {
        if (result.payload.success) {
          LocalStorage.setItem(
            'phoenix_login_user',
            JSON.stringify(result.payload)
          );
          const user = result.payload;
          if (user.user_details) {
            let routeToNavigate = from?.pathname || '/about-phoenix';
            history.replace(routeToNavigate);
          }
        } else {
          console.log('$$$$$$$$$$$$$$$$$$$');
        }
      })
      .catch(() => {
        isAuthenticated
          ? instance.logoutRedirect({
              account: LocalStorage.homeAccountId,
              postLogoutRedirectUri: '/',
              mainWindowRedirectUri: '/'
            })
          : '';
      });
  };

  return (
    <div className={styles.container}>
      <Row gutter={16} className={styles.row_container}>
        <Col sm={16} xs={0} className="m-auto">
          <Image preview={false} src={banner} className="w-100" />
        </Col>
        <Col sm={8} xs={24} className={styles.right_col_container}>
          <div className={styles.right_col_inner_container}>
            <div className={styles.web_logo}>
              <Image alt="phoenix-logo" src={logo} preview={false} />
            </div>
            <div className={styles.mobile_logo}>
              <Image alt="phoenix-logo" src={logo} preview={false} />
            </div>
            <CustomText
              font="bold"
              size={40}
              color="secondary"
              className={styles.title}
            >
              Transforming Tata Projects
            </CustomText>
            <div className={styles.mobile_banner}>
              <Image preview={false} src={banner} className="w-100" />
            </div>
            <CustomButton
              id="microsoft-login"
              alt="Microsoft"
              onClick={signin}
              className={styles.button}
            >
              Go to your Account
              <ArrowRightOutlined />
            </CustomButton>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LoginPage;
