import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col,
  Form,
  Input,
  Radio,
  Row,
  Space,
  Upload,
  Popconfirm,
  Button
  // Alert
} from 'antd';
import {
  InfoCircleOutlined,
  FilePdfOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import FormItem from 'common/FormItem';
import CustomButton from 'common/CustomButton';
import CustomCard from 'common/CustomCard';
import CustomText from 'common/CustomText';
import {
  getAreaList,
  getSubAreaList,
  postIdea,
  getIdeaById,
  deleteDoc
} from 'features/ideas/redux/slice';
import styles from './suggest_idea.module.less';
import { history } from 'app/history';
import { useParams } from 'react-router-dom';
// import Marquee from 'react-fast-marquee';

const { TextArea } = Input;

const SuggestIdeaPage = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { postIdeaLoading, areas, subAreas, idea } = useSelector(
    (state) => state.ideas
  );
  const [form] = Form.useForm();
  const [areaId, setAreaId] = useState(null);
  const [docs, setDocs] = useState([]);
  const [showDisablePopup, setShowDisablePopup] = useState(false);

  useEffect(() => {
    dispatch(getAreaList());
  }, []);

  useEffect(() => {
    if (areaId) {
      form.setFieldsValue({
        sub_area_id: idea.sub_area_id ? idea.sub_area_id : null
      });
      dispatch(getSubAreaList(areaId));
    }
  }, [areaId]);
  useEffect(() => {
    if (params.id) {
      const { area_id, sub_area_id, problem, solution, Documents } = idea;
      form.setFieldsValue({
        area_id,
        sub_area_id,
        problem,
        solution: solution != 'undefined' ? solution : ''
      });
      setAreaId(area_id);
      setDocs(Documents);
    }
  }, [idea]);

  useEffect(() => {
    if (params.id) {
      dispatch(getIdeaById(params.id));
    }
  }, []);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleDeleteDocument = () => {
    let payload = {
      id: docs.length ? docs[0].id : '',
      doc_url: docs.length ? docs[0].doc_url : ''
    };
    dispatch(deleteDoc(payload)).then(() => {
      setDocs([]);
    });
  };
  const visibility = useCallback((record) => {
    return showDisablePopup[record];
  }, showDisablePopup);

  const onFinish = (values) => {
    const { area_id, post_images, problem, solution, sub_area_id } = values;
    let formData = new FormData();
    formData.append('sugg_id', params.id ? params.id : '');
    formData.append('area_id', area_id);
    formData.append('problem', problem);
    formData.append('solution', solution);
    formData.append('sub_area_id', sub_area_id);
    formData.append(
      'post_images',
      post_images ? post_images[0].originFileObj : []
    );
    dispatch(postIdea(formData)).then(({ payload }) => {
      if (payload?.success) {
        // history.push('/ideas');
        window.location.href = '/ideas';
      }
    });
  };

  return (
    <CustomCard className={styles.container}>
      <Button
        size={'small'}
        onClick={() => history.push('/ideas')}
        style={{ float: 'right', marginBottom: '8px' }}
      >
        Back
      </Button>
      {/* <Space>
        <Alert
          banner
          message={
            <Marquee pauseOnHover gradient={false}>
              Now Live:{' '}
              <b style={{ marginLeft: '2px', marginRight: '2px' }}>
                {' '}
                Phoenix – Have an Idea? Contest, Edition 1.
              </b>{' '}
              Submit ideas under the Supply Chain Management category.
            </Marquee>
          }
          type="info"
          className={styles.infoColor}
        />
      </Space> */}

      <Form
        form={form}
        name="add-idea"
        layout="vertical"
        onFinish={onFinish}
        className="mt-3"
      >
        <FormItem
          label="Choose a category"
          name="area_id"
          rules={[
            {
              required: true,
              message: 'Please choose a category'
            }
          ]}
        >
          <Radio.Group
            className={styles.areas_container}
            onChange={({ target: { value } }) => setAreaId(value)}
            buttonStyle="solid"
          >
            {areas.map((item) => {
              const { id, area } = item;
              return (
                <Radio.Button key={id} value={id}>
                  {area}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        </FormItem>

        {areaId ? (
          <FormItem
            label="Choose a sub-category"
            name="sub_area_id"
            rules={[
              {
                required: true,
                message: 'Please choose a sub-category'
              }
            ]}
          >
            <Radio.Group className={styles.areas_container} buttonStyle="solid">
              {subAreas.map((item) => {
                const { id, sub_area } = item;
                return (
                  <Radio.Button key={id} value={id}>
                    {sub_area}
                  </Radio.Button>
                );
              })}
            </Radio.Group>
          </FormItem>
        ) : null}

        <Row gutter={24}>
          <Col sm={12} xs={24}>
            <FormItem
              label="Describe the problem that you would like to solve"
              name="problem"
              rules={[
                {
                  required: true,
                  message: 'Please enter the problem statement'
                }
              ]}
            >
              <TextArea rows={4} />
            </FormItem>
          </Col>

          <Col sm={12} xs={24}>
            <FormItem
              label="Explain how you intend you solve the problem"
              name="solution"
              rules={[
                {
                  required: false,
                  message: 'This field is required!'
                }
              ]}
            >
              <TextArea rows={4} />
            </FormItem>
          </Col>
        </Row>

        {docs?.length ? (
          <Space direction="vertical">
            <CustomText font="medium">Documents uploaded</CustomText>
            <Space wrap>
              {docs.map((item, index) => {
                return (
                  <>
                    <a href={item.doc_url} target="_target" key={index}>
                      <Space className={styles.documents}>
                        <FilePdfOutlined />
                        <CustomText font="medium" size={11} color={2}>
                          {item.file_name}
                        </CustomText>
                      </Space>
                    </a>
                    <Popconfirm
                      title="Are you sure you want to delete this document?"
                      visible={visibility(item?.id)}
                      onConfirm={() => {
                        handleDeleteDocument({
                          id: item?.id
                        });
                      }}
                      onCancel={() => {
                        setShowDisablePopup({});
                      }}
                      okText="Delete"
                      cancelText="No"
                      okButtonProps={{
                        type: 'danger'
                      }}
                    >
                      <DeleteOutlined
                        onClick={() => {
                          setShowDisablePopup({ [item?.id]: true });
                        }}
                        className={styles.deletePost}
                      />
                    </Popconfirm>
                  </>
                );
              })}
            </Space>
          </Space>
        ) : (
          <FormItem label="Upload any documents to help us understand the idea better">
            <FormItem
              name="post_images"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
            >
              <Upload.Dragger
                name="files"
                customRequest={({ onSuccess }) => {
                  setTimeout(() => {
                    onSuccess('ok');
                  }, 0);
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InfoCircleOutlined />
                </p>
                <Space>
                  <CustomText font="medium" color="primary">
                    Click to upload
                  </CustomText>
                  <CustomText color={2}>or drag and drop</CustomText>
                </Space>
              </Upload.Dragger>
            </FormItem>
          </FormItem>
        )}

        <Row gutter={24}>
          <Col sm={{ span: 6, offset: 18 }} xs={24}>
            <FormItem className="text-right">
              <CustomButton
                type="primary"
                htmlType="submit"
                loading={postIdeaLoading}
                className="w-100"
              >
                Submit
              </CustomButton>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </CustomCard>
  );
};

export default SuggestIdeaPage;
