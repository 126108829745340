import React, { useEffect } from 'react';
import CustomModal from 'common/CustomModal';
import { Form, Input, Space, DatePicker } from 'antd';
import FormItem from 'common/FormItem';
import CustomButton from 'common/CustomButton';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';

const AddEventModal = ({
  showAddModal,
  handleCancel,
  handleAddEvent,
  editMode,
  selectedEvent,
  selectedUrlId,
  setQuizQuestion,
  quizQuestion
}) => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    if (editMode) {
      values['id'] = selectedEvent.id;
    }
    values['event_url'] = 'buzz#' + selectedUrlId;
    handleAddEvent(values);
  };

  useEffect(() => {
    if (editMode && selectedEvent) {
      const { event_name, event_date, event_url } = selectedEvent;

      form.setFieldsValue({
        event_name,
        event_date: moment(event_date, dateFormat),
        event_url
      });
    }
  }, [selectedEvent]);
  console.log(quizQuestion);
  const handleChange = (i, e) => {
    let newquizQuestion = [...quizQuestion];
    newquizQuestion[i][e.target.name] = e.target.value;
    setQuizQuestion(newquizQuestion);
  };
  const handleChangeOption = (i, e) => {
    let newquizQuestion = [...quizQuestion];
    newquizQuestion[i]['options'][0][e.target.name] = e.target.value;
    setQuizQuestion(newquizQuestion);
  };

  const addFormFields = () => {
    setQuizQuestion([
      ...quizQuestion,
      {
        question: '',
        options: [{ option1: '', option2: '', option3: '', option4: '' }]
      }
    ]);
  };

  return (
    <CustomModal
      title={'Add Event'}
      open={showAddModal}
      onCancel={handleCancel}
      width={'60%'}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <FormItem
          label="Quiz Name"
          name="quiz_name"
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
        >
          <Input allowClear placeholder="Enter quiz name" />
        </FormItem>
        <FormItem
          label="Quiz expires Date"
          name="expires_date"
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
        >
          <DatePicker allowClear placeholder="Select expires date" showTime />
        </FormItem>
        {/* <CustomButton
          className="button add"
          type="button"
          onClick={() => addFormFields()}
          style={{ float: 'right' }}
        >
          Add
        </CustomButton> */}
        {quizQuestion.map((element, index) => (
          <>
            <Space size={14}>
              <FormItem
                key={index}
                label="Question"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
              >
                <Input
                  allowClear
                  placeholder="Enter question"
                  value={element.question || ''}
                  name="question"
                  onChange={(e) => handleChange(index, e)}
                  style={{ width: '600px' }}
                />
              </FormItem>
              {index > 0 ? (
                <CustomButton
                  className="button add"
                  type="button"
                  onClick={() => addFormFields()}
                  style={{ float: 'right' }}
                >
                  Remove
                </CustomButton>
              ) : (
                <CustomButton
                  className="button add"
                  type="button"
                  onClick={() => addFormFields()}
                  style={{ float: 'right' }}
                >
                  Add
                </CustomButton>
              )}
            </Space>
            <Space size={14} key={index}>
              <FormItem
                key={index}
                label="option1"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
              >
                <Input
                  allowClear
                  name="option1"
                  placeholder="Enter option"
                  value={element['options'][0]['option1'] || ''}
                  onChange={(e) => handleChangeOption(index, e)}
                />
              </FormItem>
              <FormItem
                key={index}
                label="option2"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
              >
                <Input
                  allowClear
                  name="option2"
                  placeholder="Enter option"
                  value={element['options'][0]['option2'] || ''}
                  onChange={(e) => handleChangeOption(index, e)}
                />
              </FormItem>
              <FormItem
                key={index}
                label="option3"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
              >
                <Input
                  allowClear
                  name="option3"
                  placeholder="Enter option"
                  value={element['options'][0]['option3'] || ''}
                  onChange={(e) => handleChangeOption(index, e)}
                />
              </FormItem>
              <FormItem
                key={index}
                label="option4"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
              >
                <Input
                  allowClear
                  name="option4"
                  placeholder="Enter option"
                  value={element['options'][0]['option4'] || ''}
                  onChange={(e) => handleChangeOption(index, e)}
                />
              </FormItem>
            </Space>
          </>
        ))}

        <FormItem className="text-right">
          <Space>
            <CustomButton htmlType="button" onClick={handleCancel}>
              Cancel
            </CustomButton>
            <CustomButton type="primary" htmlType="submit">
              Save
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
    </CustomModal>
  );
};
export default AddEventModal;
