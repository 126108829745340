import SocialPage from './components/SocialPage';
import Layout from 'common/CustomLayout';

const Social = () => {
  return (
    <Layout>
      <SocialPage />
    </Layout>
  );
};

export default Social;
