import apiClient from 'utilities/apiClient';

export const allEvents = () => {
  return apiClient.get(apiClient.Urls.allEvents, null, true);
};

export const poll = () => {
  return apiClient.get(apiClient.Urls.poll, null, true);
};

export const submitPoll = (payload) => {
  return apiClient.patch(apiClient.Urls.submitPoll, payload, true);
};

export const pollResponse = (payload) => {
  return apiClient.get(apiClient.Urls.pollResponse, payload, true);
};

export const quizzes = (payload) => {
  return apiClient.get(`${apiClient.Urls.allQuizzes}`, payload, true);
};
