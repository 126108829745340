import apiClient from 'utilities/apiClient';

export const allSuggestions = (payload) => {
  return apiClient.get(apiClient.Urls.allSuggestions, payload, true);
};

export const addSuggestion = (payload) => {
  return apiClient.post(apiClient.Urls.addSuggestion, payload, true, 'file');
};

export const suggestionUpvote = (payload) => {
  return apiClient.post(apiClient.Urls.suggestionUpvote, payload, true);
};

export const suggestionDownvote = (payload) => {
  return apiClient.delete(apiClient.Urls.suggestionDownvote, payload, true);
};

export const areaList = (payload = null) => {
  return apiClient.get(apiClient.Urls.areaList, payload, true);
};

export const deleteSuggestion = (payload) => {
  return apiClient.delete(apiClient.Urls.deletesuggestion, payload, true);
};

export const Suggestion = (url) => {
  return apiClient.get(`${apiClient.Urls.suggestion}/${url}`, null, true);
};

export const deleteDocument = (url, payload) => {
  return apiClient.delete(`${apiClient.Urls.suggestion}/${url}`, payload, true);
};

export const stared = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.suggestion}/${url}`, payload, true);
};

export const unstared = (url, payload) => {
  return apiClient.delete(`${apiClient.Urls.suggestion}/${url}`, payload, true);
};

export const ideaAllTags = (payload) => {
  return apiClient.get(`${apiClient.Urls.ideaAllTags}`, payload, true);
};
