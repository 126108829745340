import apiClient from 'utilities/apiClient';

export const allPosts = (payload) => {
  console.log(payload);
  return apiClient.get(apiClient.Urls.allPosts, payload, true);
};

export const addPost = (payload) => {
  return apiClient.post(apiClient.Urls.addPost, payload, true, 'file');
};

export const likePost = (payload) => {
  return apiClient.post(apiClient.Urls.likePost, payload, true);
};

export const dislikePost = (payload) => {
  return apiClient.delete(apiClient.Urls.dislikePost, payload, true);
};

export const allPostComments = (payload) => {
  return apiClient.get(apiClient.Urls.allPostComments, payload, true);
};

export const addComment = (payload) => {
  return apiClient.post(apiClient.Urls.addComment, payload, true);
};

export const pinPost = (payload) => {
  return apiClient.post(apiClient.Urls.pinPost, payload, true);
};

export const unPinPost = (payload) => {
  return apiClient.delete(apiClient.Urls.unPinPost, payload, true);
};

export const deletePost = (payload) => {
  return apiClient.delete(apiClient.Urls.deletepost, payload, true);
};

export const deleteComment = (url, payload) => {
  return apiClient.delete(
    `${apiClient.Urls.deletecomment}?id=${url}`,
    payload,
    true
  );
};

export const deleteDocument = (url, payload) => {
  return apiClient.delete(
    `${apiClient.Urls.deletedocument}/${url}`,
    payload,
    true
  );
};

export const editPost = (payload) => {
  return apiClient.post(`${apiClient.Urls.addPost}`, payload, true, 'file');
};

export const docSeq = (payload) => {
  return apiClient.patch(`${apiClient.Urls.docSeq}`, payload, true);
};
