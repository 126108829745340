// import { ArrowRightOutlined } from '@ant-design/icons';
import { Col, Image, Row, Space, Button, Modal } from 'antd';
import CustomText from 'common/CustomText';
// import CustomModal from 'common/CustomModal';
import styles from './center_stage.module.less';
import trailblazer from '../../images/trailblazer.png';
import initiative from '../../images/initiative.png';
import spirit from '../../images/spirit.png';
// import spot from '../../images/spot.png';
import category from '../../images/category.png';
import idea from '../../images/idea.png';
import applauds from '../../images/applauds.png';
import banner1 from '../../images/centre-stage-banner.jpg';
// import award from '../../images/award.gif';

const data = [
  {
    image: trailblazer,
    title: (
      <>
        <div>Phoenix</div>
        <div>Trailblazer</div>
      </>
    ), //'Phoenix Trailblazer',
    reward_id: 0
  },
  {
    image: initiative,
    title: (
      <>
        <div>Initiative of</div>
        <div>the Month</div>
      </>
    ), //'Initiative of the Month',
    reward_id: 1
  },
  {
    image: spirit,
    title: (
      <>
        <div>Spirit of</div>
        <div>Phoenix</div>
      </>
    ), // 'Spirit of Phoenix',
    reward_id: 2
  },
  // {
  //   image: spot,
  //   title: 'Spot Awards'
  // },
  {
    image: category,
    title: (
      <>
        <div>TU Super</div>
        <div> Squads</div>
      </>
    ), //'TU Super Squads',
    reward_id: 3
  },
  {
    image: idea,
    title: (
      <>
        <div>Idea</div>
        <div>Champions</div>
      </>
    ), //'Idea Champions',
    reward_id: 4
  },
  {
    image: applauds,
    title: (
      <>
        <div>Phoenix</div>
        <div>Applauds</div>
      </>
    ), //'Phoenix Applauds',
    reward_id: 5
  }
];

const rewards = [
  {
    title: 'Phoenix Trailblazer',
    Award: 'IHCL vouchers worth Rs.15000 and Phoenix goodies',
    Winners: 'Max 2 per month',
    Criteria:
      'An individual providing exceptional guidance and support, taking ownership and/or ensuring delivery as a part of Phoenix program',
    image: trailblazer
  },
  {
    title: 'Initiative of the Month',
    Award:
      'Gift Vouchers worth Rs.5000 for team awards & 7500 for individual awards along with Phoenix goodies', //'Gift Vouchers worth Rs.5000 and Phoenix goodies',
    Winners: 'Announced every month', //'Max 5 per month',
    Criteria:
      'An Initiative owner showcasing highest progress and impact during the month',
    image: initiative
  },
  {
    title: 'Spirit of Phoenix',
    Award: 'Gift Vouchers worth Rs.2000 and Phoenix goodies',
    Winners: 'Announced every month', //'Max 8 per month',
    Criteria:
      'An individual showcasing proactive action on ideas or initiatives under implementation',
    image: spirit
  },
  {
    title: 'TU Super Squads',
    Award: 'Team Lunch with MD, Trophy, Photo and Phoenix goodies',
    Winners: '1 Transformation Unit (TU) per month',
    Criteria: 'TU team demonstrating best performance and delivery',
    image: category
  },
  {
    title: 'Idea Champions',
    Award: 'Phoenix Goodies',
    Winners: 'Max 5 per campaign',
    Criteria: '5 best ideas submitted in each idea campaign',
    image: idea
  },
  {
    title: 'Phoenix Applauds',
    Award: 'Phoenix Goodies',
    Winners: 'Spot awards',
    Criteria:
      'Phoenix brand ambassadors, coordinators and those creating social awareness for Phoenix',
    image: applauds
  }
];

const CentreStagePage = ({ openModal, setOpenModal, setContent, content }) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.item_container}
        style={{ backgroundImage: `url(${banner1})` }}
      >
        <Space direction="vertical" size={40}>
          <div className={styles.header_container}>
            <CustomText font="bold" size={40} color="primary">
              Centre Stage
            </CustomText>
            <CustomText
              font="medium"
              size={16}
              color="white"
              className={styles.description}
            >
              Phoenix Rewards
            </CustomText>
          </div>
          <CustomText size={20} color="white" align="center">
            Celebrating people and ideas shaping the future of TPL
          </CustomText>
        </Space>
      </div>
      {/* <div style={{ backgroundImage: `url(${banner1})` }}>
        <Carousel className={styles.banner_carousel_container}>
          {Array.from({ length: 1 }).map((_, index) => {
            return (
              <Image
                key={index}
                preview={false}
                src={banner1}
                className={styles.banner}
              />
            );
          })}
        </Carousel>
      </div> */}
      <div className="mt-4">
        <Row gutter={[20, 20]} wrap>
          {data.map((item, index) => {
            const { image, title, reward_id } = item;

            return (
              <Col key={index} xl={8} md={12} xs={24}>
                <div
                  className={styles.card_container}
                  onClick={() => {
                    setOpenModal(true), setContent(reward_id);
                  }}
                >
                  <Space direction="vertical">
                    <Space size="middle" align="start">
                      <Image
                        src={image}
                        className={styles.image}
                        preview={false}
                      />
                      <CustomText font="bold" size={16} color="secondary">
                        {title}
                      </CustomText>
                    </Space>
                    <CustomText
                      size={16}
                      color={2}
                      style={{ marginLeft: 86 }}
                      className={styles.arrow}
                    >
                      Learn more
                    </CustomText>
                  </Space>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
      <Modal
        title={
          <Space size="middle" align="start">
            <Image
              src={rewards[content].image}
              className={styles.title_image}
              preview={false}
            />
            <CustomText font="medium" color="secondary" size={20}>
              {rewards[content].title}
            </CustomText>
          </Space>
        }
        open={openModal}
        onCancel={() => setOpenModal(false)}
        okText="Okay"
        footer={
          <Button key="back" type="primary" onClick={() => setOpenModal(false)}>
            Close
          </Button>
        }
      >
        <Space direction="vertical">
          <Space align="start">
            <CustomText font="medium">Reward:</CustomText>
            <CustomText color={2}>{rewards[content].Award}</CustomText>
          </Space>
          <Space align="start">
            <CustomText font="medium">Winners:</CustomText>
            <CustomText color={2}>{rewards[content].Winners}</CustomText>
          </Space>
          <Space align="start">
            <CustomText font="medium">Criteria:</CustomText>
            {/* </Space> */}
            {/* <Space align="start"> */}
            <CustomText color={2}>{rewards[content].Criteria}</CustomText>
          </Space>
        </Space>
      </Modal>
    </div>
  );
};

export default CentreStagePage;
