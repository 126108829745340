import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import Layout from 'common/CustomLayout';
import WinnersPage from './WinnersPage';
import {
  deleteRRLike,
  getAllRR,
  postRRLike,
  setPage,
  deleteWinner
} from '../redux/slice';
import moment from 'moment';

const Winners = () => {
  const dispatch = useDispatch();
  const { rr, getAllRRLoading, group_data, pagination, page } = useSelector(
    (state) => state.centreStage
  );
  const [showLikes, setShowLikes] = useState(false);
  const [likes, setLikes] = useState([]);
  const [dateValue, setDateValue] = useState('');
  const [showDisablePopup, setShowDisablePopup] = useState(false);

  useEffect(() => {
    if (page > (pagination?.current_page || 0)) {
      handleGetAllRR();
    }
  }, [page]);
  useEffect(() => {
    handleGetAllRR();
  }, [dateValue]);
  const handleGetAllRR = () => {
    console.log(dateValue);
    let payload = {
      page: page,
      per_page: 50,
      month: dateValue ? moment(dateValue.$d).format('YYYY-MM') : '2024-06'
    };
    dispatch(getAllRR(payload));
  };

  const handlePostRRLike = (rr_id) => {
    dispatch(postRRLike({ rr_id })).then(({ payload }) => {
      if (payload.success) {
        dispatch(handleGetAllRR());
      }
    });
  };

  const handleDeleteRRLike = (rr_id) => {
    dispatch(deleteRRLike({ rr_id })).then(({ payload }) => {
      if (payload.success) {
        dispatch(handleGetAllRR());
      }
    });
  };

  const handleSetPage = (page) => {
    dispatch(setPage(page));
  };

  const handleDeleteWinner = (id) => {
    dispatch(deleteWinner(id)).then(() => {
      const payload = { page: page };
      dispatch(handleGetAllRR(payload));
    });
  };

  return (
    <Layout>
      <Spin spinning={getAllRRLoading}>
        <WinnersPage
          data={rr}
          groupData={group_data || {}}
          showLikes={showLikes}
          setShowLikes={setShowLikes}
          likes={likes}
          setLikes={setLikes}
          handlePostRRLike={handlePostRRLike}
          handleDeleteRRLike={handleDeleteRRLike}
          setPage={handleSetPage}
          pagination={pagination}
          page={page}
          setDateValue={setDateValue}
          showDisablePopup={showDisablePopup}
          setShowDisablePopup={setShowDisablePopup}
          handleDeleteWinner={handleDeleteWinner}
        />
      </Spin>
    </Layout>
  );
};

export default Winners;
