import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Space, Table, Popconfirm } from 'antd';
import CustomCard from 'common/CustomCard';
import CustomText from 'common/CustomText';
import styles from './admin_console.module.less';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllEvents,
  addEvent,
  deleteEvent,
  getAllUrls
} from '../../redux/slice';
import CustomButton from '@/common/CustomButton';
import AddEventModal from '../AddEventModal';
import { history } from 'app/history';

const EventsPage = () => {
  const { events, urls } = useSelector((state) => state.events);
  const dispatch = useDispatch();

  const [showAddModal, setShowAddModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [uRLlink, setURLlink] = useState('');
  const [selectedUrlId, setSelectedUrlId] = useState('');

  const visibility = useCallback((record) => {
    return showDisablePopup[record];
  }, showDisablePopup);

  useEffect(() => {
    handleGetAllEvents();
  }, []);

  useEffect(() => {
    handleGetUrls();
  }, [uRLlink]);

  const handleGetUrls = () => {
    let payload = { search: uRLlink };
    dispatch(getAllUrls(payload));
  };

  const handleGetAllEvents = () => {
    dispatch(getAllEvents());
  };

  const handleCancel = () => {
    setShowAddModal(false);
  };

  const handleAddEvent = (values) => {
    dispatch(addEvent(values)).then(() => {
      setShowAddModal(false);
      handleGetAllEvents();
      setSelectedEvent('');
    });
  };

  const handleDeleteEvent = (data) => {
    const payload = { id: data.data.id };
    console.log(data.data.id);
    dispatch(deleteEvent(payload)).then(() => {
      handleGetAllEvents();
      setSelectedEvent('');
    });
  };

  const columns = [
    {
      title: 'Name',
      key: 'event_name',
      dataIndex: 'event_name'
    },
    {
      title: 'Date',
      key: 'event_date',
      dataIndex: 'event_date'
    },
    {
      title: 'Link',
      key: 'event_url',
      dataIndex: 'event_url'
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (record) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure you want to delete this event?"
            visible={visibility(record?.id)}
            onConfirm={() => {
              handleDeleteEvent({
                data: record
              });
            }}
            onCancel={() => {
              setShowDisablePopup({});
            }}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined
              onClick={() => {
                setShowDisablePopup({ [record?.id]: true });
              }}
              className={styles.deletePost}
            />
          </Popconfirm>
          <EditOutlined
            onClick={() => {
              setEditMode(true);
              setSelectedEvent(record);
              setShowAddModal(true);
            }}
          />
        </Space>
      )
    }
  ];
  return (
    <>
      <CustomCard className={`${styles.container} p-4`}>
        <Row>
          <Col span={19}>
            <CustomText
              font="medium"
              size={24}
              color="secondary"
              className="pl-2"
            >
              Events
            </CustomText>
          </Col>
          <Space>
            <Col span={1}></Col>
            <Col span={2}>
              <CustomButton onClick={() => history.push('/adminconsole')}>
                Back
              </CustomButton>
            </Col>
            <Col span={2}>
              <CustomButton
                type="primary"
                onClick={() => {
                  setShowAddModal(true);
                  setSelectedEvent('');
                }}
              >
                <PlusCircleOutlined style={{ fontSize: 12 }} />
                Add
              </CustomButton>
            </Col>
          </Space>
        </Row>
        <br />
        <Table columns={columns} dataSource={events} pagination={true} />
      </CustomCard>
      {showAddModal ? (
        <AddEventModal
          showAddModal={showAddModal}
          handleCancel={handleCancel}
          handleAddEvent={handleAddEvent}
          editMode={editMode}
          selectedEvent={selectedEvent}
          urls={urls}
          setURLlink={setURLlink}
          selectedUrlId={selectedUrlId}
          setSelectedUrlId={setSelectedUrlId}
        />
      ) : (
        ''
      )}
    </>
    // </CustomCard>
  );
};

export default EventsPage;
