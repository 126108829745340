import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  addSuggestion,
  allSuggestions,
  areaList,
  suggestionDownvote,
  suggestionUpvote,
  deleteSuggestion,
  Suggestion,
  deleteDocument,
  stared,
  unstared,
  ideaAllTags
} from './api';

const initialState = {
  allIdeas: [],
  addIdeaModal: false,
  postIdeaLoading: false,
  areas: [],
  subAreas: [],
  idea: {},
  tags: [],
  actionTags: [],
  pagination: '',
  allStaredIdeas: []
};

const actions = {
  GET_ALL_IDEAS: 'tidings/GET_ALL_IDEAS',
  POST_IDEA: 'tidings/POST_IDEA',
  POST_IDEA_UPVOTE: 'tidings/POST_IDEA_UPVOTE',
  POST_IDEA_DOWNVOTE: 'tidings/POST_IDEA_DOWNVOTE',
  GET_AREA_LIST: 'tidings/GET_AREA_LIST',
  GET_SUB_AREA_LIST: 'tidings/GET_SUB_AREA_LIST',
  DELETE_IDEA: 'tidings/DELETE_IDEA',
  GET_IDEA_BY_ID: 'tidings/GET_IDEA_BY_ID',
  DELETE_DOCUMENT: 'tidings/DELETE_DOCUMENT',
  STARED_IDEA: 'tidings/STARED_IDEA',
  UNSTARED_IDEA: 'tidings/UNSTARED_IDEA',
  GET_TAGS: 'tidings/GET_TAGS',
  GET_ACTION_TAGS: 'tidings/GET_ACTION_TAGS',
  POST_IDEA_TAG: 'tidings/POST_IDEA_TAG',
  GET_ALL_FILTERED_IDEAS: 'tidings/GET_ALL_FILTERED_IDEAS'
};

export const getAllIdeas = createAsyncThunk(
  actions.GET_ALL_IDEAS,
  async (payload) => {
    console.log('brfore payload', payload);
    const response = await allSuggestions(payload);

    return { ...response, ...payload };
  }
);

export const getAllFilteredIdeas = createAsyncThunk(
  actions.GET_ALL_FILTERED_IDEAS,
  async (payload) => {
    const response = await allSuggestions(payload);

    return response;
  }
);

export const postIdea = createAsyncThunk(actions.POST_IDEA, async (payload) => {
  const response = await addSuggestion(payload);

  return response;
});

export const postIdeaUpvote = createAsyncThunk(
  actions.POST_IDEA_UPVOTE,
  async (upvote_id) => {
    let payload = {
      upvote_id
    };

    const response = await suggestionUpvote(payload);

    return response;
  }
);

export const postIdeaDownvote = createAsyncThunk(
  actions.POST_IDEA_DOWNVOTE,
  async (upvote_id) => {
    let payload = {
      upvote_id
    };

    const response = await suggestionDownvote(payload);

    return response;
  }
);

export const getAreaList = createAsyncThunk(actions.GET_AREA_LIST, async () => {
  const response = await areaList();

  return response;
});

export const getSubAreaList = createAsyncThunk(
  actions.GET_SUB_AREA_LIST,
  async (id) => {
    let payload = { id };

    const response = await areaList(payload);

    return response;
  }
);

export const deleteIdea = createAsyncThunk(
  actions.DELETE_IDEA,
  async (payload) => {
    const response = await deleteSuggestion(payload);

    return response;
  }
);

export const getIdeaById = createAsyncThunk(
  actions.GET_IDEA_BY_ID,
  async (id) => {
    const response = await Suggestion(`findbyid?sugg_id=${id}`);

    return response;
  }
);

export const deleteDoc = createAsyncThunk(
  actions.DELETE_DOCUMENT,
  async (payload) => {
    const response = await deleteDocument(`dlt-document`, payload);

    return response;
  }
);

export const staredIdea = createAsyncThunk(
  actions.STARED_IDEA,
  async (payload) => {
    const response = await stared(`stared`, payload);

    return response;
  }
);

export const unStaredIdea = createAsyncThunk(
  actions.UNSTARED_IDEA,
  async (payload) => {
    const response = await unstared(`unStar`, payload);

    return response;
  }
);

export const getTags = createAsyncThunk(actions.GET_TAGS, async (payload) => {
  const response = await ideaAllTags(payload);

  return response;
});

export const getActionTags = createAsyncThunk(
  actions.GET_ACTION_TAGS,
  async (payload) => {
    const response = await ideaAllTags(payload);

    return response;
  }
);

export const postIdeaTag = createAsyncThunk(
  actions.POST_IDEA_TAG,
  async (payload) => {
    const response = await addSuggestion(payload);

    return response;
  }
);

export const ideasSlice = createSlice({
  name: 'ideas',
  initialState,
  reducers: {
    toggleAddIdeaModal: (state) => {
      state.addIdeaModal = !state.addIdeaModal;
    },
    resetAllIdeas: (state) => {
      state.allIdeas = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllIdeas.pending, (state) => {
        state.getAllIdeasLoading = true;
      })
      .addCase(getAllIdeas.fulfilled, (state, action) => {
        state.getAllIdeasLoading = false;
        const {
          success,
          data,
          data_started,
          message: msg,
          pagination,
          search,
          my_ideas,
          month
        } = action.payload;

        console.log('action.payload', action.payload);

        if (success) {
          let prevData = [...state.allIdeas];

          let alteredData;

          if ((search || my_ideas || month) && pagination.current_page === 1) {
            alteredData = data;
          } else {
            alteredData = [...prevData, ...data];
          }
          state.allIdeas = alteredData.filter(function (cv) {
            return !data_started.find(function (e) {
              return e.id == cv.id;
            });
          });
          state.allStaredIdeas = data_started;
          state.pagination = pagination;
        } else {
          state.allIdeas = [];
          state.allStaredIdeas = [];
          message.error(msg);
        }
      })
      .addCase(getAllIdeas.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllIdeasLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllFilteredIdeas.pending, (state) => {
        state.getAllIdeasLoading = true;
      })
      .addCase(getAllFilteredIdeas.fulfilled, (state, action) => {
        state.getAllIdeasLoading = false;

        const {
          success,
          data,
          data_started,
          message: msg,
          pagination
        } = action.payload;

        if (success) {
          if (action.meta.arg.area_id === '') {
            let prevData = [...state.allIdeas];
            let alteredData = [...prevData, ...data];
            state.allIdeas = alteredData.filter(function (cv) {
              return !data_started.find(function (e) {
                return e.id == cv.id;
              });
            });
            state.allStaredIdeas = data_started;
            state.pagination = pagination;
          } else {
            state.allIdeas = data;
            state.allStaredIdeas = data_started;
            state.pagination = pagination;
          }
        } else {
          state.allIdeas = [];
          message.error(msg);
        }
      })
      .addCase(getAllFilteredIdeas.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllIdeasLoading = false;

        message.error(msg);
      });
    builder
      .addCase(postIdea.pending, (state) => {
        state.postIdeaLoading = true;
      })
      .addCase(postIdea.fulfilled, (state, action) => {
        state.postIdeaLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(postIdea.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postIdeaLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postIdeaUpvote.pending, (state) => {
        state.postIdeaUpvoteLoading = true;
      })
      .addCase(postIdeaUpvote.fulfilled, (state, action) => {
        state.postIdeaUpvoteLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(postIdeaUpvote.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postIdeaUpvoteLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postIdeaDownvote.pending, (state) => {
        state.postIdeaDownvoteLoading = true;
      })
      .addCase(postIdeaDownvote.fulfilled, (state, action) => {
        state.postIdeaDownvoteLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(postIdeaDownvote.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postIdeaDownvoteLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAreaList.pending, (state) => {
        state.getAreaListLoading = true;
      })
      .addCase(getAreaList.fulfilled, (state, action) => {
        state.getAreaListLoading = false;

        const { data } = action.payload;
        state.areas = data;
      })
      .addCase(getAreaList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAreaListLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getSubAreaList.pending, (state) => {
        state.getSubAreaListLoading = true;
      })
      .addCase(getSubAreaList.fulfilled, (state, action) => {
        state.getSubAreaListLoading = false;

        const { data } = action.payload;
        state.subAreas = data;
      })
      .addCase(getSubAreaList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getSubAreaListLoading = false;

        message.error(msg);
      });
    builder
      .addCase(deleteIdea.pending, (state) => {
        state.getSubAreaListLoading = true;
      })
      .addCase(deleteIdea.fulfilled, (state) => {
        state.getSubAreaListLoading = false;
      })
      .addCase(deleteIdea.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getSubAreaListLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getIdeaById.pending, (state) => {
        state.getAllIdeasLoading = true;
      })
      .addCase(getIdeaById.fulfilled, (state, action) => {
        state.getAllIdeasLoading = false;

        const { success, data, message: msg } = action.payload;

        if (success) {
          state.idea = data;
        } else {
          state.idea = {};
          message.error(msg);
        }
      })
      .addCase(getIdeaById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllIdeasLoading = false;

        message.error(msg);
      });

    builder
      .addCase(deleteDoc.pending, (state) => {
        state.getAllIdeasLoading = true;
      })
      .addCase(deleteDoc.fulfilled, (state, action) => {
        state.getAllIdeasLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(deleteDoc.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllIdeasLoading = false;

        message.error(msg);
      });

    builder
      .addCase(staredIdea.pending, (state) => {
        state.getAllIdeasLoading = true;
      })
      .addCase(staredIdea.fulfilled, (state, action) => {
        state.getAllIdeasLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(staredIdea.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllIdeasLoading = false;

        message.error(msg);
      });

    builder
      .addCase(unStaredIdea.pending, (state) => {
        state.getAllIdeasLoading = true;
      })
      .addCase(unStaredIdea.fulfilled, (state, action) => {
        state.getAllIdeasLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(unStaredIdea.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllIdeasLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getTags.pending, (state) => {
        state.getTagsLoading = true;
      })
      .addCase(getTags.fulfilled, (state, action) => {
        state.getTagsLoading = false;

        const { success, data, message: msg } = action.payload;

        if (success) {
          state.tags = data;
        } else {
          state.tags = [];
          message.error(msg);
        }
      })
      .addCase(getTags.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getTagsLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getActionTags.pending, (state) => {
        state.getTagsLoading = true;
      })
      .addCase(getActionTags.fulfilled, (state, action) => {
        state.getTagsLoading = false;

        const { success, data, message: msg } = action.payload;

        if (success) {
          state.actionTags = data;
        } else {
          state.actionTags = [];
          message.error(msg);
        }
      })
      .addCase(getActionTags.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getTagsLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postIdeaTag.pending, (state) => {
        state.postIdeaTagLoading = true;
      })
      .addCase(postIdeaTag.fulfilled, (state, action) => {
        state.postIdeaTagLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          //   message.success(msg);
          // } else {
          message.error(msg);
        }
      })
      .addCase(postIdeaTag.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postIdeaTagLoading = false;

        message.error(msg);
      });
  }
});

export const { toggleAddIdeaModal, resetAllIdeas } = ideasSlice.actions;

export default ideasSlice.reducer;
