import React, { useEffect } from 'react';
import CustomModal from 'common/CustomModal';
import { Form, Space, Upload, Image } from 'antd';
import { InfoCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import FormItem from 'common/FormItem';
import CustomButton from 'common/CustomButton';
import CustomText from 'common/CustomText';
import ReactQuill from 'react-quill';

const AddMessageModal = ({
  showAddModal,
  handleCancel,
  handleAddMessage,
  editMode,
  selectedEvent,
  setMessageImage,
  messageImage
}) => {
  const [form] = Form.useForm();
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e.fileList.length > 1) {
      e.fileList.shift();
    }
    return e?.fileList;
  };
  const onFinish = (values) => {
    if (editMode) {
      values['id'] = selectedEvent.id;
    }
    let formData = new FormData();
    if (editMode) {
      formData.append('id', selectedEvent.id ? selectedEvent.id : '');
    }

    formData.append('image', values.image ? values.image[0].originFileObj : '');
    formData.append('pre_blurb', values.pre_blurb);
    formData.append('blurb', values.blurb);
    formData.append('designation', values.designation);
    formData.append('post_blurb', values.post_blurb);

    handleAddMessage(formData, values);
  };

  useEffect(() => {
    if (editMode && selectedEvent) {
      const { image, pre_blurb, blurb, designation, post_blurb } =
        selectedEvent;
      setMessageImage(image);
      form.setFieldsValue({
        pre_blurb,
        blurb,
        designation,
        post_blurb
      });
    }
  }, [selectedEvent]);
  return (
    <CustomModal
      title={editMode ? 'Update Message' : 'Add Message'}
      open={showAddModal}
      onCancel={handleCancel}
      width={'63%'}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <FormItem label="Upload Image">
          {messageImage ? (
            <Space size={14}>
              <Image width={200} src={selectedEvent.image} />
              <DeleteOutlined onClick={() => setMessageImage('')} />
            </Space>
          ) : (
            <FormItem
              name="image"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
              rules={[
                {
                  required: true,
                  message: 'Please upload image'
                }
              ]}
            >
              <Upload.Dragger
                // name="files"
                accept="image/*"
                multiple={false}
                customRequest={({ onSuccess }) => {
                  setTimeout(() => {
                    onSuccess('ok');
                  }, 0);
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InfoCircleOutlined />
                </p>
                <Space>
                  <CustomText font="medium" color="primary">
                    Click to upload
                  </CustomText>
                  <CustomText color={2}>or drag and drop</CustomText>
                </Space>
              </Upload.Dragger>
            </FormItem>
          )}
        </FormItem>
        <FormItem
          label="Pre Blurb"
          name="pre_blurb"
          rules={[
            {
              required: true,
              message: 'Please enter pre blurb'
            }
          ]}
        >
          <ReactQuill theme="snow" placeholder="Type here.." />
        </FormItem>
        <FormItem
          label="Blurb"
          name="blurb"
          rules={[
            {
              required: true,
              message: 'Please enter blurb'
            }
          ]}
        >
          <ReactQuill theme="snow" placeholder="Type here.." />
        </FormItem>
        <FormItem
          label="Designation"
          name="designation"
          rules={[
            {
              required: true,
              message: 'Please enter designation'
            }
          ]}
        >
          <ReactQuill theme="snow" placeholder="Type here.." />
        </FormItem>
        <FormItem
          label="Post Blurb"
          name="post_blurb"
          rules={[
            {
              required: true,
              message: 'Please enter post blurb'
            }
          ]}
        >
          <ReactQuill theme="snow" placeholder="Type here.." />
        </FormItem>
        <FormItem className="text-right">
          <Space>
            <CustomButton htmlType="button" onClick={handleCancel}>
              Cancel
            </CustomButton>
            <CustomButton type="primary" htmlType="submit">
              {editMode ? 'Update' : 'Save'}
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
    </CustomModal>
  );
};
export default AddMessageModal;
