import SuggestIdeaPage from './components/SuggestIdeaPage';
import Layout from 'common/CustomLayout';

const SuggestIdea = () => {
  return (
    <Layout>
      <SuggestIdeaPage />
    </Layout>
  );
};

export default SuggestIdea;
