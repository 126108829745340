import AdminConsolePage from './components/AdminConsolePage';
import Layout from 'common/CustomLayout';

const AdminConsole = () => {
  return (
    <Layout>
      <AdminConsolePage />
    </Layout>
  );
};

export default AdminConsole;
