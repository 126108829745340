import apiClient from 'utilities/apiClient';

export const users = (payload) => {
  return apiClient.get(`${apiClient.Urls.usersList}`, payload, true);
};

export const updateusers = (payload) => {
  return apiClient.patch(
    `${apiClient.Urls.switchusers}?id=${payload.id}&role=${payload.role}`,
    payload,
    true
  );
};

export const deactivateusers = (payload) => {
  return apiClient.patch(
    `${apiClient.Urls.switchusers}?id=${payload.id}&active=${payload.active}`,
    payload,
    true
  );
};

export const addarea = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.addArea}${url}`, payload, true);
};
