import CreateQuizPage from './components/CreateQuizPage';
import Layout from 'common/CustomLayout';
import CustomCard from 'common/CustomCard';
import styles from './index.module.less';
import { Col, Row } from 'antd';
import CustomText from 'common/CustomText';

const Createquiz = () => {
  return (
    <Layout>
      <CustomCard className={styles.container}>
        <Row>
          <Col span={19}>
            <CustomText
              font="medium"
              size={24}
              color="secondary"
              className="pl-2"
            >
              Create Survey/Quiz
            </CustomText>
          </Col>
        </Row>
        <br />
        <CreateQuizPage />
      </CustomCard>
    </Layout>
  );
};

export default Createquiz;
