import React, { useEffect, useState } from 'react';
import { Col, Row, Space, Table } from 'antd';
import CustomCard from 'common/CustomCard';
import CustomText from 'common/CustomText';
import styles from './admin_console.module.less';
import { EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllCategories,
  addCategory,
  getAllsubCategories
} from '../../redux/slice';
import CustomButton from '@/common/CustomButton';
import AddsubCategoryModal from '../AddsubCategoryModal';
import { useParams } from 'react-router-dom';
import { history } from 'app/history';

const SubcategoriesPage = () => {
  const { areas, subareas } = useSelector((state) => state.subcategory);
  const dispatch = useDispatch();
  const params = useParams();

  const [showAddModal, setShowAddModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    handleGetAllCategories();
    handleGetAllsubCategories();
  }, []);

  const handleGetAllCategories = () => {
    dispatch(getAllCategories());
  };

  const handleGetAllsubCategories = () => {
    let payload = { id: params.id };
    dispatch(getAllsubCategories(payload));
  };

  const handleCancel = () => {
    setShowAddModal(false);
  };

  const handleAddCategory = (values) => {
    dispatch(addCategory(values)).then(() => {
      setShowAddModal(false);
      handleGetAllsubCategories();
      setSelectedCategory('');
    });
  };

  const columns = [
    {
      title: 'Sub-Category',
      key: 'sub_area',
      dataIndex: 'sub_area'
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (record) => (
        <Space size="middle">
          <EditOutlined
            onClick={() => {
              setEditMode(true);
              setSelectedCategory(record);
              setShowAddModal(true);
            }}
          />
        </Space>
      )
    }
  ];
  return (
    <>
      <CustomCard className={`${styles.container} p-4`}>
        <Row>
          <Col span={20}>
            <CustomText
              font="medium"
              size={24}
              color="secondary"
              className="pl-2"
            >
              {subareas[0]?.area_name}
            </CustomText>
          </Col>
          <Col span={2}>
            <CustomButton
              type="primary"
              onClick={() => {
                setShowAddModal(true);
                setSelectedCategory('');
              }}
              className="mt-1 w-100"
              style={{ marginLeft: '-6px' }}
            >
              <PlusCircleOutlined style={{ fontSize: 12 }} />
              Add
            </CustomButton>
          </Col>
          <Col span={2}>
            <CustomButton
              onClick={() => history.push('/categories')}
              className="mt-1 w-100"
            >
              Back
            </CustomButton>
          </Col>
        </Row>
        <br />
        <Table columns={columns} dataSource={subareas} pagination={false} />
      </CustomCard>
      {showAddModal ? (
        <AddsubCategoryModal
          showAddModal={showAddModal}
          handleCancel={handleCancel}
          handleAddCategory={handleAddCategory}
          editMode={editMode}
          selectedCategory={selectedCategory}
          areas={areas}
          categoryId={params.id}
        />
      ) : (
        ''
      )}
    </>
    // </CustomCard>
  );
};

export default SubcategoriesPage;
