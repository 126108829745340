import { configureStore } from '@reduxjs/toolkit';
import counterReducer from 'features/counter/redux/slice';
import tidingsReducer from 'features/tidings/redux/slice';
import ideasReducer from 'features/ideas/redux/slice';
import customLayoutReducer from 'common/CustomLayout/redux/slice';
import ideasViewReducer from 'features/ideas-view/redux/slice';
import loginReducer from 'features/login/redux/slice';
import adminconsoleReducer from 'features/admin-console/redux/slice';
import subcategoryReducer from 'features/subcategories/redux/slice';
import usermanagementReducer from 'features/users/redux/slice';
import eventconsoleReducer from 'features/events/redux/slice';
import leaderSpeakReducer from 'features/leader-speak/redux/slice';
import centreStageReducer from 'features/centre-stage/redux/slice';
import quizconsoleReducer from 'features/quizList/redux/slice';
import messageconsoleReducer from 'features/manage-md-message/redux/slice';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    tidings: tidingsReducer,
    ideas: ideasReducer,
    customLayout: customLayoutReducer,
    ideasview: ideasViewReducer,
    login: loginReducer,
    adminconsole: adminconsoleReducer,
    subcategory: subcategoryReducer,
    users: usermanagementReducer,
    events: eventconsoleReducer,
    leaderSpeak: leaderSpeakReducer,
    centreStage: centreStageReducer,
    quiz: quizconsoleReducer,
    mdmessage: messageconsoleReducer
  }
});
