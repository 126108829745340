import apiClient from 'utilities/apiClient';

export const areas = () => {
  return apiClient.get(`${apiClient.Urls.areaList}`, null, true);
};

export const addarea = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.addArea}${url}`, payload, true);
};

export const subareas = (payload) => {
  return apiClient.get(`${apiClient.Urls.areaList}`, payload, true);
};
