import apiClient from 'utilities/apiClient';

export const events = () => {
  return apiClient.get(`${apiClient.Urls.allEvents}`, null, true);
};

export const addEventDetails = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.addEvent}${url}`, payload, true);
};

export const deleteEventDetails = (url, payload) => {
  return apiClient.delete(`${apiClient.Urls.deleteEvent}${url}`, payload, true);
};

export const all_urls = (payload) => {
  return apiClient.get(`${apiClient.Urls.allUrls}`, payload, true);
};
