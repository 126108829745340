import MDMessagePage from './components/MDMessagePage';
import Layout from 'common/CustomLayout';

const Managemdmessage = () => {
  return (
    <Layout>
      <MDMessagePage />
    </Layout>
  );
};

export default Managemdmessage;
