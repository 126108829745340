import { Col, Form, Input, Row, Space, Upload, Button } from 'antd';
import {
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined
} from '@ant-design/icons';
import FormItem from 'common/FormItem';
import CustomButton from 'common/CustomButton';
import CustomCard from 'common/CustomCard';
import CustomText from 'common/CustomText';
import { history } from 'app/history';
import ReactQuill from 'react-quill';
import styles from './article_create.module.less';

const ArticleCreatePage = ({ onFinish, normFile }) => {
  const [form] = Form.useForm();

  return (
    <CustomCard className={styles.container}>
      <Button
        size={'small'}
        onClick={() => history.back()}
        style={{ float: 'right', marginBottom: '8px' }}
      >
        Back
      </Button>

      <Form
        form={form}
        name="add-article"
        layout="vertical"
        onFinish={onFinish}
        className="mt-3"
        initialValues={{ article_videos: [undefined] }}
      >
        <Row gutter={24}>
          <Col sm={24} xs={24}>
            <FormItem
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Please enter title'
                }
              ]}
            >
              <Input placeholder="Enter Title" />
            </FormItem>
          </Col>

          <Col sm={12} xs={24}>
            <FormItem
              label="Leader Name"
              name="leader_name"
              rules={[
                {
                  required: true,
                  message: 'Please enter Leader Name'
                }
              ]}
            >
              <Input placeholder="Enter Leader Name" />
            </FormItem>
          </Col>

          <Col sm={12} xs={24}>
            <FormItem
              label="Designation"
              name="designation"
              rules={[
                {
                  required: true,
                  message: 'Please enter designation'
                }
              ]}
            >
              <Input placeholder="Enter Designation" />
            </FormItem>
          </Col>

          <Col sm={24} xs={24}>
            <FormItem
              label="Body"
              name="body"
              rules={[
                {
                  required: true,
                  message: 'Please enter body'
                }
              ]}
            >
              <ReactQuill theme="snow" placeholder="Type here.." />
            </FormItem>
          </Col>

          <Col xs={24}>
            <FormItem label="Upload Image(s)">
              <FormItem
                name="article_images"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                noStyle
                rules={[
                  {
                    required: true,
                    message: 'Please upload image(s)'
                  }
                ]}
              >
                <Upload.Dragger
                  // name="files"
                  accept="image/*"
                  multiple={true}
                  customRequest={({ onSuccess }) => {
                    setTimeout(() => {
                      onSuccess('ok');
                    }, 0);
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <InfoCircleOutlined />
                  </p>
                  <Space>
                    <CustomText font="medium" color="primary">
                      Click to upload
                    </CustomText>
                    <CustomText color={2}>or drag and drop</CustomText>
                  </Space>
                </Upload.Dragger>
              </FormItem>
            </FormItem>
          </Col>

          <Col xs={24}>
            <Form.List
              name="article_videos"
              rules={[
                {
                  validator: async (_, article_videos) => {
                    if (!article_videos || article_videos.length < 1) {
                      return Promise.reject(new Error('At least 1 video'));
                    }
                  }
                }
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  <FormItem>
                    <div className="df-jb-ac">
                      <CustomText font="medium">{`Video link(s)`}</CustomText>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      >
                        Add Video
                      </Button>
                    </div>
                    <Form.ErrorList errors={errors} />
                  </FormItem>
                  {fields.map(({ key, name, ...restField }, ind) => (
                    <Row key={key} gutter={12} align="middle">
                      <Col xs={23}>
                        <Row gutter={12}>
                          <Col sm={8} xs={24}>
                            <FormItem
                              {...restField}
                              name={[name, 'title']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter video title'
                                }
                              ]}
                            >
                              <Input placeholder="Enter Video Title" />
                            </FormItem>
                          </Col>
                          <Col sm={10} xs={24}>
                            <FormItem
                              {...restField}
                              name={[name, 'doc_url']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please enter video link'
                                }
                              ]}
                            >
                              <Input placeholder="Enter Video Link" />
                            </FormItem>
                          </Col>
                          <Col sm={5} xs={24}>
                            <FormItem
                              {...restField}
                              name={[name, 'thumbnail_img']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please upload thumbnail'
                                }
                              ]}
                              valuePropName="fileList"
                              getValueFromEvent={normFile}
                            >
                              <Upload
                                accept="image/*"
                                maxCount={1}
                                customRequest={({ onSuccess }) => {
                                  setTimeout(() => {
                                    onSuccess('ok');
                                  }, 0);
                                }}
                              >
                                <Button icon={<UploadOutlined />}>
                                  Upload thumbnail
                                </Button>
                              </Upload>
                            </FormItem>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={1} className={styles.minus_icon_container}>
                        {ind !== 0 ? (
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        ) : null}
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </Form.List>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col sm={{ span: 6, offset: 18 }} xs={24}>
            <FormItem className="text-right">
              <CustomButton type="primary" htmlType="submit" className="w-100">
                Submit
              </CustomButton>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </CustomCard>
  );
};

export default ArticleCreatePage;
