import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Space,
  DatePicker,
  message,
  Switch,
  Col,
  Row,
  Radio
} from 'antd';
import FormItem from 'common/FormItem';
import CustomButton from 'common/CustomButton';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
import { editQuiz, getAllQuizzesbyId, deleteOption } from '../../redux/slice';
import moment from 'moment';
import {
  PlusCircleOutlined,
  DeleteOutlined,
  HolderOutlined
} from '@ant-design/icons';
import Layout from 'common/CustomLayout';
import CustomCard from 'common/CustomCard';
import styles from '../../index.module.less';
import CustomText from 'common/CustomText';
import { useParams } from 'react-router-dom';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const CreateQuizPage = () => {
  const [form] = Form.useForm();
  const [quizQuestion, setQuizQuestion] = useState([
    {
      question: '',
      options: [{ id: 1, name: '' }],
      right_answer: ''
    }
  ]);
  const dispatch = useDispatch();
  const params = useParams();

  const [option, setOption] = useState(0);
  const [isactive, setIsactive] = useState(true);
  const [isfeature, setIsfeature] = useState(false);
  const [type, setType] = useState(true);

  console.log('isfeature', isfeature);

  const onFinish = (values) => {
    values['question_options'] = quizQuestion;
    values['is_active'] = isactive;
    values['type'] = type;
    values['is_featured'] = isfeature;
    values['question_id'] = params.id;
    values['id'] = params.id;
    console.log(values);
    dispatch(editQuiz(values)).then((response) => {
      console.log(response);
      message.success(response.payload.message);
      history.push('/quizzes-list');
    });
  };

  useEffect(() => {
    handleGetAllQuizzesById();
  }, [params.id]);

  const handleGetAllQuizzesById = () => {
    let payload = { quiz_id: params.id };
    dispatch(getAllQuizzesbyId(payload)).then((response) => {
      setQuizQuestion(response.payload.data);
    });
  };

  let removeFormFields = (index, i) => {
    let payload = { option: 'option_id', id: i };
    dispatch(deleteOption(payload)).then((response) => {
      setQuizQuestion(response.payload.data);
    });
  };

  let removeQuestionFormFields = (index) => {
    console.log(index);
    let payload = { option: 'question_id', id: index };
    dispatch(deleteOption(payload)).then((response) => {
      setQuizQuestion(response.payload.data);
    });
  };

  const handleChange = (i, e) => {
    let newquizQuestion = [...quizQuestion];
    newquizQuestion[i][e.target.name] = e.target.value;
    setQuizQuestion(newquizQuestion);
  };
  const handleChangeOption = (index, i, e) => {
    let newquizQuestion = [...quizQuestion];
    newquizQuestion[index]['options'][i][e.target.name] = e.target.value;
    setQuizQuestion(newquizQuestion);
  };

  const addFormFields = () => {
    setQuizQuestion([
      ...quizQuestion,
      {
        question: '',
        options: [{ id: 1, name: '' }],
        right_answer: ''
      }
    ]);
  };
  const addFormOptionFields = (i) => {
    setOption(option + 1);
    quizQuestion[i].options.push({
      // id: quizQuestion[i].options.length + 1,
      name: ''
    });
  };
  console.log(quizQuestion);

  const handleDrop = (index, droppedItem) => {
    console.log(index, droppedItem);
    if (!droppedItem.destination) return;
    var updatedItemList = [...quizQuestion[index].options];
    const [reorderedItemList] = updatedItemList.splice(
      droppedItem.source.index,
      1
    );
    updatedItemList.splice(droppedItem.destination.index, 0, reorderedItemList);
    console.log(updatedItemList);
    let newquizQuestion = [...quizQuestion];
    newquizQuestion[index]['options'] = updatedItemList;

    setQuizQuestion(newquizQuestion);
  };

  const onChange = (checked) => {
    setIsactive(checked);
  };
  const onChangefeature = (checked) => {
    setIsfeature(checked);
  };

  useEffect(() => {
    radioHandler();
    const { quiz } = quizQuestion[0];
    setIsfeature(quiz?.is_featured);
    console.log(quiz?.is_featured);
    form.setFieldsValue({
      type: quiz?.type,
      name: quiz?.name,
      is_featured: quiz?.is_featured,
      expires_on: moment(quiz?.expires_on, dateFormat),
      start_date: moment(quiz?.start_date, dateFormat)
    });
  }, [quizQuestion]);

  const onChangeRightAnswer = (id, index) => {
    let newquizQuestion = [...quizQuestion];
    newquizQuestion[index]['right_answer'] = id;
    setQuizQuestion(newquizQuestion);
  };

  const radioHandler = (type) => {
    setType(type);
  };

  return (
    <Layout>
      <CustomCard className={styles.container}>
        <Row>
          <Col span={19}>
            <CustomText
              font="medium"
              size={24}
              color="secondary"
              className="pl-2"
            >
              Edit Survey/Quiz
            </CustomText>
          </Col>
        </Row>
        <br />
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <FormItem
            label="Type"
            name="type"
            rules={[
              {
                required: true,
                message: 'Required field'
              }
            ]}
          >
            <Radio.Group defaultValue={type}>
              <Radio value={false} onClick={() => radioHandler(false)}>
                Survey
              </Radio>
              <Radio value={true} onClick={() => radioHandler(true)}>
                Quiz
              </Radio>
            </Radio.Group>
          </FormItem>
          <FormItem
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Required field'
              }
            ]}
          >
            <Input allowClear placeholder="Enter quiz name" />
          </FormItem>
          <Row gutter={16}>
            <Col span={8}>
              <FormItem
                label="Start date"
                name="start_date"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
              >
                <DatePicker
                  showTime
                  allowClear
                  placeholder="Select start date"
                  value={
                    moment(quizQuestion[0]?.quiz?.start_date, dateFormat) || ''
                  }
                />
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem
                label="Expires on"
                name="expires_on"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
              >
                <DatePicker
                  showTime
                  allowClear
                  placeholder="Select expires date"
                  value={
                    moment(quizQuestion[0]?.quiz?.expires_on, dateFormat) || ''
                  }
                />
              </FormItem>
            </Col>
          </Row>
          <div>
            <Space size={44}>
              <FormItem
                label="Is active?"
                name="is_active"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
              >
                <Switch defaultChecked onChange={onChange} />
              </FormItem>
              <FormItem
                label="Is featured?"
                name="is_featured"
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
              >
                <Switch checked={isfeature} onChange={onChangefeature} />
              </FormItem>
            </Space>
          </div>
          {quizQuestion?.map((element, index) => (
            <>
              <Space size={14}>
                <FormItem
                  key={index}
                  label={`Question${index + 1}`}
                  rules={[
                    {
                      required: false,
                      message: 'Required field'
                    }
                  ]}
                >
                  <Input
                    allowClear
                    placeholder="Enter question"
                    value={element.question || ''}
                    name="question"
                    onChange={(e) => handleChange(index, e)}
                    style={{ width: '600px' }}
                  />
                </FormItem>
                {index > 0 ? (
                  <CustomButton
                    className="button add"
                    type="button"
                    onClick={() =>
                      removeQuestionFormFields(element.question_id)
                    }
                    style={{ float: 'right' }}
                  >
                    Remove Question
                  </CustomButton>
                ) : !type ? null : (
                  <CustomButton
                    className="button add"
                    type="button"
                    onClick={() => addFormFields()}
                    style={{ float: 'right' }}
                  >
                    Add Question
                  </CustomButton>
                )}
              </Space>
              <DragDropContext
                onDragEnd={(droppedItem) => handleDrop(index, droppedItem)}
              >
                <Droppable droppableId="list-container">
                  {(provided) => (
                    <div
                      className="list-container"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {element.options.map((item, i) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.name}
                          index={i}
                        >
                          {(provided) => (
                            <>
                              <div
                                className="item-container"
                                ref={provided.innerRef}
                                {...provided.dragHandleProps}
                                {...provided.draggableProps}
                              >
                                <Space size={14} key={i}>
                                  <HolderOutlined
                                    style={{ fontSize: '20px' }}
                                  />
                                  <FormItem
                                    key={i}
                                    label={`Option${i + 1}`}
                                    rules={[
                                      {
                                        required: false,
                                        message: 'Required field'
                                      }
                                    ]}
                                  >
                                    <Input
                                      allowClear
                                      name="name"
                                      placeholder="Enter option"
                                      value={item.name || ''}
                                      onChange={(e) =>
                                        handleChangeOption(index, i, e)
                                      }
                                      style={{ width: '566px' }}
                                    />
                                  </FormItem>
                                  {!type ? null : (
                                    <FormItem
                                      key={i}
                                      rules={[
                                        {
                                          required: true,
                                          message: 'Required field'
                                        }
                                      ]}
                                      className="pl-2"
                                      style={{ marginTop: '22px' }}
                                    >
                                      <Radio.Group value={element.right_answer}>
                                        <Radio
                                          value={item.name}
                                          name={`right_answer${index}`}
                                          key={i}
                                          onChange={() =>
                                            onChangeRightAnswer(
                                              item.name,
                                              index
                                            )
                                          }
                                        ></Radio>
                                      </Radio.Group>
                                    </FormItem>
                                  )}
                                  {i > 0 ? (
                                    <CustomButton
                                      className="button add"
                                      type="button"
                                      onClick={() =>
                                        removeFormFields(index, item.id)
                                      }
                                      style={{
                                        float: 'right',
                                        padding: '5px 15px 32px 15px',
                                        fontSize: '19px'
                                      }}
                                    >
                                      <DeleteOutlined />
                                    </CustomButton>
                                  ) : (
                                    <CustomButton
                                      className="button add"
                                      type="button"
                                      onClick={() => addFormOptionFields(index)}
                                      style={{
                                        float: 'right',
                                        padding: '5px 15px 32px 15px',
                                        fontSize: '19px'
                                      }}
                                    >
                                      <PlusCircleOutlined />
                                    </CustomButton>
                                  )}
                                </Space>
                              </div>
                              <br />
                            </>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {/* </Space> */}
            </>
          ))}

          <FormItem className="text-right">
            <Space>
              <CustomButton type="primary" htmlType="submit">
                Update
              </CustomButton>
            </Space>
          </FormItem>
        </Form>
      </CustomCard>
    </Layout>
  );
};
export default CreateQuizPage;
