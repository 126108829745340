import { Avatar, Modal, Space } from 'antd';
import CustomText from '../CustomText';
import styles from './users_list_modal.module.less';

const UsersListModal = ({ open, title, onCancel, data }) => {
  return (
    <Modal
      open={open}
      title={
        <CustomText font="medium" size={18}>
          {title}
        </CustomText>
      }
      onCancel={onCancel}
      footer={null}
      className={styles.container}
    >
      <Space direction="vertical">
        {data.map((item, ind) => {
          const { full_name } = item;

          return (
            <Space key={ind}>
              <Avatar className={`cs-medium ${styles.avatar}`}>
                {full_name.slice(0, 1)}
              </Avatar>
              <CustomText font="medium" size={12}>
                {full_name}
              </CustomText>
            </Space>
          );
        })}
      </Space>
    </Modal>
  );
};

export default UsersListModal;
