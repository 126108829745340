import { Avatar, Card, Col, Image, Row, Space } from 'antd';
import CustomCard from 'common/CustomCard';
import CustomText from 'common/CustomText';
import CustomButton from 'common/CustomButton';
import styles from './key_contacts.module.less';
import { history } from 'app/history';

const contacts = [
  {
    intials: 'AB',
    name: 'Apeksha Balan',
    email: 'apekshab@tataprojects.com'
  },
  {
    intials: 'AD',
    name: 'Ankur Dani',
    email: 'ankurad@tataprojects.com'
  },
  {
    intials: 'DS',
    name: 'Dhairya Solanki',
    email: 'dhairyas@tataprojects.com'
  },
  {
    intials: 'GK',
    name: 'Gauri Kanade',
    email: 'gaurikanade@tataprojects.com'
  },
  // {
  //   intials: 'NC',
  //   name: 'Namrata Chahal',
  //   email: 'namratachahal-c@tataprojects.com'
  // }
  // {
  //   intials: 'SB',
  //   name: 'Shoubhik Bose',
  //   email: 'shoubhikbose@tataprojects.com'
  // },
  {
    intials: 'VP',
    name: 'Vishal Parikh',
    email: 'vishalp@tataprojects.com'
  }
];

const KeyContactsPage = () => {
  return (
    <CustomCard className={styles.container}>
      <Row className="mt-1">
        <Col xs={24} sm={0}>
          <Space direction="vertical" className="pl-2 mb-4">
            <CustomText font="medium" size={24} color="secondary">
              Reach Out
            </CustomText>
            <CustomText size={16} color={2}>
              We encourage everyone to participate in our transformational
              journey by sharing ideas.
            </CustomText>
            <Space>
              <CustomButton
                type="primary"
                onClick={() => history.push('/suggest-idea')}
              >
                Share an idea
              </CustomButton>
              <CustomButton type="primary">
                <a href="mailto:phoenix@tataprojects.com?subject=Ask%20Phoenix%20Inquiry">
                  Ask Phoenix
                </a>
              </CustomButton>
            </Space>
            <Space direction="vertical" size={0}>
              <CustomText size={14} color={2}>
                Reach out to us at
              </CustomText>
              <span className={styles.email}>phoenix@tataprojects.com</span>
            </Space>
          </Space>
        </Col>
        <Col span={24}>
          <CustomText
            font="medium"
            size={24}
            color="secondary"
            className="pl-2"
          >
            Transformation Office
          </CustomText>
        </Col>
        <Col sm={9} xs={24}>
          <Row>
            {contacts.map((pillar, index) => {
              const { intials, name, email } = pillar;

              return (
                <Col span={24} key={index}>
                  <Card vgutter={8} bordered={false} bodyStyle={{ padding: 8 }}>
                    <Space size={10}>
                      <Avatar className={styles.avatar}>
                        <CustomText font="medium" size={20} color={2}>
                          {intials}
                        </CustomText>
                      </Avatar>
                      <Space direction="vertical" size={0}>
                        <CustomText font="medium" size={16}>
                          {name}
                        </CustomText>
                        <CustomText size={12} color={2}>
                          {email}
                        </CustomText>
                      </Space>
                    </Space>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Col>
        <Col sm={6} className="text-center m-auto" xs={0}>
          <Image
            src="images/bird.svg"
            preview={false}
            className={styles.imageWidth}
          />
        </Col>
        <Col sm={5} offset={3} xs={0}>
          <Space direction="vertical">
            <Space direction="vertical" size="large">
              <Space direction="vertical">
                <CustomText color={2}>Have an idea?</CustomText>
                <CustomButton
                  type="primary"
                  onClick={() => history.push('/suggest-idea')}
                >
                  Share an idea
                </CustomButton>
              </Space>
              <Space direction="vertical">
                <CustomText color={2}>Have a question?</CustomText>
                <CustomButton type="primary">
                  <a href="mailto:phoenix@tataprojects.com?subject=Ask%20Phoenix%20Inquiry">
                    Ask Phoenix
                  </a>
                </CustomButton>
              </Space>
              <Space direction="vertical" size={0}>
                <CustomText size={14} color={2}>
                  Reach out to us at
                </CustomText>
                <span className={styles.email}>phoenix@tataprojects.com</span>
              </Space>
            </Space>
          </Space>
        </Col>
      </Row>
    </CustomCard>
  );
};

export default KeyContactsPage;
