import QuizListPage from './components/QuizListPage';
import Layout from 'common/CustomLayout';

const QuizList = () => {
  return (
    <Layout>
      <QuizListPage />
    </Layout>
  );
};

export default QuizList;
