import {
  Col,
  Form,
  Input,
  Row,
  Space,
  Upload,
  Button,
  Select,
  DatePicker,
  Popconfirm
} from 'antd';
import {
  InfoCircleOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import FormItem from 'common/FormItem';
import CustomButton from 'common/CustomButton';
import CustomCard from 'common/CustomCard';
import CustomText from 'common/CustomText';
import { history } from 'app/history';
import { useEffect, useCallback } from 'react';
import moment from 'moment';
// import { useParams } from 'react-router-dom';
// import { getRRById } from '../../redux/slice';
// import { useDispatch } from 'react-redux';
// import dayjs from 'dayjs';

const { Option } = Select;
const monthFormat = 'MM-YYYY';
// const { TextArea } = Input;

const EditWinnerPage = ({
  onFinish,
  normFile,
  rrGroups,
  rrMaster,
  handlePostRRGroup,
  postRRGroupLoading,
  group,
  setGroup,
  setSearchvalue,
  setideaSearchvalue,
  ideas,
  setItemList,
  itemList,
  WinnerDetails,
  setWinnerDetails,
  data,
  showDisablePopup,
  setShowDisablePopup,
  handleDeleteDocument
}) => {
  if (!data) return null;
  // const { name } = data;
  const [form] = Form.useForm();

  // const files = WinnerDetails?.rr_images?.filter((o) => o?.doc_url !== null);

  // console.log('tegbbvs', files);
  const visibility = useCallback((record) => {
    return showDisablePopup[record];
  }, showDisablePopup);

  useEffect(() => {
    populateFields();
    setWinnerDetails(WinnerDetails);
  });

  // console.log('testttt', name);

  const onSearch = (value) => {
    setSearchvalue(value);
    console.log('search:', value);
  };
  const onSearchIdea = (value) => {
    setideaSearchvalue(value);
  };

  const handlerrmaster = (value) => {
    value === 4 ? setItemList([{ name: '' }]) : setItemList([]);
  };

  const handleAddmember = () => {
    setItemList([...itemList, { name: '' }]);
  };

  let handleChange = (i, e) => {
    let newFormValues = [...itemList];
    newFormValues[i]['name'] = e.target.value;
    setItemList(newFormValues);
  };
  let handleRemovemember = (i) => {
    let newFormValues = [...itemList];
    newFormValues.splice(i, 1);
    setItemList(newFormValues);
  };

  const populateFields = () => {
    form.setFieldsValue({
      name: WinnerDetails?.name,
      designation: WinnerDetails?.designation,
      sugg_id: WinnerDetails?.sugg_id,
      focus_area: WinnerDetails?.focus_area,
      citation: WinnerDetails?.citation,
      rr_group_id: WinnerDetails?.rr_group_id,
      rr_master_id: WinnerDetails?.rr_master_id,
      url: WinnerDetails?.url,
      rr_date: moment(moment(WinnerDetails?.rr_date), monthFormat)
    });
  };

  console.log('date', WinnerDetails?.rr_date);
  return (
    <CustomCard>
      <div className="df-jb-ac mb-3">
        <CustomText font="bold" size={36} color="secondary">
          Edit Winner
        </CustomText>
        <Button size={'small'} onClick={() => history.back()}>
          Back
        </Button>
      </div>

      <Form
        form={form}
        name="create-rr"
        layout="vertical"
        onFinish={onFinish}
        className="mt-3"
      >
        <Row gutter={24}>
          <Col sm={12} xs={24}>
            <FormItem
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Required field!'
                }
              ]}
            >
              <Input placeholder="Please enter name" />
            </FormItem>
          </Col>

          <Col sm={12} xs={24}>
            <FormItem
              label="Transformation Unit"
              name="designation"
              rules={[
                {
                  required: false,
                  message: 'Required field!'
                }
              ]}
            >
              <Input placeholder="Please enter transformation unit" />
            </FormItem>
          </Col>
          <Col sm={24} xs={24}>
            <FormItem
              label="Ideas"
              name="sugg_id"
              rules={[
                {
                  required: false,
                  message: 'Required field!'
                }
              ]}
            >
              <Select
                placeholder="Select idea"
                allowClear
                onSearch={onSearchIdea}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                showSearch
              >
                {ideas.map((item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {`${item.User.first_name} || ${
                        item.solution.length > 60
                          ? item.solution.substring(0, 57) + '...'
                          : item.solution
                      }`}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>
          </Col>
          <Col sm={12} xs={24}>
            <FormItem
              label="Initiative"
              name="focus_area"
              // rules={[
              //   {
              //     required: true,
              //     message: 'Required field!'
              //   }
              // ]}
            >
              <Input placeholder="Please enter initiative" />
            </FormItem>
          </Col>
          <Col sm={12} xs={24}>
            <FormItem
              label="Citation"
              name="citation"
              rules={[
                {
                  required: true,
                  message: 'Required field!'
                }
              ]}
            >
              <Input placeholder="Please enter citation" />
            </FormItem>
          </Col>
          <Col sm={12} xs={24}>
            <FormItem label="Group" name="rr_group_id">
              <Select
                placeholder="Select group"
                allowClear
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                showSearch
                // onChange={(e) => handleOnchange(e)}
                // suffixIcon={<CaretDownFilled />}
                // showSearch
              >
                {rrGroups.map((item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.title}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>
          </Col>

          <Col sm={12} xs={24}>
            <FormItem label="Add group">
              <Row style={{ alignItems: 'baseline' }}>
                <Col span={22}>
                  <FormItem>
                    <Input
                      placeholder="Please enter group"
                      onChange={(e) => setGroup(e.target.value)}
                    />
                  </FormItem>
                </Col>
                <Col span={2} className="text-center">
                  <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    loading={postRRGroupLoading}
                    onClick={handlePostRRGroup}
                    disabled={!group}
                  />
                </Col>
              </Row>
            </FormItem>
          </Col>

          <Col sm={24} xs={24}>
            <FormItem
              label="Reward title"
              name="rr_master_id"
              rules={[
                {
                  required: true,
                  message: 'Required field!'
                }
              ]}
            >
              <Select
                placeholder="Select title"
                allowClear
                onChange={handlerrmaster}
              >
                {rrMaster.map((item) => {
                  return (
                    <Option key={item.id} value={item.id}>
                      {item.title}
                    </Option>
                  );
                })}
              </Select>
            </FormItem>
          </Col>
          {itemList.length
            ? itemList.map((element, index) => (
                <>
                  <Col sm={22} xs={22} key={index}>
                    <FormItem
                      // name="super_sqauds"
                      rules={[
                        {
                          required: false,
                          message: 'Required field!'
                        }
                      ]}
                    >
                      <Input
                        placeholder="Enter member name"
                        value={element.name || ''}
                        onChange={(e) => handleChange(index, e)}
                      />
                    </FormItem>
                  </Col>
                  <Col sm={2} xs={2}>
                    {index === 0 ? (
                      <CustomButton
                        type="primary"
                        onClick={handleAddmember}
                        style={{ height: '44px' }}
                      >
                        <PlusCircleOutlined style={{ fontSize: 15 }} />
                      </CustomButton>
                    ) : (
                      <CustomButton
                        type="primary"
                        onClick={() => handleRemovemember(index)}
                        style={{ height: '44px' }}
                      >
                        <MinusCircleOutlined style={{ fontSize: 15 }} />
                      </CustomButton>
                    )}
                  </Col>
                </>
              ))
            : ''}

          {/* <Col sm={24} xs={24}>
              <FormItem
                label="Result"
                name="results"
                rules={[
                  {
                    required: false,
                    message: 'Required field!'
                  }
                ]}
              >
                <TextArea placeholder="Enter result" />
              </FormItem>
            </Col> */}

          <Col sm={24} xs={24}>
            <FormItem label="Upload person picture(s)">
              <FormItem
                name="images"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                noStyle
                rules={[
                  {
                    required: true,
                    message: 'Please upload image(s)'
                  }
                ]}
              >
                <Upload.Dragger
                  name="images"
                  accept="image/*"
                  multiple={true}
                  customRequest={({ onSuccess }) => {
                    setTimeout(() => {
                      onSuccess('ok');
                    }, 0);
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <InfoCircleOutlined />
                  </p>
                  <Space>
                    <CustomText font="medium" color="primary">
                      Click to upload
                    </CustomText>
                    <CustomText color={2}>or drag and drop</CustomText>
                  </Space>
                </Upload.Dragger>
              </FormItem>
              {WinnerDetails?.rr_images?.map((item, index) =>
                item.doc_url != null ? (
                  <>
                    <a href={item.doc_url} key={index}>
                      {item.file_name}
                    </a>
                    <Popconfirm
                      title="Are you sure you want to delete this image?"
                      visible={visibility(item.id)}
                      onConfirm={() => {
                        handleDeleteDocument({
                          id: item.id,
                          doc_url: item.doc_url
                        });
                      }}
                      onCancel={() => {
                        setShowDisablePopup({});
                      }}
                      okText="Delete"
                      cancelText="No"
                      okButtonProps={{
                        type: 'danger'
                      }}
                    >
                      <DeleteOutlined
                        style={{ color: 'red', paddingLeft: '10px' }}
                      />
                    </Popconfirm>
                    {/* <a href={item.doc_url} key={index}>
                      {item.file_name}
                    </a>{' '}
                    <DeleteOutlined
                      key={index}
                      style={{ color: 'red', paddingLeft: '10px' }}
                    /> */}
                    <br />
                  </>
                ) : null
              )}
            </FormItem>
          </Col>
          <Col sm={24} xs={24}>
            <FormItem
              label="Date"
              name="rr_date"
              rules={[
                {
                  required: true,
                  message: 'Required field!'
                }
              ]}
            >
              <DatePicker format={monthFormat} picker="month" />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label="URL"
              name="url"
              rules={[
                {
                  required: false,
                  message: 'Required field!'
                }
              ]}
            >
              <Input placeholder="Please enter URL" />
            </FormItem>
          </Col>
          <Col sm={24} xs={24}>
            <FormItem label="Upload team pic details picture(s)">
              <FormItem
                name="team_pic_detail"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                noStyle
                rules={[
                  {
                    required: false,
                    message: 'Please upload image(s)'
                  }
                ]}
              >
                <Upload.Dragger
                  name="images"
                  accept="image/*"
                  multiple={true}
                  customRequest={({ onSuccess }) => {
                    setTimeout(() => {
                      onSuccess('ok');
                    }, 0);
                  }}
                >
                  <p className="ant-upload-drag-icon">
                    <InfoCircleOutlined />
                  </p>
                  <Space>
                    <CustomText font="medium" color="primary">
                      Click to upload
                    </CustomText>
                    <CustomText color={2}>or drag and drop</CustomText>
                  </Space>
                </Upload.Dragger>
              </FormItem>
              {WinnerDetails?.rr_images?.map((item, index) =>
                item.team_pic_doc_url != null ? (
                  <>
                    <a href={item.team_pic_doc_url} key={index}>
                      {item.file_name}
                    </a>
                    <Popconfirm
                      title="Are you sure you want to delete this image?"
                      visible={visibility(item.id)}
                      onConfirm={() => {
                        handleDeleteDocument({
                          id: item.id,
                          doc_url: item.team_pic_doc_url
                        });
                      }}
                      onCancel={() => {
                        setShowDisablePopup({});
                      }}
                      okText="Delete"
                      cancelText="No"
                      okButtonProps={{
                        type: 'danger'
                      }}
                    >
                      <DeleteOutlined
                        style={{ color: 'red', paddingLeft: '10px' }}
                      />
                    </Popconfirm>
                    {/* <a href={item.doc_url} key={index}>
                      {item.file_name}
                    </a>{' '}
                    <DeleteOutlined
                      key={index}
                      style={{ color: 'red', paddingLeft: '10px' }}
                    /> */}
                    <br />
                  </>
                ) : null
              )}
            </FormItem>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col sm={{ span: 6, offset: 18 }} xs={24}>
            <FormItem className="text-right">
              <CustomButton type="primary" htmlType="submit" className="w-100">
                Submit
              </CustomButton>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </CustomCard>
  );
};

export default EditWinnerPage;
