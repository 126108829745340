import React, { useEffect } from 'react';
import { Col, Image, Row, Space } from 'antd';
import CustomCard from 'common/CustomCard';
import CustomText from 'common/CustomText';
import styles from './md_message.module.less';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMessages } from '../../redux/slice';
import Layout from 'common/CustomLayout';

const ViewMDMessagePage = () => {
  const { messages } = useSelector((state) => state.mdmessage);
  const dispatch = useDispatch();
  console.log(messages);
  const filtered = messages.filter((o) => o.status === 'active');
  useEffect(() => {
    handleGetAllMessages();
  }, []);

  const handleGetAllMessages = () => {
    dispatch(getAllMessages());
  };
  // useEffect(() => {
  const regex = /(<([^>]+)>)/gi;
  const newString = messages.length
    ? filtered[0].blurb.replace(regex, ' ')
    : '';
  // }, []);

  return (
    <Layout>
      <Space direction="vertical">
        <CustomCard className={styles.container}>
          {/* <Space direction="vertical" size={'middle'}> */}
          <Row gutter={16} className={styles.top_container}>
            <Col sm={18} xs={24}>
              <CustomText size={16} color={2}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: messages.length ? filtered[0].pre_blurb : ''
                  }}
                  className={styles.body}
                />
              </CustomText>
            </Col>
            <Col sm={6} xs={24} className="text-center">
              <Image
                preview={false}
                src={filtered[0]?.image}
                wrapperClassName="mb-2"
              />
            </Col>
          </Row>
          <div className={styles.quotation_container}>
            <Space direction="vertical" size="middle">
              <Space size={10}>
                <CustomText font="medium" size={22}>
                  <CustomText font="bold" size={30} color="primary">
                    &#8220;
                  </CustomText>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: messages.length ? newString : ''
                    }}
                    className={styles.body}
                  />
                  <CustomText font="bold" size={30} color="primary">
                    &#8221;
                  </CustomText>
                </CustomText>
              </Space>
              {/* <CustomText size={16} color={2}> */}
              <span
                dangerouslySetInnerHTML={{
                  __html: messages.length ? filtered[0].designation : ''
                }}
                className={styles.body}
              />
              {/* </CustomText> */}
            </Space>
          </div>
          <br />
          <CustomText size={16} color={2}>
            <span
              dangerouslySetInnerHTML={{
                __html: messages.length ? filtered[0].post_blurb : ''
              }}
              className={styles.body}
            />
          </CustomText>
          {/* </Space> */}
        </CustomCard>
      </Space>
    </Layout>
  );
};

export default ViewMDMessagePage;
