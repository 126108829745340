import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { quizzes, addEventDetails, removeQuize, all_urls } from './api';

const initialState = {
  quizzes: [],
  urls: []
};

const actions = {
  GET_ALL_QUIZZES: 'eventconsole/GET_ALL_QUIZZES',
  ADD_EVENT: 'eventconsole/ADD_EVENT',
  DELETE_QUIZ: 'eventconsole/DELETE_QUIZ',
  GET_ALL_URLS: 'getAllUrls/GET_ALL_URLS'
};

export const getAllQuizzes = createAsyncThunk(
  actions.GET_ALL_QUIZZES,
  async (payload) => {
    const response = await quizzes(payload);

    return response;
  }
);

export const addEvent = createAsyncThunk(actions.ADD_EVENT, async (payload) => {
  let url = payload.id ? `?id=${payload.id}` : '';
  const response = await addEventDetails(url, payload);

  return response;
});

export const deleteQuiz = createAsyncThunk(
  actions.DELETE_QUIZ,
  async (payload) => {
    console.log(payload);
    let url = payload.id ? `/delete?id=${payload.id}` : '';
    const response = await removeQuize(url, payload);

    return response;
  }
);

export const getAllUrls = createAsyncThunk(
  actions.GET_ALL_URLS,
  async (payload) => {
    const response = await all_urls(payload);

    return response;
  }
);

export const quizConsoleSlice = createSlice({
  name: 'quizconsole',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllQuizzes.pending, (state) => {
        state.getAllEventsLoading = true;
      })
      .addCase(getAllQuizzes.fulfilled, (state, action) => {
        state.getAllEventsLoading = false;

        const { success, data, message: msg } = action.payload;

        if (success) {
          state.quizzes = data;
        } else {
          state.quizzes = [];
          message.error(msg);
        }
      })
      .addCase(getAllQuizzes.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEventssLoading = false;

        message.error(msg);
      });

    builder
      .addCase(addEvent.pending, (state) => {
        state.getAllEventLoading = true;
      })
      .addCase(addEvent.fulfilled, (state, action) => {
        state.getAllEventLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(addEvent.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEventLoading = false;

        message.error(msg);
      });

    builder
      .addCase(deleteQuiz.pending, (state) => {
        state.getAllEventLoading = true;
      })
      .addCase(deleteQuiz.fulfilled, (state, action) => {
        state.getAllEventLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(deleteQuiz.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEventLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllUrls.pending, (state) => {
        state.getAllEventLoading = true;
      })
      .addCase(getAllUrls.fulfilled, (state, action) => {
        state.getAllEventLoading = false;

        const { success, message: msg, data } = action.payload;

        if (success) {
          // message.success(msg);
          state.urls = data ? data : [];
        } else {
          message.error(msg);
        }
      })
      .addCase(getAllUrls.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEventLoading = false;

        message.error(msg);
      });
  }
});

export default quizConsoleSlice.reducer;
