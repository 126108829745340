import apiClient from 'utilities/apiClient';

export const allArticles = () => {
  return apiClient.get(apiClient.Urls.allArticles, null, true);
};

export const articleLike = (payload) => {
  return apiClient.post(apiClient.Urls.articleLike, payload, true);
};

export const articleDisLike = (payload) => {
  return apiClient.delete(apiClient.Urls.articleDisLike, payload, true);
};

export const articleById = (payload) => {
  return apiClient.get(apiClient.Urls.allArticles, payload, true);
};

export const articleAddComment = (payload) => {
  return apiClient.post(apiClient.Urls.articleAddComment, payload, true);
};

export const articleDeleteComment = (id) => {
  return apiClient.delete(
    `${apiClient.Urls.articleDeleteComment}?id=${id}`,
    {},
    true
  );
};

export const addArticle = (payload) => {
  return apiClient.post(`${apiClient.Urls.addArticle}`, payload, true, 'file');
};
