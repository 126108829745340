import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomLayoutPage from './components/CustomLayoutPage';
import { toggleAddIdeaModal } from 'features/ideas/redux/slice';

const CustomLayout = ({ children, header = true }) => {
  const dispatch = useDispatch();
  const { addIdeaModal } = useSelector((state) => state.ideas);

  const [floatinBtnOn, setFloatingBtnOn] = useState(false);

  const handleToggleAddIdeaModal = () => {
    dispatch(toggleAddIdeaModal());
  };

  const toggleFloatingBtnOn = () => {
    setFloatingBtnOn(!floatinBtnOn);
  };

  return (
    <CustomLayoutPage
      header={header}
      addIdeaModal={addIdeaModal}
      handleToggleAddIdeaModal={handleToggleAddIdeaModal}
      floatinBtnOn={floatinBtnOn}
      toggleFloatingBtnOn={toggleFloatingBtnOn}
    >
      {children}
    </CustomLayoutPage>
  );
};

export default CustomLayout;
