import React, { useEffect, useState } from 'react';
import { Space, Row, Col } from 'antd';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
import { getAllQuizzesbyId } from '../../redux/slice';
import { useParams } from 'react-router-dom';
import Layout from 'common/CustomLayout';
import CustomCard from 'common/CustomCard';
import styles from '../../index.module.less';
import CustomText from 'common/CustomText';
import { Progress } from 'antd';
import CustomButton from 'common/CustomButton';
// import BGIMG from '../../../quiz/images/bg1.png';

const QuizResult = () => {
  const [quiz, setQuiz] = useState('');

  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    handleGetAllQuizzesById();
  }, [params.id]);

  const handleGetAllQuizzesById = () => {
    // console.log( page);
    let payload = { quiz_id: params.id };
    dispatch(getAllQuizzesbyId(payload)).then((response) => {
      setQuiz(response.payload.data);
    });
  };

  console.log('test', quiz[0]?.question);

  const hanldeClick = () => {
    history.push(`/view-quiz-result/${params.id}`);
  };

  return (
    <Layout>
      <CustomCard
        className={styles.container}
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%'
        }}
      >
        <Row gutter={28} className={styles.areas_mobile_container}>
          <Col span={6}></Col>
          <Col span={16}>
            {quiz[0]?.response_sumitted ? (
              <>
                <Space
                  direction="vertical"
                  style={{
                    position: 'absolute',
                    textAlign: 'center',
                    marginTop: '40px'
                  }}
                >
                  <CustomText
                    font="medium"
                    size={30}
                    color="secondary"
                    className="pl-2"
                  >
                    Thank you for participating in the quiz
                  </CustomText>
                  <CustomText
                    font="medium"
                    className="pl-2"
                    color="secondary"
                    size={25}
                  >
                    Check your score
                  </CustomText>
                  <Progress
                    type="circle"
                    percent={
                      (quiz[0]?.correct_quiz_count /
                        quiz[0]?.total_questions_count) *
                      100
                    }
                    format={() =>
                      `${
                        quiz[0]?.correct_quiz_count
                          ? Math.round(
                              (quiz[0]?.correct_quiz_count /
                                quiz[0]?.total_questions_count) *
                                100
                            )
                          : 0
                      }%`
                    }
                    style={{
                      marginTop: '20px'
                    }}
                  />
                  <br></br>
                  <CustomButton
                    type="primary"
                    onClick={() => hanldeClick({ type: 'finish' })}
                  >
                    View answers
                  </CustomButton>
                </Space>
              </>
            ) : !quiz[0]?.response_sumitted & quiz[0]?.is_expired ? (
              <Space
                direction="vertical"
                style={{
                  position: 'absolute',
                  textAlign: 'center',
                  marginTop: '40px',
                  marginLeft: '0px'
                }}
              >
                <CustomText font="medium" size={25} color="secondary">
                  Oops...you missed the Quiz deadline <br />
                  Keep checking back for more quizzes!
                </CustomText>
                <br />
                <br />
                <CustomButton
                  type="primary"
                  onClick={() => hanldeClick({ type: 'finish' })}
                >
                  View quiz
                </CustomButton>
              </Space>
            ) : null}
          </Col>
          <Col span={6}></Col>
        </Row>{' '}
        <CustomButton
          type="primary"
          onClick={() => history.push(`/polls-surveys`)}
          direction="vertical"
          style={{ position: 'absolute', top: '30px', right: '10px' }}
        >
          Back
        </CustomButton>
      </CustomCard>
    </Layout>
  );
};
export default QuizResult;
