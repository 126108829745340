import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
// import { groupBy } from 'lodash';
import {
  addRRGroup,
  allRR,
  rrAddComment,
  rrAllGroups,
  rrById,
  rrCreate,
  rrEdit,
  rrDeleteComment,
  rrDisLike,
  rrLike,
  rrMasterDD,
  ideas,
  deleteWinners,
  deleteDocument
} from './api';

const initialState = {
  rr: [],
  group_data: [],
  getAllRRLoading: false,
  postRRLoading: false,
  rrGroups: [],
  rrMaster: [],
  postRRGroupLoading: false,
  rrById: null,
  getRRByIdLoading: false,
  deleteRRCommentLoading: false,
  ideas: [],
  getAllIdeasLoading: false,
  pagination: '',
  page: 1
};

const actions = {
  GET_ALL_RR: 'centre_stage/GET_ALL_RR',
  POST_RR: 'centre_stage/POST_RR',
  PATCH_RR: 'centre_stage/PATCH_RR',
  GET_ALL_RR_GROUPS: 'centre_stage/GET_ALL_RR_GROUPS',
  GET_ALL_RR_MASTER: 'centre_stage/GET_ALL_RR_MASTER',
  POST_RR_GROUP: 'centre_stage/POST_RR_GROUP',
  POST_RR_LIKE: 'centre_stage/POST_RR_LIKE',
  DELETE_RR_LIKE: 'centre_stage/DELETE_RR_LIKE',
  GET_RR_BY_ID: 'centre_stage/GET_RR_BY_ID',
  POST_RR_COMMENT: 'centre_stage/POST_RR_COMMENT',
  DELETE_RR_COMMENT: 'centre_stage/DELETE_RR_COMMENT',
  GET_ALL_IDEAS: 'centre_stage/GET_ALL_IDEAS',
  DELETE_WINNER: 'centre_stage/DELETE_WINNER',
  DELETE_DOCUMENT: 'centre_stage/DELETE_DOCUMENT'
};

export const getAllRR = createAsyncThunk(
  actions.GET_ALL_RR,
  async (payload) => {
    const response = await allRR(payload);

    return response;
  }
);

export const postRR = createAsyncThunk(actions.POST_RR, async (payload) => {
  const response = await rrCreate(payload);

  return response;
});

export const patchRR = createAsyncThunk(actions.PATCH_RR, async (payload) => {
  const response = await rrEdit(payload);

  return response;
});

export const getAllRRGroups = createAsyncThunk(
  actions.GET_ALL_RR_GROUPS,
  async (payload) => {
    const response = await rrAllGroups(payload);

    return response;
  }
);

export const getAllRRMaster = createAsyncThunk(
  actions.GET_ALL_RR_MASTER,
  async () => {
    const response = await rrMasterDD();

    return response;
  }
);

export const postRRGroup = createAsyncThunk(
  actions.POST_RR_GROUP,
  async (payload) => {
    const response = await addRRGroup(payload);

    return response;
  }
);

export const postRRLike = createAsyncThunk(
  actions.POST_RR_LIKE,
  async (payload) => {
    const response = await rrLike(payload);

    return response;
  }
);

export const deleteRRLike = createAsyncThunk(
  actions.DELETE_RR_LIKE,
  async (payload) => {
    const response = await rrDisLike(payload);

    return response;
  }
);

export const getRRById = createAsyncThunk(
  actions.GET_RR_BY_ID,
  async (payload) => {
    const response = await rrById(payload);

    return response;
  }
);

export const postRRComment = createAsyncThunk(
  actions.POST_RR_COMMENT,
  async (payload) => {
    const response = await rrAddComment(payload);

    return response;
  }
);

export const deleteRRComment = createAsyncThunk(
  actions.DELETE_RR_COMMENT,
  async (id) => {
    const response = await rrDeleteComment(id);

    return response;
  }
);

export const getAllIdeas = createAsyncThunk(
  actions.GET_ALL_IDEAS,
  async (payload) => {
    const response = await ideas(payload);

    return response;
  }
);

export const deleteWinner = createAsyncThunk(
  actions.DELETE_WINNER,
  async (payload) => {
    const response = await deleteWinners(payload);
    return response;
  }
);

export const deleteDoc = createAsyncThunk(
  actions.DELETE_DOCUMENT,
  async (payload) => {
    console.log(payload);
    const response = await deleteDocument(`dlt-document`, payload);

    return response;
  }
);

export const centreStageSlice = createSlice({
  name: 'centre_stage',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRR.pending, (state) => {
        state.getAllRRLoading = true;
      })
      .addCase(getAllRR.fulfilled, (state, action) => {
        state.getAllRRLoading = false;
        console.log(action);
        const { success, data, message: msg, pagination } = action.payload;
        const { arg } = action.meta;
        if (success) {
          // let group_data = data
          //   .filter((o) => o.rr_group_id)
          //   .map((o) => ({ ...o, group_name: o?.rr_group?.title || '' }));
          if (arg.month === '') {
            let prevData = [...state.rr];
            let alteredData = [...prevData, ...data];
            let grouping = [
              ...new Map(alteredData.map((item) => [item.id, item])).values()
            ];
            let filterdata = grouping.filter((o) => o.is_active);
            state.rr = filterdata;
            state.pagination = pagination;
          } else {
            state.rr = data;
            state.pagination = pagination;
          }
          // state.rr = groupBy(alteredData, 'id');
          // state.group_data = groupBy(group_data, 'group_name');
        } else {
          state.rr = null;
          state.group_data = null;
          state.pagination = null;
          message.error(msg);
        }
      })
      .addCase(getAllRR.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllRRLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postRR.pending, (state) => {
        state.postRRLoading = true;
      })
      .addCase(postRR.fulfilled, (state, action) => {
        state.postRRLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(postRR.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postRRLoading = false;

        message.error(msg);
      });

    builder
      .addCase(patchRR.pending, (state) => {
        state.postRRLoading = true;
      })
      .addCase(patchRR.fulfilled, (state, action) => {
        state.postRRLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(patchRR.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postRRLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllRRGroups.pending, (state) => {
        state.getAllRRGroupsLoading = true;
      })
      .addCase(getAllRRGroups.fulfilled, (state, action) => {
        state.getAllRRGroupsLoading = false;

        const { success, data, message: msg } = action.payload;

        if (success) {
          state.rrGroups = data;
        } else {
          state.rrGroups = [];
          message.error(msg);
        }
      })
      .addCase(getAllRRGroups.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllRRGroupsLoading = false;
        state.rrGroups = [];

        message.error(msg);
      });

    builder
      .addCase(getAllRRMaster.pending, (state) => {
        state.getAllRRMasterLoading = true;
      })
      .addCase(getAllRRMaster.fulfilled, (state, action) => {
        state.getAllRRMasterLoading = false;

        const { success, data, message: msg } = action.payload;

        if (success) {
          state.rrMaster = data;
        } else {
          state.rrMaster = [];
          message.error(msg);
        }
      })
      .addCase(getAllRRMaster.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllRRMasterLoading = false;
        state.rrMaster = [];

        message.error(msg);
      });

    builder
      .addCase(postRRGroup.pending, (state) => {
        state.postRRGroupLoading = true;
      })
      .addCase(postRRGroup.fulfilled, (state, action) => {
        state.postRRGroupLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(postRRGroup.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postRRGroupLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postRRLike.pending, (state) => {
        state.postRRLikeLoading = true;
      })
      .addCase(postRRLike.fulfilled, (state, action) => {
        state.postRRLikeLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(postRRLike.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postRRLikeLoading = false;

        message.error(msg);
      });

    builder
      .addCase(deleteRRLike.pending, (state) => {
        state.deleteRRLikeLoading = true;
      })
      .addCase(deleteRRLike.fulfilled, (state, action) => {
        state.deleteRRLikeLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(deleteRRLike.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.deleteRRLikeLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getRRById.pending, (state) => {
        state.getRRByIdLoading = true;
      })
      .addCase(getRRById.fulfilled, (state, action) => {
        state.getRRByIdLoading = false;

        const { data, success, message: msg } = action.payload;

        if (success) {
          state.rrById = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getRRById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getRRByIdLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postRRComment.pending, (state) => {
        state.postRRCommentLoading = true;
      })
      .addCase(postRRComment.fulfilled, (state, action) => {
        state.postRRCommentLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(postRRComment.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postRRCommentLoading = false;

        message.error(msg);
      });

    builder
      .addCase(deleteRRComment.pending, (state) => {
        state.deleteRRCommentLoading = true;
      })
      .addCase(deleteRRComment.fulfilled, (state, action) => {
        state.deleteRRCommentLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(deleteRRComment.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.deleteRRCommentLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllIdeas.pending, (state) => {
        state.getAllIdeasLoading = true;
      })
      .addCase(getAllIdeas.fulfilled, (state, action) => {
        state.getAllIdeasLoading = false;

        const { success, data, message: msg } = action.payload;

        if (success) {
          state.ideas = data;
        } else {
          state.ideas = [];
          message.error(msg);
        }
      })
      .addCase(getAllIdeas.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllIdeasLoading = false;
        state.ideas = [];

        message.error(msg);
      });

    builder
      .addCase(deleteWinner.pending, (state) => {
        state.getAllIdeasLoading = true;
      })
      .addCase(deleteWinner.fulfilled, (state, action) => {
        state.getAllIdeasLoading = false;
        console.log(action);
        const { success, message: msg } = action.payload;
        const { arg } = action.meta;

        if (success) {
          var lists = state.rr.filter((x) => {
            return x.id != arg.id;
          });
          state.rr = lists;
          console.log(state.rr);
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(deleteWinner.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllIdeasLoading = false;
        state.ideas = [];

        message.error(msg);
      });

    builder
      .addCase(deleteDoc.pending, (state) => {
        state.getAllIdeasLoading = true;
      })
      .addCase(deleteDoc.fulfilled, (state, action) => {
        state.getAllIdeasLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      });
  }
});

export const { setPage } = centreStageSlice.actions;

export default centreStageSlice.reducer;
