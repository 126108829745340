import QuizPage from './components/QuizPage';
import Layout from 'common/CustomLayout';

const Quiz = () => {
  return (
    <Layout>
      <QuizPage />
    </Layout>
  );
};

export default Quiz;
