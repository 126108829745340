import apiClient from 'utilities/apiClient';

export const mdmessages = (url) => {
  return apiClient.get(`${apiClient.Urls.allMessages}/${url}`, null, true);
};

export const addMDMessage = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.allMessages}/${url}`,
    payload,
    true,
    'file'
  );
};

export const inactivemsg = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.allMessages}/${url}`,
    payload,
    true,
    'file'
  );
};

export const all_urls = (payload) => {
  return apiClient.get(`${apiClient.Urls.allUrls}`, payload, true);
};
