import { Col, Image, Row, Space } from 'antd';
import CustomCard from 'common/CustomCard';
import CustomText from 'common/CustomText';
import styles from './md_message.module.less';

const MDMessagePage = () => {
  return (
    <Space direction="vertical">
      <CustomCard className={styles.container}>
        <Space direction="vertical" size={'middle'}>
          <Row gutter={16} className={styles.top_container}>
            <Col sm={18} xs={24}>
              <CustomText size={16} color={2}>
                {`Dear Colleagues,

TPL has come a long way in the past decade, growing from an organisation with limited presence to being a preferred EPC player in multiple segments, a market leader in India and a company recognised for the projects it delivers. We have grown much faster than our peers. Combine that with the Tata brand and our impressive list of projects, we are poised to be one of the top EPC brands in India.

Having said that, this growth has come with its unique challenges, which we have faced and learned from. It is time now to take a pause, reflect on our learnings and set ourselves up for an exciting future.
`}
              </CustomText>
            </Col>
            <Col sm={6} xs={24} className="text-center">
              <Image
                preview={false}
                src="images/md.png"
                wrapperClassName="mb-2"
              />
            </Col>
          </Row>
          <div className={styles.quotation_container}>
            <Space direction="vertical" size="middle">
              <Space size={10}>
                <CustomText font="medium" size={24}>
                  <CustomText font="bold" size={30} color="primary">
                    &#8220;
                  </CustomText>
                  {`I believe that our people understand our company the best and have a vast pool of experience and knowledge that will help us on our transformation journey.`}
                  <CustomText font="bold" size={30} color="primary">
                    &#8221;
                  </CustomText>
                </CustomText>
              </Space>
              <CustomText size={16} color={2}>
                — Vinayak Pai, Managing Director
              </CustomText>
            </Space>
          </div>
          <CustomText size={16} color={2}>
            Project Phoenix, our transformation program, will set us up for the
            next level of evolution at Tata Projects. Through Phoenix, we are
            aiming to re-evaluate what we want to be as an organization and how
            we will get there. Based on our past experiences, we will evaluate
            our present sectors and also evaluate new upcoming sectors with high
            opportunities. We will also create a “TPL way of working”, and
            ensure that we consistently demonstrate safe, timely and
            best-in-class digitally enabled project delivery.
          </CustomText>
          {/* <Image src="images/md-video.svg" preview={false} /> */}
          <CustomText size={16} color={2}>
            Over the last couple of months, we have made good progress from
            strategizing on the vision and business strategy to the launch of
            various transformation units. We have also conducted an OHI survey
            where we received responses from many of you and those inputs are
            invaluable for our Phoenix team.
          </CustomText>
          <CustomText size={16} color={2}>
            {`I believe that our people understand our company the best and have a vast pool of experience and knowledge that will help us on our transformation journey.`}
          </CustomText>
          <CustomText size={16} color={2}>
            Let’s commit to connect, contribute and collaborate.
          </CustomText>

          <CustomText font="bold">
            {`With best wishes,
Vinayak Pai
Managing Director`}
          </CustomText>
        </Space>
      </CustomCard>
    </Space>
  );
};

export default MDMessagePage;
