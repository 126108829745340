import React, { useEffect, useState } from 'react';
import { Col, Row, Space, Table, Tag, Switch } from 'antd';
import CustomCard from 'common/CustomCard';
import CustomText from 'common/CustomText';
import styles from './admin_console.module.less';
import {
  // DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllMessages,
  addMessage,
  InactiveMessage,
  getAllUrls
} from '../../redux/slice';
import CustomButton from '@/common/CustomButton';
import AddMessageModal from '../AddMessageModal';
import { history } from 'app/history';
import moment from 'moment';

const MDMessagePage = () => {
  const { messages, urls } = useSelector((state) => state.mdmessage);
  const dispatch = useDispatch();

  const [showAddModal, setShowAddModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [uRLlink, setURLlink] = useState('');
  const [selectedUrlId, setSelectedUrlId] = useState('');
  const [messageImage, setMessageImage] = useState('');

  // const visibility = useCallback((record) => {
  //   return showDisablePopup[record];
  // }, showDisablePopup);

  useEffect(() => {
    handleGetAllMessages();
  }, []);

  useEffect(() => {
    handleGetUrls();
  }, [uRLlink]);

  const handleGetUrls = () => {
    let payload = { search: uRLlink };
    dispatch(getAllUrls(payload));
  };

  const handleGetAllMessages = () => {
    dispatch(getAllMessages());
  };

  const handleCancel = () => {
    setShowAddModal(false);
  };

  const handleAddMessage = (formdata) => {
    console.log(formdata);
    dispatch(addMessage(formdata)).then(() => {
      setShowAddModal(false);
      handleGetAllMessages();
      setSelectedEvent('');
    });
  };

  const handleDeleteEvent = (data) => {
    let formData = new FormData();
    formData.append('id', data.record.id);
    formData.append('is_active', true);
    dispatch(InactiveMessage(formData)).then(() => {
      handleGetAllMessages();
      setSelectedEvent('');
    });
  };

  // const checking = (status) => (status === 'active' ? true : false);
  const columns = [
    {
      title: 'Message Summary',
      key: 'blurb',
      dataIndex: 'blurb',
      render: (text) => (
        <div
          dangerouslySetInnerHTML={{
            __html: text ? text : ''
          }}
          className={styles.tdbody}
        />
      )
    },
    {
      title: 'Date',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (text) => moment(text).format('DD, MMM YYYY')
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (text) =>
        text === 'active' ? (
          <Tag color="#87d068">{text.toUpperCase()}</Tag>
        ) : text === 'archive' ? (
          <Tag color="#f50">{text.toUpperCase()}</Tag>
        ) : (
          <Tag color="#2db7f5">{text.toUpperCase()}</Tag>
        )
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (record) => (
        <Space size="middle">
          <Switch
            size="small"
            checked={record.status === 'active' ? true : false}
            onChange={() => {
              handleDeleteEvent({ record });
            }}
          />
          <EditOutlined
            onClick={() => {
              setEditMode(true);
              setSelectedEvent(record);
              setShowAddModal(true);
            }}
          />
        </Space>
      )
    }
  ];
  return (
    <>
      <CustomCard className={`${styles.container} p-4`}>
        <Row>
          <Col span={19}>
            <CustomText
              font="medium"
              size={24}
              color="secondary"
              className="pl-2"
            >
              Events
            </CustomText>
          </Col>
          <Space>
            <Col span={1}></Col>
            <Col span={2}>
              <CustomButton onClick={() => history.push('/adminconsole')}>
                Back
              </CustomButton>
            </Col>
            <Col span={2}>
              <CustomButton
                type="primary"
                onClick={() => {
                  setShowAddModal(true);
                  setSelectedEvent('');
                }}
              >
                <PlusCircleOutlined style={{ fontSize: 12 }} />
                Add
              </CustomButton>
            </Col>
          </Space>
        </Row>
        <br />
        <Table columns={columns} dataSource={messages} pagination={false} />
      </CustomCard>
      {showAddModal ? (
        <AddMessageModal
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
          handleCancel={handleCancel}
          handleAddMessage={handleAddMessage}
          editMode={editMode}
          selectedEvent={selectedEvent}
          urls={urls}
          setURLlink={setURLlink}
          selectedUrlId={selectedUrlId}
          setSelectedUrlId={setSelectedUrlId}
          setMessageImage={setMessageImage}
          messageImage={messageImage}
        />
      ) : (
        ''
      )}
    </>
    // </CustomCard>
  );
};

export default MDMessagePage;
