import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import IdeasViewPage from './components/IdeasViewPage';
import Layout from 'common/CustomLayout';
import {
  addImplement,
  deleteSuggestionComment,
  getIdeaById,
  getIdeasByArea,
  postIdeaDownvote,
  postIdeaUpvote,
  postSuggestionComment,
  postIdeaTag,
  getTags
} from './redux/slice';
import { getAreaList, toggleAddIdeaModal } from '../ideas/redux/slice';
import { smoothScrollTo } from 'utilities/helpers';

const IdeasView = () => {
  const params = useParams();
  const dispatch = useDispatch();
  let location = useLocation();

  const { addIdeaModal, areas, tags } = useSelector((state) => state.ideas);
  const {
    idea,
    ideasByArea,
    addImplementLoading,
    deleteSuggestionCommentLoading
  } = useSelector((state) => state.ideasview);

  const [showAddImplementation, setShowAddImplementation] = useState(false);
  const [selectedAreaId, setSelectedAreaId] = useState(null);
  const [selectedIdeas, setSelectedIdeas] = useState([]);
  const [implementValues, setImplementValues] = useState({});
  const [showLikes, setShowLikes] = useState(false);
  const [likes, setLikes] = useState([]);
  const [commentText, setCommentText] = useState(null);

  useEffect(() => {
    handleGetIdeaById();
    handleGetTags();
  }, []);

  useEffect(() => {
    if (showAddImplementation) {
      handleGetAreaList();
    }
  }, [showAddImplementation]);

  useEffect(() => {
    if (selectedAreaId) {
      handleGetIdeasByArea();
    }
  }, [selectedAreaId]);

  const handleGetIdeaById = () => {
    dispatch(getIdeaById(params.id)).then(({ payload }) => {
      if (payload?.success && payload?.data) {
        setLikes(payload.data.Likes);
        if (location?.hash) {
          let sectionId = location.hash.replace('#', '');
          smoothScrollTo(sectionId);
        }
      }
    });
  };

  const handlePostIdeaUpvote = (upvote_id) => {
    dispatch(postIdeaUpvote(upvote_id)).then(({ payload }) => {
      if (payload.success) {
        handleGetIdeaById();
      }
    });
  };

  const handleGetTags = () => {
    dispatch(getTags());
  };

  const handlePostIdeaDownvote = (upvote_id) => {
    dispatch(postIdeaDownvote(upvote_id)).then(({ payload }) => {
      if (payload.success) {
        handleGetIdeaById();
      }
    });
  };

  const handleToggleAddIdeaModal = () => {
    dispatch(toggleAddIdeaModal());
  };

  const handleGetAreaList = () => {
    dispatch(getAreaList());
  };

  const handleGetIdeasByArea = () => {
    setSelectedIdeas([]);
    let payload = {
      search_area: areas.find((o) => o.id === selectedAreaId).area
    };

    dispatch(getIdeasByArea(payload));
  };

  const handleSetSelectedIdeas = (type, id) => {
    let selectedIdeasCopy = [...selectedIdeas];

    if (type === 'add') {
      selectedIdeasCopy.push(id);
    }
    if (type === 'remove') {
      selectedIdeasCopy = selectedIdeasCopy.filter((o) => o !== id);
    }

    setSelectedIdeas(selectedIdeasCopy);
  };

  const handleSetImplementValues = (name, value) => {
    setImplementValues({
      ...implementValues,
      [name]: value
    });
  };

  const handleAddImplementation = () => {
    let formData = new FormData();
    formData.append('suggestion_id', params.id);
    formData.append('area_id', selectedAreaId);
    // formData.append('realted_suggestions', selectedIdeas);
    // for (const idea of selectedIdeas) {
    //   formData.append('realted_suggestions', idea);
    // }
    for (var i = 0; i < selectedIdeas.length; i++) {
      formData.append('realted_suggestions[]', selectedIdeas[i]);
    }
    Object.keys(implementValues).forEach((key) => {
      formData.append(key, implementValues[key]);
    });

    dispatch(addImplement(formData)).then(({ payload }) => {
      if (payload?.success) {
        window.location.reload(false);
      }
    });
  };

  const handleAddSuggestionComment = (suggestion_id) => {
    let payload = { suggestion_id, user_comment: commentText };

    dispatch(postSuggestionComment(payload)).then(({ payload }) => {
      if (payload?.success) {
        handleGetIdeaById();
        setCommentText(null);
      }
    });
  };

  const handleDeleteSuggestionComment = (comment_id) => {
    dispatch(deleteSuggestionComment(comment_id)).then(({ payload }) => {
      if (payload?.success) {
        handleGetIdeaById();
      }
    });
  };

  const handlePostIdeaTag = (sugg_id, idea_tags_id) => {
    let formData = new FormData();
    formData.append('sugg_id', sugg_id);
    formData.append('idea_tags_id', idea_tags_id);

    dispatch(postIdeaTag(formData)).then(({ payload }) => {
      if (payload?.success) {
        dispatch(handleGetIdeaById());
      }
    });
  };

  return (
    <Layout>
      <IdeasViewPage
        tags={tags}
        idea={idea}
        handlePostIdeaUpvote={handlePostIdeaUpvote}
        handlePostIdeaDownvote={handlePostIdeaDownvote}
        addIdeaModal={addIdeaModal}
        handleToggleAddIdeaModal={handleToggleAddIdeaModal}
        showAddImplementation={showAddImplementation}
        setShowAddImplementation={setShowAddImplementation}
        areas={areas}
        selectedAreaId={selectedAreaId}
        setSelectedAreaId={setSelectedAreaId}
        ideasByArea={ideasByArea}
        selectedIdeas={selectedIdeas}
        handleSetSelectedIdeas={handleSetSelectedIdeas}
        handleSetImplementValues={handleSetImplementValues}
        handleAddImplementation={handleAddImplementation}
        addImplementLoading={addImplementLoading}
        showLikes={showLikes}
        setShowLikes={setShowLikes}
        likes={likes}
        commentText={commentText}
        setCommentText={setCommentText}
        handleAddSuggestionComment={handleAddSuggestionComment}
        deleteSuggestionCommentLoading={deleteSuggestionCommentLoading}
        handleDeleteSuggestionComment={handleDeleteSuggestionComment}
        handlePostIdeaTag={handlePostIdeaTag}
      />
    </Layout>
  );
};

export default IdeasView;
