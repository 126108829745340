import React from 'react';
import { useLocation } from 'react-router-dom';
import LoginPage from './components/LoginPage';

const Login = () => {
  const location = useLocation();
  const {
    state: { from }
  } = location;

  return (
    <>
      <LoginPage from={from} />
    </>
  );
};

export default Login;
