import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  addSuggestionComment,
  areaSearch,
  dltSuggestionComment,
  implement,
  Suggestion,
  suggestionDownvote,
  suggestionUpvote,
  addSuggestion,
  ideaAllTags
} from './api';

const initialState = {
  idea: {},
  ideasByArea: [],
  addImplementLoading: false,
  tags: []
};

const actions = {
  GET_IDEA_BY_ID: 'ideasview/GET_IDEA_BY_ID',
  POST_IDEA_UPVOTE: 'ideasview/POST_IDEA_UPVOTE',
  POST_IDEA_DOWNVOTE: 'ideasview/POST_IDEA_DOWNVOTE',
  GET_IDEAS_BY_AREA: 'ideasview/GET_IDEAS_BY_AREA',
  ADD_IMPLEMENT: 'ideasview/ADD_IMPLEMENT',
  POST_SUGGESTION_COMMENT: 'ideasview/POST_SUGGESTION_COMMENT',
  DELETE_SUGGESTION_COMMENT: 'ideasview/DELETE_SUGGESTION_COMMENT',
  POST_IDEA_TAG: 'ideasview/POST_IDEA_TAG',
  GET_TAGS: 'tidings/GET_TAGS'
};

export const getIdeaById = createAsyncThunk(
  actions.GET_IDEA_BY_ID,
  async (id) => {
    const response = await Suggestion(`findbyid?sugg_id=${id}`);

    return response;
  }
);

export const postIdeaUpvote = createAsyncThunk(
  actions.POST_IDEA_UPVOTE,
  async (upvote_id) => {
    let payload = {
      upvote_id
    };

    const response = await suggestionUpvote(payload);

    return response;
  }
);

export const postIdeaDownvote = createAsyncThunk(
  actions.POST_IDEA_DOWNVOTE,
  async (upvote_id) => {
    let payload = {
      upvote_id
    };

    const response = await suggestionDownvote(payload);

    return response;
  }
);

export const getIdeasByArea = createAsyncThunk(
  actions.GET_IDEAS_BY_AREA,
  async (payload) => {
    const response = await areaSearch(payload);

    return response;
  }
);

export const addImplement = createAsyncThunk(
  actions.ADD_IMPLEMENT,
  async (payload) => {
    const response = await implement(payload);

    return response;
  }
);

export const postSuggestionComment = createAsyncThunk(
  actions.POST_SUGGESTION_COMMENT,
  async (payload) => {
    const response = await addSuggestionComment(payload);

    return response;
  }
);

export const deleteSuggestionComment = createAsyncThunk(
  actions.DELETE_SUGGESTION_COMMENT,
  async (id) => {
    const response = await dltSuggestionComment(id);

    return response;
  }
);

export const postIdeaTag = createAsyncThunk(
  actions.POST_IDEA_TAG,
  async (payload) => {
    const response = await addSuggestion(payload);

    return response;
  }
);

export const getTags = createAsyncThunk(actions.GET_TAGS, async () => {
  const response = await ideaAllTags();

  return response;
});

export const ideasViewSlice = createSlice({
  name: 'ideasview',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getIdeaById.pending, (state) => {
        state.getAllIdeasLoading = true;
      })
      .addCase(getIdeaById.fulfilled, (state, action) => {
        state.getAllIdeasLoading = false;

        const { success, data, message: msg } = action.payload;

        if (success) {
          state.idea = data;
        } else {
          state.idea = {};
          message.error(msg);
        }
      })
      .addCase(getIdeaById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllIdeasLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postIdeaUpvote.pending, (state) => {
        state.postIdeaUpvoteLoading = true;
      })
      .addCase(postIdeaUpvote.fulfilled, (state, action) => {
        state.postIdeaUpvoteLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(postIdeaUpvote.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postIdeaUpvoteLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postIdeaDownvote.pending, (state) => {
        state.postIdeaDownvoteLoading = true;
      })
      .addCase(postIdeaDownvote.fulfilled, (state, action) => {
        state.postIdeaDownvoteLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(postIdeaDownvote.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postIdeaDownvoteLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getIdeasByArea.pending, (state) => {
        state.getIdeasByAreaLoading = true;
      })
      .addCase(getIdeasByArea.fulfilled, (state, action) => {
        state.getIdeasByAreaLoading = false;

        const { success, data, message: msg } = action.payload;

        if (success) {
          state.ideasByArea = data;
        } else {
          message.error(msg);
        }
      })
      .addCase(getIdeasByArea.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getIdeasByAreaLoading = false;

        message.error(msg);
      });

    builder
      .addCase(addImplement.pending, (state) => {
        state.addImplementLoading = true;
      })
      .addCase(addImplement.fulfilled, (state, action) => {
        state.addImplementLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(addImplement.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.addImplementLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postSuggestionComment.pending, (state) => {
        state.postSuggestionCommentLoading = true;
      })
      .addCase(postSuggestionComment.fulfilled, (state, action) => {
        state.postSuggestionCommentLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(postSuggestionComment.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postSuggestionCommentLoading = false;

        message.error(msg);
      });

    builder
      .addCase(deleteSuggestionComment.pending, (state) => {
        state.deleteSuggestionCommentLoading = true;
      })
      .addCase(deleteSuggestionComment.fulfilled, (state, action) => {
        state.deleteSuggestionCommentLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(deleteSuggestionComment.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.deleteSuggestionCommentLoading = false;

        message.error(msg);
      });
    builder
      .addCase(postIdeaTag.pending, (state) => {
        state.postIdeaTagLoading = true;
      })
      .addCase(postIdeaTag.fulfilled, (state, action) => {
        state.postIdeaTagLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
          // } else {
          // message.error(msg);
        }
      })
      .addCase(postIdeaTag.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postIdeaTagLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getTags.pending, (state) => {
        state.getTagsLoading = true;
      })
      .addCase(getTags.fulfilled, (state, action) => {
        state.getTagsLoading = false;

        const { success, data, message: msg } = action.payload;

        if (success) {
          state.tags = data;
        } else {
          state.tags = [];
          message.error(msg);
        }
      })
      .addCase(getTags.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getTagsLoading = false;

        message.error(msg);
      });
  }
});

export default ideasViewSlice.reducer;
