import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { areas, addarea, subareas } from './api';

const initialState = {
  areas: [],
  subareas: []
};

const actions = {
  GET_ALL_CATEGORIES: 'adminconsole/GET_ALL_CATEGORIES',
  GET_ALL_SUB_CATEGORIES: 'adminconsole/GET_ALL_SUB_CATEGORIES',
  ADD_CATEGORY: 'adminconsole/ADD_CATEGORY'
};

export const getAllCategories = createAsyncThunk(
  actions.GET_ALL_CATEGORIES,
  async () => {
    const response = await areas();

    return response;
  }
);

export const getAllsubCategories = createAsyncThunk(
  actions.GET_ALL_SUB_CATEGORIES,
  async (id) => {
    const response = await subareas(id);

    return response;
  }
);

export const addCategory = createAsyncThunk(
  actions.ADD_CATEGORY,
  async (payload) => {
    let url = payload.id ? `?id=${payload.id}` : '';
    const response = await addarea(url, payload);

    return response;
  }
);

export const subcategorySlice = createSlice({
  name: 'subcategory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCategories.pending, (state) => {
        state.getAllCategoriesLoading = true;
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.getAllCategoriesLoading = false;

        const { success, data, message: msg } = action.payload;

        if (success) {
          state.areas = data;
        } else {
          state.areas = [];
          message.error(msg);
        }
      })
      .addCase(getAllCategories.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllCategoriesLoading = false;

        message.error(msg);
      });

    builder
      .addCase(addCategory.pending, (state) => {
        state.getAllCategoriesLoading = true;
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        state.getAllCategoriesLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(addCategory.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllCategoriesLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllsubCategories.pending, (state) => {
        state.getAllsubCategoriesLoading = true;
      })
      .addCase(getAllsubCategories.fulfilled, (state, action) => {
        state.getAllsubCategoriesLoading = false;

        const { success, data, message: msg } = action.payload;

        if (success) {
          state.subareas = data;
        } else {
          state.subareas = [];
          message.error(msg);
        }
      })
      .addCase(getAllsubCategories.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllsubCategoriesLoading = false;

        message.error(msg);
      });
  }
});

export default subcategorySlice.reducer;
