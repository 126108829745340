import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import ArticleCreatePage from './ArticleCreatePage';
import Layout from 'common/CustomLayout';
import { history } from 'app/history';
import { postArticle } from '../redux/slice';

const LeaderSpeakArticleCreate = () => {
  const dispatch = useDispatch();
  const { postArticleLoading } = useSelector((state) => state.leaderSpeak);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFinish = (values) => {
    const {
      title,
      leader_name,
      designation,
      body,
      article_images,
      article_videos
    } = values;

    let formData = new FormData();
    formData.append('title', title);
    formData.append('leader_name', leader_name);
    formData.append('designation', designation);
    formData.append('body', body);
    article_images.forEach((o) =>
      formData.append('article_images', o.originFileObj)
    );
    if (article_videos?.length) {
      let article_videos_data = article_videos.map((o) => ({
        title: o.title,
        doc_url: o.doc_url
      }));
      formData.append('article_videos', JSON.stringify(article_videos_data));

      article_videos.forEach((o) =>
        formData.append(
          'video_thumbnails',
          o?.thumbnail_img?.[0]?.originFileObj
        )
      );
    }

    dispatch(postArticle(formData)).then(({ payload }) => {
      if (payload?.success) {
        history.push('/leader-speak/articles');
      }
    });
  };

  return (
    <Layout>
      <Spin spinning={postArticleLoading}>
        <ArticleCreatePage onFinish={onFinish} normFile={normFile} />
      </Spin>
    </Layout>
  );
};

export default LeaderSpeakArticleCreate;
