import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  addQuizQuestions,
  getAllQuizQuestionbyId,
  editQuizQuestions,
  deleteQuiz,
  submitQuizQuestions
} from './api';

const initialState = {
  quizzes: [],
  urls: [],
  pagination: ''
};

const actions = {
  ADD_QUIZ: 'eventconsole/ADD_QUIZ',
  GET_ALL_QUIZ_BY_ID: 'getAllQuizzesbyId/GET_ALL_QUIZ_BY_ID',
  EDIT_QUIZ: 'eventconsole/EDIT_QUIZ',
  DELETE_QUIZ: 'eventconsole/DELETE_QUIZ',
  SUBMIT_QUIZ: 'eventconsole/SUBMIT_QUIZ'
};

export const addQuiz = createAsyncThunk(actions.ADD_QUIZ, async (payload) => {
  const response = await addQuizQuestions('create', payload);

  return response;
});

export const getAllQuizzesbyId = createAsyncThunk(
  actions.GET_ALL_QUIZ_BY_ID,
  async (payload) => {
    const response = await getAllQuizQuestionbyId('all-questions', payload);

    return response;
  }
);

export const editQuiz = createAsyncThunk(actions.EDIT_QUIZ, async (payload) => {
  const response = await editQuizQuestions(`edit`, payload);

  return response;
});

export const deleteOption = createAsyncThunk(
  actions.DELETE_QUIZ,
  async (payload) => {
    const response = await deleteQuiz(
      `delete?${payload.option}=${payload.id}`,
      payload
    );

    return response;
  }
);

export const submitQuiz = createAsyncThunk(
  actions.SUBMIT_QUIZ,
  async (payload) => {
    const response = await submitQuizQuestions('submit', payload);

    return response;
  }
);

export const quizConsoleSlice = createSlice({
  name: 'quizconsole',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addQuiz.pending, (state) => {
        state.getAllEventLoading = true;
      })
      .addCase(addQuiz.fulfilled, (state, action) => {
        state.getAllEventLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(addQuiz.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEventLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllQuizzesbyId.pending, (state) => {
        state.getAllEventLoading = true;
      })
      .addCase(getAllQuizzesbyId.fulfilled, (state, action) => {
        state.getAllEventLoading = false;

        const { success, message: msg, data, pagination } = action.payload;

        if (success) {
          state.quizzes = data;
          state.pagination = pagination;
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(getAllQuizzesbyId.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEventLoading = false;

        message.error(msg);
      });

    builder
      .addCase(editQuiz.pending, (state) => {
        state.getAllEventLoading = true;
      })
      .addCase(editQuiz.fulfilled, (state, action) => {
        state.getAllEventLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(editQuiz.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEventLoading = false;

        message.error(msg);
      });

    builder
      .addCase(deleteOption.pending, (state) => {
        state.getAllEventLoading = true;
      })
      .addCase(deleteOption.fulfilled, (state, action) => {
        state.getAllEventLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(deleteOption.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEventLoading = false;

        message.error(msg);
      });
    builder
      .addCase(submitQuiz.pending, (state) => {
        state.getAllEventLoading = true;
      })
      .addCase(submitQuiz.fulfilled, (state, action) => {
        state.getAllEventLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          // state.quizzes = data;
          // state.pagination = pagination;
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(submitQuiz.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEventLoading = false;

        message.error(msg);
      });
  }
});

export default quizConsoleSlice.reducer;
