import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Space, Table, Popconfirm } from 'antd';
import CustomCard from 'common/CustomCard';
import CustomText from 'common/CustomText';
import styles from './admin_console.module.less';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  // SnippetsOutlined,
  RightCircleOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllQuizzes,
  addEvent,
  deleteQuiz,
  getAllUrls
} from '../../redux/slice';
import CustomButton from '@/common/CustomButton';
import AddEventModal from '../AddEventModal';
import { history } from 'app/history';
import moment from 'moment';
import LocalStorage from 'utilities/localStorage';

// const dateFormat = 'YYYY MM-DD';

const QuizListPage = () => {
  const { quizzes, urls } = useSelector((state) => state.quiz);
  const dispatch = useDispatch();
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [uRLlink, setURLlink] = useState('');
  const [selectedUrlId, setSelectedUrlId] = useState('');
  const [quizQuestion, setQuizQuestion] = useState([
    {
      question: '',
      options: []
    }
  ]);

  const userDetails = LocalStorage.getItem('phoenix_login_user');
  const role = userDetails?.user_details?.role;

  const visibility = useCallback((record) => {
    return showDisablePopup[record];
  }, showDisablePopup);

  useEffect(() => {
    handleGetAllQuizzes();
  }, []);

  useEffect(() => {
    handleGetUrls();
  }, [uRLlink]);

  const handleGetUrls = () => {
    let payload = { search: uRLlink };
    dispatch(getAllUrls(payload));
  };

  const handleGetAllQuizzes = () => {
    let payload = { per_page: 100 };
    dispatch(getAllQuizzes(payload));
  };

  const handleCancel = () => {
    setShowAddModal(false);
  };

  const handleAddEvent = (values) => {
    dispatch(addEvent(values)).then(() => {
      setShowAddModal(false);
      handleGetAllQuizzes();
      setSelectedEvent('');
    });
  };

  const handleDeleteEvent = (data) => {
    const payload = { id: data.data.id, is_active: false };
    dispatch(deleteQuiz(payload)).then(() => {
      handleGetAllQuizzes();
      setSelectedEvent('');
    });
  };

  const columns = [
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      render: (text) =>
        text == true ? 'Quiz' : text == false ? 'Survey' : null
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (text, record) => <a href={`view-quiz/${record.id}`}>{text}</a>
    },
    {
      title: 'Start Date',
      key: 'start_date',
      dataIndex: 'start_date',
      render: (text) => moment(text).format('DD MMM YY')
    },
    {
      title: 'End Date',
      key: 'expires_on',
      dataIndex: 'expires_on',
      render: (text) => moment(text).format('DD MMM YY')
    },
    {
      title: 'Action',
      key: 'action',
      render: (record) => (
        <Space size="middle">
          {role == 'admin' ? (
            <>
              <Popconfirm
                title="Are you sure you want to delete this quiz?"
                visible={visibility(record?.id)}
                onConfirm={() => {
                  handleDeleteEvent({
                    data: record
                  });
                }}
                onCancel={() => {
                  setShowDisablePopup({});
                }}
                okText="Yes"
                cancelText="No"
              >
                <DeleteOutlined
                  onClick={() => {
                    setShowDisablePopup({ [record?.id]: true });
                  }}
                  className={styles.deletePost}
                />
              </Popconfirm>
              <a href={`edit-quiz/${record.id}`}>
                <EditOutlined />
              </a>
              <a href={`take-to-quiz/${record.id}`}>
                <RightCircleOutlined />
              </a>
            </>
          ) : (
            <a href={`take-to-quiz/${record.id}`}>
              <RightCircleOutlined />
            </a>
          )}
        </Space>
      )
    }
  ];
  return (
    <>
      <CustomCard className={`${styles.container} p-4`}>
        <Row>
          <Col span={16}>
            <CustomText
              font="medium"
              size={24}
              color="secondary"
              className="pl-2"
            >
              Surveys & Quizzes
            </CustomText>
          </Col>
          <Space>
            <Col span={1}></Col>
            <Col span={2}>
              <CustomButton onClick={() => history.push('/adminconsole')}>
                Back
              </CustomButton>
            </Col>
            {role == 'admin' ? (
              <Col span={2}>
                <CustomButton
                  type="primary"
                  onClick={() => history.push('/create-quiz')}
                >
                  <PlusCircleOutlined style={{ fontSize: 12 }} />
                  Create Surveys & Quizzes
                </CustomButton>
              </Col>
            ) : null}
          </Space>
        </Row>
        <br />
        <Table columns={columns} dataSource={quizzes} pagination={true} />
      </CustomCard>
      {showAddModal ? (
        <AddEventModal
          showAddModal={showAddModal}
          handleCancel={handleCancel}
          handleAddEvent={handleAddEvent}
          selectedEvent={selectedEvent}
          urls={urls}
          setURLlink={setURLlink}
          selectedUrlId={selectedUrlId}
          setSelectedUrlId={setSelectedUrlId}
          quizQuestion={quizQuestion}
          setQuizQuestion={setQuizQuestion}
        />
      ) : (
        ''
      )}
    </>
    // </CustomCard>
  );
};

export default QuizListPage;
