import AboutPhoenixPage from './components/AboutPhoenixPage';
import Layout from 'common/CustomLayout';

const AboutPhoenix = () => {
  return (
    <Layout>
      <AboutPhoenixPage />
    </Layout>
  );
};

export default AboutPhoenix;
