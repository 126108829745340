import React, { useEffect, useState } from 'react';
import { Col, Row, Space, Table } from 'antd';
import CustomCard from 'common/CustomCard';
import CustomText from 'common/CustomText';
import styles from './admin_console.module.less';
import {
  EyeOutlined,
  EditOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategories, addCategory } from '../../redux/slice';
import CustomButton from '@/common/CustomButton';
import AddCategoryModal from '../AddCategoryModal';
import { history } from 'app/history';

const CategoriesPage = () => {
  const { areas } = useSelector((state) => state.adminconsole);
  const dispatch = useDispatch();

  const [showAddModal, setShowAddModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');

  useEffect(() => {
    handleGetAllCategories();
  }, []);

  const handleGetAllCategories = () => {
    dispatch(getAllCategories());
  };

  const handleCancel = () => {
    setShowAddModal(false);
  };

  const handleAddCategory = (values) => {
    dispatch(addCategory(values)).then(() => {
      setShowAddModal(false);
      handleGetAllCategories();
      setSelectedCategory('');
    });
  };

  const columns = [
    {
      title: 'Category',
      key: 'area',
      dataIndex: 'area'
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (record) => (
        <Space size="middle">
          <EyeOutlined
            onClick={() => history.push(`/subCategories/${record.id}`)}
          />
          <EditOutlined
            onClick={() => {
              setEditMode(true);
              setSelectedCategory(record);
              setShowAddModal(true);
            }}
          />
        </Space>
      )
    }
  ];
  return (
    <>
      <CustomCard className={`${styles.container} p-4`}>
        <Row>
          <Col span={19}>
            <CustomText
              font="medium"
              size={24}
              color="secondary"
              className="pl-2"
            >
              Categories
            </CustomText>
          </Col>
          <Space>
            <Col span={1}></Col>
            <Col span={2}>
              <CustomButton onClick={() => history.push('/adminconsole')}>
                Back
              </CustomButton>
            </Col>
            <Col span={2}>
              <CustomButton
                type="primary"
                onClick={() => {
                  setShowAddModal(true);
                  setSelectedCategory('');
                }}
              >
                <PlusCircleOutlined style={{ fontSize: 12 }} />
                Add
              </CustomButton>
            </Col>
          </Space>
        </Row>
        <br />
        <Table columns={columns} dataSource={areas} pagination={false} />
      </CustomCard>
      {showAddModal ? (
        <AddCategoryModal
          showAddModal={showAddModal}
          handleCancel={handleCancel}
          handleAddCategory={handleAddCategory}
          editMode={editMode}
          selectedCategory={selectedCategory}
        />
      ) : (
        ''
      )}
    </>
    // </CustomCard>
  );
};

export default CategoriesPage;
