import React, { createRef, useEffect, useState } from 'react';
import Layout from 'common/CustomLayout';
import TidingsPage from './components/TidingsPage';
import { useDispatch, useSelector } from 'react-redux';
import {
  createPost,
  getAllPostComments,
  getAllPosts,
  postComment,
  postDislike,
  postLike,
  postPin,
  postUnPin,
  deletepost,
  deletecomment,
  deletedocument,
  editPoll
} from './redux/slice';
import { useLocation } from 'react-router-dom';

const Tidings = () => {
  let location = useLocation();
  const dispatch = useDispatch();
  const {
    allPosts,
    getAllPostsLoading,
    allPostComments,
    createPostLoading,
    pagination
  } = useSelector((state) => state.tidings);
  const [commentText, setCommentText] = useState({});
  const [addPostText, setAddPostText] = useState('');
  const [fileList, setFileList] = useState([]);
  const [video, setVideo] = useState([]);
  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [showLikes, setShowLikes] = useState(false);
  const [likes, setLikes] = useState([]);
  const [slide, setSlide] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedPoll, setSelectedPoll] = useState();
  const [openImageShuffleModal, setOpenImageShuffleModal] = useState({});
  const [page, setPage] = useState(1);
  const [list, setList] = useState(allPosts ? allPosts : []);
  const postRefs = allPosts.reduce((acc, value) => {
    acc[value.id] = createRef();
    return acc;
  }, {});

  useEffect(() => {
    if (Object.keys(postRefs).length && location?.hash) {
      let postId = Number(location.hash.replace('#', ''));
      handleScrollToPostById(postId);
    }
  }, [postRefs]);
  console.log(selectedPoll);
  const handleScrollToPostById = (id) => {
    postRefs[id].current.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  };
  console.log(page);
  useEffect(() => {
    handleGetAllPosts();
  }, [page]);

  const handleGetAllPosts = () => {
    dispatch(getAllPosts(page));
    // .then((response) => {
    //   console.log(response);
    //   setList([...list, ...response.payload.data]);
    // });
  };
  console.log(list);

  const handleCreatePost = () => {
    let formData = new FormData();
    if (fileList?.length) {
      fileList.map((o) => formData.append('post_images', o.originFileObj));
      fileList.forEach((_, index) => {
        formData.append('sequence[]', fileList.length - index);
      });
    }
    if (video?.length) {
      video.map((o) => formData.append('post_images', o.originFileObj));
    }
    formData.append('post_description', addPostText);

    dispatch(createPost(formData)).then(({ payload }) => {
      if (payload?.success) {
        window.location.reload();
        setAddPostText('');
        setFileList([]);
        setVideo([]);
        handleGetAllPosts();
      }
    });
  };

  const handlePostLike = (post_id) => {
    dispatch(postLike(post_id)).then(({ payload }) => {
      if (payload.success) {
        dispatch(getAllPosts(page)).then((response) => {
          setList(response.payload.data);
        });
      }
    });
  };

  const handlePostDislike = (post_id) => {
    dispatch(postDislike(post_id)).then(({ payload }) => {
      if (payload.success) {
        dispatch(getAllPosts(page)).then((response) => {
          setList(response.payload.data);
        });
      }
    });
  };

  const handleGetAllPostComments = (post_id) => {
    dispatch(getAllPostComments(post_id));
  };

  const handlePostComment = (post_id, user_comment) => {
    let payload = { post_id, user_comment };

    dispatch(postComment(payload)).then(({ payload }) => {
      if (payload?.success) {
        setCommentText({
          ...commentText,
          [post_id]: ''
        });
      }
    });
  };

  const handlePostPin = (post_id) => {
    dispatch(postPin(post_id)).then(({ payload }) => {
      if (payload?.success) {
        dispatch(getAllPosts(page)).then((response) => {
          setList(response.payload.data);
        });
      }
    });
  };

  const handlePostUnPin = (post_id) => {
    dispatch(postUnPin(post_id)).then(({ payload }) => {
      if (payload?.success) {
        handleGetAllPosts();
      }
    });
  };

  const handleDeletePost = (payload) => {
    dispatch(deletepost(payload)).then(() => {
      setShowDisablePopup({ [payload.id]: false });
      handleGetAllPosts();
    });
  };

  const handleDeleteComment = (payload) => {
    dispatch(deletecomment(payload)).then(() => {
      setShowDisablePopup({ [payload.comment_id]: false });
      handleGetAllPosts();
      handleGetAllPostComments(payload.post_id);
    });
  };

  const handleDeleteImage = (payload) => {
    dispatch(deletedocument(payload)).then(() => {
      setShowDisablePopup({ [payload.id]: false });
      handleGetAllPosts();
    });
  };

  const handleCancel = () => {
    setShowAddModal(false);
  };

  const handleEditPoll = (values) => {
    dispatch(editPoll(values)).then(() => {
      setShowAddModal(false);
      handleGetAllPosts();
      setSelectedPoll('');
    });
  };

  return (
    <Layout>
      <TidingsPage
        allPosts={allPosts}
        getAllPostsLoading={getAllPostsLoading || createPostLoading}
        addPostText={addPostText}
        setAddPostText={setAddPostText}
        fileList={fileList}
        setFileList={setFileList}
        handleCreatePost={handleCreatePost}
        handlePostLike={handlePostLike}
        handlePostDislike={handlePostDislike}
        commentText={commentText}
        setCommentText={setCommentText}
        allPostComments={allPostComments}
        handleGetAllPostComments={handleGetAllPostComments}
        handlePostComment={handlePostComment}
        handlePostPin={handlePostPin}
        handlePostUnPin={handlePostUnPin}
        showDisablePopup={showDisablePopup}
        setShowDisablePopup={setShowDisablePopup}
        handleDeletePost={handleDeletePost}
        handleDeleteComment={handleDeleteComment}
        postRefs={postRefs}
        showLikes={showLikes}
        setShowLikes={setShowLikes}
        likes={likes}
        setLikes={setLikes}
        handleDeleteImage={handleDeleteImage}
        slide={slide}
        setSlide={setSlide}
        video={video}
        setVideo={setVideo}
        setShowAddModal={setShowAddModal}
        showAddModal={showAddModal}
        setEditMode={setEditMode}
        editMode={editMode}
        setSelectedPoll={setSelectedPoll}
        selectedPoll={selectedPoll}
        handleCancel={handleCancel}
        handleEditPoll={handleEditPoll}
        openImageShuffleModal={openImageShuffleModal}
        setOpenImageShuffleModal={setOpenImageShuffleModal}
        handleGetAllPosts={handleGetAllPosts}
        pagination={pagination}
        page={page}
        setPage={setPage}
        setList={setList}
      />
    </Layout>
  );
};

export default Tidings;
