import React, { useEffect } from 'react';
import CustomModal from 'common/CustomModal';
import { Form, Input, Space } from 'antd';
import FormItem from 'common/FormItem';
import CustomButton from 'common/CustomButton';

const AddsubCategoryModal = ({
  showAddModal,
  handleCancel,
  handleAddCategory,
  editMode,
  selectedCategory,
  categoryId
}) => {
  console.log(selectedCategory);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    if (editMode) {
      values['id'] = selectedCategory.id;
    }
    values['area_id'] = categoryId;
    handleAddCategory(values);
  };
  console.log(selectedCategory);
  useEffect(() => {
    if (editMode && selectedCategory) {
      const { sub_area } = selectedCategory;

      form.setFieldsValue({
        sub_area
      });
    }
  }, [selectedCategory]);
  return (
    <CustomModal
      title={'Add Sub Category'}
      open={showAddModal}
      onCancel={handleCancel}
      width={'43%'}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <FormItem
          label="Sub Category Name"
          name="sub_area"
          rules={[
            {
              required: true,
              message: 'Required field'
            }
          ]}
        >
          <Input allowClear placeholder="Enter sub category name" />
        </FormItem>
        <FormItem className="text-right">
          <Space>
            <CustomButton htmlType="button" onClick={handleCancel}>
              Cancel
            </CustomButton>
            <CustomButton type="primary" htmlType="submit">
              Save
            </CustomButton>
          </Space>
        </FormItem>
      </Form>
    </CustomModal>
  );
};
export default AddsubCategoryModal;
