import { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import CustomCard from 'common/CustomCard';
import CustomText from 'common/CustomText';
import styles from './pending_surveys.module.less';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllPolls,
  getPollResponse,
  handleCanShowThankyou,
  handleFetchPollResponse,
  postSubmitPoll
} from '../../redux/slice';
import SurveyDetails from '../SurveyDetails';
import PollResponse from '../PollResponse';

const Wrapper = ({ children, withOutCard, ...rest }) => {
  if (withOutCard) {
    return children;
  } else {
    return <CustomCard {...rest}>{children}</CustomCard>;
  }
};

const PendingSurveys = ({ className, style = {}, withOutCard }) => {
  const dispatch = useDispatch();
  const {
    allPolls,
    getAllPollsLoading,
    fetchPollResponse,
    pollResponseData,
    getPollResponseLoading,
    canShowThankyou
  } = useSelector((state) => state.customLayout);

  const [showThankyou, setShowThankyou] = useState(false);

  useEffect(() => {
    handleGetAllPolls();
  }, []);

  useEffect(() => {
    if (fetchPollResponse) {
      handleGetPollResponse();
    }
  }, [fetchPollResponse]);

  const handleGetAllPolls = () => {
    dispatch(getAllPolls()).then(({ payload }) => {
      if (payload?.success) {
        dispatch(handleFetchPollResponse(payload?.data?.[0]?.poll_response));
      }
    });
  };

  const handleGetPollResponse = () => {
    dispatch(getPollResponse(allPolls[0].id));
  };

  const handlePostSubmitPoll = (payload) => {
    dispatch(postSubmitPoll(payload)).then(({ payload }) => {
      if (payload?.success) {
        dispatch(handleFetchPollResponse(true));
        dispatch(handleCanShowThankyou(true));
      }
    });
  };

  return (
    <Wrapper
      title={showThankyou ? 'Thank you for your time' : 'Live Survey'}
      className={`p-1 px-2 ${className ? ` ${className}` : ''}`}
      style={style}
      topBorder={true}
      withOutCard={withOutCard}
    >
      <div className={styles.container}>
        {showThankyou ? (
          <CustomText color={3} size={12} font="medium">
            Thank you for completing the poll. We appreciate you sharing your
            opinion.
          </CustomText>
        ) : getAllPollsLoading || getPollResponseLoading ? (
          <Skeleton className="mt-2" />
        ) : (
          <>
            {pollResponseData ? (
              <PollResponse
                pollData={allPolls[0]}
                pollResponseData={pollResponseData}
                setShowThankyou={setShowThankyou}
                canShowThankyou={canShowThankyou}
                withOutCard={withOutCard}
              />
            ) : (
              <SurveyDetails
                data={allPolls[0]}
                handlePostSubmitPoll={handlePostSubmitPoll}
                withOutCard={withOutCard}
              />
            )}
          </>
        )}
      </div>
    </Wrapper>
  );
};

export default PendingSurveys;
