import Desclaimer from './components/DesclaimerPage';
import Layout from 'common/CustomLayout';

const desclaimer = () => {
  return (
    <Layout>
      <Desclaimer />
    </Layout>
  );
};

export default desclaimer;
