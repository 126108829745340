import styles from './social.module.less';
// import UserDetails from 'common/CustomLayout/components/UserDetails';
import Events from 'common/CustomLayout/components/Events';
// import PendingSurveys from 'common/CustomLayout/components/PendingSurveys';

const SocialPage = () => {
  return (
    <div className={styles.container}>
      {/* <UserDetails /> */}
      <Events className="mt-2" count={10} noCard={true} />
      {/* <PendingSurveys className="mt-2" /> */}
    </div>
  );
};

export default SocialPage;
