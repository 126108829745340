import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import { LogoutOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Drawer, Image, Space } from 'antd';
import logo from '../../images/phoenix-logo.svg';
import UserMenu from '../UserMenu';
import CustomText from 'common/CustomText';
import CustomButton from 'common/CustomButton';
import LocalStorage from 'utilities/localStorage';
import { history } from 'app/history';
import { logout } from 'features/login/redux/slice';
import styles from './left_drawer.module.less';
import { menuData } from 'utilities/helpers';
import { Link } from 'react-router-dom';

const LeftDrawer = ({ open, onClose }) => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const user_details = LocalStorage.getItem('phoenix_login_user');

  const [selectedMenuKey] = useState(
    history.location.pathname.replace('/', '')
  );

  const signOutClickHandler = () => {
    instance.logoutRedirect({
      account: LocalStorage.homeAccountId,
      postLogoutRedirectUri: '/',
      mainWindowRedirectUri: '/',
      onRedirectNavigate: () => {
        dispatch(logout());
        return true;
      }
    });
  };

  return (
    <Drawer
      open={open}
      onClose={onClose}
      placement="left"
      width={250}
      headerStyle={{ display: 'none' }}
      bodyStyle={{ padding: 5 }}
      className={styles.container}
      footer={
        <CustomText size={12}>© 2022 Phoenix. All Rights Reserved.</CustomText>
      }
    >
      <Space direction="vertical" size="large">
        <Image alt="phoenix-logo" src={logo} preview={false} />
        <Space size={12} align="center">
          <UserMenu />
          <Space direction="vertical" size={0}>
            <CustomText font="bold" size={15}>
              {user_details?.user_details?.fullname}
            </CustomText>
            <CustomText color={3}>
              {user_details?.user_details?.email
                ? '@' +
                  user_details?.user_details?.email.slice(
                    0,
                    user_details?.user_details?.email.indexOf('@')
                  )
                : ''}
            </CustomText>
          </Space>
          <LogoutOutlined onClick={signOutClickHandler} />
        </Space>
        <CustomButton
          type="primary"
          onClick={() => history.push('/suggest-idea')}
          className="w-100"
        >
          <PlusCircleOutlined style={{ fontSize: 15 }} />
          Suggest an idea
        </CustomButton>
        <div className={styles.menu_container}>
          <Space direction="vertical" size={0} className="w-100">
            {menuData.map((item, index) => {
              const { key, icon, label, mobile } = item;
              let isSelected = key === selectedMenuKey;

              if (!mobile) return null;

              return (
                <Link
                  key={index}
                  to={`/${key}`}
                  className={`${styles.menu_item}${
                    isSelected ? ` ${styles.selected}` : ''
                  }`}
                >
                  <span className={styles.menu_item_icon}>{icon}</span>
                  <CustomText
                    font="medium"
                    size={16}
                    className={styles.menu_item_label}
                  >
                    {label}
                  </CustomText>
                </Link>
              );
            })}
          </Space>
        </div>
      </Space>
    </Drawer>
  );
};

export default LeftDrawer;
