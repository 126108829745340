import { MoreOutlined, InfoCircleOutlined } from '@ant-design/icons';
import CustomText from '../CustomText';
import styles from './custom_card.module.less';
import { Popover } from 'antd';

const CustomCard = ({
  children,
  className,
  style,
  title,
  topBorder,
  leftBorder,
  ...rest
}) => {
  return (
    <div
      className={`${styles.container}${className ? ` ${className}` : ''}${
        topBorder ? ` ${styles.top_border}` : ''
      }${leftBorder ? ` ${styles.left_border}` : ''}`}
      style={style}
      {...rest}
    >
      {title ? (
        <div className={`df-jb-ac ${styles.title_container}`}>
          <CustomText font="medium" size={18}>
            {title}
          </CustomText>
          {title === 'Events' ? (
            <Popover
              content={'Go to Phoenix Buzz for past event details'}
              trigger="click"
            >
              <InfoCircleOutlined />
            </Popover>
          ) : (
            <MoreOutlined className={styles.more_icon} />
          )}
        </div>
      ) : null}
      {children}
    </div>
  );
};

export default CustomCard;
