import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import WinnerDetailsPage from './WinnerDetailsPage';
import Layout from 'common/CustomLayout';
import {
  deleteRRComment,
  deleteRRLike,
  getRRById,
  postRRComment,
  postRRLike
} from '../redux/slice';
import { smoothScrollTo } from 'utilities/helpers';

const WinnerDetails = () => {
  const params = useParams();
  const { id } = params;
  const dispatch = useDispatch();
  const { rrById, getRRByIdLoading, deleteRRCommentLoading } = useSelector(
    (state) => state.centreStage
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showLikes, setShowLikes] = useState(false);
  const [likes, setLikes] = useState([]);
  const [commentText, setCommentText] = useState(null);

  useEffect(() => {
    handleGetRRById();
  }, []);

  const handleGetRRById = () => {
    dispatch(getRRById({ rr_id: id })).then(({ payload }) => {
      if (payload?.success && payload?.data) {
        if (location?.hash) {
          let sectionId = location.hash.replace('#', '');
          smoothScrollTo(sectionId);
        }
      }
    });
  };

  const handlePostRRLike = (rr_id) => {
    dispatch(postRRLike({ rr_id })).then(({ payload }) => {
      if (payload.success) {
        dispatch(handleGetRRById());
      }
    });
  };

  const handleDeleteRRLike = (rr_id) => {
    dispatch(deleteRRLike({ rr_id })).then(({ payload }) => {
      if (payload.success) {
        dispatch(handleGetRRById());
      }
    });
  };

  const handlePostRRComment = (rr_id) => {
    let payload = { rr_id, user_comment: commentText };

    dispatch(postRRComment(payload)).then(({ payload }) => {
      if (payload?.success) {
        handleGetRRById();
        setCommentText(null);
      }
    });
  };

  const handleDeleteRRComment = (comment_id) => {
    dispatch(deleteRRComment(comment_id)).then(({ payload }) => {
      if (payload?.success) {
        handleGetRRById();
      }
    });
  };

  return (
    <Layout>
      <Spin spinning={getRRByIdLoading}>
        <WinnerDetailsPage
          data={rrById}
          showLikes={showLikes}
          setShowLikes={setShowLikes}
          likes={likes}
          setLikes={setLikes}
          commentText={commentText}
          setCommentText={setCommentText}
          handlePostRRLike={handlePostRRLike}
          handleDeleteRRLike={handleDeleteRRLike}
          handlePostRRComment={handlePostRRComment}
          handleDeleteRRComment={handleDeleteRRComment}
          deleteRRCommentLoading={deleteRRCommentLoading}
        />
      </Spin>
    </Layout>
  );
};

export default WinnerDetails;
