import React from 'react';
import { Spin } from 'antd';
import styles from './loader.module.less';

const Loader = () => {
  return (
    <div className={`df-jb-ac ${styles.container}`}>
      <Spin />
    </div>
  );
};

export default Loader;
