import { Space } from 'antd';
import CustomCard from 'common/CustomCard';
import CustomText from 'common/CustomText';
import styles from './md_message.module.less';

const DesclaimerPage = () => {
  return (
    <Space direction="vertical">
      <CustomCard className={styles.container}>
        <Space direction="vertical" size={'middle'}>
          <CustomText font="medium" size={24} color="secondary">
            {`Disclaimer:`}
          </CustomText>
          <CustomText size={16} color={2}>
            The Phoenix Portal (Portal henceforth) is intended solely for new
            ideas and information of the participants. The information contained
            here is not intended to be legal advice or legal opinion.
          </CustomText>
          <CustomText size={16} color={2}>
            {`The Company offers users the ability to post ideas via messages and videos on this portal which is open to all members of Tata Projects (Company henceforth).`}
          </CustomText>
          <CustomText size={16} color={2}>
            You acknowledge that all Content posted on the portal is User
            Content, and by here you agree to comply with the rules and
            restrictions on User Content. You acknowledge that messages posted
            on such portals are public, and Company cannot guarantee the
            security of any information you disclose through any Forum: you make
            such disclosures at your own risk. Company is not responsible for
            the content or accuracy of any information posted on the portal, and
            shall not be responsible for any decisions made based on such
            information.
          </CustomText>
          <CustomText size={16} color={2}>
            If you have a complaint about something or find you Content is being
            used incorrectly please report by writing to us at
            <a> phoenix@tataprojects.com</a>
          </CustomText>
        </Space>
      </CustomCard>
    </Space>
  );
};

export default DesclaimerPage;
