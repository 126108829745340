import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { mdmessages, addMDMessage, inactivemsg, all_urls } from './api';

const initialState = {
  messages: [],
  urls: []
};

const actions = {
  GET_ALL_MESSAGES: 'mdmessagesconsole/GET_ALL_MESSAGES',
  ADD_MESSAGE: 'mdmessagesconsole/ADD_MESSAGE',
  INACTIVE_MESSAGE: 'mdmessagesconsole/INACTIVE_MESSAGE',
  GET_ALL_URLS: 'getAllUrls/GET_ALL_URLS'
};

export const getAllMessages = createAsyncThunk(
  actions.GET_ALL_MESSAGES,
  async () => {
    const response = await mdmessages('all-messages');

    return response;
  }
);

export const addMessage = createAsyncThunk(
  actions.ADD_MESSAGE,
  async (payload) => {
    const response = await addMDMessage('create', payload);

    return response;
  }
);

export const InactiveMessage = createAsyncThunk(
  actions.INACTIVE_MESSAGE,
  async (payload) => {
    const response = await inactivemsg('create', payload);

    return response;
  }
);

export const getAllUrls = createAsyncThunk(
  actions.GET_ALL_URLS,
  async (payload) => {
    const response = await all_urls(payload);

    return response;
  }
);

export const messageConsoleSlice = createSlice({
  name: 'mdmessagesconsole',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMessages.pending, (state) => {
        state.getAllEventsLoading = true;
      })
      .addCase(getAllMessages.fulfilled, (state, action) => {
        state.getAllEventsLoading = false;

        const { success, data, message: msg } = action.payload;

        if (success) {
          state.messages = data;
        } else {
          state.messages = [];
          message.error(msg);
        }
      })
      .addCase(getAllMessages.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEventssLoading = false;

        message.error(msg);
      });

    builder
      .addCase(addMessage.pending, (state) => {
        state.getAllEventLoading = true;
      })
      .addCase(addMessage.fulfilled, (state, action) => {
        state.getAllEventLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(addMessage.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEventLoading = false;

        message.error(msg);
      });

    builder
      .addCase(InactiveMessage.pending, (state) => {
        state.getAllEventLoading = true;
      })
      .addCase(InactiveMessage.fulfilled, (state, action) => {
        state.getAllEventLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(InactiveMessage.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEventLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllUrls.pending, (state) => {
        state.getAllEventLoading = true;
      })
      .addCase(getAllUrls.fulfilled, (state, action) => {
        state.getAllEventLoading = false;

        const { success, message: msg, data } = action.payload;

        if (success) {
          // message.success(msg);
          state.urls = data ? data : [];
        } else {
          message.error(msg);
        }
      })
      .addCase(getAllUrls.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEventLoading = false;

        message.error(msg);
      });
  }
});

export default messageConsoleSlice.reducer;
