import React from 'react';
import { Input } from 'antd';
import styles from './custom_input.module.less';

const { TextArea } = Input;

const CustomInput = ({ textArea = false, ...rest }) => {
  const Component = textArea ? TextArea : Input;

  return (
    <div className={styles.container}>
      <Component {...rest} />
    </div>
  );
};

export default CustomInput;
