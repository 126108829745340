import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  addComment,
  addPost,
  allPostComments,
  allPosts,
  dislikePost,
  likePost,
  pinPost,
  unPinPost,
  deletePost,
  deleteComment,
  deleteDocument,
  editPost,
  docSeq
} from './api';

const initialState = {
  allPosts: [],
  allPostComments: {},
  getAllPostsLoading: false,
  createPostLoading: false,
  pagination: ''
};

const actions = {
  GET_ALL_POSTS: 'tidings/GET_ALL_POSTS',
  CREATE_POST: 'tidings/CREATE_POST',
  POST_LIKE: 'tidings/POST_LIKE',
  POST_DISLIKE: 'tidings/POST_DISLIKE',
  GET_ALL_POST_COMMENTS: 'tidings/GET_ALL_POST_COMMENTS',
  POST_COMMENT: 'tidings/POST_COMMENT',
  PIN_POST: 'tidings/PIN_POST',
  UN_PIN_POST: 'tidings/UN_PIN_POST',
  DELETE_POST: 'tidings/DELETE_POST',
  DELETE_COMMENT: 'tidings/DELETE_COMMENT',
  DELETE_DOCUMENT: 'tidings/DELETE_DOCUMENT',
  EDIT_POLL: 'tidings/EDIT_POLL',
  PATCH_DOCS_SEQUENCE: 'tidings/PATCH_DOCS_SEQUENCE'
};

export const getAllPosts = createAsyncThunk(
  actions.GET_ALL_POSTS,
  async (page) => {
    let payload = {
      page: page ? page : 1
    };
    const response = await allPosts(payload);

    return response;
  }
);

export const createPost = createAsyncThunk(
  actions.CREATE_POST,
  async (payload) => {
    const response = await addPost(payload, true);

    return response;
  }
);

export const postLike = createAsyncThunk(actions.POST_LIKE, async (post_id) => {
  let payload = {
    post_id
  };

  const response = await likePost(payload);

  return response;
});

export const postDislike = createAsyncThunk(
  actions.POST_DISLIKE,
  async (post_id) => {
    let payload = {
      post_id
    };

    const response = await dislikePost(payload);

    return response;
  }
);

export const getAllPostComments = createAsyncThunk(
  actions.GET_ALL_POST_COMMENTS,
  async (post_id) => {
    let payload = {
      post_id
    };

    const response = await allPostComments(payload);

    return response;
  }
);

export const postComment = createAsyncThunk(
  actions.POST_COMMENT,
  async (payload) => {
    const response = await addComment(payload);

    return response;
  }
);

export const postPin = createAsyncThunk(actions.PIN_POST, async (post_id) => {
  let payload = {
    post_id
  };

  const response = await pinPost(payload);

  return response;
});

export const postUnPin = createAsyncThunk(
  actions.UN_PIN_POST,
  async (post_id) => {
    let payload = {
      post_id
    };

    const response = await unPinPost(payload);

    return response;
  }
);

export const deletepost = createAsyncThunk(
  actions.DELETE_POST,
  async (payload) => {
    const response = await deletePost(payload);

    return response;
  }
);

export const deletecomment = createAsyncThunk(
  actions.DELETE_COMMENT,
  async (payload) => {
    const response = await deleteComment(`${payload.comment_id}`, payload);
    return response;
  }
);

export const deletedocument = createAsyncThunk(
  actions.DELETE_DOCUMENT,
  async (payload) => {
    const response = await deleteDocument(`dlt-document`, payload);
    return response;
  }
);

export const editPoll = createAsyncThunk(actions.EDIT_POLL, async (payload) => {
  const response = await editPost(payload, true);
  return response;
});

export const patchDocsSequence = createAsyncThunk(
  actions.PATCH_DOCS_SEQUENCE,
  async (payload) => {
    const response = await docSeq(payload, true);

    return response;
  }
);

export const tidingsSlice = createSlice({
  name: 'tidings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPosts.pending, (state) => {
        state.getAllPostsLoading = true;
      })
      .addCase(getAllPosts.fulfilled, (state, action) => {
        state.getAllPostsLoading = false;

        const { success, data, message: msg, pagination } = action.payload;

        if (success) {
          let prevData = [...state.allPosts];
          let alteredData = [...prevData, ...data];
          let grouping = [
            ...new Map(alteredData.map((item) => [item.id, item])).values()
          ];
          state.allPosts = grouping;
          state.pagination = pagination;
        } else {
          state.allPosts = [];
          message.error(msg);
        }
      })
      .addCase(getAllPosts.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllPostsLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createPost.pending, (state) => {
        state.createPostLoading = true;
      })
      .addCase(createPost.fulfilled, (state, action) => {
        state.createPostLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(createPost.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createPostLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postLike.pending, (state) => {
        state.postLikeLoading = true;
      })
      .addCase(postLike.fulfilled, (state, action) => {
        state.postLikeLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(postLike.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postLikeLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postDislike.pending, (state) => {
        state.postDislikeLoading = true;
      })
      .addCase(postDislike.fulfilled, (state, action) => {
        // const { arg } = action.meta;
        state.postDislikeLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(postDislike.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postDislikeLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllPostComments.pending, (state) => {
        state.getAllPostCommentsLoading = true;
      })
      .addCase(getAllPostComments.fulfilled, (state, action) => {
        const { arg } = action.meta;

        state.getAllPostCommentsLoading = false;

        const { success, data, message: msg } = action.payload;

        if (success) {
          state.allPostComments[arg] = data;
        } else {
          state.allPostComments[arg] = [];
          message.error(msg);
        }
      })
      .addCase(getAllPostComments.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllPostCommentsLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postComment.pending, (state) => {
        state.postCommentLoading = true;
      })
      .addCase(postComment.fulfilled, (state, action) => {
        state.postCommentLoading = false;

        const { success, data, message: msg } = action.payload;

        if (success) {
          const { post_id } = data;
          let copyOfAllPostComments = [
            ...(state.allPostComments[post_id] || [])
          ];
          copyOfAllPostComments.unshift(data);

          state.allPostComments[post_id] = copyOfAllPostComments;

          let likedPostIndex = state.allPosts.findIndex(
            (o) => o.id === post_id
          );
          let copyOfAllPosts = [...state.allPosts];
          copyOfAllPosts[likedPostIndex] = {
            ...copyOfAllPosts[likedPostIndex],
            comment_count: copyOfAllPosts[likedPostIndex].comment_count + 1
          };
          let grouping = [
            ...new Map(copyOfAllPosts.map((item) => [item.id, item])).values()
          ];
          state.allPosts = grouping;
        } else {
          message.error(msg);
        }
      })
      .addCase(postComment.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postCommentLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postPin.pending, (state) => {
        state.postPinLoading = true;
      })
      .addCase(postPin.fulfilled, (state, action) => {
        state.postPinLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(postPin.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postPinLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postUnPin.pending, (state) => {
        state.postUnPinLoading = true;
      })
      .addCase(postUnPin.fulfilled, (state, action) => {
        state.postUnPinLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(postUnPin.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postUnPinLoading = false;

        message.error(msg);
      });

    builder
      .addCase(deletepost.pending, (state) => {
        state.postPinLoading = true;
      })
      .addCase(deletepost.fulfilled, (state, action) => {
        state.postPinLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(deletepost.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postPinLoading = false;

        message.error(msg);
      });

    builder
      .addCase(deletedocument.pending, (state) => {
        state.postPinLoading = true;
      })
      .addCase(deletedocument.fulfilled, (state, action) => {
        state.postPinLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(deletedocument.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postPinLoading = false;

        message.error(msg);
      });

    builder
      .addCase(editPoll.pending, (state) => {
        state.createPostLoading = true;
      })
      .addCase(editPoll.fulfilled, (state, action) => {
        state.createPostLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(editPoll.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.createPostLoading = false;

        message.error(msg);
      });

    builder
      .addCase(patchDocsSequence.pending, (state) => {
        state.patchDocsSequenceLoading = true;
      })
      .addCase(patchDocsSequence.fulfilled, (state, action) => {
        state.patchDocsSequenceLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(patchDocsSequence.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.patchDocsSequenceLoading = false;

        message.error(msg);
      });
  }
});

export default tidingsSlice.reducer;
