import React from 'react';
import CustomCard from 'common/CustomCard';
import styles from './leader_speak.module.less';
import { Col, Divider, Image, Row, Space } from 'antd';
import CustomText from 'common/CustomText';
import UsersListModal from 'common/UsersListModal';
import {
  ArrowRightOutlined,
  CommentOutlined,
  FileTextOutlined,
  LikeFilled,
  LikeOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import { getLoggedInUser } from 'utilities/helpers';
import CustomButton from 'common/CustomButton';
import { history } from 'app/history';

const LeaderSpeakPage = ({
  data,
  handlePostArticleLike,
  handleDeleteArticleLike,
  showLikes,
  setShowLikes,
  likes,
  setLikes
}) => {
  const { user_details } = getLoggedInUser();
  const isAdmin = user_details?.role === 'admin';

  return (
    <CustomCard className={styles.container}>
      {isAdmin ? (
        <div className="mb-3 d-flex justify-content-end">
          <CustomButton
            type="primary"
            onClick={() => history.push('/leader-speak/articles/create')}
          >
            <PlusCircleOutlined style={{ fontSize: 15 }} />
            Add Article
          </CustomButton>
        </div>
      ) : null}
      {data.map((arr, index) => {
        return (
          <div key={index} className={styles.inner_container}>
            <Row gutter={[32, 20]}>
              {arr.map((item) => {
                const {
                  id,
                  title,
                  body,
                  Documents,
                  Likes,
                  is_like,
                  like_count,
                  comment_count,
                  leader_name,
                  desigation
                } = item;

                const likesDisplayText = `${
                  Likes.length
                    ? `${
                        Likes[0]?.full_name?.length > 10
                          ? Likes[0]?.full_name.substring(0, 10) + '...'
                          : Likes[0]?.full_name
                      }`
                    : ''
                }${
                  like_count
                    ? like_count - 1 > 0
                      ? ` and ${like_count - 1} others`
                      : ''
                    : '0 likes'
                }`;

                return (
                  <Col key={id} sm={arr.length > 1 ? 12 : 24} xs={24}>
                    <CustomCard className={styles.card_container}>
                      <div className={styles.banner_container}>
                        <Image
                          src={Documents?.[0]?.doc_url}
                          preview={false}
                          className={styles.banner}
                        />
                        <div className={styles.author_container}>
                          <div className="df-jb-ac">
                            <Space direction="vertical">
                              {leader_name ? (
                                <CustomText font="medium" color="white">
                                  {leader_name}
                                </CustomText>
                              ) : null}
                              {desigation ? (
                                <CustomText color="white">
                                  {desigation}
                                </CustomText>
                              ) : null}
                            </Space>
                            <FileTextOutlined className={styles.icon} />
                          </div>
                        </div>
                      </div>
                      <div className={styles.content_container}>
                        {title ? (
                          <CustomText
                            font="medium"
                            size={24}
                            className={styles.title}
                          >
                            {title}
                          </CustomText>
                        ) : null}
                        {body ? (
                          <div
                            dangerouslySetInnerHTML={{ __html: body }}
                            className={styles.body}
                          />
                        ) : null}
                        <div className="df-jb-ac">
                          <Space
                            align="center"
                            split={<Divider type="vertical" />}
                          >
                            <Space>
                              <span
                                className={`cursor-pointer ${styles.icon}`}
                                onClick={() => {
                                  if (is_like) {
                                    handleDeleteArticleLike(id);
                                  } else {
                                    handlePostArticleLike(id);
                                  }
                                }}
                              >
                                {is_like ? <LikeFilled /> : <LikeOutlined />}
                              </span>
                              <CustomText
                                font="medium"
                                color="primary"
                                className={`${
                                  like_count ? ` cursor-pointer` : ''
                                }`}
                                onClick={() => {
                                  if (like_count) {
                                    setShowLikes(true);
                                    setLikes(Likes);
                                  }
                                }}
                              >
                                {likesDisplayText}
                              </CustomText>
                            </Space>
                            {comment_count > 0 ? (
                              <a href={`/leader-speak/articles/${id}#comments`}>
                                <Space>
                                  <span
                                    className={`cursor-pointer ${styles.icon}`}
                                  >
                                    <CommentOutlined />
                                  </span>
                                  <CustomText
                                    font="medium"
                                    color="primary"
                                  >{`${comment_count} Comment(s)`}</CustomText>
                                </Space>
                              </a>
                            ) : null}
                          </Space>
                          <div className={`${styles.read_post} ${styles.web}`}>
                            <a href={`/leader-speak/articles/${id}`}>
                              <Space>
                                <CustomText font="medium" color="secondary">
                                  Read post
                                </CustomText>
                                <ArrowRightOutlined className={styles.icon} />
                              </Space>
                            </a>
                          </div>
                        </div>
                        <div className={`${styles.read_post} ${styles.mobile}`}>
                          <a href={`/leader-speak/articles/${id}`}>
                            <Space>
                              <CustomText font="medium" color="secondary">
                                Read post
                              </CustomText>
                              <ArrowRightOutlined className={styles.icon} />
                            </Space>
                          </a>
                        </div>
                      </div>
                    </CustomCard>
                  </Col>
                );
              })}
            </Row>
          </div>
        );
      })}
      {showLikes ? (
        <UsersListModal
          open={showLikes}
          title="Likes"
          onCancel={() => setShowLikes(false)}
          data={likes}
        />
      ) : null}
    </CustomCard>
  );
};

export default LeaderSpeakPage;
