import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { users, addarea, updateusers, deactivateusers } from './api';

const initialState = {
  users: [],
  pagination: ''
};

const actions = {
  GET_ALL_USERS: 'usermanagement/GET_ALL_USERS',
  UPDATE_ROLE: 'usermanagement/UPDATE_ROLE',
  DEACTIVATE_USER: 'usermanagement/DEACTIVATE_USER',
  ADD_CATEGORY: 'adminconsole/ADD_CATEGORY'
};

export const getAllUsers = createAsyncThunk(
  actions.GET_ALL_USERS,
  async (payload) => {
    const response = await users(payload);

    return response;
  }
);

export const updateRole = createAsyncThunk(
  actions.UPDATE_ROLE,
  async (payload) => {
    console.log(payload);
    const response = await updateusers(payload);

    return response;
  }
);

export const deactivateUser = createAsyncThunk(
  actions.DEACTIVATE_USER,
  async (payload) => {
    console.log(payload);
    const response = await deactivateusers(payload);

    return response;
  }
);

export const addCategory = createAsyncThunk(
  actions.ADD_CATEGORY,
  async (payload) => {
    let url = payload.id ? `?id=${payload.id}` : '';
    const response = await addarea(url, payload);

    return response;
  }
);

export const usermanagementSlice = createSlice({
  name: 'usermanagement',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.getAllUsersLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.getAllUsersLoading = false;

        const { success, data, message: msg } = action.payload;

        if (success) {
          state.users = data;
          state.pagination = data.pagination;
        } else {
          state.users = [];
          state.pagination = '';
          message.error(msg);
        }
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllUsersLoading = false;

        message.error(msg);
      });

    builder
      .addCase(updateRole.pending, (state) => {
        state.getAllUsersLoading = true;
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        state.getAllUsersLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(updateRole.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllUsersLoading = false;

        message.error(msg);
      });

    builder
      .addCase(deactivateUser.pending, (state) => {
        state.getAllUsersLoading = true;
      })
      .addCase(deactivateUser.fulfilled, (state, action) => {
        state.getAllUsersLoading = false;

        const { success, message: msg } = action.payload;

        if (!success) {
          message.error(msg);
        }
      })
      .addCase(deactivateUser.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllUsersLoading = false;

        message.error(msg);
      });

    builder
      .addCase(addCategory.pending, (state) => {
        state.getAllCategoriesLoading = true;
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        state.getAllCategoriesLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(addCategory.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllCategoriesLoading = false;

        message.error(msg);
      });
  }
});

export default usermanagementSlice.reducer;
