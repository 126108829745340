import React, { useCallback } from 'react';
import {
  Col,
  Image,
  Row,
  Space,
  Spin,
  Upload,
  Popconfirm,
  Empty,
  Carousel,
  Tag,
  Skeleton,
  Divider,
  List
} from 'antd';
import {
  // CommentOutlined,
  HeartFilled,
  HeartOutlined,
  // MoreOutlined,
  PushpinFilled,
  // SendOutlined,
  DeleteFilled,
  RightOutlined,
  LeftOutlined,
  VideoCameraAddOutlined,
  EditOutlined,
  PictureOutlined,
  SyncOutlined
} from '@ant-design/icons';
import CustomCard from 'common/CustomCard';
import CustomInput from 'common/CustomInput';
import CustomText from 'common/CustomText';
import CustomButton from 'common/CustomButton';
import UserMenu from 'common/CustomLayout/components/UserMenu';
import styles from './tidings.module.less';
import moment from 'moment';
import { getLoggedInUser } from 'utilities/helpers';
import UsersListModal from 'common/UsersListModal';
import EditPollModal from '../EditPollModal';
import ImageShuffleModal from '../ImageShuffleModal';
import InfiniteScroll from 'react-infinite-scroll-component';
import { apiUrl } from 'utilities/apiClient';

const TidingsPage = ({
  allPosts,
  getAllPostsLoading,
  addPostText,
  setAddPostText,
  fileList,
  setFileList,
  handleCreatePost,
  handlePostLike,
  handlePostDislike,
  // commentText,
  // setCommentText,
  // allPostComments,
  // handleGetAllPostComments,
  // handlePostComment,
  handlePostPin,
  handlePostUnPin,
  setShowDisablePopup,
  showDisablePopup,
  handleDeletePost,
  // handleDeleteComment,
  postRefs,
  showLikes,
  setShowLikes,
  likes,
  setLikes,
  handleDeleteImage,
  slide,
  setSlide,
  video,
  setVideo,
  setEditMode,
  setSelectedPoll,
  setShowAddModal,
  showAddModal,
  selectedPoll,
  editMode,
  handleEditPoll,
  handleCancel,
  openImageShuffleModal,
  setOpenImageShuffleModal,
  setPage,
  pagination,
  page,
  setList
}) => {
  const visibility = useCallback((record) => {
    return showDisablePopup[record.id];
  }, showDisablePopup);
  const { user_details } = getLoggedInUser();
  const isAdmin = user_details?.role === 'admin';
  return (
    <div className={styles.container}>
      <Space className="w-100" direction="vertical">
        {isAdmin ? (
          <CustomCard className="p-4">
            <Row>
              <Col sm={2} xs={4}>
                <UserMenu dropdown={false} />
              </Col>
              <Col sm={22} xs={20}>
                <CustomInput
                  textArea={true}
                  rows={1}
                  value={addPostText}
                  placeholder="What's on your mind"
                  onChange={(e) => setAddPostText(e.target.value)}
                />
                <div className="df-jb-ac mt-3">
                  <Space>
                    <Upload
                      accept="image/*"
                      multiple={true}
                      fileList={fileList}
                      onChange={({ fileList }) => setFileList(fileList)}
                      customRequest={({ onSuccess }) => {
                        setTimeout(() => {
                          onSuccess('ok');
                        }, 0);
                      }}
                    >
                      {!(fileList?.length || video?.length) ? (
                        <Space className="mt-2 cursor-pointer" align="center">
                          <PictureOutlined className={styles.paperclip_icon} />
                          <CustomText font="medium" size={12} color={3}>
                            Images
                          </CustomText>
                        </Space>
                      ) : null}
                    </Upload>
                    <Upload
                      accept="video/mp4,video/x-m4v,video/*"
                      multiple={false}
                      fileList={video}
                      onChange={({ fileList }) => setVideo(fileList)}
                      customRequest={({ onSuccess }) => {
                        setTimeout(() => {
                          onSuccess('ok');
                        }, 0);
                      }}
                    >
                      {!(fileList?.length || video?.length) ? (
                        <Space className="mt-2 cursor-pointer" align="center">
                          <VideoCameraAddOutlined
                            className={styles.paperclip_icon}
                          />
                          <CustomText font="medium" size={12} color={3}>
                            Video
                          </CustomText>
                        </Space>
                      ) : null}
                    </Upload>
                  </Space>
                  <CustomButton
                    type="primary"
                    disabled={!addPostText}
                    onClick={handleCreatePost}
                  >
                    Post
                  </CustomButton>
                </div>
              </Col>
            </Row>
          </CustomCard>
        ) : null}
        <Spin spinning={getAllPostsLoading}>
          <Space className="w-100" direction="vertical" size={20}>
            <InfiniteScroll
              dataLength={allPosts?.length}
              next={() =>
                setPage(pagination?.next_page ? pagination?.next_page : 1)
              }
              hasMore={allPosts?.length < pagination?.total_records}
              loader={
                <Skeleton
                  avatar
                  paragraph={{
                    rows: 1
                  }}
                  active
                />
              }
              endMessage={<Divider plain>You have reached the end 🤐</Divider>}
              scrollableTarget="scrollableDiv"
            >
              <List
                dataSource={allPosts}
                renderItem={(item) =>
                  allPosts?.length ? (
                    <div key={item?.id} ref={postRefs[item?.id]}>
                      <CustomCard className="p-4">
                        <div className="d-flex justify-content-between">
                          <Space size={12} align="center">
                            <UserMenu dropdown={false} name={item?.User} />
                            <Space direction="vertical" size={0}>
                              <CustomText font="bold" size={15}>
                                {`${item?.User?.first_name}${' '}${
                                  item?.User?.last_name
                                }`}
                              </CustomText>
                              <CustomText color={3}>
                                {`${moment(item?.createdAt).format(
                                  'DD MMMM YYYY'
                                )}`}
                              </CustomText>
                            </Space>
                          </Space>
                          {isAdmin ? (
                            <Space className={styles.header_right_container}>
                              {item.Documents?.length &&
                              item.Documents[0]?.media_type !== 'video' ? (
                                <SyncOutlined
                                  className="cursor-pointer"
                                  onClick={() =>
                                    setOpenImageShuffleModal({
                                      [item.id]: true
                                    })
                                  }
                                />
                              ) : null}
                              <EditOutlined
                                onClick={() => {
                                  setEditMode(true);
                                  setSelectedPoll(item);
                                  setShowAddModal(true);
                                }}
                              />
                              <PushpinFilled
                                className={`${styles.header_right_icon}${
                                  item.is_pinned ? ` ${styles.pinned}` : ''
                                }`}
                                onClick={() => {
                                  if (item.is_pinned) {
                                    handlePostUnPin(item.id);
                                  } else {
                                    handlePostPin(item.id);
                                  }
                                }}
                              />
                              <Popconfirm
                                title="Are you sure you want to delete this Post?"
                                visible={visibility(item)}
                                onConfirm={() => {
                                  handleDeletePost({
                                    post_id: item.id
                                  });
                                }}
                                onCancel={() => {
                                  setShowDisablePopup({});
                                }}
                                okText="Delete"
                                cancelText="No"
                                okButtonProps={{
                                  loading: getAllPostsLoading,
                                  type: 'danger'
                                }}
                              >
                                <DeleteFilled
                                  onClick={() => {
                                    setShowDisablePopup({ [item.id]: true });
                                  }}
                                  className={styles.deletePost}
                                />
                              </Popconfirm>
                              {/* <MoreOutlined className={styles.header_right_icon} /> */}
                            </Space>
                          ) : (
                            ''
                          )}
                        </div>
                        {item.post_description ? (
                          <div className="mt-4">
                            <CustomText font={13}>
                              {item.post_description}
                            </CustomText>
                          </div>
                        ) : null}
                        <div className={`mt-4 ${styles.docs_container}`}>
                          {item.Documents?.length ? (
                            <Image.PreviewGroup>
                              <Carousel
                                effect="fade"
                                arrows={true}
                                nextArrow={<RightOutlined />}
                                prevArrow={<LeftOutlined />}
                                className={styles.docs_carousel_container}
                                dots={false}
                                // slidesToShow={3}
                                // slidesToScroll={3}
                                // slidesPerRow={3}
                                slidesToShow={2}
                                slidesToScroll={2}
                                slidesPerRow={2}
                                afterChange={(currentSlide) => {
                                  setSlide({
                                    ...slide,
                                    [item.id]: currentSlide + 1
                                  });
                                }}
                                responsive={[
                                  {
                                    breakpoint: 1200,
                                    settings: {
                                      slidesToShow: 2,
                                      slidesToScroll: 2,
                                      slidesPerRow: 2
                                    }
                                  },
                                  {
                                    breakpoint: 800,
                                    settings: {
                                      slidesToShow: 1,
                                      slidesToScroll: 1,
                                      slidesPerRow: 1
                                    }
                                  }
                                ]}
                              >
                                {item.Documents.map((doc_item, index) => {
                                  const docUrlParts =
                                    doc_item.doc_url.split('/');
                                  const fetchFile = docUrlParts.pop();
                                  return (
                                    <div
                                      key={index}
                                      className={styles.deleteonimage}
                                    >
                                      {doc_item.media_type === 'image' ? (
                                        <Image
                                          // src={doc_item.doc_url}
                                          src={`${apiUrl}/fetch-file?fileName=${fetchFile}`}
                                          className={styles.image}
                                        />
                                      ) : null}
                                      {doc_item.media_type === 'video' ? (
                                        <video
                                          controls
                                          // src={`${doc_item.doc_url}#t=0.001`}
                                          src={`${apiUrl}/fetch-file?fileName=${fetchFile}#t=0.001`}
                                          className={styles.video}
                                        />
                                      ) : null}
                                      <div className={styles.delete}>
                                        {isAdmin ? (
                                          <Popconfirm
                                            title="Are you sure you want to delete this Image?"
                                            visible={visibility(
                                              item?.Documents[index]
                                            )}
                                            onConfirm={() => {
                                              handleDeleteImage({
                                                id: item?.Documents[index]?.id,
                                                doc_url: doc_item.doc_url
                                              });
                                            }}
                                            onCancel={() => {
                                              setShowDisablePopup({});
                                            }}
                                            okText="Delete"
                                            cancelText="No"
                                            okButtonProps={{
                                              loading: getAllPostsLoading,
                                              type: 'danger'
                                            }}
                                          >
                                            <DeleteFilled
                                              onClick={() => {
                                                setShowDisablePopup({
                                                  [item.id]: true
                                                });
                                              }}
                                              className={styles.deletePost}
                                            />
                                          </Popconfirm>
                                        ) : null}
                                      </div>
                                    </div>
                                  );
                                })}
                              </Carousel>
                            </Image.PreviewGroup>
                          ) : null}
                        </div>
                        {item?.Documents?.length > 1 ? (
                          <div className={styles.custom_pagination}>
                            <Tag color="#ED2024">{`${slide[item?.id] || 1}/${
                              item?.Documents.length
                            }`}</Tag>
                          </div>
                        ) : null}
                        {openImageShuffleModal[item?.id] ? (
                          <ImageShuffleModal
                            open={openImageShuffleModal[item?.id]}
                            onCancel={() =>
                              setOpenImageShuffleModal({ [item?.id]: false })
                            }
                            data={
                              item?.Documents?.length ? item?.Documents : []
                            }
                            page={page}
                            setList={setList}
                          />
                        ) : null}
                        <div className={styles.post_details_container}>
                          <Space size="large">
                            <span
                              className={`${styles.post_details_item}${
                                item?.is_like ? ` ${styles.liked}` : ''
                              }`}
                            >
                              <span
                                className="mr-1 cursor-pointer"
                                onClick={() => {
                                  if (item?.is_like) {
                                    handlePostDislike(item?.id);
                                  } else {
                                    handlePostLike(item?.id);
                                  }
                                }}
                              >
                                {item?.is_like ? (
                                  <HeartFilled />
                                ) : (
                                  <HeartOutlined />
                                )}
                              </span>
                              <CustomText
                                font="medium"
                                size={12}
                                color={item?.is_like ? 'primary' : 3}
                                onClick={() => {
                                  if (item?.like_count) {
                                    setShowLikes(true);
                                    setLikes(item?.Likes);
                                  }
                                }}
                                className={
                                  item?.like_count ? 'cursor-pointer' : ''
                                }
                              >
                                {`${
                                  item?.Likes?.length
                                    ? `${item?.Likes[0].full_name}`
                                    : ''
                                }${
                                  item?.like_count
                                    ? item?.like_count - 1 > 0
                                      ? ` and ${item?.like_count - 1} others`
                                      : ''
                                    : '0 Likes'
                                }`}
                              </CustomText>
                            </span>
                          </Space>
                        </div>
                      </CustomCard>
                    </div>
                  ) : (
                    <Empty
                      className="w-100 text-center"
                      description={
                        <CustomText font="medium" color="primary">
                          No ideas to show. Share one now!
                        </CustomText>
                      }
                    />
                  )
                }
              />
            </InfiniteScroll>
          </Space>
        </Spin>
      </Space>
      {showLikes ? (
        <UsersListModal
          open={showLikes}
          title="Likes"
          onCancel={() => setShowLikes(false)}
          data={likes}
        />
      ) : null}
      {showAddModal ? (
        <EditPollModal
          showAddModal={showAddModal}
          handleCancel={handleCancel}
          handleEditPoll={handleEditPoll}
          editMode={editMode}
          selectedPoll={selectedPoll}
        />
      ) : null}
    </div>
  );
};

export default TidingsPage;
