import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from 'common/CustomLayout';
import LeaderSpeakPage from './components/LeaderSpeakPage';
import {
  deleteArticleLike,
  getAllArticles,
  postArticleLike
} from './redux/slice';
import { Spin } from 'antd';

const LeaderSpeak = () => {
  const dispatch = useDispatch();
  const { articlesToRender, getAllArticlesLoading } = useSelector(
    (state) => state.leaderSpeak
  );

  const [showLikes, setShowLikes] = useState(false);
  const [likes, setLikes] = useState([]);

  useEffect(() => {
    handleGetAllArticles();
  }, []);

  const handleGetAllArticles = () => {
    dispatch(getAllArticles());
  };

  const handlePostArticleLike = (article_id) => {
    dispatch(postArticleLike(article_id)).then(({ payload }) => {
      if (payload.success) {
        dispatch(handleGetAllArticles());
      }
    });
  };

  const handleDeleteArticleLike = (article_id) => {
    dispatch(deleteArticleLike(article_id)).then(({ payload }) => {
      if (payload.success) {
        dispatch(handleGetAllArticles());
      }
    });
  };

  return (
    <Layout>
      <Spin spinning={getAllArticlesLoading}>
        <LeaderSpeakPage
          data={articlesToRender}
          handlePostArticleLike={handlePostArticleLike}
          handleDeleteArticleLike={handleDeleteArticleLike}
          showLikes={showLikes}
          setShowLikes={setShowLikes}
          likes={likes}
          setLikes={setLikes}
        />
      </Spin>
    </Layout>
  );
};

export default LeaderSpeak;
