import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { allEvents, poll, pollResponse, submitPoll, quizzes } from './api';

const initialState = {
  allEvents: [],
  getAllEventsLoading: false,
  allPolls: [],
  getAllPollsLoading: false,
  fetchPollResponse: false,
  pollResponseData: null,
  getPollResponseLoading: false,
  canShowThankyou: false,
  code: '',
  quizzes: []
};

const actions = {
  GET_ALL_EVENTS: 'customLayout/GET_ALL_EVENTS',
  GET_ALL_POLLS: 'customLayout/GET_ALL_POLLS',
  POST_SUBMIT_POLL: 'customLayout/POST_SUBMIT_POLL',
  GET_POLL_RESPONSE: 'customLayout/GET_POLL_RESPONSE',
  GET_ALL_QUIZZES: 'customLayout/GET_ALL_QUIZZES'
};

export const getAllEvents = createAsyncThunk(
  actions.GET_ALL_EVENTS,
  async () => {
    const response = await allEvents();

    return response;
  }
);

export const getAllPolls = createAsyncThunk(actions.GET_ALL_POLLS, async () => {
  const response = await poll();

  return response;
});

export const postSubmitPoll = createAsyncThunk(
  actions.POST_SUBMIT_POLL,
  async (payload) => {
    const response = await submitPoll(payload);

    return response;
  }
);

export const getPollResponse = createAsyncThunk(
  actions.GET_POLL_RESPONSE,
  async (id) => {
    var payload = { poll_id: id };
    const response = await pollResponse(payload);

    return response;
  }
);

export const getAllQuizzes = createAsyncThunk(
  actions.GET_ALL_QUIZZES,
  async (payload) => {
    const response = await quizzes(payload);

    return response;
  }
);

export const customLayoutSlice = createSlice({
  name: 'customLayout',
  initialState,
  reducers: {
    handleFetchPollResponse: (state, action) => {
      state.fetchPollResponse = action.payload;
    },
    handleCanShowThankyou: (state, action) => {
      state.canShowThankyou = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllEvents.pending, (state) => {
        state.getAllEventsLoading = true;
      })
      .addCase(getAllEvents.fulfilled, (state, action) => {
        state.getAllEventsLoading = false;

        const { success, data, message: msg } = action.payload;

        if (success) {
          state.allEvents = data;
        } else {
          state.allEvents = [];
          state.code = 401;
          message.error(msg);
        }
      })
      .addCase(getAllEvents.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEventsLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllPolls.pending, (state) => {
        state.getAllPollsLoading = true;
      })
      .addCase(getAllPolls.fulfilled, (state, action) => {
        state.getAllPollsLoading = false;

        const { success, data, message: msg, status_type } = action.payload;

        if (success) {
          state.allPolls = data;
        } else {
          state.allPolls = [];
          state.code = status_type;
          message.error(msg);
        }
      })
      .addCase(getAllPolls.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllPollsLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postSubmitPoll.pending, (state) => {
        state.postSubmitPollLoading = true;
      })
      .addCase(postSubmitPoll.fulfilled, (state, action) => {
        state.postSubmitPollLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(postSubmitPoll.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postSubmitPollLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getPollResponse.pending, (state) => {
        state.getPollResponseLoading = true;
      })
      .addCase(getPollResponse.fulfilled, (state, action) => {
        state.getPollResponseLoading = false;

        // const dummy_data = {
        //   success: true,
        //   data: {
        //     yes_responses: 2,
        //     no_responses: 1,
        //     yes_percentage: '66%',
        //     no_percentage: '33%'
        //   },
        //   message: ''
        // };

        const { data } = action.payload;

        // if (success) {
        state.pollResponseData = data;
        // } else {
        //   message.error(msg);
        // }
      })
      .addCase(getPollResponse.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getPollResponseLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllQuizzes.pending, (state) => {
        state.getAllEventsLoading = true;
      })
      .addCase(getAllQuizzes.fulfilled, (state, action) => {
        state.getAllEventsLoading = false;

        const { success, data, message: msg } = action.payload;

        if (success) {
          state.quizzes = data;
        } else {
          state.quizzes = [];
          message.error(msg);
        }
      })
      .addCase(getAllQuizzes.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEventssLoading = false;

        message.error(msg);
      });
  }
});

export const { handleFetchPollResponse, handleCanShowThankyou } =
  customLayoutSlice.actions;

export default customLayoutSlice.reducer;
