import { Carousel, Divider, Image, Popconfirm, Space } from 'antd';
import {
  CommentOutlined,
  DeleteFilled,
  LeftOutlined,
  LikeFilled,
  LikeOutlined,
  PlayCircleOutlined,
  RightOutlined,
  SendOutlined
  // StepBackwardOutlined,
  // StepForwardOutlined
} from '@ant-design/icons';
import CustomCard from 'common/CustomCard';
import CustomText from 'common/CustomText';
import UsersListModal from 'common/UsersListModal';
import styles from './article.module.less';
import UserMenu from '@/common/CustomLayout/components/UserMenu';
import CustomInput from '@/common/CustomInput';
import moment from 'moment';
import { getLoggedInUser } from 'utilities/helpers';
// import bg from '../../images/bg.jpg';

const Details = ({ title, desigation, body }) => {
  return (
    <>
      {title ? (
        <CustomText font="medium" size={24} color="secondary">
          {title}
        </CustomText>
      ) : null}
      {desigation ? (
        <CustomText font="medium" size={16} color={2}>
          {desigation}
        </CustomText>
      ) : null}
      {body ? (
        <div
          dangerouslySetInnerHTML={{ __html: body }}
          className={styles.body}
        />
      ) : null}
    </>
  );
};

const ArticlePage = ({
  data,
  handlePostArticleLike,
  handleDeleteArticleLike,
  showLikes,
  setShowLikes,
  likes,
  setLikes,
  commentText,
  setCommentText,
  handlePostArticleComment,
  handleDeleteArticleComment,
  deleteArticleCommentLoading,
  selectedVideo,
  setSelectedVideo
}) => {
  if (!data) return null;

  const {
    id,
    Documents,
    video_documents,
    title,
    desigation,
    body,
    is_like,
    like_count,
    Likes,
    comment_count,
    comments
  } = data;
  const likesDisplayText = `${
    Likes.length
      ? `${
          Likes[0]?.full_name?.length > 10
            ? Likes[0]?.full_name.substring(0, 10) + '...'
            : Likes[0]?.full_name
        }`
      : ''
  }${
    like_count
      ? like_count - 1 > 0
        ? ` and ${like_count - 1} others`
        : ''
      : '0 likes'
  }`;
  const { user_details } = getLoggedInUser();
  const loggedInUserId = user_details?.id || null;
  const isAdmin = user_details?.role === 'admin';

  return (
    <CustomCard className={styles.container}>
      <CustomCard
        className={styles.inner_container}
        // style={{ backgroundImage: `url(${bg})` }}
      >
        {video_documents?.length ? (
          <>
            <div className={styles.video_details_container}>
              <Details title={title} desigation={desigation} body={body} />
              <Divider className="my-1" />
            </div>
            <div className={styles.video_container}>
              {selectedVideo ? (
                <>
                  <CustomText font="medium" color="primary" size={24}>
                    {selectedVideo?.title || 'On Transforming SCM'}
                  </CustomText>
                  <iframe
                    width="100%"
                    height="400"
                    src={
                      selectedVideo?.doc_url ||
                      'https://www.youtube.com/embed/v2bAEek6nc4'
                    }
                    style={{ borderRadius: 10, border: 'none' }}
                  />
                </>
              ) : null}
              <Carousel
                arrows={true}
                nextArrow={<RightOutlined />}
                prevArrow={<LeftOutlined />}
                className={styles.video_carousel_container}
                slidesToShow={1}
                slidesToScroll={1}
                // centerMode={true}
                variableWidth={true}
                infinite={false}
                beforeChange={(_, next) => {
                  let video = video_documents[next];
                  setSelectedVideo(video);
                }}
              >
                {video_documents.map((item) => {
                  const { id, title, Thumbnail_link } = item;

                  return (
                    <div key={id}>
                      <div className={styles.video_thumbnail_container}>
                        <Image
                          preview={false}
                          src={Thumbnail_link}
                          className={styles.video_thumbnail_img}
                        />
                        {selectedVideo?.id === id ? (
                          <div className={styles.video_play_btn_container}>
                            <PlayCircleOutlined
                              className={styles.video_play_btn}
                            />
                          </div>
                        ) : null}
                        <div className={styles.video_title}>
                          <CustomText font="medium" size={10} color="white">
                            {title || 'On Transforming SCM'}
                          </CustomText>
                        </div>
                      </div>
                      {/* <CustomText
                        color="secondary"
                        className="d-block text-right"
                      >
                        {video_documents.length - 1 !== index ? (
                          <Space>
                            <StepForwardOutlined style={{ fontSize: 16 }} />
                            {`Up Next: ${video_documents[index + 1].title}`}
                          </Space>
                        ) : (
                          <Space>
                            <StepBackwardOutlined style={{ fontSize: 16 }} />
                            {'All caught up!'}
                          </Space>
                        )}
                      </CustomText> */}
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </>
        ) : (
          <div className={styles.image_container}>
            <Carousel
              effect="fade"
              autoplay
              autoplaySpeed={3000}
              className={styles.carousel_container}
            >
              {Documents.map((doc) => {
                const { id, doc_url, media_type } = doc;

                return (
                  <div key={id}>
                    {media_type === 'image' ? (
                      <Image
                        src={doc_url}
                        preview={false}
                        className={styles.image}
                      />
                    ) : null}
                  </div>
                );
              })}
            </Carousel>
          </div>
        )}
        <div className={styles.details_container}>
          <Space direction="vertical" className="w-100">
            {!video_documents?.length ? (
              <Details title={title} desigation={desigation} body={body} />
            ) : null}
            <Divider className="m-0" />
            <Space align="center" split={<Divider type="vertical" />}>
              <Space>
                <span
                  className={`cursor-pointer ${styles.icon}`}
                  onClick={() => {
                    if (is_like) {
                      handleDeleteArticleLike(id);
                    } else {
                      handlePostArticleLike(id);
                    }
                  }}
                >
                  {is_like ? <LikeFilled /> : <LikeOutlined />}
                </span>
                <CustomText
                  font="medium"
                  color="primary"
                  className={`${like_count ? ` cursor-pointer` : ''}`}
                  onClick={() => {
                    if (like_count) {
                      setShowLikes(true);
                      setLikes(Likes);
                    }
                  }}
                >
                  {likesDisplayText}
                </CustomText>
              </Space>
              {comment_count > 0 ? (
                <Space>
                  <span className={`cursor-pointer ${styles.icon}`}>
                    <CommentOutlined />
                  </span>
                  <CustomText
                    font="medium"
                    color="primary"
                  >{`${comment_count} Comment(s)`}</CustomText>
                </Space>
              ) : null}
            </Space>
            <Divider className="m-0" />
            <div id="comments" className={styles.comments_container}>
              <div className="d-flex">
                <div className="mr-3">
                  <UserMenu dropdown={false} />
                </div>
                <div className="w-100">
                  <CustomInput
                    value={commentText || null}
                    placeholder="write your comment"
                    suffix={
                      <SendOutlined
                        className={`${styles.send_icon}${
                          commentText ? ` ${styles.enabled}` : ''
                        }`}
                        onClick={() => {
                          if (commentText) {
                            handlePostArticleComment(id);
                          }
                        }}
                      />
                    }
                    onChange={(e) => setCommentText(e.target.value)}
                  />
                </div>
              </div>
              {comments?.length ? (
                <div className={`mt-3 ${styles.comments_list_container}`}>
                  {comments.map((item) => {
                    const { id, comment, User_fullname, user_id, createdAt } =
                      item;

                    return (
                      <div
                        key={id}
                        className={`mt-3 ${styles.comments_list_item_container}`}
                      >
                        <div className="d-flex justify-content-between">
                          <Space size={20} align="center">
                            <UserMenu dropdown={false} />
                            <Space direction="vertical" size={0}>
                              <Space size={10} align="center">
                                <CustomText font="medium">
                                  {User_fullname}
                                </CustomText>
                                {createdAt ? (
                                  <CustomText size={10} color={3}>
                                    {moment(createdAt)
                                      .startOf('hour')
                                      .fromNow()}
                                  </CustomText>
                                ) : null}
                              </Space>
                              <CustomText size={11} color={2}>
                                {comment}
                              </CustomText>
                            </Space>
                          </Space>
                          {isAdmin || user_id == loggedInUserId ? (
                            <Popconfirm
                              title="Are you sure you want to delete this Comment?"
                              onConfirm={() => {
                                handleDeleteArticleComment(id);
                              }}
                              okText="Delete"
                              cancelText="No"
                              okButtonProps={{
                                type: 'danger',
                                loading: deleteArticleCommentLoading
                              }}
                            >
                              <DeleteFilled className={styles.comment_delete} />
                            </Popconfirm>
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </Space>
        </div>
      </CustomCard>
      {showLikes ? (
        <UsersListModal
          open={showLikes}
          title="Likes"
          onCancel={() => setShowLikes(false)}
          data={likes}
        />
      ) : null}
    </CustomCard>
  );
};

export default ArticlePage;
