import IdeasPage from './components/IdeasPage';
import Layout from 'common/CustomLayout';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllIdeas,
  postIdeaDownvote,
  postIdeaUpvote,
  toggleAddIdeaModal,
  getAreaList,
  deleteIdea,
  staredIdea,
  unStaredIdea,
  getTags,
  postIdeaTag,
  resetAllIdeas,
  getActionTags
} from './redux/slice';
import { useEffect, useState } from 'react';
import moment from 'moment';
// import LocalStorage from 'utilities/localStorage';

const Ideas = () => {
  const dispatch = useDispatch();

  const {
    allIdeas,
    getAllIdeasLoading,
    addIdeaModal,
    areas,
    tags,
    pagination,
    allStaredIdeas,
    actionTags
  } = useSelector((state) => state.ideas);

  const [areaId, setAreaId] = useState('');
  const [showDisablePopup, setShowDisablePopup] = useState(false);
  const [showLikes, setShowLikes] = useState(false);
  const [likes, setLikes] = useState([]);
  const [page, setPage] = useState(1);
  const [enableFiltering, setEnableFiltering] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [dateValue, setDateValue] = useState('');
  const [myIdeas, setMyIdeas] = useState(false);
  // const [actionTags, setActionTags] = useState([]);
  // const userDetails = LocalStorage.getItem('phoenix_login_user');

  useEffect(() => {
    handleGetTags();
  }, []);

  useEffect(() => {
    handleGetActionTags();
  }, []);

  useEffect(() => {
    if (enableFiltering) {
      handleResetAllIdeas();
      if (page != 1) {
        setPage(1);
      } else {
        handleAllIdeas();
      }
    }
  }, [areaId]);

  useEffect(() => {
    handleAllIdeas();
    handleGetAllAreas();
    handleSeachText;
    // handleGetAllSuggestionsByDate;
  }, [page, dateValue, myIdeas]);

  // const handleGetAllSuggestionsByDate = () => {
  //   console.log(dateValue);
  //   let payload = {
  //     page: page,
  //     month: dateValue ? moment(dateValue.$d).format('YYYY-MM') : '2023-06'
  //   };
  //   dispatch(getAllIdeas(payload));
  // };

  const handleResetAllIdeas = () => {
    dispatch(resetAllIdeas());
  };

  const handleSeachText = (val) => {
    setSearchText(val);
    handleAllIdeas(val);
  };

  // const handleGetMyIdeas = () => {
  //   let user_id = userDetails?.id;
  //   setMyIdeas(user_id);
  // };

  // console.log('dateValue', moment(dateValue).format('YYYY-MM'));

  const handleAllIdeas = (val) => {
    const month_value = dateValue && moment(dateValue).format('YYYY-MM');
    const payload = {
      page,
      search: val ? val : searchText,
      // search_key: val ? val : searchText,
      month: month_value,
      my_ideas: myIdeas ? myIdeas : null
    };
    if (areaId) payload['area_id'] = areaId;

    dispatch(getAllIdeas(payload));
  };

  // console.log('allIdeasMain', allIdeas);

  const handleGetAllAreas = () => {
    dispatch(getAreaList());
  };

  const handlePostIdeaUpvote = (upvote_id) => {
    dispatch(postIdeaUpvote(upvote_id)).then(({ payload }) => {
      if (payload.success) {
        const payload = { page: page };
        dispatch(getAllIdeas(payload));
      }
    });
  };

  const handlePostIdeaDownvote = (upvote_id) => {
    dispatch(postIdeaDownvote(upvote_id)).then(({ payload }) => {
      if (payload.success) {
        const payload = { page: page };
        dispatch(getAllIdeas(payload));
      }
    });
  };

  const handleToggleAddIdeaModal = () => {
    dispatch(toggleAddIdeaModal());
  };

  const handleDeletePost = (id) => {
    dispatch(deleteIdea(id)).then(() => {
      const payload = { page: page };
      dispatch(getAllIdeas(payload));
    });
  };

  const handlePostUnStared = (id) => {
    let payload = { suggestion_id: id };
    dispatch(unStaredIdea(payload)).then(() => {
      const payload = { page: page };
      dispatch(getAllIdeas(payload));
    });
  };

  const handlePostStared = (id) => {
    let payload = { suggestion_id: id };
    dispatch(staredIdea(payload)).then(() => {
      const payload = { page: page };
      dispatch(getAllIdeas(payload));
    });
  };

  const handleGetTags = () => {
    const payload = { tags_type: 'idea' };
    dispatch(getTags(payload));
  };

  const handleGetActionTags = () => {
    const payload = { tags_type: 'action' };
    dispatch(getActionTags(payload)).then((res) => {
      console.log('respomnse', res.payload.data);
      // setActionTags(res.payload.data);
    });
  };

  const handlePostIdeaTag = (sugg_id, idea_tags_id, name) => {
    let formData = new FormData();
    formData.append('sugg_id', sugg_id);
    name === 'Open' || name === 'Closed'
      ? formData.append('action_tags_id', idea_tags_id)
      : formData.append('idea_tags_id', idea_tags_id);

    dispatch(postIdeaTag(formData)).then(({ payload }) => {
      if (payload?.success) {
        window.location.reload();
        const payload = { page: page };
        dispatch(getAllIdeas(payload));
      }
    });
  };

  return (
    <Layout>
      <IdeasPage
        allIdeas={allIdeas}
        handlePostIdeaUpvote={handlePostIdeaUpvote}
        handlePostIdeaDownvote={handlePostIdeaDownvote}
        addIdeaModal={addIdeaModal}
        handleToggleAddIdeaModal={handleToggleAddIdeaModal}
        setAreaId={setAreaId}
        areas={areas}
        areaId={areaId}
        showDisablePopup={showDisablePopup}
        setShowDisablePopup={setShowDisablePopup}
        handleDeletePost={handleDeletePost}
        showLikes={showLikes}
        setShowLikes={setShowLikes}
        likes={likes}
        setLikes={setLikes}
        handlePostStared={handlePostStared}
        handlePostUnStared={handlePostUnStared}
        getAllIdeasLoading={getAllIdeasLoading}
        tags={tags}
        handlePostIdeaTag={handlePostIdeaTag}
        setPage={setPage}
        pagination={pagination}
        allStaredIdeas={allStaredIdeas}
        setEnableFiltering={setEnableFiltering}
        handleSeachText={handleSeachText}
        setDateValue={setDateValue}
        setMyIdeas={setMyIdeas}
        actionTags={actionTags}
        myIdeas={myIdeas}
      />
    </Layout>
  );
};

export default Ideas;
