import {
  Button,
  // Carousel,
  Col,
  Divider,
  Image,
  Popconfirm,
  Row,
  Space
} from 'antd';
import {
  CommentOutlined,
  DeleteFilled,
  LikeFilled,
  LikeOutlined,
  SendOutlined
} from '@ant-design/icons';
import CustomCard from 'common/CustomCard';
import CustomText from 'common/CustomText';
import UsersListModal from 'common/UsersListModal';
import styles from './winner_details.module.less';
import UserMenu from 'common/CustomLayout/components/UserMenu';
import CustomInput from 'common/CustomInput';
import moment from 'moment';
import { getLoggedInUser } from 'utilities/helpers';
import { images } from '../WinnersPage';
import bird from '../../images/winners/bird.png';
import banner from '../../images/winners/winner_bg.png';
// import Confetti from 'react-confetti';

const WinnerDetailsPage = ({
  data,
  showLikes,
  setShowLikes,
  likes,
  setLikes,
  commentText,
  setCommentText,
  handlePostRRLike,
  handleDeleteRRLike,
  handlePostRRComment,
  handleDeleteRRComment,
  deleteRRCommentLoading
}) => {
  if (!data) return null;
  const {
    id,
    name,
    designation,
    citation,
    focus_area,
    // results,
    rr_master,
    rr_images,
    is_like,
    like_count,
    Likes,
    comment_count,
    comments,
    sugg_url,
    sugg_id,
    Suggestion,
    rr_group_id,
    // rr_group,
    super_squads
    // url
  } = data;

  const img_fil = rr_images?.filter((o) => o?.team_pic_doc_url !== null);
  const img_person = rr_images?.filter((o) => o?.doc_url !== null);
  console.log(img_fil);
  const likesDisplayText = `${
    Likes?.length
      ? `${
          Likes[0]?.full_name?.length > 10
            ? Likes[0]?.full_name.substring(0, 10) + '...'
            : Likes[0]?.full_name
        }`
      : ''
  }${
    like_count
      ? like_count - 1 > 0
        ? ` and ${like_count - 1} others`
        : ''
      : '0 likes'
  }`;
  const { user_details } = getLoggedInUser();
  const loggedInUserId = user_details?.id || null;
  const isAdmin = user_details?.role === 'admin';

  return (
    <CustomCard className={styles.container}>
      <div>
        <Row
          style={{
            backgroundImage: `url(${banner})`,
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat'
          }}
        >
          {/* <Confetti
            className={styles.confetti}
            // numberOfPieces="1000"
            height="90"
            width="850"
          /> */}
          <Col span={16}>
            <div className={styles.card_container}>
              <Space size="middle" align="center">
                <Image
                  src={images[(rr_master?.Image || '').replace('.png', '')]}
                  className={styles.image1}
                  preview={false}
                  style={{ height: '50px !important' }}
                />
                <CustomText font="bold" size={26} color="secondary">
                  {rr_group_id ? rr_master?.title : rr_master?.title}
                </CustomText>
              </Space>
            </div>
          </Col>
          <Col span={8} className="text-right">
            <Space>
              {rr_master?.type === 'D' ? (
                <Button type="primary">
                  <a href={'/ideas'}>Check out the winning ideas</a>
                </Button>
              ) : (
                ''
              )}
              <Button onClick={() => history.back()}>Back</Button>
            </Space>
          </Col>
        </Row>
      </div>
      {rr_master?.type === 'A' || rr_master?.type === 'C' ? (
        <Row className={styles.row_container}>
          <Col sm={12} xs={24}>
            <div className={styles.left_container}>
              <Space size={20} direction="vertical" className="w-100">
                <Space size={0} direction="vertical">
                  <CustomText font="bold" size={16} color="secondary">
                    {name || ''}
                  </CustomText>
                  <CustomText font="medium" size={12} color="white">
                    {designation !== 'undefined' ? designation : '' || ''}
                  </CustomText>
                </Space>
                {Array.isArray(super_squads) && super_squads?.length ? (
                  <Space size={0} direction="vertical">
                    <CustomText font="medium" size={14} color="secondary">
                      Members:
                    </CustomText>
                    {super_squads.map((item, index) => (
                      <CustomText
                        font="medium"
                        size={12}
                        color="white"
                        key={index}
                      >
                        <li>{item || ''}</li>
                      </CustomText>
                    ))}
                  </Space>
                ) : null}
                {Suggestion?.subarea?.sub_area ? (
                  <Space size={0} direction="vertical">
                    <CustomText font="medium" size={16} color="secondary">
                      Sub-category:
                    </CustomText>
                    <CustomText font="medium" size={12} color="white">
                      {Suggestion?.subarea?.sub_area || ''}
                    </CustomText>
                  </Space>
                ) : null}
                {rr_master?.type === 'A' && focus_area != 'undefined' ? (
                  <Space size={0} direction="vertical">
                    <CustomText font="medium" size={14} color="secondary">
                      Initiative:
                    </CustomText>
                    <CustomText size={12} color="white">
                      {focus_area || ''}
                    </CustomText>
                  </Space>
                ) : (
                  ''
                )}
                <Space size={0} direction="vertical">
                  <CustomText font="medium" size={14} color="secondary">
                    Citation:
                  </CustomText>
                  <CustomText size={12} color="white">
                    {citation || ''}
                  </CustomText>
                  {/* {rr_master?.type === 'A' ? (
                    <CustomText size={16} color="white">
                      {url ? (
                        <a href={url} target="_blank" rel="noreferrer">
                          Refer to the link
                        </a>
                      ) : (
                        '' || ''
                      )}
                    </CustomText>
                  ) : (
                    ''
                  )} */}
                </Space>

                {sugg_id ? (
                  <div className="text-right">
                    <u>
                      <a href={'../../' + sugg_url}>Check out the idea</a>
                    </u>
                  </div>
                ) : null}
                {/* <Space size={0} direction="vertical">
                  <CustomText font="medium" size={16} color="secondary">
                    Results:
                  </CustomText>
                  <CustomText size={16} color="white">
                    {results || ''}
                  </CustomText>
                </Space> */}
              </Space>
              <Image src={bird} preview={false} className={styles.image} />
            </div>
          </Col>
          <Col sm={12} xs={24}>
            <div className={styles.image_container}>
              {/* <Carousel
                effect="fade"
                autoplay
                autoplaySpeed={3000}
                className={styles.carousel_container}
              > */}
              <div className={styles.images_horizontal_scroll}>
                {img_person?.map((doc) => {
                  const { id, doc_url } = doc;

                  return (
                    <div key={id}>
                      <Image
                        src={doc_url}
                        preview={false}
                        className={styles.image}
                      />
                    </div>
                  );
                })}
              </div>
              {/* </Carousel> */}
            </div>
          </Col>
        </Row>
      ) : (
        <div className={styles.image_container}>
          {/* <Carousel
            effect="fade"
            autoplay
            autoplaySpeed={3000}
            className={styles.carousel_container}
          > */}
          <div className={styles.images_horizontal_scroll}>
            {img_fil?.map((doc) => {
              const { id, team_pic_doc_url } = doc;

              return (
                <div key={id}>
                  <Image
                    src={team_pic_doc_url}
                    preview={false}
                    className={styles.image}
                  />
                </div>
              );
            })}
          </div>
          {/* </Carousel> */}
        </div>
      )}
      <Divider className="my-2" />
      <div className={styles.details_container}>
        <Space direction="vertical" className="w-100">
          <Space align="center" split={<Divider type="vertical" />}>
            <Space>
              <span
                className={`cursor-pointer ${styles.icon}`}
                onClick={() => {
                  if (is_like) {
                    handleDeleteRRLike(id);
                  } else {
                    handlePostRRLike(id);
                  }
                }}
              >
                {is_like ? <LikeFilled /> : <LikeOutlined />}
              </span>
              <CustomText
                font="medium"
                color="primary"
                className={`${like_count ? ` cursor-pointer` : ''}`}
                onClick={() => {
                  if (like_count) {
                    setShowLikes(true);
                    setLikes(Likes);
                  }
                }}
              >
                {likesDisplayText}
              </CustomText>
            </Space>
            {comment_count > 0 ? (
              <Space>
                <span className={`cursor-pointer ${styles.icon}`}>
                  <CommentOutlined />
                </span>
                <CustomText
                  font="medium"
                  color="primary"
                >{`${comment_count} Comment(s)`}</CustomText>
              </Space>
            ) : null}
          </Space>
          <Divider className="m-0" />
          <div id="comments" className={styles.comments_container}>
            <div className="d-flex">
              <div className="mr-3">
                <UserMenu dropdown={false} />
              </div>
              <div className="w-100">
                <CustomInput
                  value={commentText || null}
                  placeholder="write your comment"
                  suffix={
                    <SendOutlined
                      className={`${styles.send_icon}${
                        commentText ? ` ${styles.enabled}` : ''
                      }`}
                      onClick={() => {
                        if (commentText) {
                          handlePostRRComment(id);
                        }
                      }}
                    />
                  }
                  onChange={(e) => setCommentText(e.target.value)}
                />
              </div>
            </div>
            {comments?.length ? (
              <div className={`mt-3 ${styles.comments_list_container}`}>
                {comments.map((item) => {
                  const { id, comment, User_fullname, user_id, createdAt } =
                    item;

                  return (
                    <div
                      key={id}
                      className={`mt-3 ${styles.comments_list_item_container}`}
                    >
                      <div className="d-flex justify-content-between">
                        <Space size={20} align="center">
                          <UserMenu dropdown={false} />
                          <Space direction="vertical" size={0}>
                            <Space size={10} align="center">
                              <CustomText font="medium">
                                {User_fullname}
                              </CustomText>
                              {createdAt ? (
                                <CustomText size={10} color={3}>
                                  {moment(createdAt).startOf('hour').fromNow()}
                                </CustomText>
                              ) : null}
                            </Space>
                            <CustomText size={11} color={2}>
                              {comment}
                            </CustomText>
                          </Space>
                        </Space>
                        {isAdmin || user_id == loggedInUserId ? (
                          <Popconfirm
                            title="Are you sure you want to delete this Comment?"
                            onConfirm={() => {
                              handleDeleteRRComment(id);
                            }}
                            okText="Delete"
                            cancelText="No"
                            okButtonProps={{
                              type: 'danger',
                              loading: deleteRRCommentLoading
                            }}
                          >
                            <DeleteFilled className={styles.comment_delete} />
                          </Popconfirm>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </Space>
      </div>
      {showLikes ? (
        <UsersListModal
          open={showLikes}
          title="Likes"
          onCancel={() => setShowLikes(false)}
          data={likes}
        />
      ) : null}
    </CustomCard>
  );
};

export default WinnerDetailsPage;
