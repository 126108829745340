import SubcategoriesPage from './components/SubcategoriesPage';
import Layout from 'common/CustomLayout';

const Subcategories = () => {
  return (
    <Layout>
      <SubcategoriesPage />
    </Layout>
  );
};

export default Subcategories;
