import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { quizzes, submitQuizQuestions } from './api';

const initialState = {
  quizzes: [],
  urls: [],
  getAllEventsLoading: false
};

const actions = {
  GET_ALL_QUIZZES: 'eventconsole/GET_ALL_QUIZZES',
  ADD_EVENT: 'eventconsole/ADD_EVENT',
  DELETE_QUIZ: 'eventconsole/DELETE_QUIZ',
  GET_ALL_URLS: 'getAllUrls/GET_ALL_URLS',
  SUBMIT_QUIZ: 'eventconsole/SUBMIT_QUIZ'
};

export const getAllQuizzes = createAsyncThunk(
  actions.GET_ALL_QUIZZES,
  async (payload) => {
    const response = await quizzes(payload);

    return response;
  }
);

export const submitQuiz = createAsyncThunk(
  actions.SUBMIT_QUIZ,
  async (payload) => {
    const response = await submitQuizQuestions('submit', payload);

    return response;
  }
);

export const quizConsoleSlice = createSlice({
  name: 'quizconsole',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllQuizzes.pending, (state) => {
        state.getAllEventsLoading = true;
      })
      .addCase(getAllQuizzes.fulfilled, (state, action) => {
        state.getAllEventsLoading = false;

        const { success, data, message: msg } = action.payload;

        if (success) {
          state.quizzes = data;
        } else {
          state.quizzes = [];
          message.error(msg);
        }
      })
      .addCase(getAllQuizzes.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEventssLoading = false;

        message.error(msg);
      });
    builder
      .addCase(submitQuiz.pending, (state) => {
        state.getAllEventLoading = true;
      })
      .addCase(submitQuiz.fulfilled, (state, action) => {
        state.getAllEventLoading = false;

        const { success, message: msg } = action.payload;

        if (success) {
          // state.quizzes = data;
          // state.pagination = pagination;
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(submitQuiz.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEventLoading = false;

        message.error(msg);
      });
  }
});

export default quizConsoleSlice.reducer;
