import React from 'react';
import { Col, Row, Space } from 'antd';
import CustomCard from 'common/CustomCard';
import CustomText from 'common/CustomText';
import styles from './admin_console.module.less';
import { history } from 'app/history';
import {
  CloudDownloadOutlined,
  MenuUnfoldOutlined,
  TeamOutlined,
  CalendarOutlined,
  BulbOutlined,
  MessageOutlined
} from '@ant-design/icons';

const AdminConsolePage = () => {
  const links = [
    {
      id: 1,
      name: 'Manage Categories',
      url: '/categories',
      image: <MenuUnfoldOutlined />
    },
    {
      id: 2,
      name: 'Export Ideas',
      image: <CloudDownloadOutlined />
    },
    {
      id: 3,
      name: 'User Management',
      url: '/users',
      image: <TeamOutlined />
    },
    {
      id: 4,
      name: 'Manage Events',
      url: '/events',
      image: <CalendarOutlined />
    },
    {
      id: 5,
      name: 'Manage Surveys & Quizzes',
      url: '/quizzes-list',
      image: <BulbOutlined />
    },
    {
      id: 6,
      name: 'Manage MD Message',
      url: '/md-message-list',
      image: <MessageOutlined />
    },
    {
      id: 7,
      name: 'Export Surveys & Quizzes',
      image: <CloudDownloadOutlined />
    }
  ];

  console.log('links', links);
  const handleExportasCsv = () => {
    const user = localStorage.getItem('phoenix_login_user');
    const authToken = JSON.parse(user);
    fetch(`${process.env.REACT_APP_API_URL}/suggestion/download`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/csv',
        Authorization: `Bearer ${authToken.token}`
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Ideas.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const handleQuizExportasCsv = () => {
    const user = localStorage.getItem('phoenix_login_user');
    const authToken = JSON.parse(user);
    fetch(`${process.env.REACT_APP_API_URL}/quiz/download`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/csv',
        Authorization: `Bearer ${authToken.token}`
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Quiz.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  return (
    <>
      <CustomCard className={`${styles.container} p-4`}>
        <Row>
          <Col span={22}>
            <CustomText
              font="medium"
              size={24}
              color="secondary"
              className="pl-2"
            >
              Administrator Console
            </CustomText>
          </Col>
        </Row>
        <br />
        <Row gutter={24}>
          <Space
            direction="horizental"
            size={14}
            wrap
            className={styles.spacecard}
          >
            {links.map((item) => {
              const { id, name, url, image } = item;

              return (
                <div
                  key={id}
                  className={`df-jb-ac cursor-pointer ${styles.container}`}
                  onClick={() =>
                    url
                      ? history.push(`${url}`)
                      : name === 'Export Ideas'
                      ? handleExportasCsv()
                      : handleQuizExportasCsv()
                  }
                  style={{ width: '350px' }}
                >
                  <Space size="middle" align="start">
                    <CustomText
                      font="medium"
                      size={16}
                      color="primary"
                      className="cursor-pointer"
                    >
                      <space style={{ fontSize: 20, marginRight: '12px' }}>
                        {image}
                      </space>
                      {name}
                    </CustomText>
                  </Space>
                </div>
              );
            })}
          </Space>
        </Row>
        <br />
      </CustomCard>
    </>
    // </CustomCard>
  );
};

export default AdminConsolePage;
