import React from 'react';
import { Button } from 'antd';
import styles from './custom_button.module.less';

const CustomButton = ({
  children,
  className,
  font = 'medium',
  type = 'default',
  ...rest
}) => {
  return (
    <Button
      {...rest}
      type={type}
      className={`cs-${font}${
        type === 'secondary' ? ` ${styles.secondary}` : ''
      }${className ? ` ${className}` : ''}`}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
