import React, { useState, useEffect } from 'react';
import { Input, Space, Col, Row, Divider } from 'antd';
import FormItem from 'common/FormItem';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
import { getAllQuizzesbyId } from '../../redux/slice';
import { useParams } from 'react-router-dom';
import Layout from 'common/CustomLayout';
import CustomCard from 'common/CustomCard';
import styles from '../../index.module.less';
import CustomText from 'common/CustomText';
import CustomButton from 'common/CustomButton';

const ViewQuizResult = () => {
  const [quizQuestion, setQuizQuestion] = useState([
    {
      question: '',
      options: [{ id: 1, option: '' }]
    }
  ]);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    handleGetAllQuizzesById();
  }, [params.id]);

  const handleGetAllQuizzesById = () => {
    let payload = { quiz_id: params.id };
    dispatch(getAllQuizzesbyId(payload)).then((response) => {
      setQuizQuestion(response.payload.data);
    });
  };

  console.log(quizQuestion);
  // const handleChange = (i, e) => {
  //   let newquizQuestion = [...quizQuestion];
  //   newquizQuestion[i][e.target.name] = e.target.value;
  //   setQuizQuestion(newquizQuestion);
  // };
  const handleChangeOption = (index, i, e) => {
    let newquizQuestion = [...quizQuestion];
    newquizQuestion[index]['options'][i][e.target.name] = e.target.value;
    setQuizQuestion(newquizQuestion);
  };

  const handleDrop = (index, droppedItem) => {
    console.log(index, droppedItem);
    if (!droppedItem.destination) return;
    var updatedItemList = [...quizQuestion[index].options];
    const [reorderedItemList] = updatedItemList.splice(
      droppedItem.source.index,
      1
    );
    updatedItemList.splice(droppedItem.destination.index, 0, reorderedItemList);
    console.log(updatedItemList);
    let newquizQuestion = [...quizQuestion];
    newquizQuestion[index]['options'] = updatedItemList;

    setQuizQuestion(newquizQuestion);
  };
  return (
    <Layout>
      <CustomCard
        className={styles.container}
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%'
        }}
      >
        <Row gutter={24}>
          <Col span={19}>
            <CustomText
              font="medium"
              size={24}
              color="secondary"
              // className="pl-2"
            >
              {quizQuestion[0]?.is_expired & !quizQuestion[0]?.response_sumitted
                ? 'View Quiz'
                : 'View Quiz Result'}
            </CustomText>
            <br />
            <CustomText font="medium" color="primary" size={18}>
              {quizQuestion[0]?.quiz?.name}
            </CustomText>
          </Col>
          <Col span={5}>
            {' '}
            <CustomButton
              type="primary"
              onClick={() => history.push(`/polls-surveys`)}
              direction="vertical"
              style={{ position: 'absolute', top: '30px', right: '10px' }}
            >
              Back
            </CustomButton>
          </Col>
        </Row>
        <br />

        {quizQuestion?.map((element, index) => (
          <>
            <Space size={14}>
              <FormItem
                key={index}
                label={`Question${index + 1}`}
                rules={[
                  {
                    required: false,
                    message: 'Required field'
                  }
                ]}
              >
                {/* <Input
                  value={element.question || ''}
                  name="question"
                  onChange={(e) => handleChange(index, e)}
                  style={{ width: '400px', fontWeight: 'bold' }}
                  className={styles.view_quiz_result_question}
                  disabled
                /> */}
                <CustomText
                  style={{ fontWeight: 'bold' }}
                  className={styles.view_quiz_result_question}
                  // disabled
                >
                  {element.question || ''}
                </CustomText>
              </FormItem>
              {/* {index > 0 ? (
                  <CustomButton
                    className="button add"
                    type="button"
                    onClick={() => removeQuestionFormFields(index)}
                    style={{ float: 'right' }}
                  >
                    Remove
                  </CustomButton>
                ) : (
                  <CustomButton
                    className="button add"
                    type="button"
                    onClick={() => addFormFields()}
                    style={{ float: 'right' }}
                  >
                    Add
                  </CustomButton>
                )} */}
            </Space>
            <DragDropContext
              onDragEnd={(droppedItem) => handleDrop(index, droppedItem)}
            >
              <Droppable droppableId="list-container">
                {(provided) => (
                  <div
                    className="list-container"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {element.options.map((item, i) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id.toString()}
                        index={i}
                      >
                        {(provided) => (
                          <>
                            <div
                              className="item-container"
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                            >
                              <Space size={14} key={i}>
                                <FormItem
                                  key={i}
                                  label={`option${i + 1}`}
                                  rules={[
                                    {
                                      required: false,
                                      message: 'Required field'
                                    }
                                  ]}
                                >
                                  <Input
                                    name="name"
                                    placeholder="Enter option"
                                    value={item.name || ''}
                                    onChange={(e) =>
                                      handleChangeOption(index, i, e)
                                    }
                                    style={{
                                      // width: 'auto',
                                      borderWidth: 2,
                                      fontWeight: 'bold',
                                      borderColor:
                                        item.name == element.right_answer
                                          ? 'green'
                                          : item.name == element.user_answer
                                          ? 'red'
                                          : null
                                    }}
                                    className={styles.view_quiz_result_option}
                                    disabled
                                  />
                                </FormItem>
                              </Space>
                            </div>
                          </>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {/* </Space> */}
            <Divider />
          </>
        ))}
      </CustomCard>
    </Layout>
  );
};
export default ViewQuizResult;
