import { useState } from 'react';
import { Checkbox, Space } from 'antd';
import CustomText from 'common/CustomText';
import CustomButton from 'common/CustomButton';

const SurveyDetails = ({ data, handlePostSubmitPoll, withOutCard }) => {
  if (!data) return null;

  const [selectedValue, setSelectedValue] = useState('');

  const { id, poll_question, poll_options } = data;

  let textColorStyle = withOutCard ? { color: 'white' } : {};

  return (
    <Space direction="vertical" size={4}>
      <CustomText color={3} size={12} font="medium" {...textColorStyle}>
        {poll_question}
      </CustomText>
      <Space direction="vertical">
        {poll_options.map((item, index) => {
          const { label, key } = item;

          return (
            <Checkbox
              key={index}
              value={key}
              checked={selectedValue === key}
              onChange={() => setSelectedValue(key)}
              style={{ ...textColorStyle }}
            >
              {label}
            </Checkbox>
          );
        })}
      </Space>
      <CustomButton
        type="primary"
        disabled={!selectedValue}
        onClick={() =>
          handlePostSubmitPoll({
            response: selectedValue,
            poll_id: id
          })
        }
      >
        Vote
      </CustomButton>
    </Space>
  );
};

export default SurveyDetails;
