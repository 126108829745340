import LocalStorage from './localStorage';
import {
  BulbOutlined,
  CalendarOutlined,
  HomeOutlined,
  MenuOutlined,
  // MessageOutlined,
  PhoneOutlined,
  ReadOutlined,
  SlidersOutlined,
  // SoundOutlined,
  TrophyOutlined
} from '@ant-design/icons';

export const getLoggedInUser = () => {
  const loginUser = LocalStorage.getItem('phoenix_login_user') || '';
  const user = loginUser || { user_details: null };
  return {
    ...user,
    loggedIn: loginUser !== ''
  };
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const adminMenuData = [
  {
    label: 'Home',
    key: 'about-phoenix',
    path: '/about-phoenix',
    icon: <HomeOutlined />,
    desktop: true,
    mobile: true
  },
  // {
  //   label: 'MD Message',
  //   key: 'md-message',
  //   path: '/md-message',
  //   icon: <MessageOutlined />,
  //   desktop: true,
  //   mobile: true
  // },
  {
    label: 'Pulse',
    key: 'buzz',
    path: '/buzz',
    icon: <MenuOutlined />,
    desktop: true,
    mobile: true
  },
  {
    label: 'Ideas',
    key: 'ideas',
    path: '/ideas',
    icon: <BulbOutlined />,
    desktop: true,
    mobile: true
  },
  // {
  //   label: 'Leader Speak',
  //   key: 'leader-speak/articles',
  //   path: '/leader-speak/articles',
  //   icon: <SoundOutlined />,
  //   desktop: true,
  //   mobile: true
  // },
  {
    label: 'Centre Stage',
    key: 'centre-stage/winners',
    path: '/centre-stage/winners',
    icon: <TrophyOutlined />,
    desktop: true,
    mobile: true
  },
  {
    label: 'Events',
    key: 'social',
    path: '/social',
    icon: <CalendarOutlined />,
    desktop: false,
    mobile: true
  },
  {
    label: 'Surveys & Quizzes',
    key: 'polls-surveys',
    path: '/polls-surveys',
    icon: <SlidersOutlined />,
    desktop: true,
    mobile: true
  },
  // {
  //     label: 'Surveys & Quizzes',
  //     key: 'quizzes-list',
  //     path: '/quizzes-list',
  //     icon: <SlidersOutlined />,
  //     desktop: true,
  //     mobile: true
  //   },
  {
    label: 'Contact Us',
    key: 'key-contacts',
    path: '/key-contacts',
    icon: <PhoneOutlined />,
    desktop: true,
    mobile: true
  },
  {
    label: 'Disclaimer',
    key: 'disclaimer',
    path: '/disclaimer',
    icon: <ReadOutlined />,
    desktop: false,
    mobile: true
  }
];

export const menuData = [
  {
    label: 'Home',
    key: 'about-phoenix',
    path: '/about-phoenix',
    icon: <HomeOutlined />,
    desktop: true,
    mobile: true
  },
  // {
  //   label: 'MD Message',
  //   key: 'md-message',
  //   path: '/md-message',
  //   icon: <MessageOutlined />,
  //   desktop: true,
  //   mobile: true
  // },
  {
    label: 'Buzz',
    key: 'buzz',
    path: '/buzz',
    icon: <MenuOutlined />,
    desktop: true,
    mobile: true
  },
  {
    label: 'Ideas',
    key: 'ideas',
    path: '/ideas',
    icon: <BulbOutlined />,
    desktop: true,
    mobile: true
  },
  // {
  //   label: 'Leader Speak',
  //   key: 'leader-speak/articles',
  //   path: '/leader-speak/articles',
  //   icon: <SoundOutlined />,
  //   desktop: true,
  //   mobile: true
  // },
  {
    label: 'Centre Stage',
    key: 'centre-stage/winners',
    path: '/centre-stage/winners',
    icon: <TrophyOutlined />,
    desktop: true,
    mobile: true
  },
  {
    label: 'Events',
    key: 'social',
    path: '/social',
    icon: <CalendarOutlined />,
    desktop: false,
    mobile: true
  },
  // {
  //   label: 'Surveys & Quizzes',
  //   key: 'quizzes-list',
  //   path: '/quizzes-list',
  //   icon: <SlidersOutlined />,
  //   desktop: true,
  //   mobile: true
  // },
  {
    label: 'Surveys & Quizzes',
    key: 'polls-surveys',
    path: '/polls-surveys',
    icon: <SlidersOutlined />,
    desktop: true,
    mobile: true
  },
  {
    label: 'Contact Us',
    key: 'key-contacts',
    path: '/key-contacts',
    icon: <PhoneOutlined />,
    desktop: true,
    mobile: true
  },
  {
    label: 'Disclaimer',
    key: 'disclaimer',
    path: '/disclaimer',
    icon: <ReadOutlined />,
    desktop: false,
    mobile: true
  }
];

export const smoothScrollTo = (id, block) => {
  const node = document.getElementById(id);
  if (node && node.scrollIntoView) {
    node.scrollIntoView({
      behavior: 'smooth',
      block: block || 'center'
    });
  }
};
