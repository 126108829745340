import React, { useState } from 'react';
import { Col, Image, Layout, Row, Space } from 'antd';
import styles from './custom_header.module.less';
import logo from '../../images/phoenix-logo.svg';
import tataProjectsLogo from '../../images/tata-projects-logo.svg';
import hamburgerMenu from '../../images/hamburger-menu.svg';
import { history } from 'app/history';
import { Link } from 'react-router-dom';
import { menuData, adminMenuData } from 'utilities/helpers';
import CustomText from 'common/CustomText';
import LeftDrawer from '../LeftDrawer';
import LocalStorage from 'utilities/localStorage';

const { Header } = Layout;

const CustomHeader = () => {
  const [selectedMenuKey] = useState(
    history.location.pathname.replace('/', '')
  );
  const [openLeftDrawer, setOpenLeftDrawer] = useState(false);

  const userDetails = LocalStorage.getItem('phoenix_login_user');

  const role = userDetails?.user_details?.role;
  const headerMenu =
    role == 'admin' ? adminMenuData : role == 'user' ? menuData : [];

  return (
    <Header className={styles.container}>
      <Row align="middle" className={`w-100 m-0 ${styles.row_container}`}>
        <Col sm={4} xs={12} className="p-0">
          <span
            onClick={() => history.push('/about-phoenix')}
            style={{ cursor: 'pointer' }}
          >
            <Image
              alt="phoenix-logo"
              src={logo}
              preview={false}
              className={`ant-image-img ${styles.antimage}`}
            />
          </span>
        </Col>
        <Col sm={17} xs={0} className="p-0">
          <Space align="center" size={1} className={styles.menu_container}>
            {headerMenu.map((item, index) => {
              const { key, icon, label, desktop } = item;
              let isSelected = key === selectedMenuKey;

              if (!desktop) return null;

              return (
                <Link
                  key={index}
                  to={`/${key}`}
                  className={`${styles.menu_item}${
                    isSelected ? ` ${styles.selected}` : ''
                  }`}
                >
                  <span className={styles.menu_item_icon}>{icon}</span>
                  <CustomText
                    font="medium"
                    color="secondary"
                    className={styles.menu_item_label}
                    style={{ fontSize: '14px' }}
                  >
                    {label}
                  </CustomText>
                </Link>
              );
            })}
          </Space>
        </Col>
        <Col sm={3} xs={12} className={`p-0 ${styles.right_container}`}>
          <div className={styles.tata_logo}>
            <Image
              alt="tata=projects-logo"
              src={tataProjectsLogo}
              preview={false}
            />
          </div>
          <div
            className={styles.hamburger_menu}
            onClick={() => setOpenLeftDrawer(true)}
          >
            <Image alt="hamburger-menu" src={hamburgerMenu} preview={false} />
          </div>
        </Col>
      </Row>
      <div className={styles.bottom_container} />
      {openLeftDrawer ? (
        <LeftDrawer
          open={openLeftDrawer}
          onClose={() => setOpenLeftDrawer(false)}
        />
      ) : null}
    </Header>
  );
};

export default CustomHeader;
