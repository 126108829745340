import { Avatar, Dropdown, Menu, Typography } from 'antd';
import { LogoutOutlined, CaretDownOutlined } from '@ant-design/icons';
import styles from './user_menu.module.less';
import { logout } from 'features/login/redux/slice';
import { useDispatch } from 'react-redux';
import { useMsal } from '@azure/msal-react';
import LocalStorage from 'utilities/localStorage';

const { Text } = Typography;

const UserMenu = ({ dropdown, name }) => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const userDetails = LocalStorage.getItem('phoenix_login_user');
  const userFullName = userDetails?.user_details?.fullname
    ? name
      ? name.first_name.slice(0, 1)
      : userDetails.user_details.fullname.slice(0, 1)
    : 'U';

  const signOutClickHandler = () => {
    instance.logoutRedirect({
      account: LocalStorage.homeAccountId,
      postLogoutRedirectUri: '/',
      mainWindowRedirectUri: '/',
      onRedirectNavigate: () => {
        dispatch(logout());
        return true;
      }
    });
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        icon={<LogoutOutlined />}
        onClick={() => signOutClickHandler()}
      >
        <Text onClick={() => {}}>Logout</Text>
      </Menu.Item>
    </Menu>
  );

  const UserAvatar = () => (
    <div
      className={`df-jb-ac cursor-${dropdown ? 'pointer' : 'default'} ${
        styles.container
      }`}
    >
      <Avatar className={`cs-medium ${styles.avatar}`}>{userFullName}</Avatar>
    </div>
  );

  return (
    <>
      {dropdown ? (
        <Dropdown overlay={menu}>
          <div className={`df-jb-ac ${styles.user_menu_container}`}>
            <UserAvatar />
            <CaretDownOutlined />
          </div>
        </Dropdown>
      ) : (
        <Dropdown overlay={menu}>
          <UserAvatar />
        </Dropdown>
      )}
    </>
  );
};

export default UserMenu;
