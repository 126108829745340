import LocalStorage from './localStorage';

export const apiUrl = process.env.REACT_APP_API_URL;

const encodeQueryData = (data = null) => {
  if (data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return '?' + ret.join('&');
  }
  return '';
};

const getLocalToken = () => {
  const user = LocalStorage.getItem('phoenix_login_user');
  const authToken = user?.token;
  if (authToken !== null && authToken !== '') {
    return `Bearer ${authToken}`;
  } else {
    return '';
  }
};

const apiClient = {
  Urls: {
    apiUrl,
    allPosts: `${apiUrl}/post/allposts`,
    addPost: `${apiUrl}/post/add-post`,
    likePost: `${apiUrl}/post/like`,
    dislikePost: `${apiUrl}/post/dislike`,
    allPostComments: `${apiUrl}/post/allpostcomments`,
    addComment: `${apiUrl}/post/add-comment`,
    pinPost: `${apiUrl}/post/pinned`,
    unPinPost: `${apiUrl}/post/unPinned`,
    allSuggestions: `${apiUrl}/suggestion/all-suggestion`,
    addSuggestion: `${apiUrl}/suggestion/add-suggestion`,
    suggestionUpvote: `${apiUrl}/suggestion/upvote`,
    suggestionDownvote: `${apiUrl}/suggestion/downvote`,
    areaList: `${apiUrl}/area/list`,
    allEvents: `${apiUrl}/event/all-events`,
    suggestion: `${apiUrl}/suggestion`,
    poll: `${apiUrl}/poll`,
    submitPoll: `${apiUrl}/poll/submit-poll`,
    pollResponse: `${apiUrl}/poll/poll_responses`,
    deletepost: `${apiUrl}/post/delete-post`,
    deletecomment: `${apiUrl}/post/delete-comment`,
    areaSearch: `${apiUrl}/suggestion/area-search`,
    implement: `${apiUrl}/suggestion/implement`,
    addSuggestionComment: `${apiUrl}/suggestion/add-comment`,
    dltSuggestionComment: `${apiUrl}/suggestion/dlt-comment`,
    signin: `${apiUrl}/user/signin`,
    deletesuggestion: `${apiUrl}/suggestion/dlt-suggestion`,
    addArea: `${apiUrl}/area/add-update`,
    usersList: `${apiUrl}/user/find-all`,
    switchusers: `${apiUrl}/user/user-activate`,
    addEvent: `${apiUrl}/event/add-event`,
    deleteEvent: `${apiUrl}/event/dlt-event`,
    deletedocument: `${apiUrl}/post`,
    docSeq: `${apiUrl}/post/doc-seq`,
    ideaAllTags: `${apiUrl}/idea/all-tags`,
    allUrls: `${apiUrl}/post/posts_pagination`,
    allArticles: `${apiUrl}/article/all-articles`,
    articleLike: `${apiUrl}/article/like`,
    articleDisLike: `${apiUrl}/article/dislike`,
    articleAddComment: `${apiUrl}/article/add-comment`,
    articleDeleteComment: `${apiUrl}/article/delete-comment`,
    addArticle: `${apiUrl}/article/add-article`,
    allRR: `${apiUrl}/rr/all`,
    rrCreate: `${apiUrl}/rr/create`,
    rrAllGroups: `${apiUrl}/rr/rr-groups-dd`,
    addRRGroup: `${apiUrl}/rr/add-rr-group`,
    rrMasterDD: `${apiUrl}/rr/rr-master-dd`,
    rrLike: `${apiUrl}/rr/like`,
    rrDisLike: `${apiUrl}/rr/dislike`,
    rrById: `${apiUrl}/rr/findbyid`,
    rrAddComment: `${apiUrl}/rr/add-comment`,
    rrDeleteComment: `${apiUrl}/rr/delete-comment`,
    rrDeleteDocument: `${apiUrl}/rr/dlt-document`,
    allQuizzes: `${apiUrl}/quiz/all`,
    addQuiz: `${apiUrl}/quiz`,
    allMessages: `${apiUrl}/md`,
    deleterr: `${apiUrl}/rr/delete-rr`
  },

  make: function (url, method, params, auth, type) {
    let headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    let authHeaders = {
      Authorization: getLocalToken()
    };

    if (auth) {
      headers = {
        ...headers,
        ...authHeaders
      };
    }

    if (method == 'GET') {
      let queryParams = '';
      if (Object.keys(params || {}).length) {
        queryParams = encodeQueryData(params);
      }
      return fetch(url + queryParams, {
        method,
        headers
      })
        .then((response) => response.json())
        .catch((e) => e);
    } else {
      return fetch(url, {
        method,
        headers: type === 'file' ? authHeaders : headers,
        body: type === 'file' ? params : JSON.stringify(params)
      })
        .then((response) => response.json())
        .catch((e) => e);
    }
  },

  get: function (url, params, auth) {
    return apiClient.make(url, 'GET', params, auth);
  },

  post: function (url, params, auth, type) {
    return apiClient.make(url, 'POST', params, auth, type);
  },

  put: function (url, params, auth) {
    return apiClient.make(url, 'PUT', params, auth);
  },

  patch: function (url, params, auth) {
    return apiClient.make(url, 'PATCH', params, auth);
  },

  delete: function (url, params, auth) {
    return apiClient.make(url, 'DELETE', params, auth);
  }
};

export default apiClient;
