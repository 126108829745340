import {
  Col,
  Empty,
  Input,
  Row,
  Space,
  Tag,
  Upload,
  // Button,
  Popconfirm
  // Dropdown,
  // Menu
} from 'antd';
import CustomCard from 'common/CustomCard';
import CustomText from 'common/CustomText';
import CustomButton from 'common/CustomButton';
import UserMenu from 'common/CustomLayout/components/UserMenu';
import styles from './ideas.module.less';
// import { startCase } from 'lodash';
import {
  DeleteFilled,
  FilePdfOutlined,
  InfoCircleOutlined,
  LikeFilled,
  LikeOutlined
  // SendOutlined,
  // TagOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { getLoggedInUser } from 'utilities/helpers';
// import { history } from 'app/history';
import UsersListModal from 'common/UsersListModal';
import CustomInput from 'common/CustomInput';

const { TextArea } = Input;

const IdeasViewPage = ({
  idea,
  handlePostIdeaUpvote,
  handlePostIdeaDownvote,
  showAddImplementation,
  setShowAddImplementation,
  areas,
  selectedAreaId,
  setSelectedAreaId,
  ideasByArea,
  selectedIdeas,
  handleSetSelectedIdeas,
  handleSetImplementValues,
  handleAddImplementation,
  addImplementLoading,
  showLikes,
  setShowLikes,
  likes,
  commentText,
  setCommentText,
  handleAddSuggestionComment,
  deleteSuggestionCommentLoading,
  handleDeleteSuggestionComment
  // tags,
  // handlePostIdeaTag
}) => {
  const upvotesDisplayText = `${
    idea?.Likes?.length ? `${idea.Likes[0].full_name}` : ''
  }${
    idea?.like_count
      ? idea.like_count - 1 > 0
        ? ` and ${idea.like_count - 1} others`
        : ''
      : '0 upvotes'
  }`;
  const { user_details } = getLoggedInUser();
  const loggedInUserId = user_details?.id || null;
  const isAdmin = user_details?.role === 'admin';

  return (
    <CustomCard className={styles.container}>
      <Row gutter={16}>
        <Col span={24}>
          <CustomCard className={`p-3 ${styles.top_container}`}>
            <Space className="w-100" direction="vertical" size={12}>
              <div className={`df-jb-ac ${styles.header_container}`}>
                <Space size={12} align="center">
                  <UserMenu dropdown={false} />
                  <Space direction="vertical" size={0}>
                    <CustomText font="bold" size={15}>
                      {idea?.User?.first_name + ' ' + idea?.User?.last_name}
                    </CustomText>
                    <CustomText color={3}>{`@${idea?.User?.email.slice(
                      0,
                      idea?.User?.email.indexOf('@')
                    )}`}</CustomText>
                    <CustomText color={3}>
                      {moment(idea?.createdAt).format('DD MMM YYYY')}
                    </CustomText>
                  </Space>
                </Space>
                {/* <Button size={'medium'} onClick={() => history.push('/ideas')}>
                  Back
                </Button> */}
                {idea?.status ? (
                  <div className={styles.main_status}>{idea.status}</div>
                ) : null}
              </div>
              <CustomText
                size={16}
                color={2}
              >{`${idea?.Area?.area} / ${idea?.subarea?.sub_area}`}</CustomText>
              <Space align="center" size={0}>
                <CustomText font="bold" size={20} color="primary">
                  {`Idea#${idea?.id}`}
                </CustomText>
                <CustomText font="medium" size={20} color="primary">
                  {idea?.suggestion_title ? `: ${idea.suggestion_title}` : ''}
                </CustomText>
              </Space>
              <Space direction="vertical">
                <CustomText font="medium" size={16} color="primary">
                  {'Problem / Issue:'}
                </CustomText>
                <CustomText color={2}>{idea?.problem}</CustomText>
              </Space>
              <Space direction="vertical">
                <CustomText font="medium" size={16} color="primary">
                  {'Suggested Solution:'}
                </CustomText>
                <CustomText color={2}>
                  {idea?.solution != 'undefined' ? idea?.solution : ''}
                </CustomText>
              </Space>
              <Space direction="vertical">
                <CustomText font="medium" size={16} color="primary">
                  {'Related documents:'}
                </CustomText>
                {idea?.Documents
                  ? idea.Documents.map((item, index) => {
                      return (
                        <a href={item.doc_url} target="_target" key={index}>
                          <Space className={styles.documents}>
                            <FilePdfOutlined />
                            <CustomText>{item.file_name}</CustomText>
                          </Space>
                        </a>
                      );
                    })
                  : ''}
              </Space>
              <Space>
                <div
                  className={styles.like_icon}
                  onClick={() => {
                    if (idea?.is_like) {
                      handlePostIdeaDownvote(idea.id);
                    } else {
                      handlePostIdeaUpvote(idea.id);
                    }
                  }}
                >
                  {idea?.is_like ? <LikeFilled /> : <LikeOutlined />}
                </div>
                <CustomText
                  font="medium"
                  color="primary"
                  className={`${idea?.like_count ? ` cursor-pointer` : ''}`}
                  onClick={() => {
                    if (idea?.like_count) {
                      setShowLikes(true);
                    }
                  }}
                >
                  {upvotesDisplayText}
                </CustomText>
              </Space>
              {/* {isAdmin ? (
                !idea?.Implementation ? (
                  <CustomButton
                    type="primary"
                    onClick={() => setShowAddImplementation(true)}
                  >
                    Accept for implementation
                  </CustomButton>
                ) : null
              ) : (
                ''
              )} */}
              {user_details?.role === 'admin' ? (
                <>
                  {/* <Space direction="horizental">
                    <Dropdown
                      overlay={
                        <Menu>
                          {tags.map((item) => {
                            const { id: tag_id, name } = item;

                            return (
                              <Menu.Item
                                key={tag_id}
                                disabled={idea?.idea_tags_id == tag_id}
                                onClick={() => {
                                  handlePostIdeaTag(idea?.id, tag_id);
                                }}
                              >
                                {startCase(name)}
                              </Menu.Item>
                            );
                          })}
                        </Menu>
                      }
                    >
                      <TagOutlined
                        className={`cursor-pointer ${styles.deletePost}`}
                      />
                    </Dropdown>
                    <Tag color="#ED2024" className="m-0">
                      {startCase(idea?.idea_tag?.name || '')}
                    </Tag>
                  </Space> */}
                </>
              ) : (
                ''
              )}
            </Space>
            {showLikes ? (
              <UsersListModal
                open={showLikes}
                title="Upvotes"
                onCancel={() => setShowLikes(false)}
                data={likes}
              />
            ) : null}
            <div style={{ marginTop: '12px' }}>
              <CustomText font="medium" size={18}>
                Comments
              </CustomText>
            </div>
            <div className="d-flex mt-2">
              <div className="mr-3">
                <UserMenu dropdown={false} />
              </div>
              <div className="w-100">
                <Row>
                  <CustomInput
                    value={commentText || null}
                    textArea={true}
                    placeholder="write your comment"
                    style={{ width: '650px', float: 'right' }}
                    // suffix={
                    //   <SendOutlined
                    //     className={`${styles.send_icon}${
                    //       commentText ? ` ${styles.enabled}` : ''
                    //     }`}
                    //     onClick={() => {
                    //       if (commentText) {
                    //         handleAddSuggestionComment(idea?.id);
                    //       }
                    //     }}
                    //   />
                    // }
                    onChange={(e) => setCommentText(e.target.value)}
                  />
                  <CustomButton
                    type="primary"
                    style={{
                      float: 'right',
                      marginTop: '15px',
                      marginLeft: '10px'
                    }}
                    disabled={!commentText}
                    onClick={() => {
                      if (commentText) {
                        handleAddSuggestionComment(idea?.id);
                      }
                    }}
                  >
                    Post
                  </CustomButton>
                </Row>
                {idea?.comments?.length ? (
                  <div className={`mt-3 ${styles.comments_list_container}`}>
                    {idea.comments.map((item) => {
                      const { id, comment, User_fullname, user_id, createdAt } =
                        item;

                      return (
                        <div
                          key={id}
                          className={`mt-3 ${styles.comments_list_item_container}`}
                        >
                          {/* justify-content-between */}
                          <div className="d-flex ">
                            <Space size={20} align="center">
                              <UserMenu dropdown={false} />
                              <Space direction="vertical" size={0}>
                                <Space
                                  size={10}
                                  align="center"
                                  style={{ marginRight: '472px' }}
                                >
                                  <CustomText font="medium">
                                    {User_fullname}
                                  </CustomText>
                                  {createdAt ? (
                                    <CustomText size={10} color={3}>
                                      {moment(createdAt)
                                        .startOf('hour')
                                        .fromNow()}
                                    </CustomText>
                                  ) : null}
                                </Space>
                                <CustomText size={14} color={2}>
                                  {comment}
                                </CustomText>
                              </Space>
                            </Space>
                            {isAdmin || user_id == loggedInUserId ? (
                              <Popconfirm
                                title="Are you sure you want to delete this Comment?"
                                onConfirm={() => {
                                  handleDeleteSuggestionComment(id);
                                }}
                                okText="Delete"
                                cancelText="No"
                                okButtonProps={{
                                  type: 'danger',
                                  loading: deleteSuggestionCommentLoading
                                }}
                              >
                                <DeleteFilled
                                  className={styles.comment_delete}
                                />
                              </Popconfirm>
                            ) : null}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            </div>
          </CustomCard>
          <div id="comments" className={`mt-3 ${styles.comments_container}`}>
            {/* <CustomText font="medium" size={18}>
              Comments
            </CustomText> */}
            {/* <div className="d-flex mt-2">
              <div className="mr-3">
                <UserMenu dropdown={false} />
              </div>
              <div className="w-100">
                <CustomInput
                  value={commentText || null}
                  placeholder="write your comment"
                  suffix={
                    <SendOutlined
                      className={`${styles.send_icon}${
                        commentText ? ` ${styles.enabled}` : ''
                      }`}
                      onClick={() => {
                        if (commentText) {
                          handleAddSuggestionComment(idea?.id);
                        }
                      }}
                    />
                  }
                  onChange={(e) => setCommentText(e.target.value)}
                />
              </div>
            </div> */}
            {/* {idea?.comments?.length ? (
              <div className={`mt-3 ${styles.comments_list_container}`}>
                {idea.comments.map((item) => {
                  const { id, comment, User_fullname, user_id, createdAt } =
                    item;

                  return (
                    <div
                      key={id}
                      className={`mt-3 ${styles.comments_list_item_container}`}
                    >
                      <div className="d-flex justify-content-between">
                        <Space size={20} align="center">
                          <UserMenu dropdown={false} />
                          <Space direction="vertical" size={0}>
                            <Space size={10} align="center">
                              <CustomText font="medium">
                                {User_fullname}
                              </CustomText>
                              {createdAt ? (
                                <CustomText size={10} color={3}>
                                  {moment(createdAt).startOf('hour').fromNow()}
                                </CustomText>
                              ) : null}
                            </Space>
                            <CustomText size={14} color={2}>
                              {comment}
                            </CustomText>
                          </Space>
                        </Space>
                        {isAdmin || user_id == loggedInUserId ? (
                          <Popconfirm
                            title="Are you sure you want to delete this Comment?"
                            onConfirm={() => {
                              handleDeleteSuggestionComment(id);
                            }}
                            okText="Delete"
                            cancelText="No"
                            okButtonProps={{
                              type: 'danger',
                              loading: deleteSuggestionCommentLoading
                            }}
                          >
                            <DeleteFilled className={styles.comment_delete} />
                          </Popconfirm>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null} */}
          </div>
          {idea?.Implementation ? (
            <CustomCard className="p-3 mt-4">
              <Space direction="vertical" size={20}>
                <CustomText font="medium" size={18}>
                  Implementation Details
                </CustomText>
                {idea?.Implementation?.area ? (
                  <Space>
                    <CustomText font="medium">Tracked idea to</CustomText>
                    <CustomText font="medium" size={11} color={3}>
                      {idea.Implementation.area}
                    </CustomText>
                  </Space>
                ) : null}
                {idea?.Implementation?.realted_suggestions?.length ? (
                  <Space direction="vertical">
                    <CustomText font="medium">
                      Related idea to link this idea:
                    </CustomText>
                    <Space>
                      {idea.Implementation.realted_suggestions
                        .filter((o) => o)
                        .map((item, index) => {
                          return (
                            <Space
                              key={index}
                              align="center"
                              className={styles.realted_suggestion}
                            >
                              <div className={styles.dot} />
                              <CustomText font="medium" size={12} color={2}>
                                {item}
                              </CustomText>
                            </Space>
                          );
                        })}
                    </Space>
                  </Space>
                ) : null}
                {idea?.Implementation?.project_goal ? (
                  <Space direction="vertical">
                    <CustomText font="medium">Project goals</CustomText>
                    <CustomText color={3}>
                      {idea.Implementation.project_goal}
                    </CustomText>
                  </Space>
                ) : null}
                {idea?.Implementation?.project_kpi ? (
                  <Space direction="vertical">
                    <CustomText font="medium">Project Description</CustomText>
                    <CustomText color={3}>
                      {idea.Implementation.project_kpi}
                    </CustomText>
                  </Space>
                ) : null}
                {idea?.Implementation?.Documents?.length ? (
                  <Space direction="vertical">
                    <CustomText font="medium">Documents uploaded</CustomText>
                    <Space wrap>
                      {idea.Implementation.Documents.map((item, index) => {
                        return (
                          <a href={item.doc_url} target="_target" key={index}>
                            <Space className={styles.documents}>
                              <FilePdfOutlined />
                              <CustomText font="medium" size={11} color={2}>
                                {item.file_name}
                              </CustomText>
                            </Space>
                          </a>
                        );
                      })}
                    </Space>
                  </Space>
                ) : null}
              </Space>
            </CustomCard>
          ) : null}
          {showAddImplementation ? (
            <CustomCard className="p-3 mt-4">
              <Space direction="vertical" size={20} className="w-100">
                <CustomText font="medium" size={18}>
                  Implement idea
                </CustomText>
                <Space direction="vertical" size={6}>
                  <CustomText font="medium">
                    Select a track to add this idea to
                  </CustomText>
                  <Space wrap>
                    {areas.map((item) => {
                      const { id, area } = item;

                      return (
                        <CustomCard
                          key={id}
                          className={`${styles.area_container}${
                            selectedAreaId === id ? ` ${styles.active}` : ''
                          }`}
                          onClick={() => setSelectedAreaId(id)}
                        >
                          {area}
                        </CustomCard>
                      );
                    })}
                  </Space>
                </Space>
                <Space direction="vertical" size={6} className="w-100">
                  <CustomText font="medium">
                    Select a related idea to link this idea to:
                  </CustomText>
                  {ideasByArea?.length ? (
                    <Space direction="vertical">
                      {ideasByArea.map((item) => {
                        const { id, problem, status, User } = item;
                        const { first_name, last_name, createdAt } = User;

                        return (
                          <CustomCard
                            key={id}
                            className={`${styles.idea_container}${
                              selectedIdeas.includes(id)
                                ? ` ${styles.active}`
                                : ''
                            }`}
                            onClick={() => {
                              if (selectedIdeas.includes(id)) {
                                handleSetSelectedIdeas('remove', id);
                              } else {
                                handleSetSelectedIdeas('add', id);
                              }
                            }}
                          >
                            <Space direction="vertical" className="w-100">
                              <div className="df-jb-ac">
                                <Space>
                                  <CustomText
                                    font="medium"
                                    size={15}
                                    color={2}
                                  >{`Idea#${id}`}</CustomText>
                                  <CustomText size={15} color={3}>
                                    {problem}
                                  </CustomText>
                                </Space>
                                <Tag
                                  color="green"
                                  className={styles.status_container}
                                >
                                  {status}
                                </Tag>
                              </div>
                              <Space>
                                <CustomText font="medium" size={13}>
                                  {`${first_name || ''} ${
                                    last_name || ''
                                  } • ${moment(createdAt).format(
                                    'DD MMM YYYY'
                                  )}`}
                                </CustomText>
                              </Space>
                            </Space>
                          </CustomCard>
                        );
                      })}
                    </Space>
                  ) : (
                    <Empty
                      className="w-100 text-center"
                      description={
                        <CustomText font="medium" color="primary">
                          No ideas to show. Share one now!
                        </CustomText>
                      }
                    />
                  )}
                </Space>
                <Space direction="vertical" size={6} className="w-100">
                  <CustomText font="medium">Enter project goals</CustomText>
                  <TextArea
                    rows={4}
                    name="project_goal"
                    placeholder="Please enter project goals"
                    onChange={({ target: { name, value } }) =>
                      handleSetImplementValues(name, value)
                    }
                  />
                </Space>
                <Space direction="vertical" size={6} className="w-100">
                  <CustomText font="medium">
                    Describe the KPIs for the project
                  </CustomText>
                  <TextArea
                    rows={4}
                    name="project_kpi"
                    placeholder="Describe the KPI"
                    onChange={({ target: { name, value } }) =>
                      handleSetImplementValues(name, value)
                    }
                  />
                </Space>
                <Space direction="vertical" size={6} className="w-100">
                  <CustomText font="medium">
                    Upload any benchmarks or additional documents
                  </CustomText>
                  <Upload.Dragger
                    multiple={true}
                    maxCount={1}
                    onChange={(info) => {
                      const { status, originFileObj } = info.file;

                      if (status === 'done') {
                        handleSetImplementValues('post_images', originFileObj);
                      }
                    }}
                    customRequest={({ onSuccess }) => {
                      setTimeout(() => {
                        onSuccess('ok');
                      }, 0);
                    }}
                  >
                    <div>
                      <InfoCircleOutlined
                        style={{ fontSize: 16, color: '#000' }}
                      />
                    </div>
                    <Space>
                      <CustomText font="medium" color="primary">
                        Click to upload
                      </CustomText>
                      <CustomText color={2}>or drag and drop</CustomText>
                    </Space>
                  </Upload.Dragger>
                </Space>
              </Space>
              <Row gutter={16}>
                <Col sm={{ span: 6, offset: 12 }} xs={24}>
                  <CustomButton
                    className="w-100 mt-3"
                    onClick={() => setShowAddImplementation(false)}
                  >
                    Cancel
                  </CustomButton>
                </Col>
                <Col sm={6} xs={24}>
                  <CustomButton
                    type="primary"
                    className="w-100 mt-3"
                    onClick={handleAddImplementation}
                    loading={addImplementLoading}
                  >
                    Submit
                  </CustomButton>
                </Col>
              </Row>
            </CustomCard>
          ) : null}
        </Col>
      </Row>
    </CustomCard>
  );
};

export default IdeasViewPage;
